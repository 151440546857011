import * as Yup from "yup";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Stack,
  IconButton,
  InputAdornment,
  Typography,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Iconify from "../../../components/Iconify";
import { FormProvider, RHFTextField } from "../../../components/hook-form";
import axios from "axios";
import SyncLoader from "react-spinners/SyncLoader";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTheme } from "@emotion/react";
// ----------------------------------------------------------------------

export default function AddTestimonial() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [fetchMessage, setFetchMessage] = useState();
  const [positive, setPositive] = useState();
  const [focus, setFocused] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showStartingDate, setShowStartingDate] = useState(false);
  const RegisterSchema = Yup.object().shape({
    student_name: Yup.string().required("Name required"),
    rank: Yup.string().required("Rank is required"),
    year: Yup.string().required("Year required"),
    // photo: Yup.string().required('Photo  required'),
    message: Yup.string().required("Message Required"),
    language: Yup.string().required("Language Required"),
    exam: Yup.string().required("Exam Required"),
  });

  const defaultValues = {
    student_name: "",
    rank: "",
    year: "",
    // photo: '',
    message: "",
    language: "enhi",
    exam: "",
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;
  const [file, setFile] = useState();

  const changeHandler = async (event) => {
    setFile(event.target?.files[0]);
  };
  const onSubmit = async (data) => {
    //console.log(data)
    setLoading(true);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("student_name", data?.student_name);
    formData.append("rank", data?.rank);
    formData.append("year", data?.year);
    formData.append("message", data?.message);
    formData.append("language", data?.language);
    formData.append("exam", data?.exam);
    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${authToken}`,
      },
    };
    axios
      .post(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/addTestimonialDetails`,
        formData,
        config
      )
      .then((response) => {
        // axios.post(`http://localhost:5000/api/v1/adminPanel/addTestimonialDetails`, formData, config).then((response) => {
        //console.log(response?.data);
        if (response?.data?.msg) {
          setLoading(false);
          setShowStartingDate(false);
          reset();
          toast.success("Added Testimonial");
        }
        // window.location.reload()
      })
      .catch((e) => {
        //console.log(e);
      });
  };

  return (
    <>
      {/* {loading ? (<Stack justifyContent='center' alignItems='center' sx={{ height: '60vh' }}> <SyncLoader color={theme.palette.primary.mainTheme}loading={loading} size={20} /></Stack>) */}
      {/* : ( */}
      <Stack spacing={3} px={5}>
        {/* <Typography variant='h3'>Add Testimonial</Typography> */}
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} sx={{ width: "70%" }}>
            <RHFTextField name="student_name" label="Name" />
            <RHFTextField name="exam" label="Exam" select>
              <MenuItem value="IAS">IAS</MenuItem>
              <MenuItem value="PCS">PCS</MenuItem>
            </RHFTextField>

            <Stack direction="row" spacing={3}>
              <RHFTextField name="rank" label="Rank" />
              <RHFTextField
                name="photo"
                label="Photo"
                onChange={changeHandler}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                InputProps={{
                  classes: {
                    input: "CustomTextField",
                  },
                }}
                inputProps={{ accept: "image/*" }}
                helperText="Image Only"
                onClick={() => setShowStartingDate(true)}
                type={showStartingDate || focus ? "file" : "text"}
              />
            </Stack>
            {
            <Stack direction="row" spacing={3}>
                <RHFTextField name="year" label="Year" type="number" />
                
                {/*
                  <RHFTextField name="language" label="Language" select>
                    <MenuItem value="en">English</MenuItem>
                    <MenuItem value="hi">Hindi</MenuItem>
                    <MenuItem value="enhi">Bilingual</MenuItem>
                  </RHFTextField>
            */}
            
            </Stack>
            }
            <RHFTextField
              name="message"
              label="Description"
              multiline
              rows={4}
            />

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
              sx={{
                bgcolor: theme.palette.primary.mainTheme,
                marginTop: "5%",
                "&:hover": { background: "red" },
              }}
            >
              Add
            </LoadingButton>
          </Stack>
        </FormProvider>
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Stack>
      {/* )} */}
    </>
  );
}
