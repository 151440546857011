import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Stack,
  IconButton,
  InputAdornment,
  Typography,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components

import { FormProvider, RHFTextField } from "../../../components/hook-form";
import { ToastContainer, toast } from "react-toastify";
import { useTheme } from "@emotion/react";

// ----------------------------------------------------------------------

export default function AddContact() {
  const theme = useTheme();

  const navigate = useNavigate();
  const [fetchMessage, setFetchMessage] = useState();
  const [positive, setPositive] = useState(false);
  const [users, usersSet] = useState([]);
  const RegisterSchema = Yup.object().shape({
    title: Yup.string().required("Title required"),
    // lastName: Yup.string().required('Last name required'),
    category: Yup.string().required("Category is required"),
    data: Yup.string().required("Data is required"),
    is_active: Yup.boolean().required("Status Required"),
  });

  const defaultValues = {
    title: "",
    category: "",
    data: "",
    is_active: "",
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    // //console.log(data)
    let token = localStorage.getItem("token");
    // //console.log(token);
    // const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6IlRlc3RpbmdAZ21haWwuY29tIiwiaWF0IjoxNjYzOTQzMTc5LCJleHAiOjE2NjM5NDQ5Nzl9.ix9ZXfVEqHrH20-bnMxHQD9c4Z0Z3plfkJWu3YiisKY'
    try {
      const res = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/addcontactdetails`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        }
      );
      const result = await res.json();
      // //console.log(result)
      toast.success(result?.msg);
      if (result?.msg === "Contact details added successfully") {
        setPositive(true);
        reset();
        // navigate('/dashboard', { replaced: true })
      }
    } catch (error) {
      //console.log(error);
    }
    // navigate('/dashboard', { replace: true });
  };
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/getCategory?type=Contact`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      usersSet(responseJson?.data);

      // setLoading(false)
    }

    fetchUsers();
  }, []);

  return (
    <>
      <Stack spacing={3} px={5}>
        {/* <Typography variant='h3'>Add Contact</Typography> */}
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} sx={{ width: "70%" }}>
            <RHFTextField name="title" label="Name" />
            {/* <RHFTextField name="banner_url" label="Banner URL" type='url' /> */}

            <Stack direction="row" spacing={3}>
              <RHFTextField name="category" label="Category" select>
                {users?.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item?.title}>
                      {item?.title}
                    </MenuItem>
                  );
                })}
              </RHFTextField>
              <RHFTextField name="data" label="Data/Link" />
              <RHFTextField name="is_active" label="Status" select>
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </RHFTextField>
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{
                bgcolor: theme.palette.primary.mainTheme,
                marginTop: "5%",
                "&:hover": { background: "red" },
              }}
            >
              Add
            </LoadingButton>
          </Stack>
        </FormProvider>
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {/* <Typography color={positive ? "green" : "red"} sx={{ textAlign: 'center', width: '80%', textTransform: 'capitalize' }}>{fetchMessage}</Typography> */}
      </Stack>
    </>
  );
}
