import * as Yup from "yup";
import { useState, useEffect } from "react";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Stack, Typography, MenuItem, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import { FormProvider, RHFTextField } from "../../../components/hook-form";
import axios from "axios";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// ------------------------------------------------------------------
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTheme } from "@emotion/react";
import { useParams } from "react-router-dom";
export default function UpdateCoupon() {
  const theme = useTheme();
  const { couponCode, couponId, from } = useParams();
  // console.log(couponId);

  const [subject, setSubject] = useState();
  const [loading, setLoading] = useState(false);
  const [starting, setStarting] = useState(dayjs());
  const [msg, setMsg] = useState("");
  const [val, setVal] = useState(false);

  // link
  const [type, setType] = useState("none");
  const [list, setList] = useState([]);
  const [valueId, setValueId] = useState("");

  const handleChange = (newValue) => {
    setStarting(newValue);
  };

  const [couponDetails, setCouponDetails] = useState();
  const [values, setValues] = useState({});

  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchDetails() {
      const fullResponse = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/getCouponById/${couponId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      // console.log(responseJson?.data);
      setCouponDetails(responseJson?.data);
      checkDetails(responseJson?.data);
    }

    fetchDetails();

    setLoading(false);
  }, []);

  const checkDetails = (couponDetails) => {
    setValues((prev) => ({
      ...prev,
      couponAccess: couponDetails?.couponAccess,
      couponCode: couponDetails?.couponCode,
      couponType: couponDetails?.couponType,
      couponValue: couponDetails?.couponValue,
      is_active: couponDetails?.is_active,
      expirationDate: couponDetails?.expirationDate,
      valueId: couponDetails?.linkWith,
      link: couponDetails?.link,
      linkWith: couponDetails?.linkWith,
    }));
    setType(couponDetails?.link);
  };
  useEffect(() => {
    if (type !== "" && type !== "none") {
      let urlVariable = "";
      if (type == "batch") {
        urlVariable = "adminPanel/getBatchesDetails";
      } else if (type == "testSeries") {
        urlVariable = "adminPanel/getallTestSeriesdetails";
      } else if (type == "product") {
        urlVariable = "store/getAllStoreProduct";
      } else {
        urlVariable = "store/getAllStoreProductCategory";
      }
      const token = localStorage.getItem("token");
      async function fetchDetails() {
        const fullResponse = await fetch(
          `https://api-prod.gyaankulam.com/api/v1/${urlVariable}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const responseJson = await fullResponse.json();
        setList(responseJson?.data);
      }
      fetchDetails();
    }
    setLoading(false);
  }, [type]);

  const onChange2 = (e) => {
    const { name, value } = e.target;
    setValues((prev) => ({ ...prev, [name]: value }));
    if (name == "link" && value == "none") {
      setType(value);
      // setValueId("");
      // setId("");
      defaultValues.linkWith = "";
      // console.log(defaultValues);
      setValues((prev) => ({ ...prev, ["valueId"]: "" }));
    }
    if (name == "link" && value != "none") {
      setType(value);
      setValues((prev) => ({ ...prev, ["valueId"]: "" }));
    }
    // debugger;
    if (name == "valueId" && (value != "" || typeof value != "undefined")) {
      setValues((prev) => ({ ...prev, ["valueId"]: value }));
    }
  };

  // console.log(values);
  console.log(from);
  const RegisterSchema = Yup.object().shape({});
  const defaultValues = {
    couponCode: values.couponCode,
    couponType: values.couponType,
    couponValue: values.couponValue,
    link: values.link,
    valueId: valueId,
    couponAccess: values.couponAccess,
    is_active: values.is_active,
  };
  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });
  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;
  const onSubmit = async (data) => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    const reqObject = {
      // ...data,
      couponCode: values.couponCode,
      couponType: values.couponType,
      couponValue: values.couponValue,
      link: values.link,
      // valueId:valueId,
      linkWith: valueId,
      couponAccess: values.couponAccess,
      is_active: values.is_active,

      expirationDate: starting?.$d,
    };
    // console.log(reqObject);
    axios
      .put(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/editCoupon/${couponId}`,
        reqObject,
        config
      )
      .then((response) => {
        // debugger;
        // console.log(response?.data);
        if (response?.data?.status == true) {
          toast.success(response?.data?.msg);
          setVal(false);
          setMsg("");
          setLoading(false);
          reset();

          if (from == "allCoupon") {
            window.location.href = "/dashboard/allCoupon";
          } else {
            window.location.href = "/dashboard/storeCoupon";
          }
          // window.location.href = '/dashboard/allCoupon';
        } else {
          toast.error(response?.data?.msg);
          setLoading(false);
        }
      })
      .catch((e) => {
        // console.log(e);
      });
  };
  return (
    <>
      <Stack spacing={3} px={5}>
        <Typography variant="h3">
          Update Coupon- <strong style={{ color: "blue" }}>{couponCode}</strong>
        </Typography>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} sx={{ width: "70%" }}>
            <Stack direction="row" spacing={3}>
              <RHFTextField
                name="couponCode"
                value={values?.couponCode}
                onChange={onChange2}
              />
              <RHFTextField
                name="couponType"
                label={
                  values?.couponType == "percentage" ? "Percentage" : "Fixed"
                }
                value={values?.couponType}
                onChange={onChange2}
                select
              >
                <MenuItem value="percentage">Percentage (%)</MenuItem>
                <MenuItem value="fixed">Fixed</MenuItem>
              </RHFTextField>
            </Stack>
            <Stack direction="row" spacing={3}>
              <RHFTextField
                name="link"
                value={type}
                type="checkbox"
                onChange={onChange2}
                select
              >
                <MenuItem value="none" onClick={() => setType("none")}>
                  None
                </MenuItem>
                <MenuItem value="batch" onClick={() => setType("batch")}>
                  Batch
                </MenuItem>
                <MenuItem
                  value="testSeries"
                  onClick={() => setType("testSeries")}
                >
                  Test Series
                </MenuItem>
                <MenuItem value="product" onClick={() => setType("product")}>
                  Product
                </MenuItem>
                <MenuItem
                  value="productCategory"
                  onClick={() => setType("productCategory")}
                >
                  Product Category
                </MenuItem>
              </RHFTextField>
              {type !== "none" && type !== "" && (
                <RHFTextField
                  name="valueId"
                  label="Link With"
                  value={values.valueId}
                  onChange={onChange2}
                  select
                >
                  {list?.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item?._id}
                      onClick={() => setValueId(item?._id)}
                    >
                      {/* {type == "batch"
                                            ? item?.batch_name
                                            : type == "testSeries" ? item?.testseries_name : item?.title} */}
                      {type == "batch" && item?.batch_name}
                      {type == "testSeries" && item?.testseries_name}
                      {type == "product" && item?.title}
                      {type == "productCategory" && item?.title}
                    </MenuItem>
                  ))}
                </RHFTextField>
              )}
            </Stack>
            <Stack direction="row" spacing={3}>
              <RHFTextField
                name="couponValue"
                value={values.couponValue}
                onChange={onChange2}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack
                  direction="row"
                  spacing={3}
                  sx={{ width: "100%", justifyContent: "space-between" }}
                >
                  <DateTimePicker
                    label="Expire At"
                    // value={starting.$d}
                    value={values.expirationDate}
                    onChange={handleChange}
                    // name="starting_date"
                    renderInput={(params) => (
                      <TextField {...params} sx={{ width: "100%" }} />
                    )}
                  />
                </Stack>
              </LocalizationProvider>
            </Stack>
            <Stack direction="row" spacing={3}>
              <RHFTextField
                name="couponAccess"
                label={values?.couponAccess == "all" ? "All" : "Single"}
                value={values?.couponAccess}
                onChange={onChange2}
                select
              >
                <MenuItem value="all">All</MenuItem>
                {/* <MenuItem value="single">Single</MenuItem> */}
              </RHFTextField>
              <RHFTextField
                name="is_active"
                label={values?.is_active ? "Active" : "Inactive"}
                value={values?.is_active}
                onChange={onChange2}
                select
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </RHFTextField>
            </Stack>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
              sx={{
                bgcolor: theme.palette.primary.mainTheme,
                marginTop: "5%",
                "&:hover": { background: "red" },
              }}
              onClick={() => {
                setVal(true);
                setMsg("Required");
              }}
            >
              Add
            </LoadingButton>
          </Stack>
        </FormProvider>
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {/* <Typography color={positive ? "green" : "red"} sx={{ textAlign: 'center', width: '70%', textTransform: 'capitalize' }}>{fetchMessage}</Typography> */}
      </Stack>
    </>
  );
}
