import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Page from "../../../components/Page";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import "../user/students.css";
import StudentCard from "../user/Components/StudentCard";
import { useParams } from "react-router-dom";
import BatchCard from "../user/Components/BatchCard";
import PracticesCard from "./Components/PracticesCard";
import SpecialClasses from "./Components/SpecialClasses";

//Icon Style
const iconStyle = {
  background: "rgba(255, 122, 130, 0.2)",
  padding: "5px",
  color: "rgba(249, 83, 104, 1)",
  borderRadius: "50%",
  cursor: "pointer",
};
const TeacherProfile = () => {
  //States
  const [value, setValue] = useState(0);
  const [valueTwo, setValueTwo] = useState(0);
  const [valueThree, setValueThree] = useState(0);

  //States
  const [disableName, setDisableName] = useState("left");
  const [disableNameTwo, setDisableNameTwo] = useState("left");
  const [disableNameThree, setDisableNameThree] = useState("left");

  //States
  const [slider, setslider] = useState(0);
  const [sliderTwo, setsliderTwo] = useState(0);
  const [sliderThree, setsliderThree] = useState(0);

  const sampleArray = [{ no: 1 }, { no: 1 }, { no: 1 }, { no: 1 }, { no: 1 }];

  //Scroll Logic
  const handleScroll = (direction) => {
    if (users?.BatchesTeacherIn[0].length - 3 == slider) {
      setDisableName("right");
    }
    if (slider == 0) {
      setDisableName("left");
    }
    if (direction == "left" && slider > 0) {
      setDisableName("none");
      setslider(slider - 1);
      setValue(value + 251);
    } else if (
      direction == "right" &&
      slider < users?.BatchesTeacherIn[0].length - 3
    ) {
      setDisableName("none");
      setslider(slider + 1);
      setValue(value - 251);
    }
  };
  const handleScrollTwo = (direction) => {
    if (sampleArray.length - 3 == sliderTwo) {
      setDisableNameTwo("right");
    }
    if (sliderTwo == 0) {
      setDisableNameTwo("left");
    }
    if (direction == "left" && sliderTwo > 0) {
      setDisableNameTwo("none");
      setsliderTwo(sliderTwo - 1);
      setValueTwo(valueTwo + 251);
    } else if (direction == "right" && sliderTwo < sampleArray.length - 3) {
      setDisableNameTwo("none");
      setsliderTwo(sliderTwo + 1);
      setValueTwo(valueTwo - 251);
    }
  };
  const handleScrollThree = (direction) => {
    if (sampleArray.length - 3 == sliderThree) {
      setDisableNameThree("right");
    }
    if (sliderThree == 0) {
      setDisableNameThree("left");
    }
    if (direction == "left" && sliderThree > 0) {
      setDisableNameThree("none");
      setsliderThree(sliderThree - 1);
      setValueThree(valueThree + 251);
    } else if (direction == "right" && sliderThree < sampleArray.length - 3) {
      setDisableNameThree("none");
      setsliderThree(sliderThree + 1);
      setValueThree(valueThree - 251);
    }
  };

  //Id For Teacher
  const { data } = useParams();

  //Fetching Data
  const [users, usersSet] = useState();
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminTeacher/getTeacherDetails/${data}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      usersSet(responseJson?.data);
    }

    fetchUsers();
  }, []);
  //console.log(users)
  // const item = [{ batch_name: 'IAS-PRE' }]
  return (
    <Page title="Student Profile">
      <Grid container spacing={1} sx={{ height: "80vh" }}>
        <Grid item xs={12} sm={12} md={8} sx={{ height: "100%" }}>
          <Stack sx={{ height: "100%" }}>
            <Box>
              <Stack
                direction="row"
                justifyContent="space-between"
                pl={1}
                pr={2}
              >
                <Typography fontSize="medium" sx={{ fontWeight: "600" }}>
                  Batches
                </Typography>
                {/* <Typography color='#F95368' fontSize='small'>
                                    See All
                                </Typography> */}
              </Stack>
              <Box sx={{ minWidth: "100%", minHeight: "130px" }}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ minWidth: "100%", minHeight: "130px" }}
                >
                  <ArrowBackIosOutlinedIcon
                    sx={{
                      ...iconStyle,
                      color:
                        disableName == "left"
                          ? "lightgray"
                          : "rgba(249, 83, 104, 1)",
                    }}
                    onClick={() => handleScroll("left")}
                  />
                  <Stack
                    direction="row"
                    spacing={3}
                    pl={1}
                    pr={1}
                    pb={1}
                    mt={1}
                    className="BatchScroll"
                    sx={{ minWidth: "92%" }}
                  >
                    {users?.BatchesTeacherIn[0]?.map((item, i) => {
                      //console.log(item?.length)
                      return <BatchCard value={value} batch={item} />;
                    })}
                    {users?.BatchesTeacherIn.length == 0 && (
                      <BatchCard value={value} batch={null} title="No Batch" />
                    )}
                  </Stack>

                  <ArrowForwardIosOutlinedIcon
                    sx={{
                      ...iconStyle,
                      color:
                        disableName == "right"
                          ? "lightgray"
                          : "rgba(249, 83, 104, 1)",
                    }}
                    onClick={() => handleScroll("right")}
                  />
                </Stack>
              </Box>
            </Box>
            <Box sx={{ minWidth: "100%" }}>
              <Typography
                mt={1}
                pl={1}
                fontSize="medium"
                sx={{ fontWeight: "600" }}
              >
                Practices
              </Typography>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ minWidth: "100%" }}
              >
                <ArrowBackIosOutlinedIcon
                  sx={{
                    ...iconStyle,
                    color:
                      disableNameTwo == "left"
                        ? "lightgray"
                        : "rgba(249, 83, 104, 1)",
                  }}
                  onClick={() => handleScrollTwo("left")}
                />
                <Stack
                  direction="row"
                  spacing={3}
                  pl={1}
                  pr={1}
                  mt={1}
                  pb={1}
                  className="BatchScroll"
                  sx={{ minWidth: "75%" }}
                >
                  <PracticesCard value={valueTwo} />
                  <PracticesCard value={valueTwo} />
                  <PracticesCard value={valueTwo} />
                  <PracticesCard value={valueTwo} />
                  <PracticesCard value={valueTwo} />
                </Stack>
                <ArrowForwardIosOutlinedIcon
                  sx={{
                    ...iconStyle,
                    color:
                      disableNameTwo == "right"
                        ? "lightgray"
                        : "rgba(249, 83, 104, 1)",
                  }}
                  onClick={() => handleScrollTwo("right")}
                />
              </Stack>
            </Box>
            <Box sx={{ minWidth: "100%" }}>
              <Typography
                mt={1}
                pl={1}
                fontSize="medium"
                sx={{ fontWeight: "600" }}
              >
                Special Classes
              </Typography>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ minWidth: "100%" }}
              >
                <ArrowBackIosOutlinedIcon
                  sx={{
                    ...iconStyle,
                    color:
                      disableNameThree == "left"
                        ? "lightgray"
                        : "rgba(249, 83, 104, 1)",
                  }}
                  onClick={() => handleScrollThree("left")}
                />
                <Stack
                  direction="row"
                  spacing={3}
                  pl={1}
                  pr={1}
                  pb={1}
                  mt={1}
                  className="BatchScroll"
                  sx={{ minWidth: "75%" }}
                >
                  <SpecialClasses value={valueThree} />
                  <SpecialClasses value={valueThree} />
                  <SpecialClasses value={valueThree} />
                  <SpecialClasses value={valueThree} />
                </Stack>
                <ArrowForwardIosOutlinedIcon
                  sx={{
                    ...iconStyle,
                    color:
                      disableNameThree == "right"
                        ? "lightgray"
                        : "rgba(249, 83, 104, 1)",
                  }}
                  onClick={() => handleScrollThree("right")}
                />
              </Stack>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={4} sx={{ height: "100%" }}>
          <StudentCard detail={users?.TeacherDetails} role="Teacher" />
        </Grid>
      </Grid>
    </Page>
  );
};

export default TeacherProfile;
