import React, { useEffect } from "react";
import * as Yup from "yup";
import { useState } from "react";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Stack, Typography, MenuItem, Select, InputLabel } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { FormProvider } from "../../../../components/hook-form";

import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Page from "../../../../components/Page";
import keyValueTab from "../../../../data/tabArray";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const tabsArray = [
  {
    name: "All Batches",
  },
  {
    name: "Add Batch",
  },
  {
    name: "All Test Series",
  },
  {
    name: "Add Test Series",
  },
  {
    name: "All Quiz",
  },
  {
    name: "Add Quiz",
  },
  {
    name: "Assignment",
  },
  {
    name: "Help and Support",
  },
  {
    name: "Recorded Video",
  },
  {
    name: "Pathyakram",
  },
  {
    name: "Notes",
  },
  {
    name: "AIR",
  },
  {
    name: "News Clip",
  },
  {
    name: "Teacher Generated Content Of Batch",
  },
];

function getStyles(name, teacher, theme) {
  return {
    fontWeight:
      teacher?.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const UpdatePermission = () => {
  // console.log('line113', keyValueTab)

  const [allteachers, teacherSet] = useState([]);
  const [teacher, setteacher] = React.useState([]);
  const navigate = useNavigate();
  const { batchId } = useParams();
  const theme = useTheme();
  const LoginSchema = Yup.object().shape({});

  const defaultValues = {};

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });
  //console.log(teacher)

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  let b;
  const func = () => {};
  func();
  //console.log(b)

  //Assign Teachers
  const [loading, setLoading] = useState(false);
  const onSubmit = async (data) => {
    // //console.log(data);

    setLoading(true);

    let token = localStorage.getItem("token");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    let obj = {
      tabs: teacher,
    };

    axios
      .put(
        `https://api-prod.gyaankulam.com/api/v1/adminTeacher/updateTheTeacherPermission/${teacherId}`,
        obj,
        config
      )
      .then((response) => {
        if (
          response?.data?.msg == "Teacher permissions is succefully updated"
        ) {
          reset();
          setLoading(false);
          toast.success("Teacher permissions is succefully updated");
          setTimeout(() => {
            navigate("/dashboard/staff");
          }, 1000);
        }
      })
      .catch((e) => {
        //console.log(e);
      });
  };

  //Id For Teacher
  const { teacherId } = useParams();

  //Fetching Data

  const [users, usersSet] = useState();
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminTeacher/getTeacherDetails/${teacherId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      usersSet(responseJson?.data);

      check(responseJson?.data);
    }

    fetchUsers();
  }, []);
  let helperArray = [];
  const check = (tabsDetails) => {
    setteacher(tabsDetails?.TeacherDetails?.accessToContent);

    // helperArray = keyValueTab?.keyValueTab?.map((item, index) => {
    //     if (teacher?.includes(item?.value)) {
    //         return item?.label;
    //     }

    // })
    console.log("line218", helperArray);
  };
  // console.log('line213', teacher)
  // console.log('line214', keyValueTab?.keyValueTab)

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setteacher(
      // On autofill we get a stringified value?.
      typeof value === "string" ? value?.split(",") : value
    );
  };

  // console.log(users?.TeacherDetails?.Role)

  return (
    <>
      <Page title="Batch">
        <Stack spacing={3} ml={5}>
          <Typography variant="h3">Update Staff's Permission </Typography>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack direction="row" sx={{ width: "40%" }}>
              <InputLabel
                sx={{
                  minWidth: "40%",
                  maxWidth: "40%",
                  marginRight: "20px",
                  alignSelf: "center",
                }}
              >
                Add Permissions{" "}
              </InputLabel>
              <Select
                label=""
                multiple
                name="teacher"
                value={teacher}
                onChange={handleChange}
                sx={{
                  maxWidth: "100%",
                  minWidth: "100%",
                  color: "black",
                  zIndex: 9,
                }}
                MenuProps={MenuProps}
              >
                {users?.TeacherDetails?.Role == "teacher"
                  ? keyValueTab?.keyValueTabForTeacher?.map((name) => (
                      <MenuItem
                        key={name}
                        value={name?.value}
                        // style={getStyles(name, teacher, theme)}
                      >
                        {name.label}
                      </MenuItem>
                    ))
                  : keyValueTab?.keyValueTab?.map((name) => (
                      <MenuItem
                        key={name}
                        value={name?.value}
                        // style={getStyles(name, teacher, theme)}
                      >
                        {name.label}
                      </MenuItem>
                    ))}
              </Select>
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
              sx={{
                bgcolor: theme.palette.primary.mainTheme,
                width: "58%",
                marginTop: "2%",
                "&:hover": { background: "red" },
              }}
            >
              Update
            </LoadingButton>
          </FormProvider>
          {/* <Typography color={positive ? 'green' : "red"} sx={{ textAlign: 'center', textTransform: 'capitalize', width: '40%' }}>{fetchMessage}</Typography> */}
          <ToastContainer
            position="bottom-center"
            autoClose={1000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </Stack>
      </Page>
    </>
  );
};

export default UpdatePermission;
