import React, { useEffect } from "react";
import * as Yup from "yup";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import axios from "axios";

// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Stack,
  Typography,
  MenuItem,
  IconButton,
  Grid,
  InputLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import { FormProvider, RHFTextField } from "../../../components/hook-form";

import RHFTextEditor from "../../../components/hook-form/RHFTextEditor";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MuiChipsInput } from "mui-chips-input";
import { useTheme } from "@emotion/react";
const UpdateBatchCategory = () => {
  const theme = useTheme();
  const { id } = useParams();
  const navigate = useNavigate();
  const [focus, setFocused] = React.useState(false);
  const [loading, setLoading] = useState(false);

  const [val, setVal] = useState("");
  const [restrict, setRestrict] = useState("");
  const [pdf, setPdf] = useState(false);
  const [msg, setMsg] = useState("");
  const [showImage, setShowImage] = useState(false);
  const [file, setFile] = useState();
  const [desc, setDesc] = useState();
  const [seoDesc, setSeoDesc] = useState();
  const changeHandler = async (event) => {
    setFile(event.target?.files[0]);
    var fileName = document.getElementById("file").value?.toLowerCase();
    if (fileName.endsWith(".pdf") || fileName.endsWith(".jpg")) {
      setRestrict("");
    } else {
      setRestrict("JPG Or PDF");
    }
  };
  const [chips, setChips] = useState([]);
  const handleChangeChips = (newChips) => {
    setChips(newChips);
  };
  const handleDelete = () => {
    setShowImage(false);
    setFile("");
  };
  const LoginSchema = Yup.object().shape({});
  const defaultValues = {};
  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const [value, setValue] = useState();
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/getCategoryById/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();

      setValue((prev) => ({
        ...prev,
        title: responseJson?.data?.title,
        tags: responseJson?.data?.tags ?? [],
        icon: responseJson?.data?.icon ?? "",
        slug: responseJson?.data?.slug ?? "",
        is_active: responseJson?.data?.is_active,
        type: responseJson?.data?.type,
        metaTitle: responseJson?.data?.metaTitle,
        metaDesc: responseJson?.data?.metaDesc,
        seoSlug: responseJson?.data?.seoSlug,
        seoMetaTitle: responseJson?.data?.seoMetaTitle,
        seoMetaDesc: responseJson?.data?.seoMetaDesc,
      }));
      setChips(responseJson?.data?.tags);
      setDesc(responseJson?.data?.metaDesc);
      setSeoDesc(responseJson?.data?.seoMetaDesc);
      setShowImage(true);
    }
    fetchUsers();
  }, []);

  const handleChangeDesc = (value) => {
    setDesc(value);
  };
  const handleChangeSeoDesc = (value) => {
    setSeoDesc(value);
  };

  const onSubmit = async (data) => {
    setLoading(true);
    const formData = new FormData();
    if (file) {
      formData.append("file", file);
    }

    formData.append("title", value?.title);
    chips?.length > 0 &&
      chips?.map((item) => {
        formData.append("tags", item);
      });
    formData.append("is_active", value?.is_active);
    formData.append("metaTitle", value?.metaTitle);
    formData.append("metaDesc", desc);
    formData.append("slug", value?.slug);
    formData.append("seoMetaTitle", value?.seoMetaTitle);
    formData.append("seoMetaDesc", seoDesc);
    formData.append("seoSlug", value?.seoSlug);
    formData.append("type", value?.type);

    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${authToken}`,
      },
    };
    axios
      .put(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/updatedCategorialDetails/${id}`,
        formData,
        config
      )
      .then((response) => {
        if (response?.data?.status == true) {
          reset();
          setLoading(false);
          toast.success(response?.data?.msg);

          setTimeout(() => {
            navigate(`/dashboard/category`);
            // window.location.reload();
          }, 1500);
        } else {
          toast.error(response?.data?.msg);
          setLoading(false);
        }
      })
      .catch((e) => {
        //console.log(e);
      });
  };

  const onChange2 = (e) => {
    const { name, value } = e.target;
    setValue((prev) => ({ ...prev, [name]: value }));
  };
  console.log(value);
  return (
    <Grid direction="row" pl={5}>
      <Typography variant="h2" m={2}>
        Update Category - {value?.title}
      </Typography>

      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3} sx={{ width: "70%" }}>
          <Stack direction="row">
            <InputLabel
              sx={{ width: "20%", marginRight: "20px", alignSelf: "center" }}
            >
              Title :{" "}
            </InputLabel>
            <RHFTextField
              name="title"
              value={value?.title}
              onChange={onChange2}
            />
          </Stack>
          {/*
            <Stack direction="row">
              <InputLabel
                sx={{ width: "20%", marginRight: "20px", alignSelf: "center" }}
              >
                Slug :{" "}
              </InputLabel>
              <RHFTextField
                name="slug"
                value={value?.slug}
                onChange={onChange2}
              />
            </Stack>
  */ }

          <Stack direction="row" spacing={3}>
            {showImage ? (
              <Stack
                direction="column"
                sx={{ width: "100%", position: "relative" }}
                alignItems="center"
                spacing={1}
              >
                <IconButton
                  sx={{ position: "absolute", top: "-10px", right: "-10px" }}
                  onClick={() => handleDelete()}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
                <img
                  src={value?.icon}
                  alt="CategoryIcon"
                  style={{ height: "100px", width: "200px" }}
                />
              </Stack>
            ) : (
              <RHFTextField
                name="file"
                onChange={changeHandler}
                id="file"
                label="Select image"
                error={!file && val}
                helperText="Image Only ( Maximum allowed size 100KB)"
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                // inputProps={{ multiple: true }}
                InputProps={{
                  classes: {
                    input: "CustomTextField",
                  },
                }}
                onClick={() => setPdf(true)}
                inputProps={{ accept: "image/*" }}
                type={pdf || focus ? "file" : "text"}
              />
            )}

            {/*
              <RHFTextField
                name="type"
                label="Type"
                value={value?.type}
                onChange={onChange2}
                select
              >
                <MenuItem value="Contact">Contact</MenuItem>
                <MenuItem value="Stream">Stream</MenuItem>
              </RHFTextField>
          */ }
          </Stack>

          {/*
            <Stack direction="row">
              <InputLabel
                sx={{ width: "20%", marginRight: "20px", alignSelf: "center" }}
              >
                Meta Title
              </InputLabel>
              <RHFTextField
                name="metaTitle"
                value={value?.metaTitle}
                onChange={onChange2}
              />
            </Stack>
          */ }
          {/*
            <Stack direction="row">
              <InputLabel
                sx={{ width: "20%", marginRight: "20px", alignSelf: "center" }}
              >
                SEO Meta Title
              </InputLabel>
              <RHFTextField
                name="seoMetaTitle"
                value={value?.seoMetaTitle}
                onChange={onChange2}
              />
            </Stack>
        */}
          {/*
            <Stack direction="row">
            
              <InputLabel
                sx={{ width: "20%", marginRight: "20px", alignSelf: "center" }}
              >
                SEO Slug
              </InputLabel>
              <RHFTextField
                name="seoSlug"
                value={value?.seoSlug}
                onChange={onChange2}
              />
            </Stack>
          */ }
          {/*
            <RHFTextEditor
              name="metaDesc"
              label="Meta Description"
              value={desc}
              onChange={handleChangeDesc}
            />
          */ }
          {/*
            <RHFTextEditor
              name="seoMetaDesc"
              label="SEO Meta Description"
              value={seoDesc}
              onChange={handleChangeSeoDesc}
            />
        */ }
          <MuiChipsInput
            style={{ width: "100%" }}
            value={chips}
            onChange={handleChangeChips}
            placeholder="Enter Tags"
          />
          

          <Stack spacing={3} direction="row">
            <RHFTextField
              name="is_active"
              label={value?.is_active ? "Active" : "Inactive"}
              sx={{ width: "100%" }}
              select
              type="checkbox"
              onChange={onChange2}
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </RHFTextField>
          </Stack>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={loading}
          sx={{
            bgcolor: theme.palette.primary.mainTheme,
            width: "70%",
            marginTop: "5%",
            "&:hover": { background: "red" },
          }}
        >
          Update
        </LoadingButton>
      </FormProvider>
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* <Typography color={positive ? 'green' : "red"} mt={3} sx={{ textAlign: 'center', width: '70%', textTransform: 'capitalize' }}>{fetchMessage}</Typography> */}
    </Grid>
  );
};

export default UpdateBatchCategory;
