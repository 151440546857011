import { filter } from "lodash";
// import { sentenceCase } from 'change-case';
import { useEffect, useState } from "react";
// import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  // Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Button,
} from "@mui/material";
// components
import TableHead from "@mui/material/TableHead";

import Scrollbar from "../../../components/Scrollbar";

import SearchNotFound from "../../../components/SearchNotFound";
import {
  UserListHead,
  UserListToolbar,
  UserMoreMenu,
} from "../../../sections/@dashboard/user";
// mock
// importusers ? from '../../../_mock/user';
import { useNavigate, useParams } from "react-router-dom";
import SyncLoader from "react-spinners/SyncLoader";
import Page from "../../../components/Page";

import { ToastContainer, toast } from "react-toastify";
// for Dialog box
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import axios from "axios"; // for axios request

import "./test.css";
import { CSVLink } from "react-csv"; // for CSV file download
import { useTheme } from "@emotion/react";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  // { id: '', label: 'Selected', alignRight: false },
  { id: "", label: "S.No", alignRight: false },
  { id: "FullName", label: "Name", alignRight: false },
  { id: "mobileNumber", label: "Phone No.", alignRight: false },
  { id: "email", label: "Email", alignRight: false },
  { id: "username", label: "Username", alignRight: false },
  { id: "", label: "Action", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => {
      // console.log(_user);
      const studentNameMatch = _user?.StudentName?.toLowerCase()?.includes(
        query.toLowerCase()
      );
      const emailMatch = _user?.email
        ?.toLowerCase()
        ?.includes(query.toLowerCase());
      const phoneMatch = _user?.mobileNumber
        ?.toLowerCase()
        ?.includes(query.toLowerCase());
      return studentNameMatch || emailMatch || phoneMatch;
    });
  }
  return stabilizedThis?.map((el) => el[0]);
}

function applySortFilter2(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => {
      // console.log(_user);
      const studentNameMatch = _user?.user?.FullName?.toLowerCase()?.includes(
        query.toLowerCase()
      );
      const emailMatch = _user?.user?.email
        ?.toLowerCase()
        ?.includes(query.toLowerCase());
      const phoneMatch = _user?.user?.mobileNumber
        ?.toLowerCase()
        ?.includes(query.toLowerCase());
      return studentNameMatch || emailMatch || phoneMatch;
    });
  }
  return stabilizedThis?.map((el) => el[0]);
}

export default function StudentTestSeriesList() {
  const theme = useTheme();
  // Code for handling Dialog box
  const [showDownload, setShowDownload] = useState(false);
  const [download, setDownload] = useState(true);
  const [statusUser, setStatusUser] = useState();
  const [statusStaus, setStatusStatus] = useState();
  const [errorOTP, setErrorOtp] = useState();
  const [open1, setOpen1] = useState(false);
  const handleClickOpen1 = () => {
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  const sendOtp = () => {
    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    const data = {
      action: "Exporting All Student Data",
    };

    axios
      .post(
        `https://api-prod.gyaankulam.com/api/v1/adminTeacher/send-otp`,
        data,
        config
      )
      .then((response) => {})
      .catch((e) => {
        //console.log(e);
      });
  };
  const verifyOtp = () => {
    //console.log(document.getElementById('name').value);
    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    const data = {
      otp: document.getElementById("name").value,
    };

    axios
      .post(
        `https://api-prod.gyaankulam.com/api/v1/adminTeacher/verify-otp`,
        data,
        config
      )
      .then((response) => {
        // //console.log(response);
        if (response?.data?.status == false) {
          setErrorOtp("Invalid Otp");
        } else {
          if (showDownload) {
            setShowDownload(false);
            setDownload(false);
          } else {
            //console.log("status");
            //console.log(statusStaus);
            const data1 = {
              isActive: !statusStaus,
            };
            axios
              .post(
                `https://api-prod.gyaankulam.com/api/v1/adminTeacher/activeInactiveStudent/${statusUser}`,
                data1,
                config
              )
              .then((response) => {
                //console.log(response);
              });

            handleClose();

            toast.success("Status Updated");
            setTimeout(() => window.location.reload(), 1000);

            setErrorOtp("");
          }
        }
      })
      .catch((e) => {
        //console.log(e);
      });
  };

  // For handling Dialong box
  const [allStudents, setAllStudents] = useState([]);
  const [page1, setPage1] = useState(0);
  const [rowsPerPage1, setRowsPerPage1] = useState(10);
  const [open, setOpen] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState([]);
  const [filterStudent, setFilterStudent] = useState("");
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChangePage1 = (event, newPage) => {
    setPage1(newPage);
  };

  const handleChangeRowsPerPage1 = (event) => {
    setRowsPerPage1(parseInt(event.target?.value, 10));
    setPage1(0);
  };

  const handleCheck = (event, id) => {
    const selectedIndex1 = selectedStudent.indexOf(id);
    let newSelected = [];
    if (selectedIndex1 === -1) {
      newSelected = newSelected.concat(selectedStudent, id);
    } else if (selectedIndex1 === 0) {
      newSelected = newSelected.concat(selectedStudent.slice(1));
    } else if (selectedIndex1 === selected?.length - 1) {
      newSelected = newSelected.concat(selectedStudent.slice(0, -1));
    } else if (selectedIndex1 > 0) {
      newSelected = newSelected.concat(
        selectedStudent.slice(0, selectedIndex1),
        selectedStudent.slice(selectedIndex1 + 1)
      );
    }
    setSelectedStudent(newSelected);
  };

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();
  const [users, usersSet] = useState([]);
  const [loading, setLoading] = useState(true);

  // for Dialong box All Students
  const filteredStudents = applySortFilter(
    allStudents ? allStudents : ["no"],
    getComparator(order, orderBy),
    filterStudent
  );
  const handleFilterStudentByName = (event) => {
    setFilterStudent(event.target?.value);
  };

  //Batch Id
  const { testSeriesId, active } = useParams();
  //console.log(testSeriesId)

  //Getting Details
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/getStudentsOfTestSeries/${testSeriesId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      // debugger;
      let data = responseJson?.data?.filter((item) => item?.user != null);
      // usersSet(responseJson?.data);
      usersSet(data);
      // console.log("309"+responseJson?.data);
      // console.log(users)
      setLoading(false);
      if (responseJson?.msg === "not an admin") {
        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("details");
        navigate("/");
      }
    }

    fetchUsers();
  }, []);

  // //console.log(users)
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // getting all student

  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchStudents() {
      const fullResponse = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminTeacher/admingetallstudentsdetails`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      ////console.log(responseJson?.data);
      setAllStudents(responseJson?.data);
      setLoading(false);
      if (responseJson?.msg === "not an admin") {
        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("details");
        navigate("/");
      }
    }

    fetchStudents();
  }, []);

  //   //console.log(allStudents);

  // Assigning to Student

  const handleAssign = () => {
    const token = localStorage.getItem("token");
    const authToken = token;

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    const data = {
      userIdArr: selectedStudent,
      testSriesId: testSeriesId,
    };

    axios
      .post(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/testSeriesEnroll`,
        data,
        config
      )
      .then((response) => {
        //console.log(response);
        toast.success(response?.data?.msg, { autoClose: 3000 });
        handleClose();
      })
      .catch((e) => {
        toast.error(e, { autoClose: false });
        handleClose();
        //console.log(e);
      });
    setSelectedStudent([]);
    setTimeout(() => window.location.reload(), 3000);
  };

  const handleSelectAllClick = (event) => {
    if (event.target?.checked) {
      const newSelecteds = users?.map((n) => n?.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected?.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected?.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected.concat(selected?.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected?.slice(0, selectedIndex),
        selected?.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target?.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target?.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users?.length) : 0;

  // console.log(users)
  const filteredUsers = applySortFilter2(
    users ? users : ["no"],
    getComparator(order, orderBy),
    filterName
  );
  let helperArr = [];
  helperArr = filteredUsers?.map((item) => {
    return {
      studentName: item?.user?.FullName,
      studentEmail: item?.user?.email,
      mobileNumber: item?.user?.mobileNumber,
      userName: item?.user?.username,
    };
  });
  // console.log(filteredUsers)
  const isUserNotFound =
    filteredUsers?.length === 0 || filteredUsers[0] == "no";

  const removeStudent = async (userId) => {
    const token = localStorage.getItem("token");

    // //console.log(userId)

    try {
      const response = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/removeStudentTestSeries/${userId}/${testSeriesId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response?.ok) {
        throw new Error("Network response was not ok");
      }

      toast.success("Deleted");
      setTimeout(() => window.location.reload(), 1000);
    } catch (error) {
      toast.success(error);
    }
  };

  return (
    <>
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          {" "}
          <SyncLoader
            color={theme.palette.primary.mainTheme}
            loading={loading}
            size={20}
          />
        </Stack>
      ) : (
        <Page title="Students">
          <Container sx={{ position: "absolute", minWidth: "100%" }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={3}
            >
              <Typography variant="h3" gutterBottom>
                All Students Of the Test Series
              </Typography>
              <Button
                variant="contained"
                disabled={active == "0" ? true : false}
                className="button1"
                sx={{
                  width: "20%",
                  alignSelf: "center",
                  background: "#7f11e0bf",
                  "&:hover": { background: "red" },
                  BorderAllRounded: "10px",
                }}
                onClick={handleClickOpen}
              >
                Add Students
              </Button>
            </Stack>

            {/* code for dialog box */}
            <>
              <Dialog
                open={open}
                onClose={handleClose}
                maxWidth
                className="assign_dialog"
              >
                <AppBar
                  sx={{
                    position: "relative",
                    background: theme.palette.primary.mainTheme,
                  }}
                >
                  <Toolbar sx={{ width: "100%" }}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{ width: "100%" }}
                    >
                      <DialogTitle>Assign Students</DialogTitle>
                      <Stack direction="row" justifyContent="space-between">
                        <UserListToolbar
                          numSelected={selectedStudent.length}
                          filterName={filterStudent}
                          onFilterName={handleFilterStudentByName}
                          from="AssignStudents"
                        />
                        <IconButton
                          edge="start"
                          color="inherit"
                          onClick={handleClose}
                          aria-label="close"
                        >
                          <CloseIcon />
                        </IconButton>
                      </Stack>
                    </Stack>
                  </Toolbar>
                </AppBar>
                <DialogContent>
                  <Card>
                    <Scrollbar>
                      <TableContainer sx={{ minWidth: 1000 }}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell align="left">Selected</TableCell>
                              <TableCell align="left">Student Name</TableCell>
                              <TableCell align="left">Mobile No.</TableCell>
                              <TableCell align="left">Email</TableCell>
                              <TableCell align="left">Username</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {filteredStudents
                              .slice(
                                page1 * rowsPerPage1,
                                page1 * rowsPerPage1 + rowsPerPage1
                              )
                              ?.map((row, index) => {
                                const {
                                  id,
                                  _id,
                                  StudentName,
                                  email,
                                  username,
                                  mobileNumber,
                                  profilePhoto,
                                  userId,
                                } = row;
                                const isItemSelected1 =
                                  selectedStudent.indexOf(id) !== -1;
                                // row.sno = index + 1 + rowsPerPage1 * page1
                                return (
                                  <TableRow
                                    hover
                                    key={_id}
                                    tabIndex={-1}
                                    role="checkbox"
                                    selected={isItemSelected1}
                                    aria-checked={isItemSelected1}
                                  >
                                    <TableCell padding="checkbox">
                                      <Checkbox
                                        checked={isItemSelected1}
                                        onChange={(event) => {
                                          handleCheck(event, id);
                                        }}
                                      />
                                    </TableCell>

                                    <TableCell
                                      component="th"
                                      scope="row"
                                      pl={2}
                                    >
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        spacing={2}
                                      >
                                        <Avatar
                                          alt={StudentName}
                                          src={profilePhoto}
                                          sx={{ width: 28, height: 28 }}
                                        />
                                        <Typography variant="subtitle2" noWrap>
                                          {StudentName}
                                        </Typography>
                                      </Stack>
                                    </TableCell>
                                    <TableCell align="left">
                                      {mobileNumber}
                                    </TableCell>
                                    <TableCell align="left">{email}</TableCell>
                                    <TableCell align="left">
                                      {username}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            {emptyRows > 0 && (
                              <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={6} />
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Scrollbar>
                    <TablePagination
                      rowsPerPageOptions={[10, 25]}
                      component="div"
                      count={allStudents?.length}
                      rowsPerPage={rowsPerPage1}
                      page={page1}
                      onPageChange={handleChangePage1}
                      onRowsPerPageChange={handleChangeRowsPerPage1}
                    />
                  </Card>
                </DialogContent>

                <hr></hr>

                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  m={3}
                >
                  <Button
                    onClick={handleAssign}
                    variant="contained"
                    className="button2"
                    sx={{
                      width: "20%",
                      alignSelf: "center",
                      background: "#7f11e0bf",
                      "&:hover": { background: "red" },
                      BorderAllRounded: "10px",
                    }}
                  >
                    Assign
                  </Button>
                </Stack>
              </Dialog>
            </>

            <Card>
              <Stack
                direction="row"
                spacing={2}
                justifyContent="space-between"
                alignItems="center"
                sx={{ margin: "10px" }}
              >
                <UserListToolbar
                  numSelected={selected?.length}
                  filterName={filterName}
                  onFilterName={handleFilterByName}
                />

                {/* <ExportCSV csvData={users} fileName='Student Data' /> */}
                <Button
                  variant="outlined"
                  onClick={() => {
                    handleClickOpen1();
                    sendOtp();
                    setShowDownload(true);
                  }}
                >
                  Export
                </Button>
              </Stack>

              <Dialog open={open1} onClose={handleClose1}>
                {download ? (
                  <>
                    <DialogTitle>OTP Varification</DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        OTP is send successfully to your regisered e-mail.
                      </DialogContentText>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="OTP Number"
                        type="number"
                        fullWidth
                        variant="standard"
                      />
                      <DialogContentText
                        sx={{ color: "red", fontSize: "12px", padding: "3px" }}
                      >
                        {errorOTP}
                      </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                      <Button onClick={handleClose1}>Cancel</Button>
                      <Button onClick={verifyOtp}>Verify</Button>
                    </DialogActions>
                  </>
                ) : (
                  <>
                    <DialogTitle>Download File</DialogTitle>
                    <DialogContent>
                      <CSVLink data={helperArr} filename="AllUsers.csv">
                        Download
                      </CSVLink>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={() => {
                          handleClose();
                          setDownload(true);
                        }}
                      >
                        Cancel
                      </Button>
                    </DialogActions>
                  </>
                )}
              </Dialog>

              {/* <UserListToolbar numSelected={selected?.length} filterName={filterName} onFilterName={handleFilterByName} /> */}

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={users?.length}
                      numSelected={selected?.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {filteredUsers
                        ?.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        ?.map((row, index) => {
                          // console.log(row)
                          const {
                            email,
                            username,
                            FullName,
                            mobileNumber,
                            profilePhoto,
                            userId,
                          } = row.user;
                          const isItemSelected =
                            selected?.indexOf(FullName) !== -1;
                          // row.sno = index + 1 + rowsPerPage * page
                          return (
                            <TableRow
                              hover
                              key={userId}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              {/* <TableCell padding="checkbox">
                                                        <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, FullName)} />
                                                    </TableCell> */}
                              <TableCell>
                                <Typography variant="subtitle2" noWrap>
                                  {row.sno}
                                </Typography>
                              </TableCell>

                              <TableCell component="th" scope="row" pl={2}>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  <Avatar
                                    alt={FullName}
                                    src={profilePhoto}
                                    sx={{ width: 28, height: 28 }}
                                  />
                                  <Typography variant="subtitle2" noWrap>
                                    {FullName}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell align="left">{mobileNumber}</TableCell>
                              <TableCell align="left">{email}</TableCell>
                              <TableCell align="left">{username}</TableCell>
                              <TableCell align="left">
                                <Button
                                  variant="outlined"
                                  size="small"
                                  color="error"
                                  onClick={() =>
                                    navigate(
                                      `/dashboard/students/studentsProfile/${row.user?._id}`
                                    )
                                  }
                                >
                                  view
                                </Button>
                              </TableCell>

                              <TableCell align="right">
                                {/* <UserMoreMenu id={userId} /> */}
                                <Button
                                  variant="outlined"
                                  size="small"
                                  color="error"
                                  onClick={() => removeStudent(row.user?._id)}
                                >
                                  Delete
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>

                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={users?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
            <ToastContainer
              position="bottom-center"
              autoClose={1000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
          </Container>
        </Page>
      )}
    </>
  );
}
