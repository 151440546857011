import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Stack,
  IconButton,
  InputAdornment,
  Typography,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components

import { FormProvider, RHFTextField } from "../../../components/hook-form";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import RHFTextEditor from "../../../components/hook-form/RHFTextEditor";

// import "./general.css";
import { useTheme } from "@emotion/react";

import { MuiChipsInput } from "mui-chips-input";
// import Ckeditor from '../../../components/hook-form/Ckeditor';
import MyCkEditor from "../../../components/hook-form/MyCkEditor";

// ----------------------------------------------------------------------

const AddBlog = () => {
  const theme = useTheme();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [restrict, setRestrict] = useState("");
  const [focus, setFocused] = useState(false);
  const [pdf, setPdf] = useState(false);
  const [msg, setMsg] = useState("");
  const [val, setVal] = useState(false);
  const [type, setType] = useState("none");
  const [valueId, setValueId] = useState("");
  const [list, setList] = useState([]);
  const [showForm, setShowForm] = useState({
    first: true,
    second: false,
    third: false,
    fourth: false,
    fifth: false,
  });

  //Chips
  const [chips, setChips] = useState([]);

  const handleChangeChips = (newChips) => {
    setChips(newChips);
  };
  const [category, setCategory] = useState([]);
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchCategory() {
      const response = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/getCategory?type=Stream`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await response?.json();
      setCategory(responseJson?.data);
      setLoading(false);
    }
    fetchCategory();
  }, []);

  const RegisterSchema = Yup.object().shape({
    title: Yup.string().required("Title required"),
    // desc: Yup.string().required('Description required'),
    category: Yup.string().required("Category required"),
    metaTitle: Yup.string().required("Meta Title required"),
    metaDesc: Yup.string().required("Meta description required"),
    excerptTitle: Yup.string().required("Meta Title required"),
    platform: Yup.string().required("Select Platfarm"),
    language: Yup.string().required("Language is required"),
    isActive: Yup.boolean().required("Status Required"),
  });

  const defaultValues = {
    title: "",
    category: "",
    desc: "",
    isActive: "",
  };
  const [file, setFile] = useState();
  const changeHandler = async (event) => {
    setFile(event.target?.files);
    var fileName = document.getElementById("file").value?.toLowerCase();
    if (fileName.endsWith(".pdf") || fileName.endsWith(".jpg")) {
      setRestrict("");
    } else {
      setRestrict("JPG Or PDF");
    }
    //console.log(file)
  };
  //console.log(file)
  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const [progress, setProgress] = useState();
  // for progress bar
  const [dataForDesc, setDataForDesc] = useState("");

  const onSubmit = async (data) => {
    if (file) {
      const formData = new FormData();

      setLoading(true);
      // debugger;
      //console.log(file)
      Object.values(file).forEach((file) => {
        formData.append("file", file);
      });
      formData.append("title", data?.title);
      formData.append("category", data?.category);
      formData.append("excerptTitle", data?.excerptTitle);
      formData.append("metaTitle", data?.metaTitle);
      formData.append("metaDesc", data?.metaDesc);
      formData.append("tags", chips);
      chips?.map((item) => {
        formData.append("tags", item);
      });

      formData.append("language", data?.language);
      formData.append("platform", data?.platform);
      formData.append("desc", dataForDesc);
      formData.append("isActive", data?.isActive);

      const token = localStorage.getItem("token");
      const authToken = token;
      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${authToken}`,
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setProgress(Math.round((100 * data?.loaded) / data?.total));
        },
      };
      setProgress(0);
      axios
        .post(
          `https://api-prod.gyaankulam.com/api/v1/adminPanel/addBlog`,
          formData,
          config
        )
        .then((response) => {
          console.log(response?.data);
          if (response?.data?.msg === "New Blog added successfully") {
            toast.success(response?.data?.msg);
            setLoading(false);
            setPdf(false);
            reset();

            // window.location.href = "/dashboard/banner";
          } else {
            setLoading(false);
            toast.error(response?.data?.msg);
          }
        })
        .catch((e) => {
          //console.log(e);
        });
    }
  };
  return (
    <>
      <Stack spacing={3} px={5}>
        <>
          <Typography variant="h3">Add Blog</Typography>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3} sx={{ width: "80%" }}>
              <Stack direction="row" spacing={3}>
                <RHFTextField name="title" label="Title" />
                <RHFTextField
                  name="file"
                  onChange={changeHandler}
                  id="file"
                  label="File"
                  error={!file && val}
                  helperText="Image Only"
                  onFocus={() => setFocused(true)}
                  onBlur={() => setFocused(false)}
                  // inputProps={{ multiple: true }}
                  InputProps={{
                    classes: {
                      input: "CustomTextField",
                    },
                  }}
                  onClick={() => setPdf(true)}
                  inputProps={{ accept: "image/*" }}
                  type={pdf || focus ? "file" : "text"}
                />
              </Stack>

              <Stack direction="row" spacing={3}>
                <MuiChipsInput
                  style={{ width: "50%" }}
                  value={chips}
                  onChange={handleChangeChips}
                  placeholder="Enter Tags"
                />
                <RHFTextField
                  style={{ width: "50%" }}
                  name="category"
                  label="Category"
                  select
                >
                  {category?.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item?._id}>
                        {item?.title}
                      </MenuItem>
                    );
                  })}
                </RHFTextField>
              </Stack>

              <Stack direction="row" spacing={3}>
                <RHFTextField name="metaTitle" label="Meta Title" />
                <RHFTextField name="excerptTitle" label="Excerpt Title" />
              </Stack>

              {/* <RHFTextField name="desc" label="Description" /> */}
              {/* <RHFTextEditor name="desc" label="Description" /> */}
              <RHFTextEditor name="metaDesc" label="Meta Description" />
              <Typography>Description</Typography>
              <Stack sx={{ width: "100%" }}>
                <MyCkEditor
                  dataForDesc={dataForDesc}
                  setDataForDesc={setDataForDesc}
                />
              </Stack>

              {/* <Ckeditor dataForDesc={dataForDesc} setDataForDesc={setDataForDesc}/> */}

              {/* <Stack direction="row" spacing={3}> */}

              {/* <Typography fontSize="10px" color="red" sx={{ display: restrict == '' ? 'none' : 'block' }}>{restrict}</Typography> */}

              {/* </Stack> */}

              <Stack direction="row" spacing={3}>
                <RHFTextField name="platform" label="Platform" select>
                  <MenuItem value="app">App</MenuItem>
                  <MenuItem value="website">WebSite</MenuItem>
                  <MenuItem value="store">Store</MenuItem>
                </RHFTextField>
                <RHFTextField name="language" label="Language" select>
                  <MenuItem value="en">English</MenuItem>
                  <MenuItem value="hi">Hindi</MenuItem>
                  <MenuItem value="enhi">Bilingual</MenuItem>
                </RHFTextField>

                <RHFTextField
                  name="isActive"
                  label="Status"
                  type="checkbox"
                  select
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </RHFTextField>
              </Stack>

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={loading}
                sx={{
                  bgcolor: theme.palette.primary.mainTheme,
                  marginTop: "5%",
                  "&:hover": { background: "red" },
                }}
                onClick={() => {
                  setVal(true);
                  setMsg("Required");
                }}
              >
                Add Blog
              </LoadingButton>
            </Stack>
          </FormProvider>
          <ToastContainer
            position="bottom-center"
            autoClose={1000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </>

        {/* <Typography color={positive ? "green" : "red"} sx={{ textAlign: 'center', width: '80%' }}>{fetchMessage}</Typography> */}
      </Stack>
    </>
  );
};

export default AddBlog;
