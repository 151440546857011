import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import { Modal } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Typography } from "@mui/material";
import { Stack } from "@mui/material";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  GridToolbarDensitySelector,
  useGridApiContext,
  gridExpandedSortedRowIdsSelector,
  GridActionsCellItem,
} from "@mui/x-data-grid";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { ToastContainer, toast } from "react-toastify";
import { useTheme } from "@emotion/react";
import axios from "axios";

// ----------------------------------------------------------------------
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",

  boxShadow: 24,
  borderRadius: "20px",

  p: 4,
};

const flattenObject = (obj) => {
  return `${obj.name} ${obj.email} ${obj.phone}`;
};
const flattenArray = (arr) => {
  return arr
    ?.map(
      (item) =>
        `Title - ${item?.title.replaceAll(",", "")} Quantity - ${
          item?.quantity
        }  Code - ${item?.code}`
    )
    .join("; ");
};
const removeComma = (string) => {
  return string.replaceAll(",", "");
};
function CustomToolbar({ rows, columns, ...gridProps }) {
  const { setQuickFilter, ...otherProps } = gridProps;
  // debugger;
  const apiRef = useGridApiContext();
  // console.log(gridProps)
  const exportAllRows = async () => {
    const visibleRows = gridExpandedSortedRowIdsSelector(apiRef);

    const filteredRows = rows?.filter((row) => visibleRows.includes(row.id));

    const columnIds = columns
      ?.filter((column) => column.field !== "actions")
      ?.map((column) => column.field);
    const csvHeader = columnIds.join(", ");
    const csvContent = filteredRows
      ?.map((row) => {
        const rowValues = columnIds?.map((columnId) => {
          if (columnId === "user") {
            return flattenObject(row.user);
          }
          return row[columnId]?.toString()?.replaceAll(",", " ");
        });
        return rowValues.join(", ");
      })
      .join("\n");
    const fullCsvContent = `${csvHeader}\n${csvContent}`;

    // console.log('Exporting all rows:', fullCsvContent);
    const blob = new Blob([fullCsvContent], { type: `text/csv;charset=utf-8` });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", "StorePurchaseReport.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />

      <GridToolbarDensitySelector />
      <Tooltip title="Export All">
        <IconButton onClick={exportAllRows}>
          <SaveAltIcon style={{ color: "red" }} />
        </IconButton>
      </Tooltip>
      <GridToolbarQuickFilter style={{ marginLeft: "auto" }} />
    </GridToolbarContainer>
  );
}

export default function StorePuchaseReport() {
  const theme = useTheme();

  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows?.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  const [loading, setLoading] = useState(true);
  React.useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem("token");
    async function fetchOrder() {
      const response = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/store/getAllTxn`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responseJson = await response?.json();
      setRows(responseJson?.data ?? []);
      setLoading(false);
    }
    fetchOrder();
  }, []);

  const columns = [
    {
      field: "orderId",
      headerName: "Order Id",
      type: "object",
      width: 100,
      align: "left",
      headerAlign: "left",
    },

    {
      field: "user",
      headerName: "User Details",
      type: "string",
      width: 250,
      align: "left",
      headerAlign: "left",
      editable: true,
      renderCell: (params) => (
        <div>
          <strong>Name:</strong> {params.value?.name}
          <br />
          <strong>Email:</strong> {params.value?.email}
          <br />
          <strong>Phone:</strong> {params.value?.phone}
        </div>
      ),
    },
    {
      field: "txnId",
      headerName: "Transaction Id",
      type: "object",
      width: 200,
      align: "left",
      headerAlign: "left",
      editable: true,
    },
    {
      field: "txnAmount",
      headerName: "Transaction Amount",
      type: "object",
      width: 200,
      align: "left",
      headerAlign: "left",
    },

    {
      field: "isPaid",
      headerName: "Paid",
      // type: "object",
      width: 100,
      align: "left",
      headerAlign: "left",
      // editable: true,
      renderCell: (params) => (
        <div style={{ color: params.value == true ? "green" : "red" }}>
          {params.value ? "Paid" : "Not Paid"}
        </div>
      ),
    },
    {
      field: "txnDate",
      headerName: "Transaction Date",
      // type: "object",
      width: 250,
      align: "left",
      headerAlign: "left",
    },
  ];

  return (
    <Box
      sx={{
        height: 500,
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <h2 style={{ margin: "5px" }}>Store Purchase Report</h2>
      <DataGrid
        rows={rows}
        columns={columns || []}
        // editMode="row"
        // rowHeight={155}
        autoHeight={true}
        getRowHeight={() => "auto"}
        getEstimatedRowHeight={() => 200}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        processRowUpdate={processRowUpdate}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 7,
            },
          },
        }}
        loading={loading}
        pageSizeOptions={[7, 25, 50]}
        slots={{
          toolbar: (gridProps) => (
            <CustomToolbar {...gridProps} rows={rows} columns={columns || []} />
          ),
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel, showQuickFilter: true },
        }}
      />

      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Box>
  );
}
