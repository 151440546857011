import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// form
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Stack,
  IconButton,
  InputAdornment,
  Typography,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import CloseIcon from "@mui/icons-material/Close";
// components

import { FormProvider, RHFTextField } from "../../../components/hook-form";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

import "./general.css";
import { useTheme } from "@emotion/react";

// ----------------------------------------------------------------------

export default function UpdateBannerDetails() {
  const { bannerId } = useParams();
  const theme = useTheme();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [restrict, setRestrict] = useState("");
  const [focus, setFocused] = useState(false);
  const [pdf, setPdf] = useState(false);
  const [msg, setMsg] = useState("");
  const [val, setVal] = useState(false);

  const [list, setList] = useState([]);
  const [bannerDetails, setBannerDetails] = useState();
  const [values, setValues] = useState({});
  const [type, setType] = useState(values?.link || "none");
  const [id, setId] = useState(values?.linkWith?._id || "");
  const [showImage, setShowImage] = useState(false);
  const [imageName, setImageName] = useState("");

  const RegisterSchema = Yup.object().shape({});

  const defaultValues = {
    title: "",
    BannerType: "",
    link: values?.link,
    linkWith: values?.linkWith?.id || "",
    is_active: "",
  };
  const [file, setFile] = useState();
  const changeHandler = async (event) => {
    setFile(event.target?.files);
    var fileName = document.getElementById("file").value?.toLowerCase();
    if (fileName.endsWith(".pdf") || fileName.endsWith(".jpg")) {
      setRestrict("");
    } else {
      setRestrict("JPG Or PDF");
    }
  };
  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    register,
    errors,
    handleSubmit,
    reset,
    control,
    formState: { isSubmitting },
  } = methods;

  const [progress, setProgress] = useState();
  //get banner details
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchDetails() {
      const fullResponse = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/getBannerById/${bannerId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      setBannerDetails(responseJson?.data);
      checkDetails(responseJson?.data);
    }
    fetchDetails();

    setLoading(false);
  }, []);

  const onChange2 = (e) => {
    // console.log(values);
    const { name, value } = e.target;
    // console.log( name + " " + value);
    setValues((prev) => ({ ...prev, [name]: value }));
  };
  // for progress bar

  const onSubmit = async (data) => {
    const formData = new FormData();

    setLoading(true);
    if (file) {
      Object.values(file).forEach((file) => {
        formData.append("file", file);
      });
    }

    formData.append("title", values?.title);
    formData.append("category", "Banner");
    formData.append("BannerType", values?.BannerType);
    formData.append("language", "en");
    formData.append("is_active", values?.is_active);
    formData.append("link", type);
    if (type !== "" && type !== "none") {
      formData.append("linkWith", id);
    } else {
      formData.append("linkWith", "");
    }
    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${authToken}`,
      },
      onUploadProgress: (data) => {
        setProgress(Math.round((100 * data?.loaded) / data?.total));
      },
    };
    setProgress(0);
    axios
      .put(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/updatedBannerDetails/${bannerId}`,
        formData,
        config
      )
      .then((response) => {
        if (
          response?.data?.status == false ||
          response?.data?.msg === "Please select linkWith also!"
        ) {
          toast.error(response?.data?.msg);
          setLoading(false);
        } else {
          setLoading(false);
          toast.success("Banner Updated");
          setPdf(false);
          reset();
          window.location.href = "/dashboard/banner";
        }
      })
      .catch((e) => {
        //console.log(e);
      });
  };

  const handleDelete = () => {
    setShowImage(false);
    setFile("");
  };

  useEffect(() => {
    if (type !== "" && type !== "none" && type !== "link") {
      const token = localStorage.getItem("token");
      async function fetchDetails() {
        const fullResponse = await fetch(
          `https://api-prod.gyaankulam.com/api/v1/adminPanel/${
            type == "batch"
              ? "getBatchesDetails"
              : type == "testSeries"
              ? "getallTestSeriesdetails"
              : type == "scholarship"
              ? "getScholarshipTests"
              : "getCategory"
          }`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const responseJson = await fullResponse.json();
        setList(responseJson?.data);
      }
      fetchDetails();
    }
    setLoading(false);
  }, [type]);

  const checkDetails = (bannerDetails) => {
    setValues((prev) => ({
      ...prev,
      title: bannerDetails?.title,
      BannerType: bannerDetails?.BannerType,
      link: bannerDetails?.link,
      linkWith: bannerDetails?.linkWith,
      is_active: bannerDetails?.is_active,
      banner_url: bannerDetails?.banner_url,
      valueId: bannerDetails?.linkWith?.id,
    }));
    setType(bannerDetails?.link);
    if (bannerDetails?.banner_url) {
      setShowImage(true);
      const parts = bannerDetails?.banner_url[0]?.split("/");
      const lastPart = parts[parts.length - 1];
      setImageName(lastPart);
    }
    // setValueId(bannerDetails?.linkWith?.id)
    setId(bannerDetails?.linkWith?.id);
  };

  return (
    <>
      <Stack spacing={3} px={5}>
        <Typography variant="h3">Update Banner</Typography>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} sx={{ width: "70%" }}>
            <RHFTextField
              name="title"
              value={values?.title}
              onChange={onChange2}
            />
            <Stack direction="row" spacing={3}>
              {showImage ? (
                <Stack
                  direction="column"
                  sx={{ width: "100%", position: "relative" }}
                  alignItems="center"
                  spacing={1}
                >
                  <IconButton
                    sx={{ position: "absolute", top: "-10px", right: "-10px" }}
                    onClick={() => handleDelete()}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                  <img
                    src={values?.banner_url}
                    alt="BannerImage"
                    style={{ height: "100px", width: "200px" }}
                  />
                </Stack>
              ) : (
                <RHFTextField
                  name="file"
                  onChange={changeHandler}
                  id="file"
                  label="Select image"
                  error={!file && val}
                  helperText="Image Only ( Maximum allowed size 100KB)"
                  onFocus={() => setFocused(true)}
                  onBlur={() => setFocused(false)}
                  // inputProps={{ multiple: true }}
                  InputProps={{
                    classes: {
                      input: "CustomTextField",
                    },
                  }}
                  onClick={() => setPdf(true)}
                  inputProps={{ accept: "image/*" }}
                  type={pdf || focus ? "file" : "text"}
                />
              )}
              {/* <Typography fontSize="10px" color="red" sx={{ display: restrict == '' ? 'none' : 'block' }}>{restrict}</Typography> */}
              <RHFTextField
                name="BannerType"
                label={values?.BannerType}
                value={values?.BannerType}
                onChange={onChange2}
                select
              >
                <MenuItem value="APP">App</MenuItem>
                <MenuItem value="TAB">Tab</MenuItem>
                <MenuItem value="WEB">Web</MenuItem>
              </RHFTextField>
            </Stack>
            <Stack direction="row" spacing={3}>
              <RHFTextField
                name="link"
                label={
                  values?.link == "batch"
                    ? "Batch"
                    : values?.link == "testSeries"
                    ? "Test Series"
                    : values?.link == "category"
                    ? "Category"
                    : values?.link == "link"
                    ? "Link"
                    : values?.link == "scholarship"
                    ? "Scholarship"
                    : "None"
                }
                onChange={onChange2}
                ref={register}
                select
              >
                <MenuItem
                  value="none"
                  onClick={() => {
                    setType("none");
                    setId("");
                  }}
                >
                  None
                </MenuItem>
                <MenuItem value="batch" onClick={() => setType("batch")}>
                  Batch
                </MenuItem>
                <MenuItem
                  value="testSeries"
                  onClick={() => setType("testSeries")}
                >
                  Test Series
                </MenuItem>
                <MenuItem value="category" onClick={() => setType("category")}>
                  Category
                </MenuItem>
                <MenuItem value="link" onClick={() => setType("link")}>
                  Link
                </MenuItem>
                <MenuItem
                  value="scholarship"
                  onClick={() => setType("scholarship")}
                >
                  Scholarship
                </MenuItem>
              </RHFTextField>
              {/* {errors.link && <p>{errors.link.message}</p>} */}
              {type == "link" && (
                <RHFTextField
                  name="valueId"
                  value={id}
                  onChange={(e) => {
                    console.log(e.target?.value);
                    setId(e.target?.value);
                  }}
                />
              )}
              {type !== "none" && type !== "" && type != "link" && (
                <RHFTextField
                  name="valueId"
                  label={
                    values?.linkWith?.name
                      ? values?.linkWith?.name
                      : type == "batch"
                      ? "Link With Batch"
                      : type == "testSeries"
                      ? "Link With Test Series"
                      : type == "category"
                      ? "Link With Category"
                      : type == "scholarship"
                      ? "Link With Scholarship Test"
                      : "None"
                  }
                  ref={register}
                  select
                >
                  {list?.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item?._id}
                      //   onClick={() => setValueId(item?._id)}
                      onClick={() => setId(item?._id)}
                    >
                      {type == "batch"
                        ? item?.batch_name
                        : type == "testSeries"
                        ? item?.testseries_name
                        : item?.title}
                    </MenuItem>
                  ))}
                </RHFTextField>
              )}
            </Stack>

            <Stack direction="row" spacing={3}>
              {/* <RHFTextField name="category" label="Category" select>
                            {users?.map((item, i) => { return <MenuItem key={i} value={item?.title}>{item?.title}</MenuItem> })}
                        </RHFTextField> */}

              <RHFTextField
                name="is_active"
                label={values?.is_active ? "Active" : "Inactive"}
                value={values?.is_active}
                onChange={onChange2}
                select
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </RHFTextField>
            </Stack>

            {/* <div className="mainDiv">
                        <div className="childDiv" style={{ width: `${progress}%` }}>
                            <span> </span>
                        </div>
                    </div> */}

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
              sx={{
                bgcolor: theme.palette.primary.mainTheme,
                marginTop: "5%",
                "&:hover": { background: "red" },
              }}
              onClick={() => {
                setVal(true);
                setMsg("Required");
              }}
            >
              Update Banner
            </LoadingButton>
          </Stack>
        </FormProvider>
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {/* <Typography color={positive ? "green" : "red"} sx={{ textAlign: 'center', width: '80%' }}>{fetchMessage}</Typography> */}
      </Stack>
    </>
  );
}
