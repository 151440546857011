import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { Stack, MenuItem, Typography, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { FormProvider, RHFTextField } from "../../../components/hook-form";
import * as Yup from "yup";
import { useTheme } from "@emotion/react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
const StoreOrderUpdate = ({ id, data, setOpen }) => {
  // console.log("TT", data)
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const registerSchema = Yup.object().shape({
    awbNumber: Yup.string().required("AWB Number Required"),
    trackingId: Yup.string().required("Tracking Id Required"),
    trackingLink: Yup.string().required("Tracking Link Required"),
    deliveryStatus: Yup.string().required("Delivery Status  Required"),
  });
  const defaultValues = {
    awbNumber: data?.awbNumber || "",
    trackingId: data?.trackingId || "",
    trackingLink: data?.trackingLink || "",
    deliveryStatus: data?.deliveryStatus || "",
  };
  const methods = useForm({
    resolver: yupResolver(registerSchema),
    defaultValues,
  });
  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;
  const onSubmit = async (data) => {
    // console.log(data)
    setLoading(true);
    let token = localStorage.getItem("token");

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .put(
        `https://api-prod.gyaankulam.com/api/v1/store/updateOrder/${id}`,
        { ...data },
        config
      )
      .then((response) => {
        if (response?.data?.status === true) {
          toast.success(response?.data?.msg);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
          setLoading(false);
        } else {
          toast.error(response?.data?.msg);
        }
      });
  };

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="h4" align="center" mb={2}>
          Apply Order Status
        </Typography>
        <Stack direction="column" spacing={2}>
          <RHFTextField name="awbNumber" label="AWB Number" />
          <RHFTextField name="trackingId" label="Tracking Id" />
          <RHFTextField name="trackingLink" label="Tracking Link" />
          {data?.isPaid == true && (
            <RHFTextField name="deliveryStatus" label="Delivery Status" select>
              <MenuItem value="processing">Processing</MenuItem>
              <MenuItem value="placed">Placed</MenuItem>
              <MenuItem value="shipped">Shipped</MenuItem>
              <MenuItem value="inTransit">In Transit</MenuItem>
              <MenuItem value="outForDelivery">Out For Delivery</MenuItem>
              <MenuItem value="delivered">Delivered</MenuItem>
              <MenuItem value="pending">Pending</MenuItem>
              <MenuItem value="cancelled">Cancelled</MenuItem>
            </RHFTextField>
          )}
          {data?.isPaid == false && (
            <RHFTextField name="deliveryStatus" label="Delivery Status" select>
              <MenuItem value="cancelled">Cancelled</MenuItem>
            </RHFTextField>
          )}
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          m={3}
          align="center"
          justifyContent="space-evenly"
        >
          <Button
            variant="contained"
            style={{
              backgroundColor: theme.palette.primary.mainTheme,
              color: "white",
              "&:hover": { background: "red" },
            }}
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
          {/* <Button
            variant="contained"
            
            style={{
              backgroundColor: theme.palette.primary.mainTheme,
              color: "white",
              "&:hover": { background: "red" },
            }}
            type="submit"
          >
            Update Order
          </Button> */}
          <LoadingButton
            size="large"
            type="submit"
            variant="contained"
            loading={loading}
            sx={{
              bgcolor: theme.palette.primary.mainTheme,
              marginTop: "5%",
              "&:hover": { background: theme.palette.primary.mainTheme },
            }}
          >
            Update Order
          </LoadingButton>
        </Stack>
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </FormProvider>
    </>
  );
};

export default StoreOrderUpdate;
