// import "./App.css";
import io from "socket.io-client";
import { useState } from "react";
import Chat from "./Chat";
import socket from "../batch/sockets"



function Messaging({ use }) {
    const [username, setUsername] = useState("");
    const [room, setRoom] = useState("");

    // let socket = io.connect("http://localhost:5000", { transports: ['websocket'] });
    // let socket = ''



    return (
        <div className="App">


            <Chat socket={socket} username={username} room={room} use={use} />

        </div>
    );
}

export default Messaging;