import { filter } from "lodash";
// import { sentenceCase } from 'change-case';
import { useEffect, useState } from "react";
// import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  // Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Button,
  TextField,
  MenuItem,
  Input,
} from "@mui/material";
// components
import Page from "../../../components/Page";

import Scrollbar from "../../../components/Scrollbar";

import SearchNotFound from "../../../components/SearchNotFound";
import { UserListHead, UserListToolbar, UserMoreMenu } from "../user";
// mock
// importusers ? from '../_mock/user';

import SyncLoader from "react-spinners/SyncLoader";
import axios from "axios";
import { ExportCSV } from "../../../utils/ExportCSV";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { convertLength } from "@mui/material/styles/cssUtils";
import { useTheme } from "@emotion/react";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "", label: "S.No", alignRight: false },
  { id: "title", label: "Title", alignRight: false },
  { id: "category", label: "Category", alignRight: false },
  { id: "created_at", label: "Date", alignRight: false },
  { id: "is_active", label: "Status", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.title.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis?.map((el) => el[0]);
}

export default function CategoryTable() {
  const theme = useTheme();

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");
  const [selectedId, setSelectedId] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(5);
  // const navigate = useNavigate()
  const [users, usersSet] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/getSubCategorialDetails`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      usersSet(responseJson?.data);
      setLoading(false);
    }

    fetchUsers();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target?.checked) {
      const newSelecteds = users?.map((n) => n?.title);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected?.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected?.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected.concat(selected?.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected?.slice(0, selectedIndex),
        selected?.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target?.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target?.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users?.length) : 0;
  let helperArray = [];
  const filteredUsers = applySortFilter(
    users ? users : ["no"],
    getComparator(order, orderBy),
    filterName
  );
  filteredUsers?.map((item, index) =>
    helperArray.push({
      SNo: index + 1,
      title: item?.title,
      // type : item?.type,
      category: item?.category?.title ?? "NA",
      isActive: item?.is_active,
      createdAt: item?.created_at?.split(" ")[0],
      // iconUrl : item?.icon,
    })
  );
  // console.log(filteredUsers);
  const isUserNotFound = filteredUsers?.length === 0;

  const [isEdit, setIsEdit] = useState(false);
  const [updatedValues, setUpdatedValues] = useState({});
  const [categorialDetails, setCategorialDetails] = useState([]);
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchCategory() {
      const resCategory = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/getCategorailDetails`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const resultCategory = await resCategory.json();
      setCategorialDetails(resultCategory?.data);
      setLoading(false);
    }

    fetchCategory();
  }, []);
  //console.log(updatedValues)
  return (
    <>
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "60vh" }}
        >
          {" "}
          <SyncLoader
            color={theme.palette.primary.mainTheme}
            loading={loading}
            size={20}
          />
        </Stack>
      ) : (
        <Page title="Sub Category">
          <Container sx={{ position: "absolute", minWidth: "100%" }}>
            <Card>
              <Stack
                pr={3}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <UserListToolbar
                  numSelected={selected?.length}
                  filterName={filterName}
                  onFilterName={handleFilterByName}
                />

                <ExportCSV csvData={helperArray} fileName="Sub Category Data" />
              </Stack>

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={users?.length}
                      numSelected={selected?.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {filteredUsers
                        ?.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        ?.map((row, index) => {
                          const { id, title, category, created_at, is_active } =
                            row;
                          // console.log(row);
                          const isItemSelected =
                            selected?.indexOf(title) !== -1;
                          row = index + 1 + rowsPerPage * page;
                          // row.sno = no.toString();
                          // console.log(typeof(index + 1 + rowsPerPage * page));
                          const onChange = (e, row, _id) => {
                            const { name, value } = e.target;
                            // console.log( name + " " + value);
                            // console.log(e);
                            setUpdatedValues((prev) => {
                              return { ...prev, [name]: value };
                            });
                            // console.log(updatedValues);
                          };
                          const handleUpdate = async () => {
                            setLoading(true);
                            const token = localStorage.getItem("token");
                            const authToken = token;
                            const config = {
                              headers: {
                                "content-type": "application/json",
                                Authorization: `Bearer ${authToken}`,
                              },
                            };
                            const res = await axios.put(
                              `https://api-prod.gyaankulam.com/api/v1/adminPanel/updateSubCategorialDetails/${id}`,
                              updatedValues,
                              config
                            );
                            // console.log(res.msg)

                            setTimeout(() => {
                              setLoading(false);
                              window.location.reload();
                            }, 600);
                          };
                          return (
                            <TableRow
                              hover
                              key={id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              {/* <TableCell padding="checkbox">
                                                        <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, title)} />
                                                    </TableCell> */}
                              <TableCell>
                                <Typography variant="subtitle2">
                                  {index + 1}
                                </Typography>
                              </TableCell>
                              <TableCell component="th" scope="row" pl={2}>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  {/* <Avatar alt={name} src={avatarUrl} /> */}
                                  <Typography variant="subtitle2" noWrap>
                                    {isEdit && selectedId === id ? (
                                      <Input
                                        onChange={(e) => onChange(e, row, id)}
                                        // value={value}
                                        name="title"
                                        placeholder={title}
                                      />
                                    ) : (
                                      <span>{title}</span>
                                    )}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              {/* <TableCell align='left'>
                                                        {type}
                                                    </TableCell> */}
                              <TableCell component="th" scope="row" pl={2}>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  {/* <Avatar alt={name} src={avatarUrl} /> */}
                                  <Typography variant="subtitle2" noWrap>
                                    {isEdit && selectedId === id ? (
                                      <TextField
                                        onChange={(e) => onChange(e, row, id)}
                                        // value={value}
                                        name="category"
                                        label="category"
                                        placeholder="category"
                                        select
                                        sx={{ width: "100px" }}
                                      >
                                        {categorialDetails?.map((category) => {
                                          return (
                                            <MenuItem
                                              key={category._id}
                                              value={category._id}
                                              onClick={(e) =>
                                                onChange(e, row, id)
                                              }
                                            >
                                              {category.title}
                                            </MenuItem>
                                          );
                                        })}
                                      </TextField>
                                    ) : (
                                      <span>{category?.title}</span>
                                    )}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell align="left">{created_at}</TableCell>
                              <TableCell align="left">
                                {isEdit && selectedId === id ? (
                                  <TextField
                                    onChange={(e) => onChange(e, row)}
                                    label={is_active ? "Active" : "Inactive"}
                                    name="is_active"
                                    placeholder={
                                      is_active ? "Active" : "Inactive"
                                    }
                                    select
                                    sx={{ width: "100px" }}
                                  >
                                    <MenuItem value={true}>Active</MenuItem>
                                    <MenuItem value={false}>Inactive</MenuItem>
                                  </TextField>
                                ) : (
                                  <span
                                    style={{
                                      background: is_active ? "green" : "red",
                                      padding: "5px",
                                      borderRadius: "10px",
                                      color: "white",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {is_active ? "Active" : "Inactive"}
                                  </span>
                                )}
                              </TableCell>
                              {isEdit && selectedId === id ? (
                                <TableCell align="left">
                                  <Button
                                    variant="outlined"
                                    onClick={() => handleUpdate()}
                                  >
                                    Update
                                  </Button>
                                </TableCell>
                              ) : null}

                              <TableCell
                                align="right"
                                onClick={() => setSelectedId(id)}
                              >
                                <UserMoreMenu
                                  id={id}
                                  req="DeleteSubCategorailDetails"
                                  isEdit={isEdit}
                                  setIsEdit={setIsEdit}
                                  setLoading={setLoading}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>

                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>
              <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={users?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </Container>
        </Page>
      )}
    </>
  );
}
