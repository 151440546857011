import { filter } from "lodash";
// import { sentenceCase } from 'change-case';
import { useEffect, useState } from "react";
// import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  // Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Button,
  Input,
  TextField,
  MenuItem,
} from "@mui/material";
// components
import Page from "../../../components/Page";
import ImageIcon from "@mui/icons-material/Image";

import Scrollbar from "../../../components/Scrollbar";

import SearchNotFound from "../../../components/SearchNotFound";
import { UserListHead, UserListToolbar, UserMoreMenu } from "../user";
// mock
// importusers ? from '../_mock/user';
import { useNavigate } from "react-router-dom";
import SyncLoader from "react-spinners/SyncLoader";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import axios from "axios";
import { ExportCSV } from "../../../utils/ExportCSV";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTheme } from "@emotion/react";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "id", label: "S.No", alignRight: false },
  { id: "title", label: "Title", alignRight: false },
  { id: "banner_url", label: "Banner", alignRight: false },
  // { id: 'category', label: 'Category', alignRight: false },
  // { id: 'language', label: 'Language', alignRight: false },
  // { id: 'language', label: 'Language', alignRight: false },
  { id: "BannerType", label: "BannerType", alignRight: false },
  { id: "LinkWith", label: "LinkWith", alignRight: false },
  { id: "linkDetails", label: "Link Details", alignRight: false },
  { id: "created_at", label: "AddedAt", alignRight: false },
  { id: "is_active", label: "Status", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.title.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis?.map((el) => el[0]);
}

export default function BannerTable() {
  const theme = useTheme();
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");
  const [selectedId, setSelectedId] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();
  const [users, usersSet] = useState();
  const [loading, setLoading] = useState(true);
  const [isEdit, setIsEdit] = useState();
  // const [file, setFile] = useState();
  // const [isActiveValue, setIsActiveValue] = useState(false);
  // const[type , setType] = useState('');
  // const[batchList, setBatchList] = useState([]);
  // const[testList, setTestList] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/getbannerdetails?BannerType=ALL`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();

      usersSet(responseJson?.data);

      setLoading(false);
    }

    fetchUsers();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target?.checked) {
      const newSelecteds = users?.map((n) => n?.title);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected?.indexOf(name);
  //   let newSelected = [];
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected?.slice(1));
  //   } else if (selectedIndex === selected?.length - 1) {
  //     newSelected = newSelected.concat(selected?.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected?.slice(0, selectedIndex),
  //       selected?.slice(selectedIndex + 1)
  //     );
  //   }
  //   setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target?.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target?.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users?.length) : 0;

  let helperArray = [];
  const filteredUsers = applySortFilter(
    users ? users : ["no"],
    getComparator(order, orderBy),
    filterName
  );

  filteredUsers?.map((item) =>
    helperArray.push({
      title: item?.title,
      BannerType: item?.BannerType,
      link: item?.link,
      linkWith: item?.linkWith?.name,
      bannerUrl: item?.banner_url,
      createdAt: item?.created_at,
    })
  );
  // console.log(filteredUsers);

  //   useEffect(() => {
  //     if( type !== '' && type !=='none'){
  //         const token = localStorage.getItem("token");
  //     async function fetchDetails() {
  //         const fullResponse = await fetch(
  //             `https://api-prod.gyaankulam.com/api/v1/adminPanel/${
  //               type == "batch" ? "getBatchesDetails" : "getallTestSeriesdetails"
  //             }`,
  //             {
  //               method: "GET",
  //               headers: {
  //                 "Content-Type": "application/json",
  //                 Authorization: `Bearer ${token}`,
  //               },
  //             }
  //           );
  //           const responseJson = await fullResponse.json();
  //           setList(responseJson?.data);

  //     }
  //     fetchDetails();
  //     }
  //     setLoading(false);

  // }, []);

  // useEffect(() => {
  //   let arr = []
  //   const fetchData = async () => {
  //     const promises = filteredUsers?.map((data) => {
  //       return getLinkName(data?.link, data?.linkWith);
  //     });
  //     const results = await Promise.all(promises);
  //     console.log(results)// Wait for all promises to resolve
  //       setLinkNames(results);

  //   };

  //   fetchData();
  //   setLinkNames(arr);
  //    // Call the asynchronous function
  // }, []);

  const isUserNotFound = filteredUsers?.length === 0;
  // const [isEdit, setIsEdit] = useState(false);
  // const [updatedValues, setUpdatedValues] = useState({});
  //console.log(updatedValues);
  return (
    <>
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "60vh" }}
        >
          {" "}
          <SyncLoader
            color={theme.palette.primary.mainTheme}
            loading={loading}
            size={20}
          />
        </Stack>
      ) : (
        <Page title="Banner">
          <Container sx={{ position: "absolute", minWidth: "100%" }}>
            <Card>
              <Stack
                pr={3}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <UserListToolbar
                  numSelected={selected?.length}
                  filterName={filterName}
                  onFilterName={handleFilterByName}
                />

                <ExportCSV csvData={helperArray} fileName="Banner Data" />
              </Stack>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={users?.length}
                      numSelected={selected?.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {filteredUsers
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        ?.map((row, index) => {
                          const {
                            id,
                            title,
                            _id,
                            category,
                            language,
                            link,
                            linkWith,
                            created_at,
                            is_active,
                            BannerType,
                            banner_url,
                          } = row;
                          //  console.log(row);

                          // if( let )

                          const isItemSelected =
                            selected?.indexOf(title) !== -1;
                          let createdDate = created_at?.split(" ")[0];
                          // row.sno = index + 1 + rowsPerPage * page;
                          {
                            /* //console.log('activ statues' + is_active) */
                          }

                          //Handling files
                          // const changeHandler = async (event) => {
                          //   setFile(event.target?.files[0]);
                          // };

                          //Handling updated values
                          // const onChange = (e, row, _id) => {
                          //   const { name, value } = e.target;
                          //   if (name == "is_active") {
                          //     //console.log("came");
                          //     setIsActiveValue(true);
                          //   }
                          //   if( name == 'link'){
                          //     setType(value);
                          //   }

                          //     setUpdatedValues((prev) => {
                          //       return { ...prev, [name]: value };
                          //     });

                          // };

                          // const handleUpdate = async () => {
                          //   setLoading(true);

                          //   //console.log("Category" + updatedValues?.category);
                          //   //console.log(updatedValues);
                          //   let data;
                          //   {
                          //     /* if (!updatedValues.category) {
                          //                           data = {
                          //                               ...updatedValues, category: category.title
                          //                           }
                          //                       }
                          //                       else {

                          //                       } */
                          //   }
                          //   data = { ...updatedValues };
                          //   //console.log(data);
                          //   makeReq(data);
                          // };
                          // const makeReq = async (data) => {
                          //   console.log(data);
                          //   const token = localStorage.getItem("token");
                          //   const authToken = token;
                          //   // setType()
                          //   const formData = new FormData();
                          //   if (file) {
                          //     formData.append("file", file);
                          //   }

                          //   if (data?.title) {
                          //     formData.append("title", data?.title);
                          //   }
                          //   if (data?.BannerType) {
                          //     formData.append("BannerType", data?.BannerType);
                          //   }
                          //   {
                          //     /* formData.append('BannerType', 'WEB') */
                          //   }
                          //   formData.append("category", "Banner");

                          //   if (data?.language) {
                          //     formData.append("language", data?.language);
                          //   }
                          //   if( data?.link){
                          //     formData.append("link", data?.link);
                          //   }
                          //   if( data?.linkWith){
                          //     formData.append("linkWith" , data?.linkWith)
                          //   }
                          //   if (isActiveValue) {
                          //     formData.append("is_active", data?.is_active);
                          //   }

                          //   const config = {
                          //     headers: {
                          //       "content-type": "multipart/form-data",
                          //       Authorization: `Bearer ${authToken}`,
                          //     },
                          //   };
                          //   const res = await axios.put(
                          //     `https://api-prod.gyaankulam.com/api/v1/adminPanel/updatedBannerDetails/${_id}`,
                          //     formData,
                          //     config
                          //   );
                          //   setLoading(false);
                          //   toast.success("Updated");
                          //   setTimeout(() => window.location.reload(), 800);
                          // };

                          return (
                            <TableRow
                              hover
                              key={id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              {/* <TableCell padding="checkbox">
                                                        <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, title)} />
                                                    </TableCell> */}
                              <TableCell>
                                <Typography variant="subtitle2">
                                  {index + 1 + rowsPerPage * page}
                                </Typography>
                              </TableCell>
                              <TableCell component="th" scope="row" pl={2}>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  {/* <Avatar alt={name} src={avatarUrl} /> */}
                                  <Typography variant="subtitle2" noWrap>
                                    <span>{title}</span>
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell align="left">
                                {" "}
                                <a
                                  href={banner_url}
                                  style={{ color: "black" }}
                                  target="_blank"
                                >
                                  <img
                                    src={banner_url}
                                    style={{ width: "150px" }}
                                  ></img>
                                  {/* <ImageIcon

                                                            sx={{
                                                                cursor: 'pointer', '&:hover': {
                                                                    color: 'red'
                                                                }
                                                            }} /> */}
                                </a>
                              </TableCell>
                              <TableCell align="left">
                                <span>{row.BannerType}</span>
                              </TableCell>
                              <TableCell align="left">
                                <span>
                                  {(link == "testSeries"
                                    ? "Test Series"
                                    : link == "batch"
                                    ? "Batch"
                                    : link == "category"
                                    ? "Category"
                                    : link == "link"
                                    ? "Link"
                                    : link == "scholarship"
                                    ? "Scholarship"
                                    : "None") || "NA"}
                                </span>
                              </TableCell>
                              <TableCell align="left">
                                <span>{linkWith?.name || "NA"}</span>
                              </TableCell>
                              {/* <TableCell align="left">{isEdit && selectedId === _id ? (<Input
                                                        onChange={(e) => onChange(e, row)}
                                                        // value={value}
                                                        name='category'
                                                        placeholder={category.title}



                                                    />) : <span>{category.title}</span>}</TableCell> */}
                              {/* <TableCell align="left">{isEdit && selectedId === _id ? (<TextField
                                                        onChange={(e) => onChange(e, row)}
                                                        size='small'
                                                        label={language === 'hi' ? 'Hindi' : 'English'}
                                                        name='language'
                                                        placeholder={language === 'hi' ? 'Hindi' : 'English'}
                                                        select
                                                        sx={{ width: '100px' }}>
                                                        <MenuItem value='hi'>Hindi</MenuItem>
                                                        <MenuItem value='en'>English</MenuItem>
                                                    </TextField>) : <span> {language === 'hi' ? 'Hindi' : 'English'}</span>}</TableCell> */}
                              <TableCell align="left">{createdDate}</TableCell>
                              <TableCell align="left">
                                <span
                                  style={{
                                    background: is_active ? "green" : "red",
                                    padding: "5px",
                                    borderRadius: "10px",
                                    color: "white",
                                    fontWeight: "500",
                                  }}
                                >
                                  {is_active ? "Active" : "Inactive"}
                                </span>
                              </TableCell>
                              {/* {isEdit && selectedId === _id ? (
                                <TableCell align="left">
                                  <Button
                                    variant="outlined"
                                    color="error"
                                    onClick={handleUpdate}
                                  >
                                    Update
                                  </Button>
                                </TableCell>
                              ) : null} */}

                              <TableCell
                                align="right"
                                // onClick={() => setSelectedId(_id)}
                              >
                                <UserMoreMenu
                                  id={_id}
                                  bannerId={_id}
                                  setIsEdit={setIsEdit}
                                  bannerName="BannerDetails"
                                  title="BannerDetails"
                                  req="DeleteBannerDetails"
                                  setLoading={setLoading}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>

                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>
              <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={users?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </Container>
        </Page>
      )}
    </>
  );
}
