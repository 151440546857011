import { filter } from "lodash";
// import { sentenceCase } from 'change-case';
import { useEffect, useState } from "react";
// import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  // Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Button,
  Input,
} from "@mui/material";
// components
// import Page from '../components/Page';

import Scrollbar from "../../../components/Scrollbar";

import SearchNotFound from "../../../components/SearchNotFound";
import {
  UserListHead,
  UserListToolbar,
  UserMoreMenu,
} from "../../../sections/@dashboard/user";
// mock
// importusers ? from '../_mock/user';
import { useNavigate, useParams } from "react-router-dom";
import SyncLoader from "react-spinners/SyncLoader";
import { ExportCSV } from "../../../utils/ExportCSV";
import Page from "../../../components/Page";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTheme } from "@emotion/react";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "", label: "S.No", alignRight: false },
  { id: "name", label: "Name", alignRight: false },
  { id: "Score", label: "Score", alignRight: false },
  { id: "answer_sheet", label: "Answer Sheet", alignRight: false },
  { id: "file", label: "Cheked Answer Sheet", alignRight: false },
  { id: "updated_at", label: "Last Updated", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.FullName.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis?.map((el) => el[0]);
}

export default function AttemptedTest() {
  const [page, setPage] = useState(0);

  const theme = useTheme();

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");

  const [selectedId, setSelectedId] = useState();

  const [isEdit, setIsEdit] = useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();
  const [users, usersSet] = useState();
  const [loading, setLoading] = useState(true);
  const [score, setScore] = useState("");
  const { testSeriesId } = useParams();
  //console.log(testSeriesId)
  //Getting Details
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/getAttemptedTestAdminPanelSide?testId=${testSeriesId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      usersSet(responseJson?.data);
      setLoading(false);
      if (responseJson?.msg === "not an admin") {
        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("details");
        navigate("/");
      }
    }

    fetchUsers();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target?.checked) {
      const newSelecteds = users?.map((n) => n?.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected?.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected?.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected.concat(selected?.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected?.slice(0, selectedIndex),
        selected?.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target?.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target?.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users?.length) : 0;

  const filteredUsers = applySortFilter(
    users ? users : ["no"],
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers?.length === 0;
  let helperArray = [];
  filteredUsers?.map((item) =>
    helperArray.push({
      _id: item?._id,
      Score: item?.Score,
      answer_sheet: item?.answer_sheet?.fileLoc,
      created_at: item?.created_at,
      updated_at: item?.updated_at,
      user: item?.user,
      is_active: item?.is_active,
      testSeries_id: item?.testSeries_id,
      test_id: item?.test_id,
    })
  );

  const [file, setFile] = useState();
  return (
    <>
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          {" "}
          <SyncLoader
            color={theme.palette.primary.mainTheme}
            loading={loading}
            size={20}
          />
        </Stack>
      ) : (
        <Page title="Attempted Test">
          <Container sx={{ position: "absolute", minWidth: "100%" }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={3}
            >
              <Typography variant="h3" gutterBottom>
                All Attempted Test
              </Typography>
            </Stack>

            <Card>
              <Stack
                pr={3}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <UserListToolbar
                  numSelected={selected?.length}
                  filterName={filterName}
                  onFilterName={handleFilterByName}
                />

                <ExportCSV
                  csvData={helperArray}
                  fileName="Attempted Test Data"
                />
              </Stack>

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={users?.length}
                      numSelected={selected?.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {filteredUsers
                        ?.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        ?.map((row, index) => {
                          const {
                            _id,
                            Score,
                            answer_sheet,
                            updated_at,
                            checked_answer_sheet,
                          } = row;
                          const { FullName, profilePhoto, userId } = row.user;
                          const isItemSelected =
                            selected?.indexOf(FullName) !== -1;

                          row.sno = index + 1 + rowsPerPage * page;

                          //Handling File Update
                          const changeHandler = async (event) => {
                            setFile(event.target?.files[0]);
                          };

                          const handleUpdate = async () => {
                            setLoading(true);
                            const token = localStorage.getItem("token");
                            const authToken = token;
                            const formData = new FormData();
                            if (file) {
                              formData.append("file", file);
                            }
                            if (score == "") {
                              formData.append("Score", Score);
                            } else {
                              formData.append("Score", score);
                            }

                            const config = {
                              headers: {
                                "content-type": "multipart/form-data",
                                Authorization: `Bearer ${authToken}`,
                              },
                            };
                            const res = await axios.put(
                              `https://api-prod.gyaankulam.com/api/v1/adminPanel/updateAttemptedTestSeries/${_id}`,
                              formData,
                              config
                            );
                            setLoading(false);
                            toast.success("Updated");
                            setTimeout(() => window.location.reload(), 1000);
                          };
                          return (
                            <TableRow
                              hover
                              key={index}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                              // sx={{ cursor: 'pointer' }}
                              // onClick={() => navigate('/dashboard/students/studentsProfile')}
                            >
                              {/* <TableCell padding="checkbox">
                            <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, FullName)} />
                          </TableCell> */}
                              <TableCell>
                                <Typography variant="subtitle2" noWrap>
                                  {/* {index + 1} */}
                                  {row.sno}
                                </Typography>
                              </TableCell>

                              <TableCell component="th" scope="row" pl={2}>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  <Avatar
                                    alt={FullName}
                                    src={profilePhoto}
                                    sx={{ width: 28, height: 28 }}
                                  />
                                  <Typography variant="subtitle2" noWrap>
                                    {FullName}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell align="left">
                                {isEdit && selectedId === _id ? (
                                  <Input
                                    onChange={(e) => setScore(e.target?.value)}
                                    name="Score"
                                    placeholder={Score}
                                    type="number"
                                  />
                                ) : (
                                  <span>{Score}</span>
                                )}
                              </TableCell>
                              <TableCell align="left">
                                {" "}
                                <a
                                  href={answer_sheet?.fileLoc}
                                  style={{ color: "black" }}
                                >
                                  <PictureAsPdfIcon
                                    sx={{
                                      cursor: "pointer",
                                      "&:hover": {
                                        color: "red",
                                      },
                                    }}
                                  />
                                </a>
                              </TableCell>
                              <TableCell align="left">
                                {isEdit && selectedId === _id ? (
                                  <>
                                    <input
                                      style={{
                                        display: "none",
                                      }}
                                      id="choose-file"
                                      type="file"
                                      name="file"
                                      onChange={changeHandler}
                                      // onChange={handleChange}
                                    />
                                    <label htmlFor="choose-file">
                                      <FileUploadIcon
                                        sx={{
                                          cursor: "pointer",
                                          "&:hover": {
                                            color: "red",
                                          },
                                        }}
                                      />
                                    </label>
                                  </>
                                ) : (
                                  <a
                                    href={checked_answer_sheet?.fileLoc}
                                    style={{ color: "black" }}
                                  >
                                    <PictureAsPdfIcon
                                      sx={{
                                        cursor: "pointer",
                                        "&:hover": {
                                          color: "red",
                                        },
                                      }}
                                    />
                                  </a>
                                )}
                              </TableCell>

                              <TableCell align="left">{updated_at}</TableCell>
                              {isEdit && selectedId === _id && (
                                <TableCell align="left">
                                  <Button
                                    variant="contained"
                                    onClick={handleUpdate}
                                  >
                                    Update
                                  </Button>
                                </TableCell>
                              )}

                              <TableCell
                                align="right"
                                onClick={() => setSelectedId(_id)}
                              >
                                <UserMoreMenu
                                  id={userId}
                                  isEdit={isEdit}
                                  setIsEdit={setIsEdit}
                                  setLoading={setLoading}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>

                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={users?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </Container>
          <ToastContainer
            position="bottom-center"
            autoClose={1000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </Page>
      )}
    </>
  );
}
