// @mui
import { Grid } from "@mui/material";
// components
import Page from "../components/Page";
import { Container, Typography } from "@mui/material";
// sections
import { AppWidgetSummary } from "../sections/@dashboard/app";
import { useEffect, useState } from "react";
import TodaysSchedule from "../sections/@dashboard/app/TodaysSchedule";
import getAccess from "../utils/getAccess";

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const [counts, setCounts] = useState({});
  const [loading, setLoading] = useState(true);
  const [teacherData, setTeacherData] = useState([]);
  const d = getAccess();
  const role =
    d?.role == "admin" ? "Admin" : d?.role == "teacher" ? "Teacher" : d?.role;
  const teacherId = localStorage.getItem("id");
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      // fetch count
      const fullResponse1 = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/getDashBoardDetails`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson1 = await fullResponse1.json();
      setCounts(responseJson1?.data);
      // console.log("count" +responseJson1?.data)

      //TeacherBatches
      if (role === "Teacher") {
        const teacherResponse = await fetch(
          `https://api-prod.gyaankulam.com/api/v1/adminPanel/getBatchesDetails?teacher_id=${teacherId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const response = await teacherResponse.json();
        setTeacherData(response?.data);
      }
      setLoading(false);
    }

    fetchUsers();
  }, []);
  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Typography variant="h3" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            {role === "Admin" ? (
              <AppWidgetSummary
                title="All Batches"
                // total={batches?.length}
                total={counts?.batchCount}
                icon={"eva:shopping-bag-fill"}
              />
            ) : (
              <AppWidgetSummary
                title="All Assigned Batches"
                total={teacherData?.length}
                // total={counts?.batchCount}
                icon={"eva:shopping-bag-fill"}
              />
            )}
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            {role === "Admin" ? (
              <AppWidgetSummary
                title="All Students"
                // total={users?.length}
                total={counts?.userCount}
                color="info"
                icon={"eva:people-fill"}
              />
            ) : (
              <AppWidgetSummary
                title="All Resources"
                total={0}
                color="info"
                icon={"eva:people-fill"}
              />
            )}
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            {role === "Admin" ? (
              <AppWidgetSummary
                title="All Teachers"
                // total={teachers?.length}
                total={counts?.teacherCount}
                color="warning"
                icon={"fa-solid:chalkboard-teacher"}
              />
            ) : (
              <AppWidgetSummary
                title="All XXX"
                // total={teachers?.length}
                total={counts?.teacherCount}
                color="warning"
                icon={"fa-solid:chalkboard-teacher"}
              />
            )}
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="All Test Series"
              color="error"
              // total={testSeries?.length}
              total={counts?.testSeriesCount}
              icon={"eva:file-text-fill"}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <TodaysSchedule />
          </Grid>

          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            sx={{ display: role === "Teacher" ? "none" : "" }}
          >
            {/* <NewlyResitered /> */}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
