import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";

import "./style.css";

import {
  GridRowModes,
  DataGrid,
  GridRowEditStopReasons,
  GridToolbar,
} from "@mui/x-data-grid";
import SyncLoader from "react-spinners/SyncLoader";
import { useTheme } from "@emotion/react";
import { Stack } from "@mui/material";

const BatchQuizes = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { Id } = useParams();
  const [loading, setLoading] = React.useState(false);

  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});

  React.useEffect(() => {
    const token = localStorage.getItem("token");
    //Admin Side Lecture Details
    setLoading(true);
    async function fetchUsers() {
      const fullResponse = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/getQuizsByBatchId/${Id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      // usersSet(responseJson?.data);
      setRows(responseJson?.data);
      setLoading(false);
    }

    fetchUsers();

    // setLoading(false);
  }, []);

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows?.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows?.filter((row) => row.id !== id));
    }
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const rowsWithSerialNumber = rows?.map((row, index) => ({
    ...row,
    serialNumber: index + 1,
  }));

  const columns = [
    {
      field: "serialNumber",
      headerName: "S NO",
      width: 75,
      align: "left",
      headerAlign: "left",
      editable: false,
      renderCell: (params) => <strong>{params.value}</strong>,
    },

    {
      field: "quiz_title",
      headerName: "Title",
      type: "string",
      width: 150,
      align: "left",
      headerAlign: "left",
      // editable: true
    },
    {
      field: "user",
      headerName: "Created By",
      type: "string",
      width: 200,
      align: "left",
      headerAlign: "left",
      // editable: true
      renderCell: (params) => {
        return <div>{`${params.value?.FullName} (${params.value?.Role})`}</div>;
      },
    },
    {
      field: "quiz_banner",
      headerName: "Image",
      type: "string",
      width: 200,
      align: "left",
      headerAlign: "left",
      // editable: true,
      renderCell: (params) => (
        <img
          src={params.value[0]}
          alt={`Image for row ${params.id}`}
          style={{ width: 150, height: 70 }}
        />
      ),
    },
    {
      field: "quiz_desc",
      headerName: "Desc",
      type: "number",
      width: 150,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => (
        <div dangerouslySetInnerHTML={{ __html: params?.value }}></div>
      ),
    },
    {
      field: "quiz_duration",
      headerName: "Duration",
      type: "number",
      width: 70,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "language",
      headerName: "Language",
      type: "number",
      width: 100,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => (
        <div>{params.value === "hi" ? "Hindi" : "English"}</div>
      ),
    },
    {
      field: "link",
      headerName: "Link",
      type: "number",
      width: 100,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => (
        <div>{params.value == "batch" ? "Batch" : params.value}</div>
      ),
    },
    {
      field: "linkWith",
      headerName: "Link With",
      type: "object",
      width: 150,
      align: "left",
      headerAlign: "left",
      // editable: true,
      renderCell: (params) => (
        <div>{params.value?.title ? params.value?.title : "NA"}</div>
      ),
    },
  ];
  return (
    <div style={{ height: "auto" }}>
      {loading == false ? (
        <DataGrid
          getRowHeight={() => "auto"}
          rows={rowsWithSerialNumber || []}
          columns={columns || []}
          loading={loading}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          // processRowUpdate={processRowUpdate}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 8,
              },
            },
          }}
          pageSizeOptions={[8, 25, 50]}
          slots={{
            //   toolbar: EditToolbar,
            toolbar: GridToolbar,
          }}
          slotProps={{
            toolbar: {
              setRows,
              setRowModesModel,
              showQuickFilter: true,
            },
          }}
        />
      ) : (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          {" "}
          <SyncLoader
            color={theme.palette.primary.mainTheme}
            loading={loading}
            size={20}
          />
        </Stack>
      )}
    </div>
  );
};

export default BatchQuizes;
