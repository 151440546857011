import { Box, Button, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import SyncLoader from "react-spinners/SyncLoader";
import Page from "../../../components/Page";

import "react-toastify/dist/ReactToastify.css";
import BatchDetailsCard from "./components/BatchDetailsCard";
import "./style.css";

import * as XLSX from "xlsx";
import { useTheme } from "@emotion/react";
import BatchDetailsPanel from "./BatchDetailsPanel";
import getAccess from "../../../utils/getAccess";

const BatchDetails1 = () => {
  const access = getAccess();

  const theme = useTheme();
  const navigate = useNavigate();
  const { Id } = useParams();
  const [loading, setLoading] = React.useState(false);
  const [batchName, setBatchName] = React.useState("");
  const [loading1, setLoading1] = React.useState(false);

  const handleDownload = async () => {
    const token = localStorage.getItem("token");
    setLoading1(true);
    const fullResponse = await fetch(
      `https://api-prod.gyaankulam.com/api/v1/adminPanel/timeSpendOnBatch/${Id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const responseJson = await fullResponse.json();

    const workbook = XLSX.utils.book_new();

    responseJson?.data?.forEach((lecture, index) => {
      const lectureName = lecture?.lectureName
        .substring(0, 29)
        .replace(/[^a-zA-Z ]/g, ""); // Truncate lecture name if necessary
      const timeSpendOnLecture = lecture?.timeSpendOnLecture;
      const worksheet = XLSX.utils.json_to_sheet(timeSpendOnLecture);
      XLSX.utils.book_append_sheet(
        workbook,
        worksheet,
        `${lectureName}${index + 1}`
      );
    });

    XLSX.writeFile(workbook, "StudentAnalytics.xlsx");
    setLoading1(false);
  };
  return (
    <>
      {" "}
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          {" "}
          <SyncLoader
            color={theme.palette.primary.mainTheme}
            loading={loading}
            size={20}
          />
        </Stack>
      ) : (
        <Page title="Lecture">
          <Grid container spacing={1} sx={{ height: "80vh" }}>
            <Grid item xs={12} sm={12} md={8} sx={{ height: "100%" }}>
              <Box spacing={3} ml={5}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  mb={3}
                  sx={{ width: "90%" }}
                >
                  <Typography className="page-heading">
                    All Batch Details
                  </Typography>

                  <Stack
                    direction="row"
                    spacing={3}
                    sx={{ marginLeft: "10px", width: "60%" }}
                  >
                    {(access?.accessForTab.includes("read") ||
                      access?.accessForTab.includes("readWrite") ||
                      access?.access.includes("all")) && (
                      <Button
                        variant="contained"
                        sx={{
                          width: "40%",
                          alignSelf: "center",
                          background: theme.palette.primary.mainTheme,
                          "&:hover": { background: "red" },
                        }}
                        disabled={batchName == "" || loading1}
                        onClick={() => handleDownload()}
                      >
                        {loading1 ? "Downloading...." : `Download Analytics`}
                      </Button>
                    )}
                    {(access?.access.includes("24") ||
                      access?.access.includes("all")) && (
                      <Button
                        variant="contained"
                        sx={{
                          width: "40%",
                          alignSelf: "center",
                          background: theme.palette.primary.mainTheme,
                          "&:hover": { background: "red" },
                        }}
                        disabled={batchName == ""}
                        onClick={() => {
                          let newName = "";
                          Array.from(batchName).forEach((elem) => {
                            if (elem !== "/") newName += elem;
                            else newName += "_";
                          });

                          navigate(
                            `/dashboard/batchDetails/addLecture/${Id}/${newName}`
                          );
                        }}
                      >
                        Add Lecture
                      </Button>
                    )}
                  </Stack>
                  {/* {(access?.access.includes("24") ||
                    access?.access.includes("all")) && (
                    <Button
                      variant="contained"
                      sx={{
                        width: "20%",
                        alignSelf: "center",
                        background: theme.palette.primary.mainTheme,
                        "&:hover": { background: "red" },
                      }}
                      disabled={batchName == ""}
                      onClick={() => {
                        let newName = "";
                        Array.from(batchName).forEach((elem) => {
                          if (elem !== "/") newName += elem;
                          else newName += "_";
                        });

                        navigate(
                          `/dashboard/batchDetails/addLecture/${Id}/${newName}`
                        );
                      }}
                    >
                      Add Lecture
                    </Button>
                  )} */}
                </Stack>
                <div>
                  <BatchDetailsPanel />
                </div>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={4} sx={{ height: "100%" }}>
              <BatchDetailsCard batchId={Id} setBatchName={setBatchName} />
            </Grid>
          </Grid>
        </Page>
      )}
    </>
  );
};

export default BatchDetails1;
