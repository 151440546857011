import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Stack,
  IconButton,
  InputAdornment,
  Typography,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components

import { FormProvider, RHFTextField } from "../../../../components/hook-form";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

// import "./general.css";
import { useTheme } from "@emotion/react";
import ClearIcon from "@mui/icons-material/Clear";
import zIndex from "@mui/material/styles/zIndex";

// ----------------------------------------------------------------------

export default function UpdateFourth({
  setShowForm,
  setProductId,
  productId,
  value,
  setValue,
  title,
  setSubmit4,
  editValue,
  fetchUsers,
}) {
  const theme = useTheme();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [restrict, setRestrict] = useState("");
  const [focus, setFocused] = useState(false);
  const [pdf, setPdf] = useState(false);
  const [msg, setMsg] = useState("");
  const [val, setVal] = useState(false);

  const [images, setImages] = useState([]);

  const RegisterSchema = Yup.object().shape({});

  const defaultValues = {};
  const [file, setFile] = useState();

  const [previewImages, setPreviewImages] = useState();
  const changeHandler = async (event) => {
    setFile(event.target?.files);
    const selectedFIles = [];
    const targetFiles = event.target?.files;
    const targetFilesObject = [...targetFiles];
    targetFilesObject?.map((file) => {
      return selectedFIles.push(URL.createObjectURL(file));
    });
    setPreviewImages(selectedFIles);
  };
  //console.log(file)
  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const [progress, setProgress] = useState();
  // for progress bar

  const onSubmit = async (data) => {
    const formData = new FormData();

    setLoading(true);

    let newArr = [];
    //********* HERE IS THE CHANGE ***********
    // console.log(file);
    for (let i = 0; i < file?.length; i++) {
      formData.append("files", file[i]);
    }
    if (images?.length > 1) {
      for (let i = 0; i < images?.length; i++) {
        formData.append("images", images[i]);
      }
    } else {
      formData.append("images", images);
      formData.append("images", "");
    }

    // console.log(formData)

    // formData.append("icon", file);
    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${authToken}`,
      },
      onUploadProgress: (data) => {
        //Set the progress value to show the progress bar
        setProgress(Math.round((100 * data?.loaded) / data?.total));
      },
    };
    // document.querySelector(".mainDiv").style.display = "block";
    setProgress(0);
    axios
      .post(
        `https://api-prod.gyaankulam.com/api/v1/store/addStoreProductFourth/${editValue?.id}`,
        formData,
        config
      )
      .then((response) => {
        //console.log(response?.data);
        if (response?.data?.msg === "Product Details added") {
          setProductId(response?.data?.data?._id);
          toast.success(response?.data?.msg);
          setLoading(false);
          setPdf(false);
          reset();
          setValue(value + 1);
          setSubmit4(true);
          fetchUsers();
          // window.location.href = "/dashboard/storeAllProducts";
        } else {
          setLoading(false);
          toast.error(response?.data?.msg);
        }
      })
      .catch((e) => {
        //console.log(e);
      });
  };
  const handleImages = (item) => {
    // console.log(item?.target?.id);
    let imgs = images.filter((img) => img != item?.target?.id);

    setImages([...imgs]);
  };
  const handleNewImages = (item) => {
    // console.log(file);
    let newFiles = file.filter((img) => img != item);
    setFile([...newFiles]);
    const selectedFIles = [];
    const targetFiles = newFiles;
    const targetFilesObject = [...targetFiles];
    targetFilesObject?.map((file) => {
      return selectedFIles.push(URL.createObjectURL(file));
    });
    setPreviewImages(selectedFIles);
  };
  useEffect(() => {
    setImages([...editValue?.images]);
  }, []);

  // console.log(previewImages);
  return (
    <>
      <Stack spacing={3} px={5}>
        <Typography variant="h3">
          Update Images for <span style={{ color: "blue" }}>{title}</span>{" "}
        </Typography>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} sx={{ width: "70%" }}>
            <Stack direction="row" spacing={3}>
              <RHFTextField
                name="files"
                onChange={changeHandler}
                id="file"
                label="File"
                // error={!file && val}
                helperText="Image Only( Maximum size allowed 100KB)"
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                inputProps={{ multiple: true, accept: "image/*" }}
                InputProps={{
                  classes: {
                    input: "CustomTextField",
                  },
                }}
                onClick={() => setPdf(true)}
                // inputProps={{ accept: "image/*" }}
                type={pdf || focus ? "file" : "text"}
              />

              <div
                style={{
                  width: "600px",
                  overflowX: "scroll",
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                }}
              >
                {!file &&
                  images?.map((item, index) => {
                    // console.log(item)
                    return (
                      <Stack direction="row" sx={{ position: "relative" }}>
                        {" "}
                        <img
                          src={item}
                          alt="imglist"
                          style={{ minWidth: 200, height: 100 }}
                        ></img>
                        <ClearIcon
                          id={item}
                          size="small"
                          sx={{
                            position: "absolute",
                            right: "10px",
                            zIndex: 100,
                            cursor: "pointer",
                            background: "lightgray",
                            borderRadius: "5px",
                            padding: "4px",
                          }}
                          onClick={handleImages}
                        />
                      </Stack>
                    );
                  })}

                {file?.length > 0 && (
                  <>
                    {previewImages?.map((item, index) => {
                      return (
                        <Stack direction="row" sx={{ position: "relative" }}>
                          {" "}
                          <img
                            src={item}
                            alt="imglist"
                            style={{ minWidth: 200, height: 100 }}
                          ></img>
                          <ClearIcon
                            id={item}
                            size="small"
                            sx={{
                              position: "absolute",
                              right: "10px",
                              zIndex: 100,
                              cursor: "pointer",
                              background: "lightgray",
                              borderRadius: "5px",
                              padding: "4px",
                            }}
                          />
                        </Stack>
                      );
                    })}
                    {images?.map((item, index) => {
                      // console.log(item)
                      return (
                        <Stack direction="row" sx={{ position: "relative" }}>
                          {" "}
                          <img
                            src={item}
                            alt="imglist"
                            style={{ minWidth: 200, height: 100 }}
                          />
                          <ClearIcon
                            size="small"
                            sx={{
                              position: "absolute",
                              right: "10px",
                              zIndex: 100,
                              cursor: "pointer",
                              background: "lightgray",
                              borderRadius: "5px",
                              padding: "4px",
                            }}
                            onClick={handleImages}
                          />
                        </Stack>
                      );
                    })}
                  </>
                )}
              </div>
            </Stack>

            {/* <div className="mainDiv">
                        <div className="childDiv" style={{ width: `${progress}%` }}>
                            <span> </span>
                        </div>
                    </div> */}

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
              sx={{
                bgcolor: theme.palette.primary.mainTheme,
                marginTop: "5%",
                "&:hover": { background: "red" },
              }}
              onClick={() => {
                setVal(true);
                setMsg("Required");
              }}
            >
              Next Step
            </LoadingButton>
          </Stack>
        </FormProvider>
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {/* <Typography color={positive ? "green" : "red"} sx={{ textAlign: 'center', width: '80%' }}>{fetchMessage}</Typography> */}
      </Stack>
    </>
  );
}
