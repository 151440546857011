import React, { useEffect } from "react";
import * as Yup from "yup";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import axios from "axios";

// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Stack, Typography, MenuItem, Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components

import { FormProvider, RHFTextField } from "../../../components/hook-form";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTheme } from "@emotion/react";

const UpdateStoreAlerts = ({ editData }) => {
  const [type, setType] = useState("none");
  const [valueId, setValueId] = useState("");
  const [list, setList] = useState([]);

  const theme = useTheme();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const LoginSchema = Yup.object().shape({});

  const defaultValues = {};

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const [value, setValue] = useState();
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/store/getStoreAlertForAdmin/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();

      setValue((prev) => ({
        ...prev,
        title: responseJson?.data?.title,
        link: responseJson?.data?.link,
        isActive: responseJson?.data?.isActive,
        linkWith: responseJson?.data?.linkWith,
      }));
      setType(responseJson?.data?.link);
      // setType(responseJson?.data?.type)
    }
    fetchUsers();
  }, []);

  const onSubmit = async (data) => {
    setLoading(true);
    const dataDetails = {
      title: value?.title,
      link: value?.link,
      linkWith: valueId !== "" ? valueId : value?.linkWith?.id,
      isActive: value?.isActive,
    };

    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    axios
      .put(`/store/updateStoreAlert/${id}`, dataDetails, config)
      .then((response) => {
        if (response?.data?.msg === "Store alert updated successfully") {
          // reset();
          setLoading(false);
          toast.success("Store alert updated successfully");

          setTimeout(() => {
            navigate(`/dashboard/storeAlert`);
            // window.location.reload();
          }, 100);
        } else {
          toast.success(response?.data?.msg);
          setLoading(false);
        }
      })
      .catch((e) => {
        //console.log(e);
      });
  };

  const onChange2 = (e) => {
    const { name, value } = e.target;
    // console.log('parentCategory', name, value)
    setValue((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    if (type !== "" && type !== "none") {
      const token = localStorage.getItem("token");
      async function fetchDetails() {
        const fullResponse = await fetch(
          `https://api-prod.gyaankulam.com/api/v1/store/${
            type == "product"
              ? "getAllStoreProduct"
              : "getAllStoreProductCategory"
          }`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const responseJson = await fullResponse.json();
        let data = responseJson?.data?.filter((item) => item?.isActive == true);
        setList(data);
      }
      fetchDetails();
    }
    setLoading(false);
  }, [type]);

  return (
    <Grid direction="row" pl={5}>
      <Typography variant="h3" m={2}>
        Update Store Alerts
      </Typography>

      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        {/* <Stack direction="row" spacing={5} > */}
        <Stack spacing={3} sx={{ width: "70%" }}>
          <RHFTextField
            name="title"
            value={value?.title}
            onChange={onChange2}
          />

          <Stack direction="row" spacing={3}>
            <RHFTextField
              name="link"
              label={
                value?.link == "none"
                  ? "None"
                  : value?.link == "product"
                  ? "Product"
                  : "Category"
              }
              type="checkbox"
              select
              onChange={onChange2}
            >
              <MenuItem value="none" onClick={() => setType("none")}>
                None
              </MenuItem>
              <MenuItem value="product" onClick={() => setType("product")}>
                Product
              </MenuItem>
              <MenuItem value="category" onClick={() => setType("category")}>
                Category
              </MenuItem>
            </RHFTextField>
            {type !== "none" && type !== "" && (
              <RHFTextField
                name="valueId"
                label={value?.linkWith?.title}
                select
                onChange={onChange2}
              >
                {list?.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={item?.id}
                    onClick={() => setValueId(item?.id)}
                  >
                    {item?.title}
                  </MenuItem>
                ))}
              </RHFTextField>
            )}
          </Stack>
          <RHFTextField
            name="isActive"
            label={value?.isActive ? "Active" : "Inactive"}
            value={value?.isActive}
            onChange={onChange2}
            select
          >
            <MenuItem value={true}>Active</MenuItem>
            <MenuItem value={false}>Inactive</MenuItem>
          </RHFTextField>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          // loading={loading}
          sx={{
            bgcolor: theme.palette.primary.mainTheme,
            width: "70%",
            marginTop: "5%",
            "&:hover": { background: "red" },
          }}
        >
          Update
        </LoadingButton>
      </FormProvider>
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* <Typography color={positive ? 'green' : "red"} mt={3} sx={{ textAlign: 'center', width: '70%', textTransform: 'capitalize' }}>{fetchMessage}</Typography> */}
    </Grid>
  );
};

export default UpdateStoreAlerts;
