import * as Yup from "yup";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Link,
  Stack,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Iconify from "../../../components/Iconify";
import {
  FormProvider,
  RHFTextField,
  RHFCheckbox,
} from "../../../components/hook-form";
import { useTheme } from "@emotion/react";
// ----------------------------------------------------------------------

export default function LoginTeacherForm() {
  const theme = useTheme();

  const navigate = useNavigate();
  const [fetchMessage, setFetchMessage] = useState();

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const defaultValues = {
    email: "",
    password: "",
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      const res = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminTeacher/TeacherLogin`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      const result = await res.json();

      // alert(result?.msg)
      setFetchMessage(result?.msg);
      if (result?.msg === "Teacher Successfully loggedIn") {
        localStorage.setItem("role", "Teacher");
        localStorage.setItem("details", result?.username);
        localStorage.setItem("token", result?.data);
        localStorage.setItem("id", result?.teacher_id);
        localStorage.setItem("detailObject", JSON.stringify(result));
        localStorage.setItem("isLoggedIn", true);
        localStorage.setItem("accessArray", [result?.access]);
        navigate("/dashboard/app", { replace: true });
        window.location.reload();
      }
    } catch (error) {
      //console.log(error);
    }
  };

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <RHFTextField name="email" label="Email address" />

          <RHFTextField
            name="password"
            label="Password"
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    <Iconify
                      icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
        >
          <RHFCheckbox name="remember" label="Remember me" />
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          sx={{ bgcolor: theme.palette.primary.mainTheme }}
        >
          Login
        </LoadingButton>
      </FormProvider>
      <br />
      <Typography sx={{ color: "red", textAlign: "center" }}>
        {fetchMessage}
      </Typography>
    </>
  );
}
