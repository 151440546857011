import * as React from "react";
import { useState } from "react";
import { Box, Select, MenuItem } from "@mui/material";

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  GridToolbarDensitySelector,
  useGridApiContext,
  gridExpandedSortedRowIdsSelector,
} from "@mui/x-data-grid";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import SaveAltIcon from "@mui/icons-material/SaveAlt";

const flattenObject = (obj) => {
  return `${obj.name} ${obj.email} ${obj.phone}`;
};
const flattenArray = (arr) => {
  return arr
    ?.map(
      (item) =>
        `Title - ${item?.title.toString().replaceAll(",", " ")} Quantity - ${
          item?.quantity
        }  Code - ${item?.code}`
    )
    .join(" ");
};

function CustomToolbar({ rows, columns }) {
  const apiRef = useGridApiContext();
  const exportAllRows = async () => {
    const visibleRows = gridExpandedSortedRowIdsSelector(apiRef);

    const filteredRows = rows?.filter((row) => visibleRows.includes(row.id));

    const columnIds = columns?.map((column) => column.field);
    const csvHeader = columnIds.join(", ");
    const csvContent = filteredRows
      ?.map((row) => {
        const rowValues = columnIds?.map((columnId) => {
          if (columnId === "productDetails") {
            return flattenArray(row.productDetails);
          } else if (columnId === "user") {
            return flattenObject(row.user);
          }
          return row[columnId]?.toString()?.replaceAll(",", " ");
        });
        return rowValues.join(", ");
      })
      .join("\n");
    const fullCsvContent = `${csvHeader}\n${csvContent}`;
    const blob = new Blob([fullCsvContent], { type: `text/csv;charset=utf-8` });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", "StoreCarts.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />

      <GridToolbarDensitySelector />
      <Tooltip title="Export All">
        <IconButton onClick={exportAllRows}>
          <SaveAltIcon style={{ color: "red" }} />
        </IconButton>
      </Tooltip>
      <GridToolbarQuickFilter style={{ marginLeft: "auto" }} />
    </GridToolbarContainer>
  );
}

export default function StoreCartsTable() {
  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [loading, setLoading] = useState();

  React.useEffect(() => {
    const token = localStorage.getItem("token");
    setLoading(true);
    async function fetchOrder() {
      const response = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/store/getAllCartDetails`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await response?.json();
      setRows(responseJson?.data ?? []);
      setLoading(false);
    }
    fetchOrder();
  }, []);

  const columns = [
    {
      field: "sNo",
      headerName: "S NO",
      width: 150,
      align: "left",
      headerAlign: "left",
      editable: false,
      renderCell: (params) => <strong>{params.value}</strong>,
    },

    {
      field: "productDetails",
      headerName: "Product Details",
      width: 300,
      align: "left",
      headerAlign: "left",
      //   editable: true,
      renderCell: (params) => (
        <div>
          {params.value?.map((detail, index) => (
            <div key={index}>
              <strong>Title:</strong> {detail?.title}
              <br />
              <strong>Quantity:</strong> {detail?.quantity}
              <br />
              <strong>Code:</strong> {detail?.code}
            </div>
          ))}
        </div>
      ),
    },
    {
      field: "user",
      headerName: "User Details",
      type: "string",
      width: 250,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => (
        <div>
          <strong>Name:</strong> {params.value?.name}
          <br />
          <strong>Email:</strong> {params.value?.email}
          <br />
          <strong>Phone:</strong> {params.value?.phone}
        </div>
      ),
    },
    {
      field: "createdAt",
      headerName: "Created At",

      width: 150,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "updatedAt",
      headerName: "Updated Date",

      width: 150,
      align: "left",
      headerAlign: "left",
    },
  ];

  return (
    <Box
      sx={{
        height: 590,
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <h2 style={{ marginBottom: "6px" }}>All Store Carts</h2>
      <DataGrid
        rows={rows}
        columns={columns || []}
        loading={loading}
        getRowHeight={() => "auto"}
        autoHeight={true}
        getEstimatedRowHeight={() => 200}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 7,
            },
          },
        }}
        pageSizeOptions={[7, 25, 50]}
        slots={{
          toolbar: (gridProps) => (
            <CustomToolbar {...gridProps} rows={rows} columns={columns || []} />
          ),
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel, showQuickFilter: true },
        }}
      />
    </Box>
  );
}
