import { Card, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { fShortenNumber } from "../../../utils/formatNumber";
import Iconify from "../../../components/Iconify";
import { responsiveFontSizes, styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import InfoCard from "../../../components/batch/InfoCard";
import SyncLoader from "react-spinners/SyncLoader";
import { useTheme } from "@emotion/react";
import axios from "axios";
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridToolbar,
} from "@mui/x-data-grid";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { VisibilityOutlined } from "@mui/icons-material";
import getAccess from "../../../utils/getAccess";
const StudentTestSeries1 = () => {
  const d = getAccess();
  const navigate = useNavigate();
  const theme = useTheme();
  const [batchInfo, setBatchInfo] = useState();
  const [loading, setLoading] = React.useState(true);

  const IconWrapperStyle = styled("div")(({ theme }) => ({
    margin: "auto",
    display: "flex",
    borderRadius: "50%",
    alignItems: "center",
    width: theme.spacing(8),
    height: theme.spacing(8),
    justifyContent: "center",
    marginBottom: theme.spacing(3),
  }));

  //Batches
  //  table thind
  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows?.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows?.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows?.map((row) => (row.id === newRow.id ? updatedRow : row)));
    //   editDataInDb(newRow)
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const rowsWithSerialNumber = rows?.map((row, index) => ({
    ...row,
    serialNumber: index + 1,
  }));
  const columns = [
    {
      field: "serialNumber",
      headerName: "S NO",
      width: 75,
      align: "left",
      headerAlign: "left",
      editable: false,
      renderCell: (params) => <strong>{params.value}</strong>,
    },

    {
      field: "testseries_name",
      headerName: "Title",
      type: "string",
      width: 200,
      align: "left",
      headerAlign: "left",
      editable: true,
    },
    {
      field: "starting_date",
      headerName: "Start Date",
      type: "string",
      width: 200,
      align: "left",
      headerAlign: "left",
      // editable: true,
    },
    {
      field: "exam_type",
      headerName: "Exam Type",
      type: "string",
      width: 150,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "student",
      headerName: "Students",
      type: "object",
      width: 100,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => (
        <div>{params.value ? params.value?.length : 0}</div>
      ),
    },

    {
      field: "is_active",
      headerName: "Status",
      type: "boolean",
      width: 100,
      align: "left",
      headerAlign: "left",
      editable: true,
      renderCell: (params) => (
        <div style={{ color: params.value == true ? "green" : "red" }}>
          {params.value ? "Active" : "inActive"}
        </div>
      ),
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        const rowData = rows?.find((item) => item?.id === id);
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          d?.access?.includes("all") ||
          d?.accessForTab?.includes("read") ||
          d?.accessForTab?.includes("readWrite") ? (
            <GridActionsCellItem
              icon={<VisibilityOutlined />}
              label="Edit"
              className="textPrimary"
              // onClick={handleEditClick(id)}
              onClick={() =>
                navigate(
                  `/dashboard/studentTestSeries/studentList/${
                    rowData.is_active ? "1" : "0"
                  }/${id}`
                )
              }
              color="inherit"
            />
          ) : (
            <></>
          ),

          //     <GridActionsCellItem
          //     icon={<DeleteIcon style={{ color : 'red'}}/>}
          //     label="Delete"
          //     onClick={handleDeleteClick(id)}
          //     // onClick={ (id) => handleOpen(id)}

          //     color="inherit"
          //   />
        ];
      },
    },
  ];

  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/getallTestSeriesdetails`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      //console.log(responseJson?.data);
      setBatchInfo(responseJson?.data);
      setRows(responseJson?.data);
      setLoading(false);
    }
    fetchUsers();
  }, []);
  //console.log(batchInfo);
  return (
    <>
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          {" "}
          <SyncLoader
            color={theme.palette.primary.mainTheme}
            loading={loading}
            size={20}
          />
        </Stack>
      ) : (
        <>
          <Typography variant="h3" mb={5} ml={3}>
            Test Series Purchases
          </Typography>
          <DataGrid
            // sx={{ overflow: "auto" }}
            rowHeight={50}
            rows={rowsWithSerialNumber || []}
            loading={loading}
            columns={columns || []}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 15,
                },
              },
            }}
            pageSizeOptions={[15, 25, 50]}
            slots={{
              //   toolbar: EditToolbar,
              toolbar: GridToolbar,
            }}
            slotProps={{
              toolbar: { setRows, setRowModesModel, showQuickFilter: true },
            }}
          />
          <ToastContainer
            position="bottom-center"
            autoClose={1000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </>
      )}
    </>
  );
};

export default StudentTestSeries1;
