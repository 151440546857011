import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import StoreOrdersTable from "./StoreOrdersTable";
import getAccess from "../../../utils/getAccess";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const StoreOrder = () => {
  // const access = getAccess();
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  React.useEffect(() => {
    const token = localStorage.getItem("token");
    setLoading(true);
    async function fetchOrder() {
      const response = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/store/getAllOrder`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responseJson = await response?.json();
      setData(responseJson?.data ?? []);
      setLoading(false);
    }
    fetchOrder();
  }, []);
  return (
    <Box sx={{ width: "100%" }}>
      <h2 style={{ marginBottom: "6px" }}>All Store Orders</h2>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{ width: "100%" }}
        >
          <Tab label="Recent orders" sx={{ width: "10%" }} {...a11yProps(0)} />

          <Tab label="Placed" sx={{ width: "10%" }} {...a11yProps(1)} />
          <Tab label="Shipped" sx={{ width: "10%" }} {...a11yProps(2)} />
          <Tab label="In Transit" sx={{ width: "10%" }} {...a11yProps(3)} />
          <Tab
            label="Out For Delivery"
            sx={{ width: "10%" }}
            {...a11yProps(4)}
          />
          <Tab label="Delivered" sx={{ width: "10%" }} {...a11yProps(5)} />
          <Tab label="Pending" sx={{ width: "10%" }} {...a11yProps(6)} />
          <Tab label="Payment Failed" sx={{ width: "10%" }} {...a11yProps(7)} />
          <Tab label="Cancelled" sx={{ width: "10%" }} {...a11yProps(8)} />
          <Tab label="User Cancelled" sx={{ width: "10%" }} {...a11yProps(9)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <StoreOrdersTable
          data={data}
          type="processing"
          paid="paid"
          isLoading={loading}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <StoreOrdersTable
          data={data}
          type="placed"
          paid=""
          isLoading={loading}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <StoreOrdersTable
          data={data}
          type="shipped"
          paid=""
          isLoading={loading}
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <StoreOrdersTable
          data={data}
          type="inTransit"
          paid=""
          isLoading={loading}
        />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <StoreOrdersTable
          data={data}
          type="outForDelivery"
          paid=""
          isLoading={loading}
        />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <StoreOrdersTable
          data={data}
          type="delivered"
          paid=""
          isLoading={loading}
        />
      </TabPanel>
      <TabPanel value={value} index={6}>
        <StoreOrdersTable
          data={data}
          type="pending"
          paid=""
          isLoading={loading}
        />
      </TabPanel>
      <TabPanel value={value} index={7}>
        <StoreOrdersTable
          data={data}
          type="processing"
          paid="notPaid"
          isLoading={loading}
        />
      </TabPanel>
      <TabPanel value={value} index={8}>
        <StoreOrdersTable
          data={data}
          type="cancelled"
          paid=""
          isLoading={loading}
        />
      </TabPanel>
      <TabPanel value={value} index={9}>
        <StoreOrdersTable
          data={data}
          type="userCancelled"
          paid=""
          isLoading={loading}
        />
      </TabPanel>
    </Box>
  );
};

export default StoreOrder;
