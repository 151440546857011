import * as Yup from "yup";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Link,
  Stack,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Iconify from "../../../components/Iconify";
import {
  FormProvider,
  RHFTextField,
  RHFCheckbox,
} from "../../../components/hook-form";
import { User } from "../../../context/context";
import { useTheme } from "@emotion/react";
// ----------------------------------------------------------------------

export default function LoginForm() {
  const theme = useTheme();
  const navigate = useNavigate();

  const [fetchMessage, setFetchMessage] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [otpText, setOtpText] = useState(false);
  const [otp, setOtp] = useState();

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const defaultValues = {
    email: "",
    password: "",
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
  const handleOtp = (e) => {
    setOtp(e.target?.value);
  };

  const onSubmit = async (data) => {
    let browserInfo = {
      userAgent: navigator.userAgent,
      language: navigator.language,
      platform: navigator.platform,
    };
    let locationInfo = {
      latitude: "",
      longitude: "",
    };
    //  if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition(function(position) {
    //       locationInfo = {
    //           latitude: position.coords.latitude,
    //           longitude: position.coords.longitude
    //       };

    //       console.log('Location Information:', locationInfo);
    //       console.log('Browser Information:', browserInfo);
    //   }, function(error) {
    //       console.error("Error getting geolocation:", error.message);
    //   });
    //  } else {
    //     console.error("Geolocation is not supported by this browser.");
    // }

    // console.log('Location Information:', locationInfo);
    //   console.log('Browser Information:', browserInfo);
    // let key = 'AIzaSyBBsAxhtmjp7gpqaxa1yYLF_OuXV3wMFWk';
    // const latitude = locationInfo.latitude;
    //  const longitude = locationInfo.longitude;
    // const latLngString = `${latitude},${longitude}`
    // const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLngString}&key=${key}`
    // // let location = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?
    // //   latlng=${locationInfo.latitude},${locationInfo.longitude}&key=${key}`);
    // // console.log(url);
    // let location = await fetch(url);
    // let locationObj = await location.json();
    // console.log(locationInfo +" -> browserInfo"+ browserInfo);
    // console.log(locationObj);
    // const geocoder = new google.maps.Geocoder();

    try {
      const res = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminTeacher/adminLogin`,
        {
          // const res = await fetch(`http://localhost:3002/api/v1/adminTeacher/adminLogin`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      const result = await res.json();
      if (result?.status == true && result?.msg == "OTP send successfully") {
        setOtpText(true);
        localStorage.setItem("email", data?.email);
        setFetchMessage(result?.msg);
      } else {
        setFetchMessage(result?.msg);
        // localStorage.setItem("role", 'Admin');
      }
      //console.log(result)
      // setFetchMessage(result?.msg == 'Successfully loggedIn' ? 'Log In Successfully' : result?.msg)
      // if (result?.msg === 'Successfully loggedIn') {
      //   if (result?.role === 'admin') {
      //     // localStorage.setItem("role", 'Admin');
      //     // localStorage.setItem("idOfAdmin", result?.adminId);
      //     // localStorage.setItem("detailObject", JSON.stringify(result));
      //     // localStorage.setItem("details", result?.username);
      //     // // navigate('/dashboard/app', { replace: true });
      //     // window.location.href = '/dashboard/app';
      //     // localStorage.setItem("token", result?.data);
      //     // localStorage.setItem("isLoggedIn", true);
      //   } else if (result?.role === 'subadmin') {
      //     // localStorage.setItem("role", result?.role);
      //     // localStorage.setItem("idOfAdmin", result?.adminId);
      //     // localStorage.setItem('id', result?.adminId)
      //     // localStorage.setItem("detailObject", JSON.stringify(result));
      //     // localStorage.setItem("details", result?.username);
      //     // // navigate('/dashboard/app', { replace: true });
      //     // window.location.href = '/dashboard/app';
      //     // localStorage.setItem("token", result?.data);
      //     // localStorage.setItem("isLoggedIn", true);
      //   }

      // }

      // window.location = "/";
    } catch (error) {
      //console.log(error);
    }
  };
  const handleVerify = async () => {
    // console.log(data)

    const email = localStorage.getItem("email");
    // console.log( otp + " "+ email);
    try {
      const res = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminTeacher/verify2FA`,
        {
          // const res = await fetch(`http://localhost:3002/api/v1/adminTeacher/adminLogin`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ otp, email }),
        }
      );
      const result = await res.json();
      // console.log(result)
      setFetchMessage(
        result?.msg == "Successfully loggedIn"
          ? "Log In Successfully"
          : result?.msg
      );
      if (result?.msg === "Successfully loggedIn") {
        if (result?.role === "admin") {
          localStorage.setItem("role", "Admin");
          localStorage.setItem("idOfAdmin", result?.adminId);
          localStorage.setItem("detailObject", JSON.stringify(result));
          localStorage.setItem("details", result?.username);
          localStorage.setItem("id", result?.id);
          // navigate('/dashboard/app', { replace: true });
          window.location.href = "/dashboard/app";
          localStorage.setItem("token", result?.data);
          localStorage.setItem("isLoggedIn", true);
        } else if (result?.role === "subadmin") {
          localStorage.setItem("role", result?.role);
          localStorage.setItem("idOfAdmin", result?.adminId);
          localStorage.setItem("id", result?.adminId);
          localStorage.setItem("detailObject", JSON.stringify(result));
          localStorage.setItem("details", result?.username);
          // navigate('/dashboard/app', { replace: true });
          window.location.href = "/dashboard/app";
          localStorage.setItem("token", result?.data);
          localStorage.setItem("isLoggedIn", true);
        }
      }
      window.location = "/";
    } catch (error) {
      //console.log(error);
    }
  };
  const [label2, setLabel] = useState(true);
  // const onChange3 = (e) => {
  //   const { value } = e.target
  //   if (value == '') {
  //     setLabel(false)
  //   }
  //   //console.log(value)

  // }

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        {!otpText && (
          <Stack spacing={3}>
            <RHFTextField
              name="email"
              label="Email address"
              InputLabelProps={{ shrink: label2 }}
            />

            <RHFTextField
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      <Iconify
                        icon={
                          showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
        )}

        {!otpText && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ my: 2 }}
          >
            <RHFCheckbox name="remember" label="Remember me" />
          </Stack>
        )}
        {
          otpText && (
            <Stack spacing={3}>
              <RHFTextField
                name="otp"
                label="Enter Otp"
                type="number"
                onChange={(e) => handleOtp(e)}
              />
              <LoadingButton
                fullWidth
                size="large"
                variant="contained"
                onClick={() => handleVerify()}
                sx={{ bgcolor: theme.palette.primary.mainTheme }}
              >
                Submit OTP
              </LoadingButton>
            </Stack>
          )

          // <Typography sx={{ color: 'red', textAlign: 'center' }}>{fetchMessage}</Typography>
        }

        {!otpText && (
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            sx={{ bgcolor: theme.palette.primary.mainTheme }}
          >
            Login
          </LoadingButton>
        )}
      </FormProvider>
      <Typography sx={{ color: "red", textAlign: "center" }}>
        {fetchMessage}
      </Typography>
    </>
  );
}
