import * as Yup from "yup";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// form
import { set, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// @mui
import { Stack, Typography, MenuItem, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Select from "react-select";
import makeAnimated from "react-select/animated";
// components

import { FormProvider, RHFTextField } from "../../../components/hook-form";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useTheme } from "@emotion/react";
import MyCkEditor from "../../../components/hook-form/MyCkEditor";
// ----------------------------------------------------------------------

export default function AddQuiz() {
  const theme = useTheme();
  const animatedComponents = makeAnimated();

  const [loading, setLoading] = useState(false);
  const [focusThree, setFocusedThree] = useState(false);
  const [showBanner, setShowBanner] = useState(false);
  const [msg, setMsg] = useState("");
  const [val, setVal] = useState(false);
  const [type, setType] = useState("none");
  const [valueId, setValueId] = useState("");
  const [list, setList] = useState([]);
  const [dataForDesc, setDataForDesc] = useState();

  const navigate = useNavigate();

  const changeHandler = async (event) => {
    setFile(event.target?.files[0]);
  };

  const RegisterSchema = Yup.object().shape({
    title: Yup.string().required("Title required"),
    // description: Yup.string().required("Description is required"),
    duration: Yup.string().required("Duration is required"),
    // NoOfQues: Yup.string().required('No. Of Questions is required'),
    isNegative: Yup.boolean().required("Negative Marking is required"),
    isActive: Yup.string().required("Status is required"),
    language: Yup.string().required("Language is required"),
    eachQueMarks: Yup.string().required("Language is required"),
    resultType: Yup.string().required("Result Type is required"),
    negativeMarks: Yup.number().when("isNegative", (isNegative, schema) => {
      return isNegative
        ? schema
            .test(
              "positive-and-greater-than-zero",
              "Negative Marks must be a positive number greater than zero",
              (value) => value > 0
            )
            .max(
              Yup.ref("eachQueMarks"),
              "Negative Marks must not exceed Each Question Marks"
            )
        : schema;
    }),
    link: Yup.string()
      .required("Link Banner Required")
      .oneOf(["none", "batch", "testSeries"], "Invalid Link Value"),
    // valueId: Yup.string().when('link', {
    //   is: (link) => link !== 'none' && link !== '',
    //   then: Yup.string().required('Link With Required'),
    //   otherwise: Yup.string().notRequired(),
    // }),
    // negativeMarks: Yup.number().min(
    //   0,
    //   "Quantity must be greater than or equal to 0"
    // ),
  });

  const defaultValues = {
    title: "",
    description: "",
    duration: "",
    // NoOfQues: '',
    isNegative: "",
    isActive: true,
    language: "enhi",
    eachQueMarks: "",
    negativeMarks: 0,
    resultType: "",
    link: "",
    linkWith: "",
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const [showNegative, setShowNegative] = useState(false);

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;
  const [file, setFile] = useState();

  let formatDate = (date) => {
    let datehelper = "" + date;
    datehelper = datehelper.split(" ");
    let monthsList = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let time = datehelper[4];
    let year = datehelper[3];
    let month = `0${monthsList.indexOf(datehelper[1]) + 1}`.slice(-2);
    let day = datehelper[2];

    return `${day}-${month}-${year} ${time}`;
  };
  const onSubmit = async (data) => {
    //console.log(data);
    let created_at = formatDate(starting?.$d);
    setLoading(true);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("title", data?.title);
    formData.append("description", dataForDesc);
    formData.append("duration", data?.duration);
    // formData.append('no_ques    ', data?.NoOfQues);
    formData.append("isNegative", data?.isNegative);
    formData.append("language", data?.language);
    formData.append("isActive", data?.isActive);
    formData.append("eachQueMarks", data?.eachQueMarks);
    formData.append("resultType", data?.resultType);
    if (data?.isNegative) {
      formData.append("negativeMarks", data?.negativeMarks);
    }
    formData.append("link", data?.link);
    if (data?.link !== "" && data?.link !== "none") {
      // formData.append('linkWith' , data?.valueId);
      formData.append("linkWith", valueId?._id);
    }
    formData.append("created_at", created_at);
    // alert(formData);
    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${authToken}`,
      },
    };
    axios
      .post(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/addQuiz`,
        formData,
        config
      )
      .then((response) => {
        // axios.post(`http:localhost:5000/api/v1/adminPanel/addQuiz`, formData, config).then((response) => {
        //console.log(response?.data);
        if (response?.data?.msg === "Quiz Details Added Successfully") {
          toast.success(response?.data?.msg);
          setVal(false);
          setMsg("");
          setLoading(false);
          reset();
          setTimeout(() => {
            navigate("/dashboard/quiz");
          }, 1000);
        } else {
          toast.error(response?.data?.msg);
          setLoading(false);
        }
      })
      .catch((e) => {
        //console.log(e);
      });
  };
  const handleChangeValue = (data) => {
    setValueId(data);
  };

  const [starting, setStarting] = useState(null);
  const handleChange = (newValue) => {
    setStarting(newValue);
  };

  useEffect(() => {
    if (type !== "" && type !== "none") {
      const token = localStorage.getItem("token");
      async function fetchDetails() {
        const fullResponse = await fetch(
          `https://api-prod.gyaankulam.com/api/v1/adminPanel/${
            type == "batch" ? "getBatches" : "getallTestSeriesdetails"
          }`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const responseJson = await fullResponse.json();
        let data = responseJson?.data?.filter(
          (item) => item?.is_active == true
        );
        setList(data);
      }
      fetchDetails();
    }
    setLoading(false);
  }, [type]);

  return (
    <>
      <Stack spacing={3} px={5}>
        <Typography variant="h3">Add Quiz</Typography>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} sx={{ width: "70%" }}>
            <RHFTextField name="title" label="Quiz Title" />
            <Stack direction="row" spacing={3}>
              <RHFTextField
                name="duration"
                label="Duration (in Min)"
                type="number"
              />
              {/*
                <RHFTextField
                  name="language"
                  label="Language"
                  sx={{ width: "100%" }}
                  select
                >
                  <MenuItem value="en">English</MenuItem>
                  <MenuItem value="hi">Hindi</MenuItem>
                  <MenuItem value="enhi">Bilungual</MenuItem>
                </RHFTextField>
  */}
              {/* <RHFTextField name="NoOfQues" label="No. Of Questions" /> */}
            </Stack>
            {/* <RHFTextField name="description" label="Description" /> */}
            {/*<Typography>Description</Typography>*/}
            <MyCkEditor
              dataForDesc={dataForDesc}
              setDataForDesc={setDataForDesc}
            />
            <RHFTextField
              name="file"
              error={!file && val}
              onChange={changeHandler}
              onFocus={() => setFocusedThree(true)}
              onBlur={() => setFocusedThree(false)}
              // inputProps={{ accept: "application/pdf" }}
              // helperText="Pdf Files Only"
              InputProps={{
                classes: {
                  input: "CustomTextField",
                },
              }}
              label="Banner"
              onClick={() => setShowBanner(true)}
              type={showBanner || focusThree ? "file" : "text"}
            />

            <Stack direction="row" spacing={3}>
              <Stack
                sx={{ width: type !== "none" && type !== "" ? "50%" : "100%" }}
              >
                <RHFTextField
                  name="link"
                  label="Link Quiz"
                  type="checkbox"
                  select
                >
                  <MenuItem value="none" onClick={() => setType("none")}>
                    None
                  </MenuItem>
                  <MenuItem value="batch" onClick={() => setType("batch")}>
                    Batch
                  </MenuItem>
                  <MenuItem
                    value="testSeries"
                    onClick={() => setType("testSeries")}
                  >
                    Test Series
                  </MenuItem>
                </RHFTextField>
              </Stack>

              {type !== "none" && type !== "" && (
                // <RHFTextField name="valueId" label={ type == "batch" ? "Link With Batch" : "Link With Test Series"} select>
                // {list?.map((item, index) => (

                //                 <MenuItem
                //                   key={index}
                //                   value={item?._id}
                //                   onClick={() => setValueId(item?._id)}
                //                 >
                //                   {type == "batch"
                //                     ? item?.batch_name
                //                     : item?.testseries_name}
                //                 </MenuItem>
                //               )

                // )}
                //     </RHFTextField>
                <Stack sx={{ width: "50%", height: "100%" }}>
                  <Select
                    name="valueId"
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    value={valueId}
                    onChange={handleChangeValue}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    //  defaultValue={[colourOptions[4], colourOptions[5]]}
                    isMulti={false}
                    options={list}
                  />
                </Stack>
              )}
            </Stack>
            <Stack direction="row" spacing={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label="Starting Date time"
                  value={starting?.$d}
                  onChange={handleChange}
                  // name="starting"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ width: "100%" }}
                      error={starting == null}
                    />
                  )}
                />
              </LocalizationProvider>
              <RHFTextField name="resultType" label="Result Type" select>
                <MenuItem value={true}>Manual Result</MenuItem>
                <MenuItem value={false}>Autogenerate Result</MenuItem>
              </RHFTextField>
            </Stack>

            <Stack direction="row" spacing={3}>
              <RHFTextField
                name="isNegative"
                label="Negative Marking"
                sx={{ width: "100%" }}
                select
                // value={showNegative}
                // type="checkbox"
              >
                <MenuItem value={true} onClick={() => setShowNegative(true)}>
                  Yes
                </MenuItem>
                <MenuItem value={false} onClick={() => setShowNegative(false)}>
                  No
                </MenuItem>
              </RHFTextField>
              <RHFTextField name="isActive" label="Status" select>
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </RHFTextField>
            </Stack>

            <Stack direction="row" spacing={3}>
              <RHFTextField
                name="eachQueMarks"
                label="Each Question Marks"
                sx={{ width: "100%" }}
                type="number"
              />

              {showNegative && (
                <RHFTextField
                  name="negativeMarks"
                  label="Negative Marking"
                  sx={{ width: "100%" }}
                  type="number"
                />
              )}
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
              sx={{
                bgcolor: theme.palette.primary.mainTheme,
                marginTop: "5%",
                "&:hover": { background: "red" },
              }}
              onClick={() => {
                setVal(true);
                setMsg("Required");
              }}
            >
              Add
            </LoadingButton>
          </Stack>
        </FormProvider>
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Stack>
    </>
  );
}
