import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Stack,
  IconButton,
  InputAdornment,
  Typography,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components

import { FormProvider, RHFTextField } from "../../../../components/hook-form";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

// import "./general.css";
import { useTheme } from "@emotion/react";
import RHFTextEditor from "../../../../components/hook-form/RHFTextEditor";

// ----------------------------------------------------------------------

export default function UpdateSecond({
  setShowForm,
  setProductId,
  productId,
  value,
  setValue,
  title,
  setSubmit2,
  onChange2,
  editValue,
  fetchUsers,
}) {
  const theme = useTheme();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [restrict, setRestrict] = useState("");
  const [focus, setFocused] = useState(false);
  const [pdf, setPdf] = useState(false);
  const [msg, setMsg] = useState("");
  const [val, setVal] = useState(false);

  const RegisterSchema = Yup.object().shape({});

  const defaultValues = {};

  const [keyFeature, setKeyFeature] = useState(editValue?.keyFeature);
  const onChange3 = (value) => {
    setKeyFeature(value);
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const [progress, setProgress] = useState();

  const onSubmit = async (data) => {
    setLoading(true);
    const details = {
      inStock: editValue?.inStock,
      productType: data?.productType
        ? data?.productType
        : editValue?.productType,
      maxPurchaseQty: data?.maxPurchaseQty
        ? data?.maxPurchaseQty
        : editValue?.maxPurchaseQty,
      deliveryType: data?.deliveryType
        ? data?.deliveryType
        : editValue?.deliveryType,
      marketingCat: data?.marketingCat
        ? data?.marketingCat
        : editValue?.marketingCat,
      keyFeature: keyFeature,
    };

    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      onUploadProgress: (data) => {
        //Set the progress value to show the progress bar
        setProgress(Math.round((100 * data?.loaded) / data?.total));
      },
    };
    setProgress(0);
    axios
      .post(
        `https://api-prod.gyaankulam.com/api/v1/store/addStoreProductSecond/${editValue?.id}`,
        details,
        config
      )
      .then((response) => {
        if (response?.data?.msg === "Product Details added") {
          setProductId(response?.data?.data?._id);
          toast.success(response?.data?.msg);
          setLoading(false);
          setPdf(false);
          reset();
          setValue(value + 1);
          setSubmit2(true);
          fetchUsers();
        } else {
          toast.error(response?.data?.msg);
          setLoading(false);
        }
      })
      .catch((e) => {
        //console.log(e);
      });
  };

  return (
    <>
      <Stack spacing={3} px={5}>
        <Typography variant="h3">
          Update <span style={{ color: "blue" }}>{title}</span> Product details
        </Typography>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} sx={{ width: "70%" }}>
            <Stack direction="row" spacing={3}>
              <RHFTextField
                name="productType"
                label={editValue?.productType}
                select
              >
                <MenuItem value="simple">Simple</MenuItem>

                <MenuItem value="grouped">Grouped</MenuItem>
                <MenuItem value="variable">Variable</MenuItem>
              </RHFTextField>
              <RHFTextField
                name="inStock"
                value={editValue?.inStock}
                type="number"
                label="Stock"
                onChange={onChange2}
              />
            </Stack>

            <Stack direction="row" spacing={3}>
              <RHFTextField
                name="maxPurchaseQty"
                value={editValue?.maxPurchaseQty}
                type="number"
                label="Max Purchase Quantity"
                onChange={onChange2}
              />
              <RHFTextField
                name="deliveryType"
                label={editValue?.deliveryType}
                select
              >
                <MenuItem value="cod">COD</MenuItem>
                <MenuItem value="online">Online</MenuItem>
                <MenuItem value="both">Both</MenuItem>
              </RHFTextField>
              <RHFTextField
                name="marketingCat"
                label={editValue?.marketingCat}
                select
              >
                <MenuItem value="POPULAR">Popular Products</MenuItem>
                <MenuItem value="BOOK">Book Section</MenuItem>
                <MenuItem value="SA">Stationary Accessories</MenuItem>
                <MenuItem value="HDC">High Demanding Combos</MenuItem>
                <MenuItem value="HDP">High Demanding Products</MenuItem>
              </RHFTextField>
            </Stack>
            <RHFTextEditor
              name="keyFeature"
              label="Key Feature"
              value={keyFeature}
              onChange={onChange3}
            />

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
              sx={{
                bgcolor: theme.palette.primary.mainTheme,
                marginTop: "5%",
                "&:hover": { background: "red" },
              }}
              onClick={() => {
                setVal(true);
                setMsg("Required");
              }}
            >
              Next
            </LoadingButton>
          </Stack>
        </FormProvider>
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {/* <Typography color={positive ? "green" : "red"} sx={{ textAlign: 'center', width: '80%' }}>{fetchMessage}</Typography> */}
      </Stack>
    </>
  );
}
