import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Stack,
  IconButton,
  InputAdornment,
  Typography,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Iconify from "../../../components/Iconify";
import { FormProvider, RHFTextField } from "../../../components/hook-form";
import { ToastContainer, toast } from "react-toastify";
import { useTheme } from "@emotion/react";

// ----------------------------------------------------------------------

export default function AddTelegram() {
  const theme = useTheme();

  const navigate = useNavigate();
  const [fetchMessage, setFetchMessage] = useState();
  const [positive, setPositive] = useState(false);
  const [users, usersSet] = useState([]);
  const [loading, setLoading] = useState(false);
  const RegisterSchema = Yup.object().shape({
    title: Yup.string().required("Title required"),
    // lastName: Yup.string().required('Last name required'),
    // category: Yup.string().required('Category is required'),
    channel_url: Yup.string().required("Url No. is required"),
    // language: Yup.string().required('Language is required'),

    is_active: Yup.boolean().required("Status Required"),
  });

  const defaultValues = {
    title: "",
    // category: '',
    channel_url: "",
    language: "",
    is_active: true,
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    setLoading(true);
    let token = localStorage.getItem("token");
    //console.log(token);
    // const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6IlRlc3RpbmdAZ21haWwuY29tIiwiaWF0IjoxNjYzOTQzMTc5LCJleHAiOjE2NjM5NDQ5Nzl9.ix9ZXfVEqHrH20-bnMxHQD9c4Z0Z3plfkJWu3YiisKY'
    try {
      const res = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/addTelegramChannelDetails`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        }
      );
      const result = await res.json();
      //console.log(result)
      setFetchMessage(result?.msg);
      toast.success(result?.msg);
      setLoading(false);
      reset();
      // if (result?.msg === 'Details Added successfully') {

      //     // navigate('/dashboard', { replaced: true })
      // }
    } catch (error) {
      //console.log(error);
    }
    // navigate('/dashboard', { replace: true });
  };

  return (
    <>
      <Stack spacing={3} px={5}>
        <Typography variant="h3">Add Telegram</Typography>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} sx={{ width: "70%" }}>
            <RHFTextField name="title" label="Title" />
            <Stack direction="row" spacing={3}>
              <RHFTextField name="channel_url" label="Channel URL" type="url" />
            </Stack>

            <Stack direction="row" spacing={3}>
              {/* <RHFTextField name="category" label="Category" select>
                            {users?.map((item) => { return <MenuItem value={item?.title}>{item?.title}</MenuItem> })}



                        </RHFTextField> */}
              <RHFTextField
                name="is_active"
                label="Status"
                type="checkbox"
                select
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </RHFTextField>
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
              sx={{
                bgcolor: theme.palette.primary.mainTheme,
                marginTop: "5%",
                "&:hover": { background: "red" },
              }}
            >
              Add
            </LoadingButton>
          </Stack>
        </FormProvider>
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Stack>
    </>
  );
}
