import React, { useEffect } from "react";
import * as Yup from "yup";
import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";

// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Stack, Typography, TextField, MenuItem, Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components

import { FormProvider, RHFTextField } from "../../../components/hook-form";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useTheme } from "@emotion/react";
import { convertYoutubeUrlToEmbed } from "../../../utils/convertYoutubeUrlToEmbed";

const UpdateLectureDetails = () => {
  const theme = useTheme();

  const navigate = useNavigate();
  const [focus, setFocused] = React.useState(false);
  const [resources, setResources] = React.useState(false);
  const [users, usersSet] = React.useState([]);
  const { lectId, batchId } = useParams();
  const [loading, setLoading] = useState(false);
  const [fetchMessage, setFetchMessage] = useState();
  const [starting, setStarting] = React.useState(dayjs("2022-01-01T21:11:54"));
  const [ending, setEnding] = React.useState(dayjs("2022-01-01T21:11:54"));

  const handleChange = (newValue) => {
    setStarting(newValue);
  };
  const handleChangeTwo = (newValue) => {
    setEnding(newValue);
  };

  const LoginSchema = Yup.object().shape({});

  const defaultValues = {};

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const [file, setFile] = useState();

  const changeHandler = async (event) => {
    setFile(event.target?.files[0]);
  };
  const [file1, setFile1] = useState();
  const changeHandler1 = async (event) => {
    setFile1(event.target?.files[0]);
  };
  //Getting Existing details
  const [lectureDetails, setLectureDetails] = useState();
  const [teacherList, setTeacherList] = useState([]);
  const [value, setValue] = useState();
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/getPerticularLectureDetails/${lectId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      setLectureDetails(responseJson?.data);
      //console.log(responseJson?.data?.starting_date);

      setStarting(
        dayjs(responseJson?.data?.starting_date, "DD-MM-YYYY HH:mm:ss")
      );
      setEnding(dayjs(responseJson?.data?.ending_date, "DD-MM-YYYY HH:mm:ss"));

      setValue((prev) => ({
        ...prev,
        lecture_type: responseJson?.data?.lecture_type,
        lecture_title: responseJson?.data?.lecture_title,
        teacherName: responseJson?.data?.teacher[0]?.FullName,
        description: responseJson?.data?.description,
        isActive: responseJson?.data?.isActive,
      }));
    }
    fetchUsers();
    async function fetchTeacher() {
      const fullResponse = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminTeacher/teacherList`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      setTeacherList(responseJson?.data);

      // setLoading(false)
    }
    fetchTeacher();
  }, []);
  // //console.log(lectureDetails?.starting_date);
  //console.log(starting);

  let formatDate = (date) => {
    let datehelper = "" + date;
    datehelper = datehelper.split(" ");
    let monthsList = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let time = datehelper[4];
    let year = datehelper[3];
    let month = `0${monthsList.indexOf(datehelper[1]) + 1}`.slice(-2);
    let day = datehelper[2];

    return `${day}-${month}-${year} ${time}`;
  };

  const onSubmit = async (data) => {
    let StartingDate = formatDate(starting.$d);
    let EndingDate = formatDate(ending.$d);

    setLoading(true);
    // //console.log(starting.$d == 'Invalid Date')
    const formData = new FormData();
    if (file) {
      formData.append("file", file);
    }
    if (file1) {
      formData.append("file1", file1);
    }

    if (data?.lecture_type) {
      formData.append("lecture_type", data?.lecture_type);
    } else {
      formData.append("lecture_type", lectureDetails?.lecture_type);
    }

    formData.append("lecture_title", value?.lecture_title);
    formData.append("description", value?.description);

    if (data?.language) {
      formData.append("language", data?.language);
    } else {
      formData.append("language", lectureDetails?.language);
    }

    if (data?.link) {
      let link = convertYoutubeUrlToEmbed(data?.link);
      formData.append("link", link ? link : data?.link);
    } else {
      let link = convertYoutubeUrlToEmbed(lectureDetails?.link);
      formData.append("link", link ? link : lectureDetails?.link);
    }

    if (data?.LiveOrRecorded) {
      formData.append("LiveOrRecorded", data?.LiveOrRecorded);
    } else {
      formData.append("LiveOrRecorded", lectureDetails?.LiveOrRecorded);
    }

    if (data?.subject_id) {
      formData.append("subject_id", data?.subject_id);
    } else {
      formData.append("subject_id", lectureDetails?.subject?._id);
    }
    if (data?.teacher) {
      formData.append("teacher", data?.teacher);
    } else {
      formData.append("teacher", lectureDetails?.teacher[0]?._id);
    }

    if (data?.batch_id) {
      formData.append("batch_id", data?.batch_id);
    } else {
      formData.append("batch_id", lectureDetails?.batch);
    }

    if (starting.$d != "Invalid Date") {
      formData.append("starting_date", StartingDate);
    } else {
      formData.append("starting_date", lectureDetails?.starting_date);
    }

    if (ending.$d != "Invalid Date") {
      formData.append("ending_date", EndingDate);
    } else {
      formData.append("ending_date", lectureDetails?.ending_date);
    }

    formData.append("batch_id", batchId);
    formData.append("isActive", value?.isActive);

    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${authToken}`,
      },
    };
    axios
      .put(`https://api-prod.gyaankulam.com/api/v1/adminPanel/updateLectureDetails/${lectId}`, formData, config)
      .then((response) => {
        // //console.log(response?.data);

        if (response?.data?.msg === "updated the lecture  successfully") {
          reset();
          setLoading(false);
          toast.success("Lecture Details Updated");

          setTimeout(() => {
            navigate(`/dashboard/batchDetails/${batchId}`);
            // window.location.reload();
          }, 1500);
        } else {
          toast.error(response?.data?.msg);
        }
      })
      .catch((e) => {
        //console.log(e);
      });
  };

  //Getting Batch
  React.useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/get-select-subject`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      usersSet(responseJson?.data);

      // setLoading(false)
    }
    fetchUsers();
  }, []);

  const onChange2 = (e) => {
    const { name, value } = e.target;
    setValue((prev) => ({ ...prev, [name]: value }));
  };
  return (
    <Grid direction="row" pl={5}>
      <Typography variant="h2" ml={2}>
        Update Lecture Details
      </Typography>
      <Typography variant="h6" ml={2} mb={4}>
        Enter the below required details to update a lecture
      </Typography>

      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        {/* <Stack direction="row" spacing={5} > */}
        <Stack spacing={3} sx={{ width: "70%" }}>
          {/*
            <RHFTextField
              name="lecture_type"
              label={lectureDetails?.lecture_type == "YT" ? "YouTube" : "App"}
              select
            >
              <MenuItem value="YT">YouTube</MenuItem>
              <MenuItem value="APP">App</MenuItem>
            </RHFTextField>
  */ }

          <RHFTextField
            name="lecture_title"
            value={value?.lecture_title}
            onChange={onChange2}
          />
          

          <RHFTextField
            name="description"
            value={value?.description}
            multiline
            onChange={onChange2}
            rows={3}
          />
          {/* <RHFTextEditor name="description" value={value?.description} onChange={onChange2} /> */}

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack
              direction="row"
              spacing={3}
              sx={{ width: "100%", justifyContent: "space-between" }}
            >
              <DateTimePicker
                label="Starting Date and Time"
                value={starting.$d}
                onChange={handleChange}
                // name="starting_date"
                renderInput={(params) => (
                  <TextField {...params} sx={{ width: "100%" }} />
                )}
              />
              <DateTimePicker
                label="Starting Date and Time"
                value={ending.$d}
                onChange={handleChangeTwo}
                sx={{ color: "black" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ width: "100%", color: "black" }}
                  />
                )}
              />
            </Stack>
          </LocalizationProvider>

          <Stack direction="row" spacing={3}>
            
            {/*
              <RHFTextField
                name="language"
                label={lectureDetails?.language == "en" ? "English" : "Hindi"}
                select
              >
            
                <MenuItem value="en">English</MenuItem>
                <MenuItem value="hi">Hindi</MenuItem>
              </RHFTextField>
              */}
            <RHFTextField
              name="subject_id"
              label={lectureDetails?.subject?.title}
              select
            >
              {users?.map((item, index) => {
                return (
                  <MenuItem key={index} value={item?._id}>
                    {item?.title}
                  </MenuItem>
                );
              })}
            </RHFTextField>
            <RHFTextField
              name="LiveOrRecorded"
              label={lectureDetails?.LiveOrRecorded}
              select
            >
              <MenuItem value="Live">Live</MenuItem>
              <MenuItem value="Recorded">Recorded</MenuItem>
            </RHFTextField>
          </Stack>
          <Stack spacing={3} direction="row">
            <RHFTextField
              id="assi"
              name="materials"
              label="Resources"
              onChange={changeHandler}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              InputProps={{
                classes: {
                  input: "CustomTextField",
                },
              }}
              onClick={() => setResources(true)}
              type={resources || focus ? "file" : "text"}
            />
            <RHFTextField
              id="dpp"
              name="dpp"
              label="DPP"
              onChange={changeHandler1}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              InputProps={{
                classes: {
                  input: "CustomTextField",
                },
              }}
              onClick={() => setResources(true)}
              type={resources || focus ? "file" : "text"}
            />
            <RHFTextField name="link" label={lectureDetails?.link} type="url" />
          </Stack>
          <Stack direction="row" spacing={2}>
            <RHFTextField
              name="isActive"
              label={lectureDetails?.isActive ? "Active" : "Inactive"}
              value={lectureDetails?.isActive}
              onChange={onChange2}
              select
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </RHFTextField>
            <RHFTextField
              name="teacher"
              label={lectureDetails?.teacher[0]?.FullName}
              select
            >
              {teacherList?.map((item, index) => {
                return (
                  <MenuItem key={index} value={item?._id}>
                    {item?.FullName}
                  </MenuItem>
                );
              })}
            </RHFTextField>
          </Stack>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          // loading={loading}
          sx={{
            bgcolor: theme.palette.primary.mainTheme,
            width: "70%",
            marginTop: "5%",
            "&:hover": { background: "red" },
          }}
        >
          Update
        </LoadingButton>
      </FormProvider>
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* <Typography color={positive ? 'green' : "red"} mt={3} sx={{ textAlign: 'center', width: '70%', textTransform: 'capitalize' }}>{fetchMessage}</Typography> */}
    </Grid>
  );
};

export default UpdateLectureDetails;
