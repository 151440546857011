import {
  Navigate,
  useRoutes,
  BrowserRouter,
  Route,
  Switch,
} from "react-router-dom";
import DashboardLayout from "./layouts/dashboard";
// import Blog from './pages/Blog';
import Login from "./pages/Login";
import NotFound from "./pages/Page404";
// import Products from './pages/Products';
import DashboardApp from "./pages/DashboardApp";
import LoginTeacher from "./pages/LoginTeacher";
import Student from "./pages/User";
import Batch from "./sections/@dashboard/batch/Batch";
import Test from "./sections/@dashboard/testSeries/test";
import AddTestSeries from "./sections/@dashboard/testSeries/AddTestSeries";
import AddBatch from "./sections/@dashboard/batch/AddBatch";
import BatchDetails from "./sections/@dashboard/batch/BatchDetails";
import BatchDetails1 from "./sections/@dashboard/batch/BatchDetails1";
import Staff from "./sections/@dashboard/manageStaff/Staff";
import LectureForm from "./sections/@dashboard/batch/LectureForm";
import LiveLecture from "./sections/@dashboard/batch/LiveLecture";
import Banner from "./sections/@dashboard/generalSettiing/Banner";
import ContactUs from "./sections/@dashboard/generalSettiing/ContactUs";
import StudentBatches from "./sections/@dashboard/user/StudentBatches";
import SubAdmin from "./sections/@dashboard/manageStaff/SubAdmin";
import Moderators from "./sections/@dashboard/manageStaff/Moderators";
import Assignment from "./sections/@dashboard/assignmennt/Assignment";
import Testimonial from "./sections/@dashboard/testimonial/Testimonial";
import AIR from "./sections/@dashboard/resources/AIR";
import CustomNotification from "./sections/@dashboard/resources/CustomNotification";
import AddCustomNotification from "./sections/@dashboard/resources/AddCustomNotification";
import NewsClip from "./sections/@dashboard/resources/NewsClip";
import TeacherGenerated from "./sections/@dashboard/resources/TeacherGenerated";
import Pathyakram from "./sections/@dashboard/resources/Pathyakram";
import Notes from "./sections/@dashboard/resources/Notes";
import LiveStream from "./sections/@dashboard/livestream/LiveStream";
import Category from "./sections/@dashboard/generalSettiing/Category";
import Youtube from "./sections/@dashboard/generalSettiing/Youtube";
import HelpAndSupport from "./sections/@dashboard/generalSettiing/HelpAndSupport";
import Telegram from "./sections/@dashboard/generalSettiing/Telegram";
import Subject from "./sections/@dashboard/generalSettiing/Subject";
import AddResources from "./sections/@dashboard/resources/AddResources";
import Resources from "./sections/@dashboard/resources/Resources";
import LectureResources from "./sections/@dashboard/batch/LectureResources";
import Profile from "./sections/@dashboard/profile/Profile";
import Messaging from "./sections/@dashboard/messaging/Messaging";
import LogoutUsers from "./sections/@dashboard/generalSettiing/LogoutUsers";
import Recorded from "./sections/@dashboard/resources/Recorded";
import AddRecordedVideo from "./sections/@dashboard/resources/AddRecordedVideo";
// import RecordedTable from './sections/@dashboard/resources/RecordedTable';
import RecordedLectures from "./sections/@dashboard/batch/RecordedLectures";
import StudentList from "./sections/@dashboard/user/StudentList";
import StudentProfile from "./sections/@dashboard/user/StudentProfile";
import TeacherProfile from "./sections/@dashboard/manageStaff/TeacherProfile";
import AssignTeacherToBatch from "./sections/@dashboard/batch/AssignTeacherToBatch";
import UpdateBatchDetails from "./sections/@dashboard/batch/UpdateBatchDetails";
import UpdateLectureDetails from "./sections/@dashboard/batch/UpdateLectureDetails";
import TestSeriesDetails from "./sections/@dashboard/testSeries/TestSeriesDetails";
import AddTest from "./sections/@dashboard/testSeries/AddTest";
import UpdateTestSeries from "./sections/@dashboard/testSeries/UpdateTestSeries";
import AttemptedTest from "./sections/@dashboard/testSeries/AttemptedTest";
import StudentTestSeries from "./sections/@dashboard/testSeries/StudentTestSeries";
import StudentTestSeries1 from "./sections/@dashboard/testSeries/StudentTestSeries1";
import StudentTestSeriesList from "./sections/@dashboard/testSeries/StudentTestSeriesList";
import UpdateTest from "./sections/@dashboard/testSeries/UpdateTest";
// import APP from './backgroundUpload/APP';
// import FlutterLiveStream from './flutterLiveStream/FlutterLiveStream';
import UpdatePermission from "./sections/@dashboard/manageStaff/Components/UpdatePermission";
import UpdateStaff from "./sections/@dashboard/manageStaff/UpdateStaff";
// import QuizQuestions from "./sections/@dashboard/quiz/QuizQuestions";
import Quiz from "./sections/@dashboard/quiz/Quiz";
import AddQuizQuestion from "./sections/@dashboard/quiz/AddQuizQuestion";
import AddQuiz from "./sections/@dashboard/quiz/AddQuiz";
// import EditQuestion from "./sections/@dashboard/quiz/Quiz";
import EditQuestion from "./sections/@dashboard/quiz/EditQuestion";
import UpdateQuiz from "./sections/@dashboard/quiz/UpdateQuiz";
import IssuesTable from "./sections/@dashboard/quiz/IssuesTable";
import DoubtsTable from "./sections/@dashboard/quiz/DoubtsTable";
import QuizTable from "./sections/@dashboard/quiz/QuizTable";
import UpdateQuizQuestions from "./sections/@dashboard/quiz/UpdateQuizQuestions";
import QuizStudents from "./sections/@dashboard/quiz/QuizStudents";
import QuizSResults from "./sections/@dashboard/quiz/QuizResults";
import StudentResults from "./sections/@dashboard/quiz/StudentResults";
import AllCoupon from "./sections/@dashboard/coupon/AllCoupon";
import AddCoupon from "./sections/@dashboard/coupon/AddCoupon";
import Oath2callback from "./sections/@dashboard/Oath2callback";
import LeaderBoard from "./sections/@dashboard/quiz/LeaderBoard";
import SubCategory from "./sections/@dashboard/generalSettiing/SubCategory";
import PreviousYearQuestionPapers from "./sections/@dashboard/resources/PreviousYearQuestionPapers";
import Students from "./pages/Students";
import UserReport from "./pages/UserReport";
import YotubeLive from "./sections/@dashboard/batch/YotubeLive";
import UpdateBannerDetails from "./sections/@dashboard/generalSettiing/UpdateBannerDetails";
import { User } from "./context/context";
import CenterLectureForm from "./sections/@dashboard/batch/CenterLectureForm";
import AddCenterLectureResources from "./sections/@dashboard/batch/AddCenterLectureResources";

// store 
import StoreCategory from "./sections/@dashboard/store/StoreCategory.js";
import StoreBanner from "./sections/@dashboard/store/StoreBanner";
import StoreFeaturedVideo from "./sections/@dashboard/store/StoreFeaturedVideo";
import StoreProduct from "./sections/@dashboard/store/StoreProduct";
import AddStoreProduct from "./sections/@dashboard/store/AddStoreProduct";
import AddStoreCategory from "./sections/@dashboard/store/AddStoreCategory.js";
import UpdateCoupon from "./sections/@dashboard/coupon/UpdateCoupon.js";
import StoreOrdersTable from "./sections/@dashboard/store/StoreOrdersTable.js";
import StorePuchaseReport from "./sections/@dashboard/store/StorePuchaseReport.js";
import StoreAlert from "./sections/@dashboard/store/StoreAlert.js";
import CTA from "./sections/@dashboard/user/CTA.js";
import User1 from "./pages/User1.js";
import UserReport1 from "./pages/UserReport1.js";
import Students1 from "./pages/Students1.js";
import AllCoupon1 from "./sections/@dashboard/coupon/AllCoupon1.js";
import Draggable from "./sections/@dashboard/store/Draggable.js";
import DraggableComponent from "./sections/@dashboard/store/Draggable.js";
import AnnouncementTable from "./sections/@dashboard/announcement/AnnouncementTable.js";
import AddAnnouncement from "./sections/@dashboard/announcement/AddAnnouncement.js";
import Announcement from "./sections/@dashboard/announcement/Announcement.js";
import UpdateCategory from "./sections/@dashboard/store/UpdateCategory.js";
import UpdateStoreBanner from "./sections/@dashboard/store/UpdateStoreBanner.js";
import UpdateStoreAlerts from "./sections/@dashboard/store/UpdateStoreAlerts.js";
import UpdateStoreFeaturedVideo from "./sections/@dashboard/store/UpdateStoreFeaturedVideo.js";
import UpdateStoreProduct from "./sections/@dashboard/store/UpdateStoreProducts.js";
import AddCenterAnnouncements from "./sections/@dashboard/batch/addCenterAnnouncements.js";
import ScholarshipTestTable from "./sections/@dashboard/scholarshipTest/ScholarshipTestTable.js";
import AddScholarshipTest from "./sections/@dashboard/scholarshipTest/AddScholarshipTest.js";
import EditScholarshipTest from "./sections/@dashboard/scholarshipTest/EditScholarshipTest.js";
import Batch1 from "./sections/@dashboard/batch/Batch1.js";
import StudentBatches1 from "./sections/@dashboard/user/StudentBatches1.js";
// import Batch1 from "./sections/@dashboard/batch/Batch1.js";
import ProductProfilePage from "./sections/@dashboard/store/ProductProfilePage.js";
import SchlorshipTestUser from "./sections/@dashboard/scholarshipTest/SchlorshipTestUser.js";
import UpdateYoutube from "./sections/@dashboard/generalSettiing/UpdateYoutube.js";
import { element } from "prop-types";
import AddBlog from "./sections/@dashboard/cms/AddBlog.js";
import BlogTable from "./sections/@dashboard/cms/BlogTable.js";
import BlogProfile from "./sections/@dashboard/cms/BlogProfile.js";
import UpdateBlog from "./sections/@dashboard/cms/UpdateBlog.js";
import UpdateLectureResource from "./sections/@dashboard/batch/UpdateLectureResource.js";
import AppLive from "./sections/@dashboard/batch/AppLive.js";
import StoreWishlistTable from "./sections/@dashboard/store/StoreWishlistTable.js";
import StoreCartsTable from "./sections/@dashboard/store/StoreCartsTable.js";
import Community from "./sections/@dashboard/community/Community.js";
import EditCommunity from "./sections/@dashboard/community/EditCommunity.js";
import CommunityProfile from "./sections/@dashboard/community/CommunityProfile.js";
import StoreOrder from "./sections/@dashboard/store/StoreOrder.js";
import StoreUser from "./sections/@dashboard/store/StoreUser.js";
import StoreTrashProduct from "./sections/@dashboard/store/StoreTrashProduct.js";
import UpdateBatchCategory from "./sections/@dashboard/generalSettiing/UpdateBatchCateory.js";
import EditSubject from "./sections/@dashboard/generalSettiing/EditSubject.js";
import Notification from "./sections/@dashboard/notification/Notification.js";
import AddNotification from "./sections/@dashboard/notification/AddNotification.js";
import { useEffect, useState } from "react";
import { setDate } from "date-fns";
import getAccess from "./utils/getAccess.js";
import CTABanner from "./sections/@dashboard/generalSettiing/CTABanner.js";
import StoreCoupon from "./sections/@dashboard/store/StoreCoupon.js";
import StudentList1 from "./sections/@dashboard/user/StudentList1.js";




// ----------------------------------------------------------------------

export default function Router() {
  const d = getAccess();
  const role = d?.role == 'admin' ? "Admin" :  d?.role ;
  
  const LoggedIn = localStorage.getItem("isLoggedIn");
  const assignBatch = localStorage.getItem('assignBatch');
  console.log(d)
  console.log(assignBatch)
  return useRoutes([
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      // eslint-disable-next-line no-sparse-arrays
      children: [
        { path: "app", element: <DashboardApp /> },
        { path: "product_profile/:id", element:( !d?.access.includes('all') && !d?.accessForTab?.includes('read') && !d?.accessForTab?.includes('readWrite') ) ? <Navigate to="/404" replace />: <ProductProfilePage /> },
        // { path: "product_profile/:id", element: <ProductProfilePage /> },
        // { path: "message", element: <Messaging /> },
        // { path: "logoutUsers", element: <LogoutUsers /> },
        { path: "logoutUsers", element: ( !d?.access.includes('all') && !d?.access?.includes('102')) ? <Navigate to="/404" replace />: <LogoutUsers /> },
        // { path: "students", element: <Student /> },
        { path: "teacher/teacherProfile/:data", element: ( !d?.access.includes('all') && !d?.accessForTab?.includes('read') && !d?.accessForTab?.includes('readWrite') ) ? <Navigate to="/404" replace /> : <TeacherProfile /> },
        { path: "students/studentsProfile/:data", element:( !d?.access.includes('all') && !d?.accessForTab?.includes('read')  && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <StudentProfile /> },
        { path: "profile", element: ( !d?.access.includes('all') && !d?.accessForTab?.includes('read') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <Profile /> },
        // { path: "studentBatches", element: <StudentBatches /> },
        {
          path: "studentBatches/studentList/:active/:batchId",
          element: <StudentList1 />,
        },
        // { path: 'products', element: <Products /> },
        { path: 'youtubeLive/:lectId/:lectTitle/:lectDesc/:ytUrl', element: <YotubeLive /> },
        { path: 'appLive/:lectId/:lectTitle/:lectDesc/:ytUrl', element: <AppLive /> },
        // { path: "batches", element: <Batch /> },
        {
          path: "batches/addTeacherToBatch/:batchId",
          element: <AssignTeacherToBatch />,
        },
        {
          path: "batches/updateBatchDetails/:batchId",
          element:( !d?.access.includes('all')  && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <UpdateBatchDetails />,
        },
        // update banner Routes
        {
          path: "banner/updateBannerDetails/:bannerName/:bannerId",
          element: ( !d?.access.includes('all')  && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <UpdateBannerDetails />
        },
        {
          path: 'callToAction', element:( !d?.access.includes('all') && !d?.accessForTab?.includes('read')) ? <Navigate to="/404" replace /> : <CTA />
        },
        // store 




        { path: "helpAndSupport", element: ( !d?.access.includes('all') && !d?.access?.includes('109')) ? <Navigate to="/404" replace /> :<HelpAndSupport /> },
        // { path: "addBatch", element: <AddBatch /> },
        // { path: "batchDetails/:Id", element: <BatchDetails /> },
        { path: "batchDetails/:Id", element: ( !d?.access.includes('all') && !d?.access.includes('21')) ? <Navigate to="/404" replace /> : <BatchDetails1 /> },
        {
          path: "batchDetails/updateLectureDetails/:batchId/:lectId",
          element: ( !d?.access.includes('all')  && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <UpdateLectureDetails />,
        },
        {
          path: "updateLectureResources/:id",
          element: ( !d?.access.includes('all') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <UpdateLectureResource />,
        },
        {
          path: "batchDetails/addLecture/:Id/:batchName",
          element: ( !d?.access.includes('all') && !d?.access?.includes('24')) ? <Navigate to="/404" replace /> : <LectureForm />,
        },
        {
          path: "batchDetails/addCenterLecture",
          element: ( !d?.access.includes('all') && !d?.access?.includes('24') ) ? <Navigate to="/404" replace /> : <CenterLectureForm />
        },
        {
          path: "batchDetails/addCenterLectureResources",
          element: ( !d?.access.includes('all')  && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <AddCenterLectureResources />
        },
        {
          path: "batchDetails/liveLecture/:Id/:batchName",
          element:( !d?.access.includes('all')  && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <LiveLecture />,
        },
        // { path: "test", element: <Test /> },
        {
          path: "test/testSeriesDetails/updateTestDetails/:testSeriesId/:testId",
          element: ( !d?.access.includes('all')  && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> :<UpdateTest />,
        },

        // { path: "studentTestSeries", element: <StudentTestSeries /> },
        {
          path: "studentTestSeries/studentList/:active/:testSeriesId",
          element: ( !d?.access.includes('all') && !d?.accessForTab?.includes('read') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <StudentTestSeriesList />,
        },
        {
          path: "test/updateTestSeries/:testSeriesId",
          element:( !d?.access.includes('all')  && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <UpdateTestSeries />,
        },
        {
          path: "test/testSeriesDetails/:testSeriesId",
          element:( !d?.access.includes('all') && !d?.accessForTab?.includes('read') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <TestSeriesDetails />,
        },
        {
          path: "test/editquestion/:testSeriesId/:questionId/:data",
          element: ( !d?.access.includes('all') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <EditQuestion />,
        },
        {
          path: "test/editquestion/",
          element: ( !d?.access.includes('all')  && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <EditQuestion />,
        },
        {
          path: "test/testSeriesDetails/attemptedTest/:testSeriesId",
          element: <AttemptedTest />,
        },
        {
          path: "test/testSeriesDetails/addTest/:testSeriesId",
          element: ( !d?.access.includes('all') && !d?.access?.includes('34')) ? <Navigate to="/404" replace /> : <AddTest />,
        },
        { path: "recordedLectures", element: <RecordedLectures /> },
        // { path: "addTest", element: <AddTestSeries /> },
        // { path: "staff", element: <Staff /> },
        { path: "staff/updateStaff/:teacherId", element: ( !d?.access.includes('all')) ? <Navigate to="/404" replace /> :<UpdateStaff /> },
        {
          path: "staff/updatePermission/:teacherId",
          element: ( !d?.access.includes('all') ) ? <Navigate to="/404" replace /> :<UpdatePermission />,
        },
        { path: "moderators", element: <Moderators /> },
        { path: "subAdmin", element: <SubAdmin /> },
        // { path: "banner", element: <Banner /> },
        // { path: "youtube", element: <Youtube /> },
        // { path: "telegram", element: <Telegram /> },
        // { path: "contactUs", element: <ContactUs /> },
        // { path: "category", element: <Category /> },
        // { path: "subCategory", element: <SubCategory /> },
        // { path: "subject", element: <Subject /> },
        { path : 'updateCategory/:id' , element:(!d?.access?.includes('all') && !d?.accessForTab?.includes('readWrite') ) ? <Navigate to="/404" replace /> : <UpdateBatchCategory />},
        {path : 'editSubject/:id' , element : (!d?.access?.includes('all') && !d?.accessForTab?.includes('readWrite') ) ? <Navigate to="/404" replace /> :<EditSubject />},
        { path: "updateYoutubeVideo/:youtubeId", element: (!d?.access?.includes('all') && !d?.accessForTab?.includes('readWrite') ) ? <Navigate to="/404" replace /> :<UpdateYoutube /> },
        { path: "addResources", element: <AddResources /> },
        // { path: "customNotification", element: <CustomNotification /> },
        { path: "addCustomNotification", element: <AddCustomNotification /> },
        { path: "resources", element: <Resources /> },
        {
          path: "lectureResources/:lectId/:batchId",
          element:(!d?.access?.includes('all') && !d?.accessForTab?.includes('readWrite') && !d?.accessForTab?.includes('read')) ? <Navigate to="/404" replace /> : <LectureResources />,
        },
        // { path: "assignment", element: <Assignment /> },
        // { path: "testimonial", element: <Testimonial /> },
        // { path: "pathyakram", element: <Pathyakram /> },
        // { path: "recorded", element: <Recorded /> },
        { path: "addRecorded/:lectId/:batchId", element: (!d?.access?.includes('all') && !d?.access?.includes('611')) ? <Navigate to="/404" replace /> :<AddRecordedVideo /> },
        // { path: "air", element: <AIR /> },
        // { path: "newsClip", element: <NewsClip /> },
        // { path: "notes", element: <Notes /> },
        // { path: "previousYearQuestionPapers", element: <PreviousYearQuestionPapers /> },
        // { path: "quiz", element: <Quiz /> },
        { path: "quiz/doubts/:quizName/:quizId", element:(!d?.access?.includes('all') && !d?.accessForTab?.includes('readWrite') && !d?.accessForTab?.includes('read')) ? <Navigate to="/404" replace /> : <DoubtsTable /> },
        { path: "quiz/issues/:quizName/:quizId", element:(!d?.access?.includes('all') && !d?.accessForTab?.includes('readWrite') && !d?.accessForTab?.includes('read')) ? <Navigate to="/404" replace /> : <IssuesTable /> },
        {
          path: "quiz/quizStudents/:quizName/:quizId",
          element: (!d?.access?.includes('all') && !d?.accessForTab?.includes('readWrite') && !d?.accessForTab?.includes('read')) ? <Navigate to="/404" replace /> :<QuizStudents />,
        },
        {
          path: "quiz/leaderboard/:quizId/:quizName",
          element: (!d?.access?.includes('all') && !d?.accessForTab?.includes('readWrite') && !d?.accessForTab?.includes('read')) ? <Navigate to="/404" replace /> :<LeaderBoard />
        },
        {
          path: "quiz/quizResults/:quizName/:quizId",
          element: (!d?.access?.includes('all') && !d?.accessForTab?.includes('readWrite') && !d?.accessForTab?.includes('read')) ? <Navigate to="/404" replace /> :<QuizSResults />,
        },
        {
          path: "quiz/editquestion/:quizId/:selectedIdOfQuestion/:quizTitle",
          element: (!d?.access?.includes('all') && !d?.accessForTab?.includes('readWrite') ) ? <Navigate to="/404" replace /> :<EditQuestion />,
        },
        // { path: "purchaseReport", element: <Students /> },
        { path: "purchaseReport", element:(!d?.access?.includes('all') && !d?.access?.includes('1114') ) ? <Navigate to="/404" replace /> : <Students1 /> },
        { path: "userReport", element:(!d?.access?.includes('all') && !d?.access?.includes('1115')) ? <Navigate to="/404" replace /> : <UserReport1 /> },
        // { path: "userReport", element: <UserReport /> },



        //Store
        { path: "storeCategory", element: (!d?.access?.includes('all') && !d?.access?.includes('111')) ? <Navigate to="/404" replace /> : <StoreCategory /> },

        // { path: "addStoreCategory", element: role !== 'Admin' && !d?.access?.includes('111') ? <Navigate to="/404" replace /> : <AddStoreCategory /> },
        { path: "storeBanner", element: (!d?.access?.includes('all') && !d?.access?.includes('112')) ? <Navigate to="/404" replace /> : <StoreBanner /> },
        { path: "storeFeaturedVideo", element: (!d?.access?.includes('all') && !d?.access?.includes('113')) ? <Navigate to="/404" replace /> : <StoreFeaturedVideo /> },
        {
          path: "storeAllProducts", element: (!d?.access?.includes('all') && !d?.access?.includes('115') ) ? <Navigate to="/404" replace /> : <StoreProduct />
        },
        {path : "storeUser" , element: (!d?.access?.includes('all') && !d?.access?.includes('1201') ) ? <Navigate to="/404" replace /> :<StoreUser />},
        {path : 'storeTrashProduct' , element :(!d?.access?.includes('all') && !d?.access?.includes('1202') ) ? <Navigate to="/404" replace /> : <StoreTrashProduct />},
        {path : 'storeCoupon' , element :(!d?.access?.includes('all') && !d?.access?.includes('1203') ) ? <Navigate to="/404" replace /> : <StoreCoupon />},
        { path: "addStoreProduct", element: (!d?.access?.includes('all') && !d?.access?.includes('116') )? <Navigate to="/404" replace /> : <AddStoreProduct /> },
        { path: "storeOrders", element: (!d?.access?.includes('all') && !d?.access?.includes('117') ) ? <Navigate to="/404" replace /> : <StoreOrder /> },
        { path: 'storePurchaseReport', element: (!d?.access?.includes('all') && !d?.access?.includes('118')) ? <Navigate to="/404" replace /> : <StorePuchaseReport /> },
        { path: "storeAlert", element: (!d?.access?.includes('all') && !d?.access?.includes('114') ) ? <Navigate to="/404" replace /> : <StoreAlert /> },
        { path: "storeWishlist", element:(!d?.access?.includes('all') && !d?.access?.includes('119') ) ? <Navigate to="/404" replace /> : <StoreWishlistTable /> },
        { path: "storeCarts", element: (!d?.access?.includes('all') && !d?.access?.includes('120') ) ? <Navigate to="/404" replace /> :<StoreCartsTable /> },


        //User
        {
          // path: "students", element: role !== 'Admin' && !d?.access?.includes('11') ? <Navigate to="/404" replace /> : <Student />
          path: "students", element: (!d?.access?.includes('all') && !d?.access?.includes('1111'))? <Navigate to="/404" replace /> : <User1 />

        },
        {
          path: "drag/:id", element: (!d?.access?.includes('all') && !d?.accessForTab?.includes('readWrite') ) ? <Navigate to="/404" replace /> :<UpdateCategory />

        },
        {
          path: "updateStoreBanner/:bannerId", element: (!d?.access?.includes('all') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> :<UpdateStoreBanner />

        },
        {
          path: "updateStoreProduct/:id", element:(!d?.access?.includes('all') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <UpdateStoreProduct />

        },
        {
          path: "updateStoreAlerts/:id", element: (!d?.access?.includes('all') && !d?.accessForTab?.includes('readWrite') ) ? <Navigate to="/404" replace /> :<UpdateStoreAlerts />

        },
        {
          path: "updateStoreFeatureVideos/:id", element: (!d?.access?.includes('all') && !d?.accessForTab?.includes('readWrite') ) ? <Navigate to="/404" replace /> :<UpdateStoreFeaturedVideo />

        },
        {
          // path: "storeBanner/:id", element: <UpdateStoreBanner />

        },
        // {
        //   path: "studentBatches", element: role !== 'Admin' && !d?.access?.includes('12') ? <Navigate to="/404" replace /> : <StudentBatches />

        // },
        {
          path: "studentBatches", element:(!d?.access?.includes('all') && !d?.access?.includes('1112')) ? <Navigate to="/404" replace /> : <StudentBatches1 />

        },
        {
          path: "updateCategory", element: role !== 'Admin' && <Students />

        }
        ,
        // {
        //   path: "studentTestSeries", element: role !== 'Admin' && !d?.access?.includes('13') ? <Navigate to="/404" replace /> : <StudentTestSeries />

        // }
        {
          path: "studentTestSeries", element: (!d?.access?.includes('all') && !d?.access?.includes('1113')) ? <Navigate to="/404" replace /> : <StudentTestSeries1 />

        }
        ,
        {
          path: "purchaseReport", element: (!d?.access?.includes('all') && !d?.access?.includes('1114')) ? <Navigate to="/404" replace /> : <Students />

        }
        ,
        {
          path: "userReport", element: (!d?.access?.includes('all') && !d?.access?.includes('1115')) ? <Navigate to="/404" replace /> : <UserReport />
          // path: "userReport", element: role !== 'Admin' && !d?.access?.includes('15') ? <Navigate to="/404" replace /> : <UserReport1 />

        }
        ,

        //Batch
        // {
        //   path: "batches", element: (role !== 'Admin' && !d?.access?.includes('21') && assignBatch?.length == 0) ? <Navigate to="/404" replace /> : <Batch />

        // },
        {
          path: "blogs", element:  (!d?.access?.includes('all') && !d?.access?.includes('121')) ? <Navigate to="/404" replace /> :<BlogTable />

        },
        {
          path: "community", element: (!d?.access?.includes('all') && !d?.access?.includes('131')) ? <Navigate to="/404" replace /> : <Community />

        },
        {
          path: "community/:postId", element:(!d?.access?.includes('all') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <EditCommunity />

        },
        { path : "notification" , element : (!d?.access?.includes('all') && !d?.access?.includes('141')) ? <Navigate to="/404" replace /> : <Notification />} ,
        { path : 'createNotification' , element  : (!d?.access?.includes('all') && !d?.access?.includes('142')) ? <Navigate to="/404" replace /> : <AddNotification />},
        {
          path: "communityProfile/:postId", element: (!d?.access?.includes('all') && !d?.accessForTab?.includes('readWrite') && !d?.accessForTab?.includes('read')) ? <Navigate to="/404" replace /> : <CommunityProfile />

        },
        {
          path: "addBlog", element:  (!d?.access?.includes('all') && !d?.access?.includes('122')) ? <Navigate to="/404" replace /> :<AddBlog />

        },
        {
          path: "blog/:id", element: (!d?.access?.includes('all') && !d?.accessForTab?.includes('readWrite') && !d?.accessForTab?.includes('read')) ? <Navigate to="/404" replace /> :<BlogProfile />

        },
        {
          path: "updateBlog/:id", element:(!d?.access?.includes('all') && !d?.accessForTab?.includes('readWrite') ) ? <Navigate to="/404" replace /> : <UpdateBlog />

        },
        {
          path: "batches", element: (!d?.access?.includes('all') && !d?.access?.includes('21') && (parseInt(assignBatch) == 0 ? false : assignBatch?.length > 0 ? true : false)) ? <Navigate to="/404" replace /> : <Batch1 />

        },
      
        {
          path: "addBatch", element: (!d?.access?.includes('all') && !d?.access?.includes('22')) ? <Navigate to="/404" replace /> : <AddBatch />

        },
        {
          path: "announcements", element: (!d?.access?.includes('all') && !d?.access?.includes('25')) ? <Navigate to="/404" replace /> : <Announcement />

        },
        {
          path: "addAnnouncement", element: (!d?.access?.includes('all') && !d?.access?.includes('25') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <AddAnnouncement />

        },
        {
          path: "addCenterAnnouncement", element: (!d?.access?.includes('all') && !d?.access?.includes('25') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <AddCenterAnnouncements />

        },
        {
          path: "scholarshipTests", element: (!d?.access?.includes('all') && !d?.access?.includes('151') ) ? <Navigate to="/404" replace /> : <ScholarshipTestTable />
        },
        {
          path: "scholarshipTestUser/:title/:id", element: (!d?.access?.includes('all') && !d?.access?.includes('151') && !d?.accessForTab?.includes('read') && !d?.accessForTab?.includes('readWrite') ) ? <Navigate to="/404" replace /> : <SchlorshipTestUser />
        },
        {
          path: "addScholarshipTest", element: (!d?.access?.includes('all') && !d?.access?.includes('152')) ? <Navigate to="/404" replace /> : <AddScholarshipTest />

        },
        {
          path: "editScholarshipTest/:id", element:(!d?.access?.includes('all') && !d?.access?.includes('151') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <EditScholarshipTest />

        },




        //Test Series
        {
          path: "test", element: (!d?.access?.includes('all') && !d?.access?.includes('31')) ? <Navigate to="/404" replace /> : <Test />

        },
        {
          path: "addTest", element: (!d?.access?.includes('all') && !d?.access?.includes('32')) ? <Navigate to="/404" replace /> : <AddTestSeries />

        },



        //Quiz

        {
          path: "quiz", element: (!d?.access?.includes('all') && !d?.access?.includes('41')) ? <Navigate to="/404" replace /> : <Quiz />

        },
        {
          path: "addQuiz", element:(!d?.access?.includes('all') && !d?.access?.includes('42')) ? <Navigate to="/404" replace /> : <AddQuiz />

        },


        //Coupon
        {
          // path: "allCoupon", element: role !== 'Admin' && !d?.access?.includes('51') ? <Navigate to="/404" replace /> : <AllCoupon />
          path: "allCoupon", element: (!d?.access?.includes('all') && !d?.access?.includes('51')) ? <Navigate to="/404" replace /> : <AllCoupon1 />
        },
        {
          path: "addCoupon", element: (!d?.access?.includes('all') && !d?.access?.includes('52')) ? <Navigate to="/404" replace /> : <AddCoupon />

        },
        {
          path: "coupon/updateCouponDetails/:couponId/:from",
          element: (!d?.access?.includes('all') && d?.accessForTab?.includes('readWrite') ) ? <Navigate to="/404" replace /> :<UpdateCoupon />
        },



        //Resources
        {
          path: "recorded", element:  (!d?.access?.includes('all') && !d?.access?.includes('61')) ? <Navigate to="/404" replace /> : <Recorded />

        },
        {
          path: "pathyakram", element:  (!d?.access?.includes('all') && !d?.access?.includes('62') ) ? <Navigate to="/404" replace /> : <Pathyakram />

        },
        {
          path: "notes", element:  (!d?.access?.includes('all') && !d?.access?.includes('63') ) ? <Navigate to="/404" replace /> : <Notes />

        },

        {
          path: "previousYearQuestionPapers", element:  (!d?.access?.includes('all') && !d?.access?.includes('64')) ? <Navigate to="/404" replace /> : <PreviousYearQuestionPapers />

        },
        {
          path: "air", element:  (!d?.access?.includes('all') && !d?.access?.includes('65')) ? <Navigate to="/404" replace /> : <AIR />

        },
        ,
        {
          path: "newsClip", element:  (!d?.access?.includes('all') && !d?.access?.includes('66')) ? <Navigate to="/404" replace /> : <NewsClip />

        },
        {
          path: "teacherGenerated", element: (!d?.access?.includes('all') && !d?.access?.includes('67')) ? <Navigate to="/404" replace /> : <TeacherGenerated />

        },
        {
          path: "customNotification", element: (!d?.access?.includes('all') && !d?.access?.includes('68')) ? <Navigate to="/404" replace /> : <CustomNotification />

        },


        //Assignment
        {
          path: "assignment", element:  (!d?.access?.includes('all') && !d?.access?.includes('71')) ? <Navigate to="/404" replace /> : <Assignment />

        },

        //Manage Staff
        {
          path: "staff", element:  (!d?.access?.includes('all') && !d?.access?.includes('81')) ? <Navigate to="/404" replace /> : <Staff />

        },
        //Testimonial
        {
          path: "testimonial", element:  (!d?.access?.includes('all') && !d?.access?.includes('91')) ? <Navigate to="/404" replace /> : <Testimonial />

        },


        //General Setting
        {
          path: "banner", element:  (!d?.access?.includes('all') && !d?.access?.includes('101') ) ? <Navigate to="/404" replace /> : <Banner />

        },
        {
          path: "ctaBanner", element:  (!d?.access?.includes('all') && !d?.access?.includes('10001') ) ? <Navigate to="/404" replace /> : <CTABanner />

        },
        {
          path: "logoutUsers", element:  (!d?.access?.includes('all') && !d?.access?.includes('102')) ? <Navigate to="/404" replace /> : <LogoutUsers />

        },
        {
          path: "subject", element:  (!d?.access?.includes('all') && !d?.access?.includes('103')) ? <Navigate to="/404" replace /> : <Subject />

        },

        {
          path: "youtube", element:  (!d?.access?.includes('all') && !d?.access?.includes('104')) ? <Navigate to="/404" replace /> : <Youtube />

        },
        {
          path: "telegram", element:  (!d?.access?.includes('all') && !d?.access?.includes('105')) ? <Navigate to="/404" replace /> : <Telegram />

        },
        ,
        {
          path: "category", element:  (!d?.access?.includes('all') && !d?.access?.includes('106')) ? <Navigate to="/404" replace /> : <Category />

        },
        {
          path: "subCategory", element:  (!d?.access?.includes('all') && !d?.access?.includes('107')) ? <Navigate to="/404" replace /> : <SubCategory />

        },
        {
          path: "contactUs", element:  (!d?.access?.includes('all') && !d?.access?.includes('108')) ? <Navigate to="/404" replace /> : <ContactUs />

        },
        // {
        //   path: "message", element: role !== 'Admin' && !d?.access?.includes('109') ? <Navigate to="/404" replace /> : <Messaging />

        // },
        {
          path: "message", element: <Messaging />

        },

        {
          path: "updateQuizQuestions/:questionId",
          element:  (!d?.access?.includes('all') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> :<UpdateQuizQuestions />,
        },
        { path: "updateQuiz/:quizId", element: (!d?.access?.includes('all') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> : <UpdateQuiz /> },
        {
          path: "addQuizQuestion/:quizTitle/:quizId",
          element:  (!d?.access?.includes('all') && !d?.accessForTab?.includes('readWrite')) ? <Navigate to="/404" replace /> :<AddQuizQuestion />,
        },
        // { path: "quiz/:quizTitle/:quizId", element: <QuizQuestions /> },
        {
          path: "quiz/:quizTitle/:quizId/:studentId",
          element: (!d?.access?.includes('all') && !d?.accessForTab?.includes('readWrite') && !d?.accessForTab?.includes('read')) ? <Navigate to="/404" replace /> : <StudentResults />,
        },
        { path: "quiz/:quizTitle/:quizId", element: (!d?.access?.includes('all')  && !d?.accessForTab?.includes('read')) ? <Navigate to="/404" replace /> :<QuizTable /> },
        // { path: "allCoupon", element: <AllCoupon /> },
        // { path: "addCoupon", element: <AddCoupon /> },
        // { path: "teacherGenerated", element: <TeacherGenerated /> },
        {
          path: "livestream/:channul/:lectId/:batchId/:desc",
          element: <LiveStream />,
        },


      ],
    },
    { path: "oauth/callback/", element: <Oath2callback /> },

    //{ path: "oauth2callback/", element: <Oath2callback /> },
    {
      path: "login",
      element: <Login />,
    },
    // {
    //   path: "flutter",
    //   element: <FlutterLiveStream />,
    // },
    // {
    //   path: 'file',
    //   element: <APP />,
    // },
    {
      path: "loginTeacher",
      element: <LoginTeacher />,
    },

    {
      path: "/",
      element: LoggedIn ? <Navigate to="/dashboard/app" replace /> : <Login />,
    },
    {
      path: "/404",
      element: <NotFound text='No Access' />,
    },

    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);
}
