import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import { Stack } from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  GridToolbarDensitySelector,
  useGridApiContext,
  GridRowEditStopReasons,
  gridExpandedSortedRowIdsSelector,
} from "@mui/x-data-grid";

import IconButton from "@mui/material/IconButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Tooltip from "@mui/material/Tooltip";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import getAccess from "../../../utils/getAccess";

// ----------------------------------------------------------------------

const flattenArray = (arr) => {
  return arr?.map((item) => `${item.replaceAll(",", "")}`).join("");
};

function CustomToolbar({ rows, columns }) {
  const apiRef = useGridApiContext();

  const exportAllRows = async () => {
    const visibleRows = gridExpandedSortedRowIdsSelector(apiRef);

    const filteredRows = rows?.filter((row) => visibleRows.includes(row.id));

    let columnIds = columns
      ?.filter((column) => column.field !== "actions")
      ?.map((column) => column.field);
    columnIds.push("id");
    const csvHeader = columnIds.join(", ");
    const csvContent = filteredRows
      ?.map((row, index) => {
        const rowValues = columnIds?.map((columnId) => {
          if (columnId === "admin") {
            return `${row.admin.name}(${row.admin.role})`;
          } else if (columnId === "title") {
            return row.title?.replaceAll(",", " ");
          } else if (columnId === "id") {
            return row.id;
          } else if (columnId === "badge") {
            return row.badge.replaceAll(",", " ");
          } else if (columnId === "serialNumber") {
            return index + 1;
          } else if (columnId === "images") {
            return flattenArray(row.images);
          } else if (columnId === "tags") {
            return flattenArray(row.tags);
          } else if (columnId === "category") {
            return row.category.title;
          }
          return row[columnId]?.toString()?.replaceAll(",", "");
        });
        return rowValues.join(", ");
      })
      .join("\n");
    const fullCsvContent = `${csvHeader}\n${csvContent}`;

    const blob = new Blob([fullCsvContent], { type: `text/csv;charset=utf-8` });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", "StoreProducts.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />

      <GridToolbarDensitySelector />
      <Tooltip title="Export All">
        <IconButton onClick={exportAllRows}>
          <SaveAltIcon style={{ color: "red" }} />
        </IconButton>
      </Tooltip>
      <GridToolbarQuickFilter style={{ marginLeft: "auto" }} />
    </GridToolbarContainer>
  );
}

export default function StoreTrashProduct() {
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [rowModesModel, setRowModesModel] = React.useState({});
  React.useEffect(() => {
    const token = localStorage.getItem("token");
    setLoading(true);
    async function fetchProducts() {
      const response = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/store/getAllTrashProduct`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await response?.json();
      // console.log(responseJson?.data);
      setRows(responseJson?.data);
      setLoading(false);
    }
    fetchProducts();
  }, []);
  const navigate = useNavigate();

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };
  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };
  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows?.map((row) => (row.id === newRow.id ? updatedRow : row)));
    // editDataInDb(newRow)
    return updatedRow;
  };

  const handleTrashClick = (id) => async () => {
    let confirmDelete = window.confirm("Want to Un Trash Product?");
    if (confirmDelete) {
      const token = localStorage.getItem("token");
      const res = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/store/trashStoreProduct/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // setLoadingButton(false)
      const resJson = await res.json();
      if (resJson?.status) {
        toast.success(resJson?.msg);
        setRows(rows?.filter((row) => row.id !== id));
      } else {
        toast.error(resJson?.msg);
      }
      // toast.success(resJson);
    }
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows?.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows?.filter((row) => row.id !== id));
    }
  };

  const access = getAccess();

  const columns = [
    {
      field: "serialNumber",
      headerName: "S NO",
      width: 75,
      align: "left",
      headerAlign: "left",
      editable: false,
      renderCell: (params) => <strong>{params.value}</strong>,
    },

    {
      field: "title",
      headerName: "Title",
      type: "string",
      width: 250,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "admin",
      headerName: "Created By",
      type: "string",
      width: 200,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => {
        return <div>{`${params.value?.name} (${params.value?.role})`}</div>;
      },
    },
    {
      field: "url",
      headerName: "Url",
      type: "string",
      width: 100,
      align: "left",
      headerAlign: "left",
      editable: false,
      renderCell: (params) => (
        <a href={params.value ? params.value : "NA"} target="_blank">
          Link
        </a>
      ),
    },
    {
      field: "featuredImage",
      headerName: "Feature Image",
      type: "string",
      width: 100,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => (
        <img
          src={params.value}
          alt={`Image for row ${params.id}`}
          style={{ width: 70, height: 70 }}
        />
      ),
    },
    {
      field: "images",
      headerName: "Image",
      type: "string",
      width: 300,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => (
        <div style={{ display: "flex" }}>
          {params.value?.map((imageUrl, index) => (
            <img
              key={index}
              src={imageUrl}
              alt={`Image ${index + 1} for row ${params.id}`}
              style={{ width: 40, height: 70, marginRight: 5 }}
            />
          ))}
        </div>
      ),
    },
    {
      field: "productType",
      headerName: "Product Type",
      type: "object",
      width: 100,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "category",
      headerName: "Category",
      type: "object",
      width: 100,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => (
        <div>{params.value?.title ? params.value?.title : "NA"}</div>
      ),
    },
    {
      field: "marketingCat",
      headerName: "MarketingCategory",
      type: "object",
      width: 100,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => <div>{params.value ? params.value : "NA"}</div>,
    },
    {
      field: "code",
      headerName: "Code",
      type: "object",
      width: 100,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => <div>{params.value ? params.value : "NA"}</div>,
    },
    {
      field: "inStock",
      headerName: "Stock",
      type: "object",
      width: 100,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "maxPurchaseQty",
      headerName: "Maximum Purchase Qty",
      type: "object",
      width: 100,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "regularPrice",
      headerName: "Regular Price",
      type: "object",
      width: 100,
      align: "left",
      headerAlign: "left",
      editable: true,
    },
    {
      field: "salePrice",
      headerName: "Sale Price",
      type: "object",
      width: 100,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "badge",
      headerName: "Badge",
      type: "object",
      width: 100,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "tags",
      headerName: "Tags",
      type: "object",
      width: 100,
      align: "left",
      headerAlign: "left",
    },

    {
      field: "isCoinApplicable",
      headerName: "Coin Applicable",
      type: "object",
      width: 100,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => (
        <div style={{ color: params.value == true ? "green" : "red" }}>
          {params.value ? "Applicable" : "Not Applicable"}
        </div>
      ),
    },
    {
      field: "maxAllowedCoins",
      headerName: "Maximun Allowed Coins",
      type: "object",
      width: 100,
      align: "left",
      headerAlign: "left",
    },

    {
      field: "isActive",
      headerName: "Status",
      type: "boolean",
      width: 100,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => (
        <div style={{ color: params.value == true ? "green" : "red" }}>
          {params.value ? "Active" : "inActive"}
        </div>
      ),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      type: "object",
      width: 150,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 150,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <Tooltip title="View Product">
            <GridActionsCellItem
              icon={<VisibilityOutlinedIcon />}
              label="View"
              // onClick={handleDeleteClick(id)}
              onClick={() => navigate(`/dashboard/product_profile/${id}`)}
              color="inherit"
            />
          </Tooltip>,
          access?.deleteAccess || access?.access.includes("all") ? (
            <Tooltip title="Un Trash Product">
              <GridActionsCellItem
                icon={<DeleteForeverIcon style={{ color: "green" }} />}
                label="Trash"
                onClick={handleTrashClick(id)}
                // onClick={ (id) => handleOpen(id)}
                color="inherit"
              />
            </Tooltip>
          ) : (
            <></>
          ),
        ];
      },
    },
  ];

  return (
    <>
      <Box
        sx={{
          height: 590,
          width: "100%",
          "& .actions": {
            color: "text.secondary",
          },
          "& .textPrimary": {
            color: "text.primary",
          },
        }}
      >
        <Stack
          direction="row"
          style={{ display: "flex", justifyContent: "space-between" }}
          m={3}
        >
          <h2>All Trash Product</h2>
        </Stack>

        <DataGrid
          getRowHeight={() => "auto"}
          rows={rows}
          columns={columns || []}
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          editMode="row"
          checkboxSelection
          // onRowSelectionModelChange={handleSelectionModelChange}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 25,
              },
            },
          }}
          pageSizeOptions={[25, 50, 100]}
          slots={{
            toolbar: (gridProps) => (
              <CustomToolbar
                {...gridProps}
                rows={rows}
                columns={columns || []}
              />
            ),
          }}
          slotProps={{
            toolbar: { setRows, setRowModesModel, showQuickFilter: true },
          }}
        />

        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Box>
    </>
  );
}
