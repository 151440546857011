import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const StudentAnaytics = () => {
  const { lectId } = useParams();
  const [products, setProducts] = useState([]);
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchVideos() {
      const response = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/getTimeSpendByLecture/${lectId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await response?.json();
      // console.log(responseJson?.data);
      setProducts(responseJson?.data);
      // setLoading(false);
    }
    fetchVideos();
  }, []);

  return (
    <div>
      <ul>
        {products?.map((item, index) => (
          <li style={{ listStyle: "none " }}>
            <p>
              <b>Name:</b>
              {item?.userName}
            </p>
            <p>
              <b>Time Spend:</b>
              {item?.timeSpends}
            </p>
            <hr />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default StudentAnaytics;
