import {
  Avatar,
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import Page from "../../../components/Page";
import ScrollToBottom from "react-scroll-to-bottom";
import "./style.css";
import axios from "axios";
import { AttachFile, EmojiEmotions, Send } from "@mui/icons-material";
import DefaultScreen from "./DefaultScreen";
import ScrollToTop from "../../../components/ScrollToTop";
import { useTheme } from "@emotion/react";
import getAccess from "../../../utils/getAccess";
// import EmojiPicker from 'emoji-picker-react';

const inputStyle = {
  width: "100%",
  height: "40px",
  border: "none",
  background: "transparent",
  outline: "none",
};

function Chat({ socket, username, room, use }) {
  const [currentMessage, setCurrentMessage] = useState("");
  const [messageList, setMessageList] = useState([]);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [arrivalMessage, setArrivalMessage] = useState([]);
  const [getMessage, setGetMessage] = useState([]);
  const name = localStorage.getItem("details");
  const idForMessage = localStorage.getItem("id");
  const idOfAdmin = "6342a4474390f89205f3d578 ";

  const [teacherName, setTeacherName] = useState();
  const [receiverId, setRecieverId] = useState();
  const [teacherAvatar, setTeacherAvatar] = useState();
  const details = getAccess();
  const role = details?.role == "admin" ? "Admin" : details?.role;

  const theme = useTheme();

  const listItem = {
    transition: "all 0.1s",
    bgcolor: "white",
    marginBottom: "10px",
    width: "92%",
    height: "75px",
    borderRadius: "15px",
    "&:hover": {
      background: `linear-gradient(180deg, ${theme.palette.primary.mainThemeLight} 0%, rgba(249, 83, 104, 0.4) 100%)', color: 'white`,
    },
  };

  //Sending Message to another user
  const sendMessage = async () => {
    // console.log('line62')
    if (currentMessage !== "") {
      const messageData = {
        senderId: role === "Admin" ? "6342a4474390f89205f3d578" : idForMessage,
        receiverId: role === "Admin" ? receiverId : "6342a4474390f89205f3d578",
        text: currentMessage,
        time:
          new Date(Date.now()).getHours() +
          ":" +
          new Date(Date.now()).getMinutes(),
      };

      socket.emit("sendMessage", messageData);
      setMessageList((list) => [...list, messageData]);
      setCurrentMessage("");
    }
  };

  // //console.log(new Date(Date.now()).getHours() +
  //     ":" +
  //     new Date(Date.now()).getMinutes())

  //Getting Messages From Particular user
  useEffect(() => {
    socket.on("getMessage", (data) => {
      // //console.log('camr')
      let obj = {
        senderId: data?.senderId,
        text: data?.text,
        time: Date.now(),
      };
      //console.log(obj)
      setArrivalMessage((list) => [...list, obj]);
      setMessageList((list) => [...list, obj]);
    });
  }, []);

  //Adding new User to Socket
  useEffect(() => {
    const id = role === "Admin" ? "6342a4474390f89205f3d578" : idForMessage;
    let data = {
      id,
      username: role,
    };
    socket.emit("addUser", data);
    socket.on("getUsers", (users) => {
      setOnlineUsers(users);
    });
  }, [socket]);

  // console.log(role)

  //Fetching Messages
  useEffect(() => {
    const id = role === "Admin" ? receiverId : idForMessage;
    const fetchMessages = async () => {
      const res = await axios.get(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/getMessages?admin=6342a4474390f89205f3d578&teacher=${id}`
      );
      //console.log(res?.data?.data)
      setGetMessage(res?.data?.data);
      // setMessageList((list) => [...list, ]);
      setCurrentMessage("");
      // window.location.
    };
    fetchMessages();
  }, [teacherName]);

  //console.log(getMessage[getMessage.length - 1]?.messageTime)
  //Getting Teacher List
  const [users, usersSet] = useState();
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminTeacher/teacherList`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      usersSet(responseJson?.data);
    }

    fetchUsers();
  }, []);

  return (
    <>
      <Page title="Inbox">
        <Grid container spacing={0} sx={{ height: "72vh" }}>
          {teacherName || role === "Teacher" ? (
            <Grid
              item
              xs={10}
              sm={9}
              md={role === "Teacher" ? 10 : 9}
              sx={{
                position: "relative",
                minHeight: "75%",
                maxHeight: "75%",
                display: use == "MainPage" ? "none" : "block",
              }}
            >
              {/* <Card sx={{ minHeight: '80vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}> */}

              <Box
                pl={2}
                pt={2}
                pb={2}
                sx={{
                  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.25)",
                  bgcolor: "white",
                  borderRadius: "0",
                  height: "70px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Stack direction="row" spacing={2}>
                  <Avatar src={teacherAvatar} />
                  <Stack>
                    <Typography sx={{ fontSize: "15px", fontWeight: "700" }}>
                      {role === "Admin" ? teacherName : "Admin"}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "500",
                        color: "#637381",
                      }}
                    >
                      Last Seen:{" "}
                      {getMessage[getMessage.length - 1]?.messageTime}
                    </Typography>
                  </Stack>
                </Stack>
              </Box>

              <ScrollToBottom className="rootc">
                <Box sx={{ padding: "10px 10px", maxHeight: "200px" }}>
                  {getMessage?.map((messageContent) => {
                    return (
                      <>
                        {role === "Admin" ? (
                          messageContent?.from ===
                          "6342a4474390f89205f3d578" ? (
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Stack
                                sx={{
                                  borderRadius: "20px",
                                  border: "1px solid lightgray",
                                  p: 1,
                                  maxWidth: "45%",
                                  minWidth: "12%",
                                  mt: 1,
                                  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
                                }}
                              >
                                {" "}
                                <Typography>
                                  {messageContent.messageBody}
                                </Typography>
                                <Typography
                                  sx={{
                                    textAlign: "end",
                                    fontWeight: "700",
                                    fontSize: "10px",
                                  }}
                                >
                                  {messageContent.messageTime}
                                </Typography>
                              </Stack>
                            </Box>
                          ) : (
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "flex-start",
                              }}
                            >
                              <Stack
                                sx={{
                                  borderRadius: "20px",
                                  border: "1px solid lightgray",
                                  p: 1,
                                  maxWidth: "45%",
                                  minWidth: "12%",
                                  mt: 1,
                                  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
                                }}
                              >
                                {" "}
                                <Typography>
                                  {messageContent.messageBody}
                                </Typography>
                                <Typography
                                  sx={{
                                    textAlign: "end",
                                    fontWeight: "700",
                                    borderRadius: "5px",
                                    fontSize: "10px",
                                  }}
                                >
                                  {messageContent.messageTime}
                                </Typography>
                              </Stack>
                            </Box>
                          )
                        ) : null}
                        {role === "Teacher" ? (
                          messageContent?.from === idForMessage &&
                          role === "Teacher" ? (
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Stack
                                sx={{
                                  borderRadius: "20px",
                                  border: "1px solid lightgray",
                                  p: 1,
                                  maxWidth: "45%",
                                  minWidth: "12%",
                                  mt: 1,
                                  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
                                }}
                              >
                                {" "}
                                <Typography>
                                  {messageContent.messageBody}
                                </Typography>
                                <Typography
                                  sx={{
                                    textAlign: "end",
                                    fontWeight: "700",
                                    borderRadius: "5px",
                                    fontSize: "10px",
                                  }}
                                >
                                  {messageContent.messageTime}
                                </Typography>
                              </Stack>
                            </Box>
                          ) : (
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "flex-start",
                              }}
                            >
                              <Stack
                                sx={{
                                  borderRadius: "20px",
                                  border: "1px solid lightgray",
                                  p: 1,
                                  maxWidth: "45%",
                                  minWidth: "12%",
                                  mt: 1,
                                  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
                                }}
                              >
                                <Typography>
                                  {messageContent.messageBody}
                                </Typography>
                                <Typography
                                  sx={{
                                    textAlign: "end",
                                    fontWeight: "700",
                                    borderRadius: "5px",
                                    fontSize: "10px",
                                  }}
                                >
                                  {messageContent.messageTime}
                                </Typography>
                              </Stack>{" "}
                            </Box>
                          )
                        ) : null}
                      </>
                    );
                  })}

                  {messageList?.map((messageContent) => {
                    {
                      /* //console.log(messageContent)
                                    //console.log(receiverId)
                                    //console.log(messageContent.senderId) */
                    }
                    return (
                      <>
                        {role === "Admin" ? (
                          messageContent?.receiverId === receiverId ? (
                            messageContent?.senderId ===
                            "6342a4474390f89205f3d578" ? (
                              <Box
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <Stack
                                  sx={{
                                    borderRadius: "20px",
                                    border: "1px solid lightgray",
                                    p: 1,
                                    maxWidth: "45%",
                                    minWidth: "12%",
                                    mt: 1,
                                    boxShadow:
                                      "0px 4px 20px rgba(0, 0, 0, 0.15)",
                                  }}
                                >
                                  <Typography>{messageContent.text}</Typography>
                                  <Typography
                                    sx={{
                                      textAlign: "end",
                                      fontWeight: "700",
                                      borderRadius: "5px",
                                      fontSize: "10px",
                                    }}
                                  >
                                    {messageContent.time}
                                  </Typography>
                                </Stack>
                              </Box>
                            ) : (
                              <Box
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <Stack
                                  sx={{
                                    borderRadius: "20px",
                                    border: "1px solid lightgray",
                                    p: 1,
                                    maxWidth: "45%",
                                    minWidth: "12%",
                                    mt: 1,
                                    boxShadow:
                                      "0px 4px 20px rgba(0, 0, 0, 0.15)",
                                  }}
                                >
                                  <Typography>{messageContent.text}</Typography>
                                  <Typography
                                    sx={{
                                      textAlign: "end",
                                      fontWeight: "700",
                                      borderRadius: "5px",
                                      fontSize: "10px",
                                    }}
                                  >
                                    {messageContent.time}
                                  </Typography>
                                </Stack>
                              </Box>
                            )
                          ) : receiverId === messageContent?.senderId ? (
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "flex-start",
                              }}
                            >
                              <Stack
                                sx={{
                                  borderRadius: "20px",
                                  border: "1px solid lightgray",
                                  p: 1,
                                  maxWidth: "45%",
                                  minWidth: "12%",
                                  mt: 1,
                                  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
                                }}
                              >
                                <Typography>{messageContent.text} </Typography>
                                <Typography
                                  sx={{
                                    textAlign: "end",
                                    fontWeight: "700",
                                    borderRadius: "5px",
                                    fontSize: "10px",
                                  }}
                                >
                                  {messageContent.time}
                                </Typography>
                              </Stack>
                            </Box>
                          ) : null
                        ) : null}
                        {role === "Teacher" ? (
                          messageContent?.senderId === idForMessage ? (
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Stack
                                sx={{
                                  borderRadius: "20px",
                                  border: "1px solid lightgray",
                                  p: 1,
                                  maxWidth: "45%",
                                  minWidth: "12%",
                                  mt: 1,
                                  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
                                }}
                              >
                                <Typography>{messageContent.text}</Typography>
                                <Typography
                                  sx={{
                                    textAlign: "end",
                                    fontWeight: "700",
                                    borderRadius: "5px",
                                    fontSize: "10px",
                                  }}
                                >
                                  {messageContent.time}
                                </Typography>
                              </Stack>
                            </Box>
                          ) : (
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "flex-start",
                              }}
                            >
                              <Stack
                                sx={{
                                  borderRadius: "20px",
                                  border: "1px solid lightgray",
                                  p: 1,
                                  maxWidth: "45%",
                                  minWidth: "12%",
                                  mt: 1,
                                  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
                                }}
                              >
                                <Typography>{messageContent.text}</Typography>
                                <Typography
                                  sx={{
                                    textAlign: "end",
                                    fontWeight: "700",
                                    borderRadius: "5px",
                                    fontSize: "10px",
                                  }}
                                >
                                  {messageContent.time}
                                </Typography>
                              </Stack>
                            </Box>
                          )
                        ) : null}
                      </>
                    );
                  })}
                </Box>
              </ScrollToBottom>

              {teacherName || role === "Teacher" ? (
                <Stack
                  direction="row"
                  p={2}
                  sx={{ alignItems: "center", justifyContent: "center" }}
                >
                  <Avatar src={details?.profilePhoto} sx={{ mr: 2 }} />

                  <Box
                    sx={{
                      display: "flex",
                      width: "80%",
                      height: "50px",
                      alignItems: "center",
                      justifyContent: "space-between",
                      border: "1px solid #FF5895",
                      boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.25)",
                      borderRadius: "10px",
                      padding: 1,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      {/* <IconButton color="primary" aria-label="upload picture" component="label">
                                        <input hidden accept="image/*" type="file" />
                                        <AttachFile color="error" />
                                    </IconButton> */}

                      <input
                        placeholder="Type Something....."
                        name="title"
                        value={currentMessage}
                        onChange={(event) => {
                          setCurrentMessage(event.target?.value);
                        }}
                        onKeyPress={(event) => {
                          event.key === "Enter" && sendMessage();
                        }}
                        style={{ ...inputStyle, pb: 1 }}
                      />
                    </div>
                    {/* <EmojiEmotions color='error' /> */}
                    {/* <EmojiPicker /> */}
                    <Button sx={{ color: "red" }} onClick={sendMessage}>
                      <Send />
                    </Button>
                  </Box>
                </Stack>
              ) : null}

              {/* </Card> */}
            </Grid>
          ) : role === "Admin" ? (
            <DefaultScreen use={use} />
          ) : (
            "hello"
          )}
          {role === "Admin" && (
            <Grid
              item
              xs={2}
              sm={3}
              md={use == "MainPage" ? 12 : 3}
              p={0}
              sx={{
                height: "72vh",
                width: "100%",
                background: `linear-gradient(180deg, ${theme.palette.primary.mainThemeLight} 0%, ${theme.palette.primary.mainThemeLight} 100%)`,
              }}
            >
              <Typography variant="h5" sx={{ textAlign: "center", p: 2.5 }}>
                Chats
              </Typography>
              <Divider />

              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "15px",
                  mt: 1,
                  mb: 1,
                  fontWeight: "500",
                }}
              >
                All Teachers
              </Typography>
              <List
                sx={{
                  width: "100%",
                  height: "100%",
                  bgcolor: "transparent",
                  padding: "2",
                }}
              >
                <Box className="rootcc" sx={{}}>
                  {users?.map((item, i) => {
                    return (
                      <ListItem
                        sx={{ cursor: "pointer", ...listItem, ml: 2 }}
                        onClick={() => {
                          setTeacherName(item?.FullName);
                          setRecieverId(item?._id);
                          setTeacherAvatar(item?.profilePhoto);
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar
                            sx={{ height: "40px", width: "40px" }}
                            src={item?.profilePhoto}
                          ></Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primaryTypographyProps={{
                            fontSize: "15px",
                            fontWeight: "700",
                          }}
                          secondaryTypographyProps={{}}
                          primary={item?.FullName}
                          // secondary={
                          //     <Typography sx={{
                          //         fontSize: '10px',
                          //         color: 'gray',
                          //         '&:hover': {
                          //             color: 'black'
                          //         }
                          //     }}>{getMessage[getMessage.length - 1]?.messageBody}</Typography>
                          // }
                        />

                        {/* <span style={{
                                            fontSize: '12px', color: '#FF5895',
                                        }}>9:11</span> */}
                      </ListItem>
                    );
                  })}
                </Box>
                {/* <Divider /> */}
                {/* <Typography m={1} sx={{ textAlign: 'center', fontSize: '13px', fontWeight: '500', color: 'gray ' }}>See All ↓</Typography> */}
              </List>
              {/* </Card> */}
            </Grid>
          )}
        </Grid>
      </Page>
    </>
  );
}
export default Chat;
