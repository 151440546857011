import { filter } from "lodash";
// import { sentenceCase } from 'change-case';
import { useEffect, useState } from "react";
// import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  // Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Button,
  MenuItem,
  TextField,
  Input,
} from "@mui/material";
// components
import Page from "../../../components/Page";

import Scrollbar from "../../../components/Scrollbar";

import SearchNotFound from "../../../components/SearchNotFound";
import { UserListHead, UserListToolbar, UserMoreMenu } from "../user";
// mock
// importusers ? from '../_mock/user';
import { useNavigate } from "react-router-dom";
import SyncLoader from "react-spinners/SyncLoader";
import PhotoIcon from "@mui/icons-material/Photo";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTheme } from "@emotion/react";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "", label: "SNO", alignRight: false },
  { id: "student_name", label: "Student Name", alignRight: false },
  { id: "exam", label: "Exam", alignRight: false },
  { id: "photo", label: "File", alignRight: false },
  { id: "rank", label: "Rank", alignRight: false },
  { id: "message", label: "Message", alignRight: false },
  { id: "language", label: "Language", alignRight: false },
  { id: "created_at", label: "Date", alignRight: false },
  { id: "is_active", label: "Status", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.student_name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis?.map((el) => el[0]);
}

export default function TestimonialTable() {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");
  const [selectedId, setSelectedId] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();
  const [users, usersSet] = useState();
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState();

  const [isActiveValue, setIsActiveValue] = useState(false);
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/getTestimonialDetails`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      usersSet(responseJson?.data);
      setLoading(false);
    }

    fetchUsers();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target?.checked) {
      const newSelecteds = users?.map((n) => n?.student_name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected?.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected?.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected.concat(selected?.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected?.slice(0, selectedIndex),
        selected?.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target?.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target?.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users?.length) : 0;

  const filteredUsers = applySortFilter(
    users ? users : ["no"],
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound =
    filteredUsers?.length === 0 || filteredUsers[0] === "no";

  const [isEdit, setIsEdit] = useState(false);
  const [updatedValues, setUpdatedValues] = useState({});
  //console.log(updatedValues);
  const theme = useTheme();

  return (
    <>
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "60vh" }}
        >
          {" "}
          <SyncLoader
            color={theme.palette.primary.mainTheme}
            loading={loading}
            size={20}
          />
        </Stack>
      ) : (
        <Page title="Testimonials">
          <Container sx={{ position: "absolute", minWidth: "100%" }}>
            <Card>
              <UserListToolbar
                numSelected={selected?.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
              />

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={users?.length}
                      numSelected={selected?.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {filteredUsers[0] !== "no" &&
                        filteredUsers
                          ?.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          ?.map((row, index) => {
                            const {
                              id,
                              student_name,
                              _id,
                              photo,
                              rank,
                              message,
                              exam,
                              language,
                              created_at,
                              is_active,
                            } = row;
                            const isItemSelected =
                              selected?.indexOf(student_name) !== -1;
                            {
                              /* row.sno = index + 1 + rowsPerPage * page */
                            }

                            //Handling File
                            const changeHandler = async (event) => {
                              setFile(event.target?.files[0]);
                            };

                            const onChange = (e, row, _id) => {
                              const { name, value } = e.target;
                              if (name == "is_active") {
                                setIsActiveValue(true);
                              }
                              setUpdatedValues((prev) => {
                                return { ...prev, [name]: value };
                              });

                              {
                                /* else {
                                                    setUpdatedValues((prev) => {
                                                        return { ...row, [name]: value, category: row.category.title }
                                                    })
                                                } */
                              }
                            };
                            const handleUpdate = async () => {
                              setLoading(true);
                              let data = { ...updatedValues };
                              makeReq(data);
                              {
                                /* const token = localStorage.getItem("token");
                                                const authToken = token;
                                                const config = {
                                                    headers: {
                                                        'content-type': 'application/json',
                                                        Authorization: `Bearer ${authToken}`,
                                                    },
                                                };
                                                const res = await axios.put(`https://api-prod.gyaankulam.com/api/v1/adminPanel/updatedTestimonialDetails/${_id}`, updatedValues, config);
                                                setLoading(false)
                                                toast.success('Updated')
                                                setTimeout(() => {

                                                    window.location.reload()

                                                }
                                                    , 600) */
                              }
                            };

                            const makeReq = async (data) => {
                              const token = localStorage.getItem("token");
                              const authToken = token;
                              const formData = new FormData();

                              if (file) {
                                formData.append("file", file);
                              }
                              if (data?.student_name) {
                                formData.append(
                                  "student_name",
                                  data?.student_name
                                );
                              }
                              if (data?.rank) {
                                formData.append("rank", data?.rank);
                              }
                              if (data?.year) {
                                formData.append("year", data?.year);
                              }
                              if (data?.message) {
                                formData.append("message", data?.message);
                              }
                              if (data?.exam) {
                                formData.append("exam", data?.exam);
                              }
                              if (data?.language) {
                                formData.append("language", data?.language);
                              }
                              if (isActiveValue) {
                                formData.append("is_active", data?.is_active);
                              }

                              const config = {
                                headers: {
                                  "content-type": "multipart/form-data",
                                  Authorization: `Bearer ${authToken}`,
                                },
                              };
                              const res = await axios.put(
                                `https://api-prod.gyaankulam.com/api/v1/adminPanel/updatedTestimonialDetails/${_id}`,
                                formData,
                                config
                              );
                              toast.success("Updated");
                              setLoading(false);
                              setTimeout(() => window.location.reload(), 800);
                            };

                            return (
                              <TableRow
                                hover
                                key={id}
                                tabIndex={-1}
                                role="checkbox"
                                selected={isItemSelected}
                                aria-checked={isItemSelected}
                              >
                                {/* <TableCell padding="checkbox">
                                                        <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, student_name)} />
                                                    </TableCell> */}
                                <TableCell>
                                  <Typography variant="subtitle2">
                                    {index + 1}
                                  </Typography>
                                </TableCell>
                                <TableCell component="th" scope="row" pl={2}>
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={2}
                                  >
                                    {/* <Avatar alt={name} src={avatarUrl} /> */}
                                    <Typography
                                      variant="substudent_name2"
                                      noWrap
                                      sx={{ fontWeight: "600" }}
                                    >
                                      {isEdit && selectedId === _id ? (
                                        <Input
                                          onChange={(e) =>
                                            onChange(e, row, _id)
                                          }
                                          // value={value}
                                          name="student_name"
                                          placeholder={student_name}
                                        />
                                      ) : (
                                        <span>{student_name}</span>
                                      )}
                                    </Typography>
                                  </Stack>
                                </TableCell>

                                <TableCell align="left">
                                  {isEdit && selectedId === _id ? (
                                    <Input
                                      onChange={(e) => onChange(e, row, _id)}
                                      // value={value}
                                      name="exam"
                                      placeholder={exam}
                                    />
                                  ) : (
                                    <span>{exam}</span>
                                  )}
                                </TableCell>
                                <TableCell align="left">
                                  {isEdit && selectedId === _id ? (
                                    <>
                                      <input
                                        style={{
                                          display: "none",
                                        }}
                                        id="choose-file"
                                        type="file"
                                        onChange={changeHandler}
                                      />
                                      <label htmlFor="choose-file">
                                        <FileUploadIcon
                                          sx={{
                                            cursor: "pointer",
                                            "&:hover": {
                                              color: "red",
                                            },
                                          }}
                                        />
                                      </label>
                                    </>
                                  ) : (
                                    <a
                                      href={photo?.fileLoc}
                                      style={{ color: "black" }}
                                    >
                                      <PhotoIcon
                                        sx={{
                                          cursor: "pointer",
                                          "&:hover": {
                                            color: "red",
                                          },
                                        }}
                                      />
                                    </a>
                                  )}
                                </TableCell>
                                <TableCell align="left">
                                  {isEdit && selectedId === _id ? (
                                    <Input
                                      onChange={(e) => onChange(e, row, _id)}
                                      // value={value}
                                      name="rank"
                                      placeholder={rank}
                                    />
                                  ) : (
                                    <span>{rank}</span>
                                  )}
                                </TableCell>
                                <TableCell align="left">
                                  {isEdit && selectedId === _id ? (
                                    <Input
                                      onChange={(e) => onChange(e, row, _id)}
                                      // value={value}
                                      name="message"
                                      placeholder={message}
                                    />
                                  ) : (
                                    <span>{message}</span>
                                  )}
                                </TableCell>
                                <TableCell align="left">
                                  {isEdit && selectedId === _id ? (
                                    <TextField
                                      onChange={(e) => onChange(e, row)}
                                      // value={value}
                                      label={
                                        language === "hi" ? "Hindi" : "English"
                                      }
                                      name="language"
                                      placeholder={
                                        language === "hi" ? "Hindi" : "English"
                                      }
                                      select
                                      sx={{ width: "100px" }}
                                    >
                                      <MenuItem value="hi">Hindi</MenuItem>
                                      <MenuItem value="en">English</MenuItem>
                                    </TextField>
                                  ) : (
                                    <span>
                                      {" "}
                                      {language === "hi" ? "Hindi" : "English"}
                                    </span>
                                  )}
                                </TableCell>
                                <TableCell align="left">
                                  {created_at.split(" ")[0] ?? ""}
                                </TableCell>
                                <TableCell align="left">
                                  {" "}
                                  {isEdit && selectedId === _id ? (
                                    <TextField
                                      onChange={(e) => onChange(e, row)}
                                      // value={value}
                                      label={is_active ? "Active" : "Inactive"}
                                      name="is_active"
                                      placeholder={
                                        is_active ? "Active" : "Inactive"
                                      }
                                      select
                                      sx={{ width: "100px" }}
                                    >
                                      <MenuItem value={true}>Active</MenuItem>
                                      <MenuItem value={false}>
                                        Inactive
                                      </MenuItem>
                                    </TextField>
                                  ) : (
                                    <span
                                      style={{
                                        background: is_active ? "green" : "red",
                                        padding: "5px",
                                        borderRadius: "10px",
                                        color: "white",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {is_active ? "Active" : "Inactive"}
                                    </span>
                                  )}
                                </TableCell>
                                {/* <TableCell align="left">{username}</TableCell> */}
                                {isEdit && selectedId === _id ? (
                                  <TableCell align="left">
                                    <Button
                                      variant="outlined"
                                      onClick={handleUpdate}
                                    >
                                      Update
                                    </Button>
                                  </TableCell>
                                ) : null}

                                <TableCell
                                  align="right"
                                  onClick={() => setSelectedId(_id)}
                                >
                                  <UserMoreMenu
                                    id={_id}
                                    req="DeleteTestimonialDetails"
                                    isEdit={isEdit}
                                    setIsEdit={setIsEdit}
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>

                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>
              <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={users?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </Container>
        </Page>
      )}
    </>
  );
}
