import PropTypes from "prop-types";
// form
import { useFormContext, Controller } from "react-hook-form";
// @mui
import { Typography } from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

// ----------------------------------------------------------------------

RHFTextEditor.propTypes = {
  name: PropTypes.string,
};

export default function RHFTextEditor({ name, label, ...other }) {
  const { control } = useFormContext();
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6] }],
      [{ font: [] }],
      [{ size: [] }],
      [{ color: [] }, { background: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
    ],
  };
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        // <TextField
        //   {...field}
        //   fullWidth
        //   value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
        //   error={!!error}
        //   helperText={error?.message}
        //   {...other}
        // />
        

        <div>
          <Typography sx={{ padding: "5px" }}> {label} </Typography>
          <ReactQuill
            {...field}
            theme="snow"
            value={
              typeof field.value === "number" && field.value === 0
                ? ""
                : field.value
            }
            error={!!error}
            helperText={error?.message}
            modules={modules}
            {...other}
          />
          {/* {error && <Typography color="error">{error.message}</Typography>} */}
        </div>
      )}
    />
  );
}
