import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Stack,
  IconButton,
  InputAdornment,
  Typography,
  MenuItem,
  Button,
  Card,
  Divider,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components

import { FormProvider, RHFTextField } from "../../../../components/hook-form";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

// import "./general.css";
import { useTheme } from "@emotion/react";

// ----------------------------------------------------------------------

export default function UpdateFifth({
  setShowForm,
  setProductId,
  productId,
  title,
  editValue,
  fetchUsers,
}) {
  const theme = useTheme();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [restrict, setRestrict] = useState("");
  const [focus, setFocused] = useState(false);
  const [pdf, setPdf] = useState(false);
  const [msg, setMsg] = useState("");
  const [val, setVal] = useState(false);
  const [type, setType] = useState("none");
  const [valueId, setValueId] = useState("");
  const [list, setList] = useState([]);
  const [batchId, setBatchId] = useState("");

  const RegisterSchema = Yup.object().shape({
    // title: Yup.string().required('Title required'),
    // videoType: Yup.string().required('Video Type required'),
    // url: Yup.string()
    // .required('Link Banner Required'),
    // valueId: Yup.string().when('link', {
    //   is: (link) => link !== 'none' && link !== '',
    //   then: Yup.string().required('Link With Required'),
    //   otherwise: Yup.string().notRequired(),
    // }),
    // category: Yup.string().required('Category is required'),
    // banner_url: Yup.string().required('Url No. is required'),
    // language: Yup.string().required('Language is required'),
    // isActive: Yup.boolean().required('Status Required'),
  });

  const defaultValues = {
    // title: '',
    // BannerType: '',
    // category: '',
    // banner_url: '',
    // language: '',
    // videoType: '',
    // linkWith: '',
    // url: '',
    // isActive: '',
  };
  const [file, setFile] = useState();

  const handleChange = (value) => {
    //console.log(`${value}`);
  };
  const changeHandler = async (event) => {
    setFile(event.target?.files);
    var fileName = document.getElementById("file").value?.toLowerCase();
    if (fileName.endsWith(".pdf") || fileName.endsWith(".jpg")) {
      setRestrict("");
    } else {
      setRestrict("JPG Or PDF");
    }
    //console.log(file)
  };
  //console.log(file)
  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const [progress, setProgress] = useState();
  // for progress bar

  const onSubmit = async (data) => {
    // //console.log(data)
    // var fileName = document.getElementById('file').value?.toLowerCase();

    // if (fileName.endsWith('.pdf') || fileName.endsWith('.jpg')) {

    // setRestrict('')
    // let myfile = [...file]

    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      onUploadProgress: (data) => {
        //Set the progress value to show the progress bar
        setProgress(Math.round((100 * data?.loaded) / data?.total));
      },
    };
    const formData = new FormData();
    attribute?.map((item) => {
      // console.log(item)
      formData.append("attributes", item);
    });
    // document.querySelector(".mainDiv").style.display = "block";
    setProgress(0);

    axios
      .post(
        `https://api-prod.gyaankulam.com/api/v1/store/addStoreProductFiveth/${editValue?.id}`,
        { attributes: attribute },
        config
      )
      .then((response) => {
        //console.log(response?.data);
        if (response?.data?.msg === "Product Details added") {
          setProductId(response?.data?.data?._id);
          toast.success(response?.data?.msg);
          setLoading(false);
          setPdf(false);
          reset();
          fetchUsers();
          window.location.href = "/dashboard/storeAllProducts";
        }
      })
      .catch((e) => {
        //console.log(e);
      });

    // } else {

    //     setRestrict('Please Select pdf or jpg')
    // }
  };

  //get batch or get testSeries
  useEffect(() => {
    if (type !== "" && type !== "none") {
      const token = localStorage.getItem("token");
      async function fetchDetails() {
        const fullResponse = await fetch(
          `https://api-prod.gyaankulam.com/api/v1/adminPanel/${
            type == "batch" ? "getBatchesDetails" : "getallTestSeriesdetails"
          }`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const responseJson = await fullResponse.json();
        setList(responseJson?.data);
      }
      fetchDetails();
    }
    setLoading(false);
  }, [type]);

  const [products, setProducts] = useState([]);
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchVideos() {
      const response = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/store/getAllStoreProduct`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await response?.json();
      // console.log(responseJson?.data);
      setProducts(responseJson?.data);
      setLoading(false);
    }
    fetchVideos();
  }, []);

  const [attribute, setAttribute] = useState([]);
  const [attrName, setAttrName] = useState("");
  const [attrValue, setAttrValue] = useState("");
  const [variations, setVariations] = useState([]);
  const [variationValue, setVariationValue] = useState({});

  useEffect(() => {
    setAttribute(editValue?.attributes);
  }, []);
  // console.log(attribute)
  return (
    <>
      <Stack spacing={3} px={5} direction="row">
        <Stack spacing={3} sx={{ width: "100%" }}>
          {" "}
          <Typography variant="h3">
            Update Attritbutes for{" "}
            <span style={{ color: "blue" }}>{title}</span> Product{" "}
          </Typography>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3} sx={{ width: "70%" }}>
              {/* {attribute} */}

              <RHFTextField
                name="attrName"
                onChange={(e) => {
                  setAttrName(e.target?.value);
                  // setVariationValue((prev) => ({ ...prev, [e.target?.name]: e.target?.value }))
                }}
                label="Attritbute Name"
                value={attrName}
              />
              <RHFTextField
                name="attrValue"
                onChange={(e) => {
                  setAttrValue(e.target?.value);

                  setVariationValue((prev) => ({
                    ...prev,
                    [attrName]: e.target?.value,
                  }));
                }}
                label="Attritbute Value"
                value={attrValue}
              />

              {/* {attribute?.map((item) => { return <Typography>{item?.color}   </Typography> })} */}

              {/* <RHFTextField name="code" onChange={(e) => { setVariationValue((prev) => ({ ...prev, [e.target?.name]: e.target?.value })) }} label="Code" />
                    <RHFTextField name="regularPrice" onChange={(e) => { setVariationValue((prev) => ({ ...prev, [e.target?.name]: e.target?.value })) }} label="Regular Price" />
                    <RHFTextField name="salePrice" onChange={(e) => { setVariationValue((prev) => ({ ...prev, [e.target?.name]: e.target?.value })) }} label="Sale Price" />
                    <RHFTextField name="startDate" type='date' onChange={(e) => { setVariationValue((prev) => ({ ...prev, schedule: { startDate: e.target?.value } })) }} label="" />
                    <RHFTextField name="endDate" type='date' onChange={(e) => { setVariationValue((prev) => ({ ...prev, schedule: { endDate: e.target?.value } })) }} label="" />
                    <RHFTextField name="desc" onChange={(e) => { setVariationValue((prev) => ({ ...prev, [e.target?.name]: e.target?.value })) }} label="Description" /> */}

              {/* <RHFTextField name="batchId" label="Batch Id" select>
                        {products?.map((item, index) => <MenuItem value={item?._id} onClick={() => setBatchId(item?._id)}>{item?.title}</MenuItem>)}

                    </RHFTextField> */}
              <Button
                sx={{
                  color: "white",
                  bgcolor: theme.palette.primary.mainTheme,
                  marginTop: "5%",
                  "&:hover": { background: "red" },
                }}
                onClick={() => {
                  setAttribute((prev) => [...prev, { [attrName]: attrValue }]);
                  // setVariations((prev) => ([...prev, variationValue]))
                  setAttrName("");
                  setAttrValue("");
                  reset();
                }}
              >
                Add More Attributes
              </Button>

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={loading}
                sx={{
                  bgcolor: theme.palette.primary.mainTheme,
                  marginTop: "5%",
                  "&:hover": { background: "red" },
                }}
                onClick={() => {
                  setVal(true);
                  setMsg("Required");
                }}
              >
                Submit
              </LoadingButton>
            </Stack>
          </FormProvider>
          <ToastContainer
            position="bottom-center"
            autoClose={1000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </Stack>
        <Stack sx={{ width: "40%" }}>
          <h3>Selected Attributes</h3>
          <Card sx={{ paddingBottom: 0, paddingLeft: 2 }}>
            {attribute?.map((item, index) => {
              let key = Object.keys(item)[0];
              let value = item[key];
              return (
                <>
                  {" "}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      marginBottom: "10px",
                      marginTop: "10px",
                    }}
                  >
                    <Typography sx={{ fontSize: "15px", fontWeight: 600 }}>
                      {index + 1}- {key} :
                    </Typography>
                    <span style={{ color: "gray" }}>{value}</span>{" "}
                  </div>
                  <Divider />
                </>
              );
            })}
          </Card>
        </Stack>
        {/* <Typography color={positive ? "green" : "red"} sx={{ textAlign: 'center', width: '80%' }}>{fetchMessage}</Typography> */}
      </Stack>
    </>
  );
}
