import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// form
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Stack,
  IconButton,
  InputAdornment,
  Typography,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import CloseIcon from "@mui/icons-material/Close";
// components

import { FormProvider, RHFTextField } from "../../../components/hook-form";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

import "./general.css";
import { useTheme } from "@emotion/react";

// ----------------------------------------------------------------------

export default function UpdateYoutube() {
  const { youtubeId } = useParams();
  const theme = useTheme();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [restrict, setRestrict] = useState("");
  const [focus, setFocused] = useState(false);
  const [pdf, setPdf] = useState(false);
  const [msg, setMsg] = useState("");
  const [val, setVal] = useState(false);

  const [list, setList] = useState([]);
  const [videoDetails, setVideoDetails] = useState();
  const [values, setValues] = useState({});
  const [type, setType] = useState(values?.link || "none");
  const [id, setId] = useState(values?.linkWith?._id || "");
  const [showImage, setShowImage] = useState(false);
  const [imageName, setImageName] = useState("");

  const RegisterSchema = Yup.object().shape({});

  const defaultValues = {
    title: "",
    BannerType: "",
    link: values?.link,
    linkWith: values?.linkWith?.id || "",
    is_active: "",
  };

  const [file, setFile] = useState();

  const changeHandler = async (event) => {
    setFile(event.target?.files);
    var fileName = document.getElementById("file").value?.toLowerCase();
    if (fileName.endsWith(".pdf") || fileName.endsWith(".jpg")) {
      setRestrict("");
    } else {
      setRestrict("JPG Or PDF");
    }
    //console.log(file)
  };
  //console.log(file)
  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    register,
    errors,
    handleSubmit,
    reset,
    control,
    formState: { isSubmitting },
  } = methods;

  const [progress, setProgress] = useState();
  //get banner details
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchDetails() {
      const fullResponse = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/getYoutubeVideo/${youtubeId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      setVideoDetails(responseJson?.data);
      checkDetails(responseJson?.data);
    }
    fetchDetails();

    setLoading(false);
  }, []);

  const onChange2 = (e) => {
    // console.log(values);
    const { name, value } = e.target;
    // console.log( name + " " + value);
    setValues((prev) => ({ ...prev, [name]: value }));
  };
  // for progress bar

  const onSubmit = async (data) => {
    setLoading(true);
    let reqObject = {
      ...values,
      link: type,
      linkWith: id,
    };
    // formData.append('fileName', file.name);
    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    // document.querySelector(".mainDiv").style.display = "block";
    setProgress(0);
    // setLoading(false);
    axios
      .put(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/updatedYouTubeDetails/${youtubeId}`,
        reqObject,
        config
      )
      .then((response) => {
        //console.log(response?.data);
        if (
          response?.data?.status == false ||
          response?.data?.msg === "Please select linkWith also!"
        ) {
          toast.error(response?.data?.msg);
          setLoading(false);
          // setPdf(false);
          // reset();
          // window.location.href = "/dashboard/banner";
        } else {
          setLoading(false);
          toast.success("Video Updated");
          setPdf(false);
          reset();
          window.location.href = "/dashboard/youtube";
        }
      })
      .catch((e) => {
        //console.log(e);
      });
    // }
    // } else {

    //     setRestrict('Please Select pdf or jpg')
    // }
  };

  const handleDelete = () => {
    setShowImage(false);
    setFile("");
  };

  // get tests or batches
  useEffect(() => {
    if (type !== "" && type !== "none") {
      const token = localStorage.getItem("token");
      async function fetchDetails() {
        const fullResponse = await fetch(
          `https://api-prod.gyaankulam.com/api/v1/adminPanel/${
            type == "batch"
              ? "getBatchesDetails"
              : type == "testSeries"
              ? "getallTestSeriesdetails"
              : "getCategory"
          }`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const responseJson = await fullResponse.json();
        setList(responseJson?.data);
      }
      fetchDetails();
    }
    setLoading(false);
  }, [type]);

  const checkDetails = (bannerDetails) => {
    setValues((prev) => ({
      ...prev,
      title: bannerDetails?.title,
      // BannerType : bannerDetails?.BannerType,
      link: bannerDetails?.link,
      linkWith: bannerDetails?.linkWith,
      is_active: bannerDetails?.is_active,
      video_url: bannerDetails?.video_url,
      language: bannerDetails?.language,
      valueId: bannerDetails?.linkWith?.id,
    }));
    setType(bannerDetails?.link);
    setId(bannerDetails?.linkWith.id);

    // setValueId(bannerDetails?.linkWith?.id)
    setId(bannerDetails?.linkWith?.id);
  };
  // console.log(list);

  return (
    <>
      <Stack spacing={3} px={5}>
        <Typography variant="h3">{`Update Youtube Video ${values?.title}`}</Typography>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} sx={{ width: "70%" }}>
            <RHFTextField
              name="title"
              value={values?.title}
              onChange={onChange2}
            />
            <Stack direction="row" spacing={3}>
              <RHFTextField
                name="video_url"
                label="Video URL"
                value={values?.video_url}
                type="url"
                onChange={onChange2}
              />
              {/*
                <RHFTextField
                  name="language"
                  label="Language"
                  value={values?.language}
                  onChange={onChange2}
                  select
                >
                  <MenuItem value="en">English</MenuItem>
                  <MenuItem value="hi">Hindi</MenuItem>
                </RHFTextField>
  */ }
            </Stack>
            <Stack direction="row" spacing={3}>
              <RHFTextField
                name="link"
                label={
                  values?.link == "batch"
                    ? "Batch"
                    : values?.link == "testSeries"
                    ? "Test Series"
                    : values?.link == "category"
                    ? "Category"
                    : "None"
                }
                onChange={onChange2}
                ref={register}
                select
              >
                <MenuItem value="none" onClick={() => setType("none")}>
                  None
                </MenuItem>
                <MenuItem value="batch" onClick={() => setType("batch")}>
                  Batch
                </MenuItem>
                <MenuItem
                  value="testSeries"
                  onClick={() => setType("testSeries")}
                >
                  Test Series
                </MenuItem>
                <MenuItem value="category" onClick={() => setType("category")}>
                  Category
                </MenuItem>
              </RHFTextField>
              {/* {errors.link && <p>{errors.link.message}</p>} */}
              {type !== "none" && type !== "" && (
                <RHFTextField
                  name="valueId"
                  label={
                    values?.linkWith?.name
                      ? values?.linkWith?.name
                      : type == "batch"
                      ? "Link With Batch"
                      : type == "testSeries"
                      ? "Link With Test Series"
                      : type == "category"
                      ? "Link With Category"
                      : "None"
                  }
                  onChange={onChange2}
                  ref={register}
                  select
                >
                  {list?.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item?._id}
                      //   onClick={() => setValueId(item?._id)}
                      onClick={() => setId(item?._id)}
                    >
                      {type == "batch"
                        ? item?.batch_name
                        : type == "testSeries"
                        ? item?.testseries_name
                        : item?.title}
                    </MenuItem>
                  ))}
                </RHFTextField>
              )}
            </Stack>

            <Stack direction="row" spacing={3}>
              {/* <RHFTextField name="category" label="Category" select>
                            {users?.map((item, i) => { return <MenuItem key={i} value={item?.title}>{item?.title}</MenuItem> })}
                        </RHFTextField> */}

              <RHFTextField
                name="is_active"
                label={values?.is_active ? "Active" : "Inactive"}
                value={values?.is_active}
                onChange={onChange2}
                select
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </RHFTextField>
            </Stack>

            {/* <div className="mainDiv">
                        <div className="childDiv" style={{ width: `${progress}%` }}>
                            <span> </span>
                        </div>
                    </div> */}

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
              sx={{
                bgcolor: theme.palette.primary.mainTheme,
                marginTop: "5%",
                "&:hover": { background: "red" },
              }}
              onClick={() => {
                setVal(true);
                setMsg("Required");
              }}
            >
              Update Banner
            </LoadingButton>
          </Stack>
        </FormProvider>
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {/* <Typography color={positive ? "green" : "red"} sx={{ textAlign: 'center', width: '80%' }}>{fetchMessage}</Typography> */}
      </Stack>
    </>
  );
}
