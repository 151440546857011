import { useRef, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Button,
  Stack,
  Typography,
  Box,
  Modal,
} from "@mui/material";
// component
import Iconify from "../../../components/Iconify";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LoadingButton } from "@mui/lab";
import { useTheme } from "@emotion/react";
import getAccess from "../../../utils/getAccess";

// ----------------------------------------------------------------------
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",

  boxShadow: 24,
  borderRadius: "20px",

  p: 4,
};

export default function UserMoreMenu(props) {
  const access = getAccess();
  const theme = useTheme();
  const {
    id,
    req,
    isEdit,
    setIsEdit,
    lectId,
    batchId,
    bannerId,
    bannerName,
    title,
    item,
    setLoading,
    testSeriesId,
    testId,
    reqTeacher,
    quizName,
    couponCode,
    couponId,
  } = props;
  const ref = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const navigate = useNavigate();
  // const role = localStorage.getItem("role");
  const role = access?.role == "admin" ? "Admin" : access?.role;
  let section = "adminPanel";
  if (title == "Staff") {
    section = "adminTeacher";
  }

  // console.log(access?.access)
  // console.log(role)
  //Delete
  const deleteDetails = async (id) => {
    const token = localStorage.getItem("token");
    setLoadingButton(true);

    await fetch(
      `https://api-prod.gyaankulam.com/api/v1/${section}/${req}/${id}`,
      {
        // await fetch(`http://localhost:3001/api/v1/${section}/${req}/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setLoadingButton(false);
    toast.success("Deleted");
    setTimeout(() => window.location.reload(), 1000);
  };
  // const deleteTeacher = async (id) => {

  //   const token = localStorage.getItem("token");
  //   setLoadingButton(true)

  //   await fetch(`https://api-prod.gyaankulam.com/api/v1/adminTeacher/${reqTeacher}/${id}`, {
  //     method: 'DELETE',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: `Bearer ${token}`
  //     },

  //   });
  //   setLoadingButton(false)
  //   toast.success('Deleted')
  //   setTimeout(() => window.location.reload()
  //     , 1000)

  // }
  // //console.log();]
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // debugger;

  const path = `/dashboard/lectureResources/${lectId}/${batchId}`;
  const pathToUpdatePermission = `/dashboard/staff/updatePermission/${id}`;
  const pathToEditTeacher = `/dashboard/staff/updateStaff/${id}`;
  const pathToLectureUpdateForm = `/dashboard/batchDetails/updateLectureDetails/${batchId}/${lectId}`;
  const pathToTestUpdateForm = `/dashboard/test/testSeriesDetails/updateTestDetails/${testSeriesId}/${testId}`;
  const pathToQuizUpdateForm = `/dashboard/updateQuiz/${id}`;
  const pathToQuizDoubts = `/dashboard/quiz/doubts/${quizName}/${id}`;
  const pathToQuizIssues = `/dashboard/quiz/issues/${quizName}/${id}`;
  const pathToQuizStudents = `/dashboard/quiz/quizStudents/${quizName}/${id}`;
  const pathToQuizResults = `/dashboard/quiz/quizResults/${quizName}/${id}`;
  const pathToUpdateBanner = `/dashboard/banner/updateBannerDetails/${bannerName}/${bannerId}`;
  const pathToUpdateCoupon = `/dashboard/coupon/updateCouponDetails/${couponCode}/${couponId}`;
  return (
    <>
      <IconButton
        ref={ref}
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {access?.access.includes("all") && (
          <MenuItem
            sx={{ color: "text.secondary" }}
            onClick={() => handleOpen()}
          >
            {(access?.deleteAccess || access?.access.includes("all")) && (
              <>
                <ListItemIcon>
                  <Iconify icon="eva:trash-2-outline" width={24} height={24} />
                </ListItemIcon>
                <ListItemText
                  primary="Delete"
                  primaryTypographyProps={{ variant: "body2" }}
                />
              </>
            )}
            {/* {title == 'Staff' && <><ListItemIcon>
            <Iconify icon="eva:trash-2-outline" width={24} height={24} />
          </ListItemIcon>
            <ListItemText primary="Delete Karo" primaryTypographyProps={{ variant: 'body2' }} />
          </>} */}
          </MenuItem>
        )}

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ textAlign: "center" }}
            >
              Want to Delete?
            </Typography>
            <Stack
              id="modal-modal-description"
              direction="row"
              mt={4}
              justifyContent="space-around"
            >
              <LoadingButton
                fullWidth
                loading={loadingButton}
                variant="contained"
                sx={{
                  width: "30%",
                  background: theme.palette.primary.mainTheme,
                  "&:hover": { background: "red" },
                }}
                onClick={() => {
                  deleteDetails(id);
                }}
              >
                Yes
              </LoadingButton>
              <Button
                variant="contained"
                sx={{
                  width: "30%",
                  background: theme.palette.primary.mainTheme,
                  "&:hover": { background: "red" },
                }}
                onClick={() => handleClose()}
              >
                No
              </Button>
            </Stack>
          </Box>
        </Modal>
        {/* 
        {role === 'Admin' && title == 'TestDetails' && <MenuItem component={RouterLink} to={`/dashboard/quiz/${id}`} sx={{ color: 'text.secondary' }} onClick={() => setIsEdit(!isEdit)}>
          <ListItemIcon>
            <Iconify icon="material-symbols:quiz-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Quiz" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>} */}
        {access?.access.includes("all") &&
          title !== "BatchDetails" &&
          title !== "TestDetails" &&
          title !== "Staff" &&
          title !== "Quiz" &&
          title !== "BannerDetails" &&
          title !== "CouponDetails" && (
            <MenuItem
              component={RouterLink}
              to="#"
              sx={{ color: "text.secondary" }}
              onClick={() => setIsEdit(!isEdit)}
            >
              <ListItemIcon>
                <Iconify icon="eva:edit-fill" width={24} height={24} />
              </ListItemIcon>
              <ListItemText
                primary="Edit"
                primaryTypographyProps={{ variant: "body2" }}
              />
            </MenuItem>
          )}

        {(access?.access.includes("42") || access?.access.includes("all")) &&
          title == "Quiz" && (
            <MenuItem
              component={RouterLink}
              to={pathToQuizUpdateForm}
              sx={{ color: "text.secondary" }}
              onClick={() => navigate(`/dashboard/updateQuiz/${id}`)}
            >
              <ListItemIcon>
                <Iconify icon="eva:edit-fill" width={24} height={24} />
              </ListItemIcon>
              <ListItemText
                primary="Edit"
                primaryTypographyProps={{ variant: "body2" }}
              />
            </MenuItem>
          )}

        {(access?.access.includes("42") || access?.access.includes("all")) &&
          title == "Quiz" && (
            <MenuItem
              component={RouterLink}
              to={pathToQuizDoubts}
              sx={{ color: "text.secondary" }}
            >
              <ListItemIcon>
                <Iconify icon="mdi:account-question" width={24} height={24} />
              </ListItemIcon>
              <ListItemText
                primary="Doubts"
                primaryTypographyProps={{ variant: "body2" }}
              />
            </MenuItem>
          )}
        {(access?.access.includes("42") || access?.access.includes("all")) &&
          title == "Quiz" && (
            <MenuItem
              component={RouterLink}
              to={pathToQuizIssues}
              sx={{ color: "text.secondary" }}
            >
              <ListItemIcon>
                <Iconify icon="mdi:git-issue" width={24} height={24} />
              </ListItemIcon>
              <ListItemText
                primary="Issue"
                primaryTypographyProps={{ variant: "body2" }}
              />
            </MenuItem>
          )}
        {(access?.access.includes("42") || access?.access.includes("all")) &&
          title == "Quiz" && (
            <MenuItem
              component={RouterLink}
              to={pathToQuizStudents}
              sx={{ color: "text.secondary" }}
              onClick={() => navigate(``)}
            >
              <ListItemIcon>
                <Iconify icon="eva:people-fill" width={24} height={24} />
              </ListItemIcon>
              <ListItemText
                primary="Quiz Students"
                primaryTypographyProps={{ variant: "body2" }}
              />
            </MenuItem>
          )}
        {/* {role == 'Admin' && title == 'Quiz' && <MenuItem component={RouterLink} to={pathToQuizResults} sx={{ color: 'text.secondary' }} onClick={() => navigate(``)}>
          <ListItemIcon>
            <Iconify icon="eva:people-fill" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Quiz Results" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>} */}

        {role == "Admin" && title == "BannerDetails" && (
          <MenuItem
            component={RouterLink}
            to={pathToUpdateBanner}
            sx={{ color: "text.secondary" }}
            onClick={() =>
              navigate(
                `/dashboard/banner/updateBannerDetails/${bannerName}/${bannerId}`
              )
            }
          >
            <ListItemIcon>
              <Iconify icon="eva:edit-fill" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Edit"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}
        {role == "Admin" && title == "CouponDetails" && (
          <MenuItem
            component={RouterLink}
            to={pathToUpdateCoupon}
            sx={{ color: "text.secondary" }}
            onClick={() =>
              navigate(
                `/dashboard/coupon/updateCouponDetails/${couponCode}/${couponId}`
              )
            }
          >
            <ListItemIcon>
              <Iconify icon="eva:edit-fill" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Edit"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}
        {role == "Admin" && title == "BatchDetails" && (
          <MenuItem
            component={RouterLink}
            to={pathToLectureUpdateForm}
            sx={{ color: "text.secondary" }}
            onClick={() =>
              navigate(
                `/dashboard/batchDetails/updateLectureDetails/${batchId}/${lectId}`
              )
            }
          >
            <ListItemIcon>
              <Iconify icon="eva:edit-fill" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Edit"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}
        {(role == "Admin" || access?.accessForTab == "readWrite") &&
          title == "TestDetails" && (
            <MenuItem
              component={RouterLink}
              to={pathToTestUpdateForm}
              sx={{ color: "text.secondary" }}
              onClick={() =>
                navigate(
                  `/dashboard/batchDetails/updateLectureDetails/${batchId}/${lectId}`
                )
              }
            >
              <ListItemIcon>
                <Iconify icon="eva:edit-fill" width={24} height={24} />
              </ListItemIcon>
              <ListItemText
                primary="Edit"
                primaryTypographyProps={{ variant: "body2" }}
              />
            </MenuItem>
          )}
        {title === "BatchDetails" && (
          <MenuItem
            component={RouterLink}
            to={path}
            sx={{ color: "text.secondary" }}
          >
            <ListItemIcon>
              <Iconify
                icon="carbon:software-resource-cluster"
                width={24}
                height={24}
              />
            </ListItemIcon>
            <ListItemText
              primary="Resources"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}
        {title === "Staff" && access?.access.includes("all") && (
          <MenuItem
            component={RouterLink}
            to={pathToEditTeacher}
            sx={{ color: "text.secondary" }}
          >
            <ListItemIcon>
              <Iconify icon="eva:edit-fill" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Edit"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}
        {title === "Staff" && access?.access.includes("all") && (
          <MenuItem
            component={RouterLink}
            to={pathToUpdatePermission}
            sx={{ color: "text.secondary" }}
          >
            <ListItemIcon>
              <Iconify
                icon="carbon:software-resource-cluster"
                width={24}
                height={24}
              />
            </ListItemIcon>
            <ListItemText
              primary="Update Permissions"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}
      </Menu>
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}
