import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// form
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Stack,
  IconButton,
  InputAdornment,
  Typography,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import CloseIcon from "@mui/icons-material/Close";
// components

import { FormProvider, RHFTextField } from "../../../components/hook-form";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

// import "./general.css";
import { useTheme } from "@emotion/react";

// ----------------------------------------------------------------------

export default function UpdateStoreBanner() {
  const { bannerId } = useParams();
  const theme = useTheme();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [restrict, setRestrict] = useState("");
  const [focus, setFocused] = useState(false);
  const [pdf, setPdf] = useState(false);
  const [msg, setMsg] = useState("");
  const [val, setVal] = useState(false);

  const [list, setList] = useState([]);
  const [bannerDetails, setBannerDetails] = useState();
  const [values, setValues] = useState({});
  const [type, setType] = useState(values?.link || "none");
  const [id, setId] = useState(values?.linkWith?._id || "");
  const [showImage, setShowImage] = useState(false);
  const [imageName, setImageName] = useState("");
  const [bannerOrder, setBannerOrder] = useState([]);
  const [order, setOrder] = useState();

  const RegisterSchema = Yup.object().shape({});

  const defaultValues = {
    title: "",
    BannerType: "",

    order: "",

    link: values?.link,
    linkWith: values?.linkWith?.id || "",
    is_active: "",
  };
  const [file, setFile] = useState();

  const changeHandler = async (event) => {
    setFile(event.target?.files);
    var fileName = document.getElementById("file").value?.toLowerCase();
    if (fileName.endsWith(".pdf") || fileName.endsWith(".jpg")) {
      setRestrict("");
    } else {
      setRestrict("JPG Or PDF");
    }
    //console.log(file)
  };
  //console.log(file)
  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    register,
    errors,
    handleSubmit,
    reset,
    control,
    formState: { isSubmitting },
  } = methods;

  const [progress, setProgress] = useState();
  //get banner details
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchDetails() {
      const fullResponse = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/store/getStoreBannerByIdAdmin/${bannerId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      setBannerDetails(responseJson?.data);
      checkDetails(responseJson?.data);
    }
    fetchDetails();
    async function bannerCount() {
      const fullResponse = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/store/getAllStoreBannerAdmin`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      let list = Array.from(
        { length: responseJson?.data?.length },
        (_, index) => index + 1
      );
      setBannerOrder(list);
      //   setBannerDetails(responseJson?.data);
      //   checkDetails(responseJson?.data);
    }
    bannerCount();

    setLoading(false);
  }, []);

  const onChange2 = (e) => {
    const { name, value } = e.target;
    // console.log(name + " "+ value);
    setValues((prev) => ({ ...prev, [name]: value }));
  };
  // for progress bar

  const onSubmit = async () => {
    // console.log(values);
    const formData = new FormData();

    setLoading(true);
    if (file) {
      Object.values(file).forEach((file) => {
        formData.append("file", file);
      });
    }

    formData.append("title", values?.title);

    formData.append("bannerType", values?.bannerType);
    formData.append("language", "en");
    formData.append("isActive", values?.isActive);
    formData.append("link", type);
    formData.append("order", order);
    // debugger;
    if (type !== "" && type !== "none") {
      formData.append("linkWith", id);
    } else {
      formData.append("linkWith", "NA");
    }
    // debugger;

    // formData.append('fileName', file.name);
    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${authToken}`,
      },
      onUploadProgress: (data) => {
        //Set the progress value to show the progress bar
        setProgress(Math.round((100 * data?.loaded) / data?.total));
      },
    };
    // document.querySelector(".mainDiv").style.display = "block";
    setProgress(0);
    // setLoading(false);
    axios
      .put(
        `https://api-prod.gyaankulam.com/api/v1/store/updateStoreBanner/${bannerId}`,
        formData,
        config
      )
      .then((response) => {
        //console.log(response?.data);
        if (
          response?.data?.status == false ||
          response?.data?.msg === "Please select linkWith also!"
        ) {
          toast.error(response?.data?.msg);
          setLoading(false);
        } else {
          setLoading(false);
          toast.success("Banner Updated");
          setPdf(false);
          reset();
          window.location.href = "/dashboard/storeBanner";
        }
      })
      .catch((e) => {
        //console.log(e);
      });
  };

  const handleDelete = () => {
    setShowImage(false);
    setFile("");
  };

  // get tests or batches
  useEffect(() => {
    if (type !== "" && type !== "none" && type != "link") {
      const token = localStorage.getItem("token");
      async function fetchDetails() {
        const fullResponse = await fetch(
          `https://api-prod.gyaankulam.com/api/v1/store/${
            type == "product"
              ? "getAllStoreProduct"
              : "getAllStoreProductCategory"
          }`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const responseJson = await fullResponse.json();
        setList(responseJson?.data);
      }
      fetchDetails();
    }
    setLoading(false);
  }, [type]);

  const checkDetails = (bannerDetails) => {
    setValues((prev) => ({
      ...prev,
      title: bannerDetails?.title,
      bannerType: bannerDetails?.bannerType,
      link: bannerDetails?.link,
      linkWith: bannerDetails?.linkWith,
      isActive: bannerDetails?.isActive,
      icon: bannerDetails?.icon,
      valueId: bannerDetails?.linkWith?.id,
    }));
    setType(bannerDetails?.link);
    if (bannerDetails?.icon) {
      setShowImage(true);
      const parts = bannerDetails?.icon;
      //    const lastPart = parts[parts.length - 1];
      setImageName(parts);
    }
    // setValueId(bannerDetails?.linkWith?.id)
    setId(bannerDetails?.linkWith?.id);
    setOrder(bannerDetails?.order);
  };

  return (
    <>
      <Stack spacing={3} px={5}>
        <Typography variant="h3">Update Banner</Typography>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} sx={{ width: "70%" }}>
            <RHFTextField
              name="title"
              value={values?.title}
              onChange={onChange2}
            />
            <Stack direction="row" spacing={3}>
              {showImage ? (
                <Stack
                  direction="column"
                  sx={{ width: "100%", position: "relative" }}
                  alignItems="center"
                  spacing={1}
                >
                  <IconButton
                    sx={{ position: "absolute", top: "-10px", right: "-10px" }}
                    onClick={() => handleDelete()}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>

                  {/* <a href={values?.banner_url} style={{ textDecoration: 'none' }}><FileCopyIcon fontSize='small' /></a> */}
                  <img
                    src={values?.icon}
                    alt="BannerImage"
                    style={{ height: "100px", width: "200px" }}
                  />
                  {/* <Typography sx={{ fontSize: '12px' }}>{imageName}</Typography> */}
                </Stack>
              ) : (
                <RHFTextField
                  name="file"
                  onChange={changeHandler}
                  id="file"
                  label="Select image"
                  // value={value?.banner_url}
                  error={!file && val}
                  helperText="Image Only (Maximum size 100KB allowed)"
                  onFocus={() => setFocused(true)}
                  onBlur={() => setFocused(false)}
                  // inputProps={{ multiple: true }}
                  InputProps={{
                    classes: {
                      input: "CustomTextField",
                    },
                  }}
                  onClick={() => setPdf(true)}
                  inputProps={{ accept: "image/*" }}
                  type={pdf || focus ? "file" : "text"}
                />
              )}
              {/* <Typography fontSize="10px" color="red" sx={{ display: restrict == '' ? 'none' : 'block' }}>{restrict}</Typography> */}
              <RHFTextField
                name="bannerType"
                label={values?.bannerType}
                value={values?.bannerType}
                onChange={onChange2}
                select
              >
                <MenuItem value="APP">App</MenuItem>
                <MenuItem value="TAB">Tab</MenuItem>
                <MenuItem value="WEB">Web</MenuItem>
              </RHFTextField>
            </Stack>
            <Stack direction="row" spacing={3}>
              <RHFTextField
                name="link"
                label={
                  values?.link == "product"
                    ? "Product"
                    : values?.link == "category"
                    ? "Category"
                    : values?.link == "link"
                    ? "Link"
                    : "None"
                }
                ref={register}
                select
              >
                <MenuItem value="none" onClick={() => setType("none")}>
                  None
                </MenuItem>
                <MenuItem value="product" onClick={() => setType("product")}>
                  Product
                </MenuItem>
                <MenuItem value="category" onClick={() => setType("category")}>
                  Category
                </MenuItem>
                <MenuItem value="link" onClick={() => setType("link")}>
                  Link
                </MenuItem>
              </RHFTextField>
              {type == "link" && (
                <RHFTextField
                  name="valueId"
                  value={id}
                  onChange={(e) => setId(e.target?.value)}
                />
              )}
              {/* {errors.link && <p>{errors.link.message}</p>} */}
              {type !== "none" && type !== "" && type != "link" && (
                <RHFTextField
                  name="valueId"
                  label={
                    values?.linkWith?.title
                      ? values?.linkWith?.title
                      : type == "product"
                      ? "Link With Product"
                      : "Link With Category"
                  }
                  ref={register}
                  select
                >
                  {list?.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item?._id}
                      //   onClick={() => setValueId(item?._id)}
                      onClick={() => setId(item?._id)}
                    >
                      {type == "product" ? item?.title : item?.title}
                    </MenuItem>
                  ))}
                </RHFTextField>
              )}
            </Stack>
            <Stack direction="row" spacing={3}>
              <RHFTextField
                name="isActive"
                label={values?.isActive ? "Active" : "Inactive"}
                value={values?.isActive}
                onChange={onChange2}
                select
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </RHFTextField>
              <RHFTextField name="order" value={order} label="Order" select>
                {bannerOrder?.map((item, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={item}
                      onClick={() => setOrder(item)}
                    >
                      {item}
                    </MenuItem>
                  );
                })}
              </RHFTextField>
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
              sx={{
                bgcolor: theme.palette.primary.mainTheme,
                marginTop: "5%",
                "&:hover": { background: "red" },
              }}
              onClick={() => {
                setVal(true);
                setMsg("Required");
              }}
            >
              Update Banner
            </LoadingButton>
          </Stack>
        </FormProvider>
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {/* <Typography color={positive ? "green" : "red"} sx={{ textAlign: 'center', width: '80%' }}>{fetchMessage}</Typography> */}
      </Stack>
    </>
  );
}
