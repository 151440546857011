import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Stack,
  IconButton,
  InputAdornment,
  Typography,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components

import { FormProvider, RHFTextField } from "../../../components/hook-form";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

// import "./general.css";
import { useTheme } from "@emotion/react";

// ----------------------------------------------------------------------

export default function AddStoreCategory() {
  const theme = useTheme();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [restrict, setRestrict] = useState("");
  const [focus, setFocused] = useState(false);
  const [pdf, setPdf] = useState(false);
  const [msg, setMsg] = useState("");
  const [val, setVal] = useState(false);

  const RegisterSchema = Yup.object().shape({
    title: Yup.string().required("Title required"),
    parentCategory: Yup.string().required("Parent Category required"),
    // category: Yup.string().required('Category is required'),
    // banner_url: Yup.string().required('Url No. is required'),
    // language: Yup.string().required('Language is required'),

    isActive: Yup.boolean().required("Status Required"),
  });

  const defaultValues = {
    title: "",
    // BannerType: '',
    // category: '',
    // banner_url: '',
    // language: '',

    parentCategory: "",
    // linkWith: '',
    isActive: "",
  };
  const [file, setFile] = useState();

  const [category, setCategory] = useState([]);
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchCategory() {
      const response = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/store/getAllStoreProductCategory`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await response?.json();
      setCategory(responseJson?.data);
      setLoading(false);
    }
    fetchCategory();
  }, []);
  // console.log(category)
  const changeHandler = async (event) => {
    let size = event.target?.files[0].size / 1024;
    if (size > 100) {
      setVal(true);
      setFile();
    } else {
      setFile(event.target?.files);
      var fileName = document.getElementById("file").value?.toLowerCase();
      if (fileName.endsWith(".pdf") || fileName.endsWith(".jpg")) {
        setRestrict("");
      } else {
        setRestrict("JPG Or PDF");
      }
    }
  };
  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const [progress, setProgress] = useState();
  // for progress bar

  const onSubmit = async (data) => {
    const formData = new FormData();

    setLoading(true);
    if (!parentCategory) {
      if (file) {
        // debugger;
        Object.values(file).forEach((file) => {
          formData.append("file", file);
        });
        formData.append("fileName", file.name);
      }
    }
    if (parentCategory) {
      formData.append("file", "");
    }
    formData.append("title", data?.title);
    if (data?.parentCategory !== "none") {
      formData.append("parentCategory", data?.parentCategory);
    }

    formData.append("isActive", data?.isActive);

    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${authToken}`,
      },
      onUploadProgress: (data) => {
        //Set the progress value to show the progress bar
        setProgress(Math.round((100 * data?.loaded) / data?.total));
      },
    };
    setProgress(0);
    axios
      .post(
        `https://api-prod.gyaankulam.com/api/v1/store/addProductCategory`,
        formData,
        config
      )
      .then((response) => {
        if (
          response?.data?.msg === "New Product Category added successfully."
        ) {
          toast.success(response?.data?.msg);
          setLoading(false);
          setPdf(false);
          reset();
          window.location.href = "/dashboard/storeCategory";
        } else {
          toast.error(response?.data?.msg);
          setLoading(false);
        }
      })
      .catch((e) => {
        //console.log(e);
      });
  };

  const [parentCategory, setParentCategory] = useState(false);
  return (
    <>
      <Stack spacing={3} px={5}>
        <Typography variant="h3">Add Store Category</Typography>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} sx={{ width: "70%" }}>
            <RHFTextField name="title" label="Title" />
            <Stack direction="row" spacing={3}>
              {!parentCategory && (
                <RHFTextField
                  name="file"
                  onChange={changeHandler}
                  id="file"
                  label="File"
                  error={!file && val}
                  helperText="Image Only( Maximum size 100KB allowed)"
                  onFocus={() => setFocused(true)}
                  onBlur={() => setFocused(false)}
                  // inputProps={{ multiple: true }}
                  InputProps={{
                    classes: {
                      input: "CustomTextField",
                    },
                  }}
                  onClick={() => setPdf(true)}
                  inputProps={{ accept: "image/*" }}
                  type={pdf || focus ? "file" : "text"}
                />
              )}
              {/* <Typography fontSize="10px" color="red" sx={{ display: restrict == '' ? 'none' : 'block' }}>{restrict}</Typography> */}
              <RHFTextField
                name="parentCategory"
                label="Parent Category"
                select
              >
                <MenuItem
                  value="none"
                  onClick={() => {
                    setParentCategory(false);
                  }}
                >
                  None
                </MenuItem>
                {category?.map((item, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={item?.id}
                      onClick={() => {
                        setParentCategory(true);
                      }}
                    >
                      {item?.title}
                    </MenuItem>
                  );
                })}
              </RHFTextField>
            </Stack>

            <Stack direction="row" spacing={3}>
              <RHFTextField
                name="isActive"
                label="Status"
                type="checkbox"
                select
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </RHFTextField>
            </Stack>

            {/* <div className="mainDiv">
                        <div className="childDiv" style={{ width: `${progress}%` }}>
                            <span> </span>
                        </div>
                    </div> */}

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
              sx={{
                bgcolor: theme.palette.primary.mainTheme,
                marginTop: "5%",
                "&:hover": { background: "red" },
              }}
              onClick={() => {
                setVal(true);
                setMsg("Required");
              }}
            >
              Add
            </LoadingButton>
          </Stack>
        </FormProvider>
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {/* <Typography color={positive ? "green" : "red"} sx={{ textAlign: 'center', width: '80%' }}>{fetchMessage}</Typography> */}
      </Stack>
    </>
  );
}
