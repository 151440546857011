import {
  Box,
  Button,
  Collapse,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Popper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import * as React from "react";

import { Link, useNavigate, useParams } from "react-router-dom";
import SyncLoader from "react-spinners/SyncLoader";

import axios from "axios";

import FileCopyIcon from "@mui/icons-material/FileCopy";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserMoreMenu } from "../../user";
import { useTheme } from "@emotion/react";


const TestCard = ({ data, setLoading, loading, testSeriesId }) => {
  const theme = useTheme()

  const navigate = useNavigate();
  const [showAccordian, setShowAccordian] = React.useState(false);
  const [openCo, setOpen] = React.useState(false);
  const handleClickCollapse = () => {
    setOpen(!openCo);
  };


  const AccordionStyle = {
    // boxShadow: '1px 1px 7px 1px rgba(128,128,128,0.4)',
    // background: "rgba(249, 172, 192, 0.15)",
    bgcolor: theme.palette.primary.mainThemeLight,
    borderRadius: "18px",
    width: "100%",
    transition: "all 0.3s",
    position: "relative",
    transition: "all 0.2s",
    "&:hover": {
      background:
        `linear-gradient(180deg, ${theme.palette.primary.mainThemeLight} 0%, rgba(255, 122, 130, 0.104) 100%)`,
    },
  };

  return (
    <Box sx={{ marginBottom: "25px", ...AccordionStyle, }}>
      <Box
        onClick={() => {
          setShowAccordian(!showAccordian);
          handleClickCollapse();
        }}
        sx={{
          cursor: "pointer",
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          position: "relative",
          height: "120px",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          p={2}
          sx={{ width: "95%" }}
        >
          <Stack direction="column" justifyContent="space-around">
            <Typography sx={{ fontSize: "20px", fontWeight: "600" }}>
              {data?.Test_title}
            </Typography>
            <Typography sx={{ fontSize: "16px", fontWeight: "400" }}>
              {data?.Test_code}
            </Typography>
            <Typography sx={{ fontSize: "12px", fontWeight: "600" }}>
              Date:-{" "}
              <span style={{ fontWeight: "400" }}>{data?.starting_date}</span>
            </Typography>
          </Stack>
          <Stack direction="column" justifyContent="space-around"></Stack>
          {data?.question_paper_type === "objective" ? (
            <Button
              variant="contained"
              sx={{
                width: "15%",
                alignSelf: "center",
                background: theme.palette.primary.mainTheme,
                borderRadius: "20px",
                "&:hover": { background: "red" },
              }}
              onClick={() =>
                navigate(`/dashboard/quiz/${data?.Test_title}/${data?._id}`)
              }
            //   `/dashboard/quiz/${data?.quiz_title}/${data?._id}`
            >
              Questions
            </Button>
          ) : (
            ""
          )}

          {data?.question_paper_type === "objective" ? (
            <Button
              variant="contained"
              sx={{
                width: "20%",
                alignSelf: "center",
                background: theme.palette.primary.mainTheme,
                borderRadius: "20px",
                "&:hover": { background: "red" },
              }}
              onClick={() =>
                navigate(
                  `/dashboard/quiz/quizStudents/${data?.Test_title}/${data?._id}`
                )
              }
            //   `/dashboard/quiz/${data?.quiz_title}/${data?._id}`
            >
              O-Result
            </Button>
          ) : (
            ""
          )}
          {data?.question_paper_type === "objective" ? (
            <Button
              variant="contained"
              sx={{
                width: "15%",
                alignSelf: "center",
                background: theme.palette.primary.mainTheme,
                borderRadius: "20px",
                "&:hover": { background: "red" },
              }}
              onClick={() =>
                navigate(
                  `/dashboard/test/testSeriesDetails/attemptedTest/${data?._id}`
                )
              }
            //   `/dashboard/quiz/${data?.quiz_title}/${data?._id}`
            >
              OMR
            </Button>
          ) : (
            <Button
              variant="contained"
              sx={{
                width: "15%",
                alignSelf: "center",
                background: theme.palette.primary.mainTheme,
                borderRadius: "20px",
                "&:hover": { background: "red" },
              }}
              onClick={() =>
                navigate(
                  `/dashboard/test/testSeriesDetails/attemptedTest/${data?._id}`
                )
              }
            //   `/dashboard/quiz/${data?.quiz_title}/${data?._id}`
            >
              A-Sheet
            </Button>
          )}
        </Stack>
      </Box>

      <Collapse in={openCo} timeout="auto" unmountOnExit>
        <Box
          p={2}
          sx={{
            bgcolor: theme.palette.primary.mainThemeLight,
            borderRadius: "0 0 18px 18px",
          }}
        >
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="column">
              <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
                Total Questions:-{" "}
                <span style={{ fontWeight: "400" }}>
                  {data?.No_of_questions}
                </span>
              </Typography>
              <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
                Total Marks:-{" "}
                <span style={{ fontWeight: "400" }}>{data?.totalMarks}</span>
              </Typography>
              <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
                Type:-{" "}
                <span style={{ fontWeight: "400" }}>
                  {data?.question_paper_type}
                </span>
              </Typography>
            </Stack>

            <Stack direction="column">
              <Stack direction="row" spacing={2}>
                <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
                  Question Paper:
                </Typography>
                <a href={data?.question_paper?.fileLoc}>
                  <FileCopyIcon fontSize="medium" />
                </a>
              </Stack>
              <Stack direction="row" spacing={2}>
                <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
                  Answer Template:
                </Typography>
                <a href={data?.answer_template?.fileLoc}>
                  <FileCopyIcon fontSize="medium" />
                </a>
              </Stack>
              <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
                Negative Marking:-{" "}
                <span style={{ fontWeight: "400" }}>
                  {data?.negativemarking ? "Yes" : "No"}
                </span>
              </Typography>
            </Stack>
          </Stack>
          <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
            Instructions:-{" "}
            <span style={{ fontWeight: "400" }}>{data?.instructions}</span>
          </Typography>
        </Box>
      </Collapse>
      < Box sx={{ position: "absolute", right: "1%", top: "42px" }}>
        <UserMoreMenu
          title="TestDetails"
          req="deleteTheTestFromTestSeries"
          id={data?._id}
          setLoading={setLoading}
          testSeriesId={testSeriesId}
          testId={data?._id}
        />
      </Box>
    </Box >
  );
};

export default TestCard;
