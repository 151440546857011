import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Stack,
  IconButton,
  InputAdornment,
  Typography,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Iconify from "../../../components/Iconify";
import { FormProvider, RHFTextField } from "../../../components/hook-form";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useTheme } from "@emotion/react";
// ----------------------------------------------------------------------

export default function AddPathyakram() {
  const theme = useTheme();

  const navigate = useNavigate();

  const [subject, setSubject] = useState();
  const [loading, setLoading] = useState(true);

  const [focus, setFocused] = useState(false);
  const [pdf, setPdf] = useState(false);

  const [msg, setMsg] = useState("");
  const [val, setVal] = useState(false);

  const RegisterSchema = Yup.object().shape({
    title: Yup.string().required("Title required"),
    // lastName: Yup.string().required('Last name required'),
    // category: Yup.string().required('Category is required'),
    // file_url: Yup.string().required('Url  is requiXred'),
    resource_type: Yup.string().required("Resource Type  is required"),
    subject: Yup.string().required("Subject"),
    language: Yup.string().required("Language is required"),
  });

  const defaultValues = {
    title: "",
    // category: '',
    // file: '',
    resource_type: "",
    subject: "",
    language: "enhi",
  };
  const [file, setFile] = useState();

  const changeHandler = async (event) => {
    if (!type) {
      setFile(event.target?.files[0]);
    } else {
      setFile(event.target?.value);
    }
  };
  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    if (file) {
      setLoading(true);
      const formData = new FormData();
      // formData.append('file', file);
      //console.log([file])
      if (!type) {
        formData.append("file", file);
      }
      if (type) {
        formData.append("link", file);
      }
      formData.append("title", data?.title);
      // formData.append('category', data?.category);
      formData.append("category", "Pathyakram");
      formData.append("subject", data?.subject);
      formData.append("resource_type", data?.resource_type);
      formData.append("language", data?.language);
      // formData.append('is_active', data?.is_active);
      // formData.append('fileName', file.name);
      const token = localStorage.getItem("token");
      const authToken = token;
      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${authToken}`,
        },
      };
      axios
        .post(
          `https://api-prod.gyaankulam.com/api/v1/adminPanel/addResourceDetails`,
          formData,
          config
        )
        .then((response) => {
          //console.log(response?.data);
          setPdf(false);
          if (response?.data?.msg === "added Resource details successfully") {
            toast.success("Pathyakram Added");
            setFile(null);
            setPdf(false);
            setFocused(false);
            setVal(false);
            setMsg("");
            setLoading(false);
            reset();
          }
        })
        .catch((e) => {
          //console.log(e);
        });
    }
  };
  const [users, usersSet] = useState([]);

  //Setting resource type
  const [type, setType] = useState(false);
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/getCategory?type=Resources`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      usersSet(responseJson?.data);
      const res = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/get-select-subject`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const resSubject = await res.json();
      setSubject(resSubject?.data);
      setLoading(false);
    }

    fetchUsers();
  }, []);

  return (
    <>
      <Stack spacing={3} px={5}>
        <Typography variant="h3">Add Pathyakram</Typography>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} sx={{ width: "70%" }}>
            <RHFTextField name="title" label="Title" />

            <RHFTextField name="resource_type" label="Resource type" select>
              <MenuItem onClick={() => setType(true)} value="link">
                Link
              </MenuItem>
              <MenuItem onClick={() => setType(false)} value="file">
                File
              </MenuItem>
            </RHFTextField>

            <Stack direction="row" spacing={3}>
              <Stack direction="column" spacing={0} sx={{ width: "100%" }}>
                <RHFTextField
                  name="file"
                  onChange={changeHandler}
                  error={!file && val}
                  label={type ? "Link" : "PDF"}
                  onFocus={() => setFocused(true)}
                  onBlur={() => setFocused(false)}
                  onClick={() => setPdf(true)}
                  type={pdf || focus ? (type ? "url" : "file") : "text"}
                  inputProps={{ accept: "application/pdf" }}
                  helperText="Pdf Files Only"
                  InputProps={{
                    classes: {
                      input: "CustomTextField",
                    },
                  }}
                />
                {!file && (
                  <Typography
                    sx={{
                      fontSize: "12px",
                      marginTop: "5px",
                      marginLeft: "10px",
                      color: "red",
                    }}
                  >
                    {msg}
                  </Typography>
                )}
              </Stack>
              {/*
                <RHFTextField name="language" label="Language" select>
                  <MenuItem value="en">English</MenuItem>
                  <MenuItem value="hi">Hindi</MenuItem>
                  <MenuItem value="enhi">Bilingual</MenuItem>
                  
                </RHFTextField>
                */
              }
            </Stack>
            <Stack direction="row" spacing={3}>
              {/* <RHFTextField name="category" label="Categroy" select>
                            {users?.map((item, index) => {
                                return <MenuItem key={index} value={item?.title}>{item?.title}</MenuItem>
                            })}

                        </RHFTextField> */}
              <RHFTextField name="subject" label="Subject" select>
                {subject?.map((sub, i) => {
                  return (
                    <MenuItem key={i} value={sub.title}>
                      {sub.title}
                    </MenuItem>
                  );
                })}
              </RHFTextField>
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
              sx={{
                bgcolor: theme.palette.primary.mainTheme,
                marginTop: "5%",
                "&:hover": { background: "red" },
              }}
              onClick={() => {
                setVal(true);
                setMsg("Required");
              }}
            >
              Add
            </LoadingButton>
          </Stack>
        </FormProvider>
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {/* <Typography color={positive ? "green" : "red"} sx={{ textAlign: 'center', width: '70%', textTransform: 'capitalize' }}>{fetchMessage}</Typography> */}
      </Stack>
    </>
  );
}
