import * as Yup from "yup";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Stack,
  IconButton,
  InputAdornment,
  Typography,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Iconify from "../../../components/Iconify";
import { FormProvider, RHFTextField } from "../../../components/hook-form";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useTheme } from "@emotion/react";
// ----------------------------------------------------------------------

export default function AddAIR() {
  const theme = useTheme();

  const navigate = useNavigate();
  const [focus, setFocused] = useState(false);
  const [showAudio, setShowAudio] = useState(false);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const [val, setVal] = useState(false);

  const RegisterSchema = Yup.object().shape({
    data: Yup.string().required("Data required"),
    // lastName: Yup.string().required('Last name required'),
    is_active: Yup.boolean().required("Category is required"),
    // audio_file: Yup.string().required('Audio No. is required'),
    language: Yup.string().required("language Required"),
  });

  const defaultValues = {
    data: "",
    is_active: true,
    // audio_file: '',
    language: "enhi",
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;
  const [file, setFile] = useState();

  const changeHandler = async (event) => {
    setFile(event.target?.files[0]);
  };
  const onSubmit = async (data) => {
    //console.log(data)
    if (file?.size) {
      setLoading(true);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("data", data?.data);
      formData.append("is_active", data?.is_active);
      formData.append("language", data?.language);

      const token = localStorage.getItem("token");
      const authToken = token;
      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${authToken}`,
        },
      };
      axios
        .post(
          `https://api-prod.gyaankulam.com/api/v1/adminPanel/addAIRdetails`,
          formData,
          config
        )
        .then((response) => {
          //console.log(response?.data);
          if (response?.data?.msg === "added AIR details successfully") {
            toast.success(response?.data?.msg);
            setFile(null);
            setLoading(false);
            setShowAudio(false);
            setFocused(false);
            setVal(false);
            setMsg("");
            reset();
          }
        })
        .catch((e) => {
          //console.log(e);
        });
    }
  };

  return (
    <>
      <Stack spacing={3} px={5}>
        <Typography variant="h3">Add AIR</Typography>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} sx={{ width: "70%" }}>
            <RHFTextField name="data" label="Data" />

            <Stack direction="row" spacing={3}>
              <Stack direction="column" spacing={0} sx={{ width: "100%" }}>
                <RHFTextField
                  name="audio_file"
                  label="Audio"
                  onChange={changeHandler}
                  error={!file && val}
                  onFocus={() => setFocused(true)}
                  onBlur={() => setFocused(false)}
                  InputProps={{
                    classes: {
                      input: "CustomTextField",
                    },
                  }}
                  onClick={() => setShowAudio(true)}
                  inputProps={{ accept: "audio/*" }}
                  helperText="Audio Files Only"
                  type={showAudio || focus ? "file" : "text"}
                />
                {!file && (
                  <Typography
                    sx={{
                      fontSize: "12px",
                      marginTop: "5px",
                      marginLeft: "10px",
                      color: "red",
                    }}
                  >
                    {msg}
                  </Typography>
                )}
              </Stack>
              { /*
                <RHFTextField name="language" label="Language" select>
                  <MenuItem value="en">English</MenuItem>
                  <MenuItem value="hi">Hindi</MenuItem>
                  <MenuItem value="enhi">Bilingual</MenuItem>
                </RHFTextField>
              */ }
              <RHFTextField
                name="is_active"
                label="Status"
                type="checkbox"
                select
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </RHFTextField>
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
              sx={{
                bgcolor: theme.palette.primary.mainTheme,
                marginTop: "5%",
                "&:hover": { background: "red" },
              }}
              onClick={() => {
                setVal(true);
                setMsg("Required");
              }}
            >
              Add
            </LoadingButton>
          </Stack>
        </FormProvider>
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {/* <Typography color={positive ? "green" : "red"} sx={{ textAlign: 'center', width: '80%', textTransform: 'capitalize' }}>{fetchMessage}</Typography> */}
      </Stack>
    </>
  );
}
