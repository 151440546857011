import * as Yup from "yup";
import { useState, useEffect } from "react";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Stack,
  Typography,
  MenuItem,
  TextField,
  Select,
  InputLabel,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import { FormProvider, RHFTextField } from "../../../components/hook-form";
import axios from "axios";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// ------------------------------------------------------------------
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTheme } from "@emotion/react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export default function AddStoreCoupon() {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [starting, setStarting] = useState(dayjs());
  const [msg, setMsg] = useState("");
  const [val, setVal] = useState(false);

  // link
  const [type, setType] = useState("none");
  const [list, setList] = useState([]);
  const [valueId, setValueId] = useState("");
  const [selectedList, setSelectedList] = useState([]);
  const RegisterSchema = Yup.object().shape({
    couponCode: Yup.string().required("couponCode required"),
    couponType: Yup.string().required("couponType is required"),
    couponValue: Yup.number().required("couponValue should be number"),
    link: Yup.string()
      .required("Link Coupon Required")
      .oneOf(
        ["none", "batch", "testSeries", "product", "productCategory"],
        "Invalid Link Value"
      ),
    // valueId: Yup.string().when('link', {
    //   is: (link) => link !== 'none' && link !== '',
    //   then: Yup.string().required('Link With Required'),
    //   otherwise: Yup.string().notRequired(),
    // }),
    couponAccess: Yup.string().required("couponAccess is required"),
    is_active: Yup.boolean().required("Status Required"),
  });
  const defaultValues = {
    couponCode: "",
    couponType: "",
    couponValue: 0,
    link: "",
    valueId: "",
    couponAccess: "",
    is_active: "",
  };
  const handleChange = (newValue) => {
    setStarting(newValue);
  };

  const handleSelectedList = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedList(typeof value === "string" ? value?.split(",") : value);
  };
  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });
  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    const reqObject = {
      ...data,
      selectedList,
      expirationDate: starting?.$d,
    };
    axios
      .post(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/addCoupon`,
        reqObject,
        config
      )
      .then((response) => {
        if (response?.data?.msg === "Coupons Details Added Successfully") {
          toast.success(response?.data?.msg);
          setVal(false);
          setMsg("");
          setLoading(false);
          setSelectedList([]);
          reset();
        } else {
          toast.error(response?.data?.msg);
          setLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (type !== "" && type !== "none") {
      let urlVariable = "";
      if (type == "product") {
        urlVariable = "store/getAllStoreProduct";
      } else {
        urlVariable = "store/getAllStoreProductCategory";
      }
      const token = localStorage.getItem("token");
      async function fetchDetails() {
        const fullResponse = await fetch(
          `https://api-prod.gyaankulam.com/api/v1/${urlVariable}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const responseJson = await fullResponse.json();
        setList(responseJson?.data);
      }
      fetchDetails();
    }
    setLoading(false);
    setSelectedList([]);
  }, [type]);

  //console.log(starting.$d);
  return (
    <>
      <Stack spacing={3} px={5}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} sx={{ width: "70%" }}>
            <Stack direction="row" spacing={3}>
              <RHFTextField name="couponCode" label="Coupon Code" />
              <RHFTextField name="couponType" label="Coupon Type" select>
                <MenuItem value="percentage">Percentage (%)</MenuItem>
                <MenuItem value="fixed">Fixed</MenuItem>
              </RHFTextField>
            </Stack>
            <Stack direction="row" spacing={3}>
              <RHFTextField
                name="link"
                label="Link Coupon"
                type="checkbox"
                select
              >
                <MenuItem value="product" onClick={() => setType("product")}>
                  Product
                </MenuItem>
                <MenuItem
                  value="productCategory"
                  onClick={() => setType("productCategory")}
                >
                  Product Category
                </MenuItem>
              </RHFTextField>
            </Stack>
            {type != "none" && (
              <Stack direction="row">
                <InputLabel
                  sx={{
                    width: "35%",
                    marginRight: "20px",
                    alignSelf: "center",
                  }}
                >
                  Select{" "}
                  {type == "batch"
                    ? "Batches"
                    : type == "testSeries"
                    ? "TestSeries"
                    : type == "product"
                    ? "Products"
                    : "Product Catgeory"}
                </InputLabel>
                <Stack direction="column" sx={{ width: "100%" }}>
                  <Select
                    label=""
                    error={selectedList.length == 0 && val}
                    multiple
                    name="selected"
                    value={selectedList}
                    onChange={handleSelectedList}
                    sx={{ width: "100%", color: "black", zIndex: 9 }}
                    MenuProps={MenuProps}
                  >
                    {type == "product" && (
                      <MenuItem value="all" onClick={() => setValueId("all")}>
                        All
                      </MenuItem>
                    )}
                    {list?.map((item, index) => {
                      return (
                        <MenuItem
                          key={index}
                          value={item?._id}
                          onClick={() => setValueId(item?._id)}
                        >
                          {type == "product" && item?.title}
                          {type == "productCategory" && item?.title}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {selectedList.length == 0 && (
                    <Typography
                      sx={{
                        fontSize: "12px",
                        marginTop: "5px",
                        marginLeft: "10px",
                        color: "red",
                      }}
                    >
                      {msg}
                    </Typography>
                  )}
                </Stack>
              </Stack>
            )}
            <Stack direction="row" spacing={3}>
              <RHFTextField name="couponValue" label="Coupon Value" />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack
                  direction="row"
                  spacing={3}
                  sx={{ width: "100%", justifyContent: "space-between" }}
                >
                  <DateTimePicker
                    label="Expire At"
                    value={starting.$d}
                    onChange={handleChange}
                    renderInput={(params) => (
                      <TextField {...params} sx={{ width: "100%" }} />
                    )}
                  />
                </Stack>
              </LocalizationProvider>
            </Stack>
            <Stack direction="row" spacing={3}>
              <RHFTextField name="couponAccess" label="Coupon Access" select>
                <MenuItem value="all">All</MenuItem>
              </RHFTextField>
              <RHFTextField name="is_active" label="Status" select>
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </RHFTextField>
            </Stack>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
              sx={{
                bgcolor: theme.palette.primary.mainTheme,
                marginTop: "5%",
                "&:hover": { background: "red" },
              }}
              onClick={() => {
                setVal(true);
                setMsg("Required");
              }}
            >
              Add
            </LoadingButton>
          </Stack>
        </FormProvider>
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Stack>
    </>
  );
}
