import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Stack,
  IconButton,
  InputAdornment,
  Typography,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Iconify from "../../../components/Iconify";
import { FormProvider, RHFTextField } from "../../../components/hook-form";
import { reset } from "numeral";
import { ToastContainer, toast } from "react-toastify";
import { useTheme } from "@emotion/react";
// ----------------------------------------------------------------------

export default function AddSubCategory() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [categoryDetails, setCategoryDetails] = useState([]);
  const base_url = process.env.REACT_APP_LIVE_URL;

  const RegisterSchema = Yup.object().shape({
    title: Yup.string().required("Title required"),
    category: Yup.string().required("Category required"),
    is_active: Yup.boolean().required("Status required"),
  });

  const defaultValues = {
    title: "",
    category: "",
    is_active: "",
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  async function fetchCategoryData() {
    let token = localStorage.getItem("token");
    try {
      const res = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/getCategorailDetails`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const result = await res.json();
      if (!result?.status) {
        throw new Error(" categories not found");
      }
      setCategoryDetails(result?.data);
      // console.log(result?.data);
    } catch (error) {
      //
    }
  }

  useEffect(() => {
    // console.log("Fetching category data?...");
    fetchCategoryData();
    // console.log(categoryDetails);
  }, []);

  const onSubmit = async (data) => {
    setLoading(true);
    let token = localStorage.getItem("token");
    // console.log(data);

    try {
      const res = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/addSubCategorialDetails`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        }
      );
      const result = await res.json();

      toast.success(result?.msg);
      if (result?.msg === "New Sub Category added successfully") {
        setLoading(false);
        reset();
      }
    } catch (error) {
      //console.log(error);
    }
  };

  return (
    <>
      <Stack spacing={3} px={5}>
        <Typography variant="h3">Add SubCategory</Typography>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} sx={{ width: "70%" }}>
            <RHFTextField name="title" label="Title" />
            <Stack direction="row" spacing={3}>
              <RHFTextField name="category" label="Category" select>
                {/* <MenuItem value='Batch'>Batch</MenuItem> */}
                {/* <MenuItem value='Resources'>Resources</MenuItem> */}
                {/* <MenuItem value='Banner'>Banner</MenuItem> */}
                {/* <MenuItem value='Contact'>Contact</MenuItem>
                            <MenuItem value='Stream'>Stream</MenuItem> */}
                {categoryDetails?.map((item) => (
                  <MenuItem key={item?._id} value={item?._id}>
                    {item?.title}
                  </MenuItem>
                ))}
              </RHFTextField>

              <RHFTextField
                name="is_active"
                label="Status"
                type="checkbox"
                select
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </RHFTextField>
            </Stack>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
              sx={{
                bgcolor: theme.palette.primary.mainTheme,
                marginTop: "5%",
                "&:hover": { background: "red" },
              }}
            >
              Add
            </LoadingButton>
          </Stack>
        </FormProvider>
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {/* <Typography color={positive ? "green" : "red"} sx={{ textAlign: 'center', width: '80%', textTransform: 'capitalize' }}>{fetchMessage}</Typography> */}
      </Stack>
    </>
  );
}
