import { useTheme } from '@emotion/react'
import { Box, Typography } from '@mui/material'
import React, { useState } from 'react'


const BatchCard = ({ value, batch, title }) => {
    const theme = useTheme()    // //console.log(batch)

    const BatchStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: '31%',
        maxWidth: '31%',
        minHeight: '130px',
        background: theme.palette.primary.mainTheme,
        borderRadius: '15px',
        boxShadow: '2px 2px 8px rgba(0, 0, 0, 0.20)',
        transition: 'all 0.3s',
        translate: `${value}px`,
        padding: '0 5%'
        // translate: `20px`
    }
    return (
        <Box sx={{ ...BatchStyle }}>
            <Typography color="white" fontSize="medium" sx={{ textAlign: 'center', fontWeight: '700' }}>
                {batch?.batch_name ? batch?.batch_name : title}
            </Typography>
        </Box>
    )
}

export default BatchCard