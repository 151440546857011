import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  TextField,
  Tooltip,
} from "@mui/material";
// form
import "./style.css";
// @mui
import { Stack, IconButton, Typography } from "@mui/material";

import axios from "axios";
import AgoraRTC from "agora-rtc-sdk-ng";
import AgoraRTM from "agora-rtm-sdk";

import ScrollToBottom from "react-scroll-to-bottom";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import MicIcon from "@mui/icons-material/Mic";
import VideocamIcon from "@mui/icons-material/Videocam";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import ZoomInMapIcon from "@mui/icons-material/ZoomInMap";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import PauseIcon from "@mui/icons-material/Pause";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MicOffIcon from "@mui/icons-material/MicOff";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import * as React from "react";
import Popover from "@mui/material/Popover";
import { INITIAL_STATE, reducer } from "../../reducer/reducer";

import Modal from "@mui/material/Modal";
import { UserMoreMenu } from "../user";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import { useStopwatch } from "react-timer-hook";
import { useTheme } from "@emotion/react";
// import ScrollToBottom from 'react-scroll-to-bottom';
// ----------------------------------------------------------------------
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  display: "flex",
  justifyContent: "center",
  borderRadius: "20px",
};
export default function LiveStream() {
  const theme = useTheme();

  //Modal
  const [openModal, setOpenModal] = React.useState(false);
  const [openModalThree, setOpenModalThree] = React.useState(false);
  const [allUsers, setAllUsers] = React.useState([]);
  const [channelId, setChanneId] = React.useState();
  const [messageList, setMessageList] = React.useState([]);
  let idForPeer;
  idForPeer = false;

  const [block, setBlock] = React.useState(false);
  const [blockUid, setBlockUid] = React.useState();
  const handleOpenTwo = () => {
    setOpenModal(true);
  };
  const handleOpenThreee = () => {
    setOpenModalThree(true);
  };
  const handleCloseTwo = () => {
    setOpenModal(false);
  };
  const handleCloseThree = () => {
    setOpenModalThree(false);
    navigate("/dashboard/batches");
    window.location.reload();
  };
  let streamId;
  let userArr;
  //timer
  const { seconds, minutes, hours, days, isRunning, start, pause, reset } =
    useStopwatch({ autoStart: false });

  const navigate = useNavigate();

  //Name of User
  const nameO = localStorage.getItem("details");
  //start
  const [first, setFirst] = React.useState(true);

  //Reducer Function
  const [state, dispatch] = React.useReducer(reducer, INITIAL_STATE);

  // const [sharing, setSharing] = React.useState(false);
  let sharing;
  sharing = false;
  //Channel Name
  const { channul, lectId, desc, batchId } = useParams();
  // //console.log(channul);

  //Handling Popover
  const [anchorEl, setAnchorEl] = React.useState(null);

  //AppId
  // const [appId, setAppId] = React.useState()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  //Resources
  const [users, usersSet] = React.useState();
  React.useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/getLectureResourceDetails?Lecture_id=${lectId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      usersSet(responseJson?.data);
    }

    fetchUsers();
  }, []);

  //Objects for agora live stream
  let rtc = {
    localAudioTrack: null,
    localVideoTrack: null,
    localScreenTrack: null,
    remoteVideoTrack: null,
    remoteAudioTrack: null,
    client: null,
  };

  let options = {
    appId: "5d035487d2bb4859a5faa4677c039873",
    channel: channul,
  };
  //members of rtm

  let tokenRtm;
  // let blockUid;
  //Fetching RTC token
  function fetchToken(uid, channelName, tokenRole) {
    const token = localStorage.getItem("token");
    const authToken = token;

    const dataone = {
      clientName: "testTeacher",
      clientEmail: "",
      account: "askd",
      Description: "desc",
      channelName: channelName,
      uid: uid,
      expireTime: 3600,
      role: "publisher",
      tokentype: "uid",
    };

    return new Promise(function (resolve) {
      axios
        .post(
          `https://api-prod.gyaankulam.com/api/v1/Streaming/StartMeetingRTCAndRTMToken`,
          dataone,
          {
            headers: {
              "Content-Type": "application/json; charset=UTF-8",
              Authorization: `Bearer ${authToken}`,
            },
          }
        )
        .then(function (response) {
          //console.log(response?.data?.RtcToken);
          const tokenRtc = response?.data?.RtcToken;
          tokenRtm = response?.data?.RtmToke;
          setChanneId(response?.data?.streamId);
          streamId = response?.data?.streamId;
          //console.log(response?.data?.msg)
          if (response?.data?.msg === "channel already exists ") {
            //console.log('In cahnnel already');
            setFirst(false);
            handleOpenTwo();
          }
          //console.log("RTC TOKEN" + tokenRtc);
          //console.log("TOKEN RTM" + tokenRtm);
          resolve(tokenRtc);
        })
        .catch(function (error) {
          //console.log(error);
        });
    });
  }

  //Getting User Details

  const getUserDetails = async () => {
    const token = localStorage.getItem("token");
    const fullResponse = await fetch(
      `https://api-prod.gyaankulam.com/api/v1/Streaming/StreamingUserDetails?channelId=${streamId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const responseJson = await fullResponse.json();

    setAllUsers(responseJson?.data);
    userArr = responseJson?.data;
  };

  //Blocking Student
  async function Block() {
    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    const data = {
      channelName: channul,
      userID: blockUid,
    };

    const res = await axios.put(
      `https://api-prod.gyaankulam.com/api/v1/Streaming/BlockTheStudent`,
      data,
      config
    );
    //console.log(res)
  }
  //UnBlocking Student
  async function unBlock() {
    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    const data = {
      channelName: channul,
      userID: blockUid,
    };

    const res = await axios.put(
      `https://api-prod.gyaankulam.com/api/v1/Streaming/unBlockTheStudent`,
      data,
      config
    );
    //console.log(res)
  }

  //Ending Stream
  function Delete() {
    const token = localStorage.getItem("token");
    const authToken = token;
    return new Promise(function (resolve) {
      axios
        .delete(
          `https://api-prod.gyaankulam.com/api/v1/Streaming/EndStreaming?channelName=${channul}`,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: `Bearer ${authToken}`,
            },
          }
        )
        .then(function (response) {
          //console.log(response);
          pause();

          setTimeout(() => {
            setOpenModalThree(true);
          }, 200);
        })
        .catch(function (error) {
          //console.log(error);
        });
    });
  }

  //Agora stream
  let tokenRtc;

  const send = async (channelRtm) => {
    let t = document.getElementById("idForBlockedUser").innerText;
    //console.log(t)
    // let BlockedId = t.text.split(":")[0]
    // let BlockedTest = t.text.split(":")[1]
    // //console.log(`${BlockedTest ? 'Block' : 'UnBlock'}:${BlockedId}`)
    if (channelRtm != null) {
      await channelRtm.sendMessage({ text: `${t}` }).then(() => {
        //console.log(`BlockUserId:${t}`)
        let log = document.getElementById("log");
        let myMsg = `<div id='my-msg-cont'><span id='myMessage'>Admin Blocked a User</span></div>`;
        log.insertAdjacentHTML("beforeend", myMsg);
      });
    }
  };

  const init = async () => {
    const date = new Date();
    //Rtm
    const clientRtm = AgoraRTM.createInstance(options.appId);
    let channelRtm = clientRtm.createChannel(options.channel);
    //Saving name

    //Rtc
    let client = AgoraRTC.createClient({ mode: "live", codec: "h264" });
    const dr =
      date.getHours() * 60 + date.getMinutes() * 60 + date.getSeconds();
    const uid = "" + Math.floor(Math.random() * 100000) + dr;

    tokenRtc = await fetchToken(uid, options.channel, 1);

    //Rtm Login
    try {
      await clientRtm.login({ uid: uid, token: tokenRtm });
      //console.log('succeess');
      await clientRtm.addOrUpdateLocalUserAttributes({ username: nameO });
    } catch (error) {
      //console.log('Rtm login failed' + error)
    }
    //Rtm join
    await channelRtm.join().then(() => {
      //console.log("RTM JOIN CHANNEL SUCCESS");
      let log = document.getElementById("log");
      let botMsg = `<div id='bot-msg-cont'><span id='bot-msg'>${nameO} ${" "} joined the Channel ${" "} ${
        channelRtm.channelId
      }<span></div>`;
      log.insertAdjacentHTML("beforeend", botMsg);
      getUserDetails();
    });

    channelRtm.on("ChannelMessage", function (message, memberId) {
      let nameOfUser = message.text.split(":")[0];
      let messageOfUser = message.text.split(":")[1];
      //console.log(messageOfUser)
      if (!nameOfUser.includes("messageType")) {
        let log = document.getElementById("log");

        let channelMsg = `<div id='channel-msg-cont'><span id='channelMsg'>${nameOfUser} ${" "} :<span>${messageOfUser}</div>`;
        log.insertAdjacentHTML("beforeend", channelMsg);
      }
    });

    channelRtm.on("MemberJoined", async function (memberId) {
      await getUserDetails();

      for (let i = 0; i < userArr.length; i++) {
        if (userArr[i].userJoinUID == memberId) {
          let log = document.getElementById("log");
          let botMsg = `<div id='bot-msg-cont'><span id='bot-msg'>${userArr[i]?.studentName} joined the channel<span></div>`;
          log.insertAdjacentHTML("beforeend", botMsg);
        }
      }
    });

    channelRtm.on("MemberLeft", async function (memberId) {
      setTimeout(() => {
        const details = async () => {
          //console.log(userArr)
          for (let i = 0; i < userArr.length; i++) {
            //console.log(userArr)

            if (userArr[i]?.userJoinUID == memberId) {
              //console.log('inside')
              let log = document.getElementById("log");
              let botMsg = `<div id='bot-msg-cont'><span id='bot-msg'>${
                userArr[i]?.studentName
              } ${" "} left the channel<span></div>`;
              log.insertAdjacentHTML("beforeend", botMsg);
            }
          }
          await getUserDetails();
        };
        details();
      }, 1000);
    });

    document
      .getElementById("channelMessage")
      .addEventListener("keypress", async function (event) {
        // If the user presses the "Enter" key on the keyboard
        if (event.key === "Enter") {
          // //console.log('hellllo')

          let channelMessage = document
            .getElementById("channelMessage")
            .value?.toString();
          if (channelRtm != null) {
            if (channelMessage != "") {
              await channelRtm
                .sendMessage({ text: `${nameO}:${channelMessage}` })
                .then(() => {
                  //console.log(channelMessage);
                  let log = document.getElementById("log");
                  let myMsg = `<div id='my-msg-cont'><span id='myMessage'>${nameO}:</span>  ${channelMessage}</div>`;
                  log.insertAdjacentHTML("beforeend", myMsg);
                });
            }
          }
          event.preventDefault();
          document.getElementById("channelMessage").value = "";
          // Trigger the button element with a click
        }
      });

    document.getElementById("send_channel_message").onclick = async function (
      msdDataa,
      memberId
    ) {
      // //console.log(memberId)
      let channelMessage = document
        .getElementById("channelMessage")
        .value?.toString();
      if (channelRtm != null) {
        //console.log('ChannelMessage' + channelMessage)
        if (channelMessage != "") {
          await channelRtm
            .sendMessage({ text: `${nameO}:${channelMessage}` })
            .then(() => {
              //console.log(channelMessage);
              let log = document.getElementById("log");
              let myMsg = `<div id='my-msg-cont'><span id='myMessage'>${nameO}:</span>  ${channelMessage}</div>`;
              log.insertAdjacentHTML("beforeend", myMsg);
            });
        }
      }

      document.getElementById("channelMessage").value = "";
    };
    document.getElementById("block_user").onclick = async function (
      msdDataa,
      memberId
    ) {
      send(channelRtm);
    };

    //Rtc Join
    try {
      await client.join(options.appId, options.channel, tokenRtc, uid);
      // //console.log(uid);
      //console.log("join success");
      rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
      rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack({
        encoderConfig: {
          width: { min: 640, ideal: 1920, max: 1920 },
          height: { min: 480, ideal: 1080, max: 1080 },
          frameRate: 30,
          bitrateMin: 600,
          bitrateMax: 1000,
        },
      });
      // //console.log("create local audio/video track success");
      rtc.localVideoTrack.play("stream");
      rtc.localAudioTrack.play();
      start();

      try {
        await client.setClientRole("host");
        await client.publish([rtc.localAudioTrack, rtc.localVideoTrack]);
        //console.log("publish success");

        document.getElementById("unmute").onclick = async function () {
          //console.log('offsfsdf');
          if (rtc.localVideoTrack._muted) {
            await rtc.localVideoTrack.setMuted(false);
          } else {
            await rtc.localVideoTrack.setMuted(true);
          }
        };
        document.getElementById("mute").onclick = async function () {
          //console.log('offsfsdf');
          if (rtc.localAudioTrack._muted) {
            await rtc.localAudioTrack.setMuted(false);
          } else {
            await rtc.localAudioTrack.setMuted(true);
          }
        };
        // document.getElementById("screen").onclick = async function () {
        //     //console.log('offsfsdf');

        //     if (!sharing) {
        //         sharing = true
        //         //console.log('in !sharing' + sharing);
        //         rtc.localScreenTrack = await AgoraRTC.createScreenVideoTrack()
        //         //console.log(rtc.localScreenTrack);
        //         rtc.localVideoTrack.close()
        //         await client.unpublish([rtc.localVideoTrack])
        //         rtc.localScreenTrack.play('stream')
        //         await client.publish([rtc.localScreenTrack])

        //     } else {
        //         sharing = false
        //         //console.log('in sharing');
        //         rtc.localScreenTrack.close()
        //         await client.unpublish([rtc.localScreenTrack])
        //         rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack({
        //             encoderConfig: {
        //                 width: { min: 640, ideal: 1920, max: 1920 },
        //                 height: { min: 480, ideal: 1080, max: 1080 }, frameRate: 30,
        //                 bitrateMin: 600, bitrateMax: 1000,
        //             }
        //         })
        //         //console.log("create local audio/video track success");
        //         rtc.localVideoTrack.play("stream");
        //         try {
        //             await client.setClientRole("host");
        //             await client.publish([rtc.localVideoTrack])
        //             //console.log("Publish Success after scrren share");
        //         } catch (error) {
        //             //console.log("Publish Failed " + error);
        //         }

        //     }
        // }

        document.getElementById("leave").onclick = async function () {
          rtc.localVideoTrack.close();
          rtc.localAudioTrack.close();
          await client.unpublish([rtc.localAudioTrack, rtc.localVideoTrack]);
          await client.leave();
          // await channelRtm.
        };
      } catch (e) {
        //console.log("publish failed", e);
      }
    } catch (e) {
      //console.log("join failed", e);
      pause();
    }
  };

  //Re-Join
  function fetchTokenRtcAudience(uid, channelName, tokenRole) {
    const token = localStorage.getItem("token");
    const authToken = token;
    // const authToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdHVkZW50SWQiOiJlMjNiZjQ0MC0zZjEwLTExZWQtYTY5OS1lM2I2OGY2ZjBiMzciLCJpYXQiOjE2NjQzNTc3MjYsImV4cCI6MTY2NDM1OTUyNn0.os7_b6hvP9RC0l-r9Wg4n4HuKKl6Nd8DNr8yPUz0PXs";
    return new Promise(function (resolve) {
      axios
        .post(
          `https://api-prod.gyaankulam.com/api/v1/Streaming/JoinMeetingRTCAndRTMToken`,
          {
            channelName: channelName,
            uid: uid,
            expireTime: 3600,
            role: "publisher",
            tokentype: "uid",
            clientName: "testTeacher",
            clientEmail: "test@gmail.comm",
            account: "askd",
            Description: "A new Meeting",
          },
          {
            headers: {
              "Content-Type": "application/json; charset=UTF-8",
              Authorization: `Bearer ${authToken}`,
            },
          }
        )
        .then(function (response) {
          //console.log(response);
          tokenRtcAudience = response?.data?.RtcToken;
          tokenRtmAudience = response?.data?.RtmToke;
          //console.log(tokenRtcAudience);
          //console.log(tokenRtmAudience);
          resolve(tokenRtcAudience);
        })
        .catch(function (error) {
          //console.log(error);
        });
    });
  }

  let tokenRtmAudience;
  let tokenRtcAudience;

  const initRejoin = async () => {
    //Rtm
    const clientRtm = AgoraRTM.createInstance(options.appId);

    let channelRtm = clientRtm.createChannel(options.channel);
    //Rtc
    const client = AgoraRTC.createClient({ mode: "live", codec: "h264" });
    const date = new Date();

    const dr =
      date.getHours() * 60 + date.getMinutes() * 60 + date.getSeconds();
    const uid = "" + Math.floor(Math.random() * 100000) + dr;
    tokenRtcAudience = await fetchTokenRtcAudience(uid, options.channel, 1);
    // let token = '0069675807e93584b4c9fec19451a1186faIACtiSsGXc8DeIUWodM+xPS1lIPWUz50PGGfIuTgdG3gemTNKL9h03IJEACPaDJrIGc1YwEAAQCvIzRj'
    //Rtm Login
    try {
      await clientRtm.login({ uid: uid, token: tokenRtmAudience });
      //console.log('succeess');

      await clientRtm.addOrUpdateLocalUserAttributes({ name: nameO });
    } catch (error) {
      //console.log('Rtm login failed' + error)
    }
    //Rtm join
    await channelRtm.join().then(() => {
      //console.log("RTM JOIN CHANNEL SUCCESS"); let log = document.getElementById("log")
      let botMsg = `<div id='bot-msg-cont'><span id='bot-msg'>${nameO}   joined the Channel ${" "} ${
        channelRtm.channelId
      }<span></div>`;
      console.log.insertAdjacentHTML("beforeend", botMsg);
      getUserDetails();

      // let { name } = await clientRtm.getUserAttributesByKeys(memberId, ['name'])
      // document.getElementById("log").appendChild(document.createElement('div')).append(`${nameO} joined the channel ${channelRtm.channelId}`)
    });

    channelRtm.on("ChannelMessage", async function (message, memberId) {
      let nameOfUser = message.text.split(":")[0];
      let messageOfUser = message.text.split(":")[1];
      if (!nameOfUser.includes("messageType")) {
        let log = document.getElementById("log");
        let channelMsg = `<div id='channel-msg-cont'><span id='channelMsg'>${nameOfUser} ${" "} :<span>${messageOfUser}</div>`;
        log.insertAdjacentHTML("beforeend", channelMsg);
      }
    });

    channelRtm.on("MemberJoined", async function (memberId) {
      // let { name } = await clientRtm.getUserAttributesByKeys(memberId, ['name'])
      // // document.getElementById("log").appendChild(document.createElement('div')).append(name + " joined the channel")
      // let log = document.getElementById("log")
      // let botMsg = `<div id='bot-msg-cont'><span id='bot-msg'> ${' '} Someone Joined the Channel<span></div>`
      // log.insertAdjacentHTML('beforeend', botMsg)
      // const getName = async () => {

      //     let members = await channelRtm.getMembers()
      //     //console.log('Members' + members)
      //     let total = document.getElementById('members__count')
      //     total.innerText = members.length
      //     let membersWrapper = document.getElementById('member__list')
      //     //console.log('Name Of user' + name);
      //     for (let i = 0; i < members.length; i++) {
      //         // let { name } = await clientRtm.getUserAttributesByKeys(members[i], ['name'])
      //         let memberwrapper = document.getElementById(`member__${members[i]}__wrapper`)
      //         memberwrapper?.remove()

      //     }
      //     for (let i = 0; i < members.length; i++) {
      //         let { name } = await clientRtm.getUserAttributesByKeys(members[i], ['name'])

      //         let memberItem = `<div class="member__wrapper" id="member__${members[i]}__wrapper">
      //             <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
      //             <p class="member_name">${name}</p>
      //         </div>`

      //         membersWrapper?.insertAdjacentHTML('beforeend', memberItem)
      //     }

      // }
      // getName()

      await getUserDetails();
      //console.log(userArr.length)
      //console.log(userArr)
      for (let i = 0; i < userArr.length; i++) {
        //console.log('hiii')
        //console.log(userArr?.userJoinUID)
        //console.log(userArr?.studentName)
        if (userArr[i].userJoinUID == memberId) {
          let log = document.getElementById("log");
          let botMsg = `<div id='bot-msg-cont'><span id='bot-msg'>${
            userArr[i]?.studentName
          } ${" "} joined the Channel <span></div>`;
          log.insertAdjacentHTML("beforeend", botMsg);
          // document.getElementById("log").appendChild(document.createElement('div')).append(userArr[i]?.studentName + " joined the channel")
        }
      }
    });

    channelRtm.on("MemberLeft", async function (memberId) {
      for (let i = 0; i < userArr.length; i++) {
        //console.log('hiii')
        //console.log(userArr?.userJoinUID)
        //console.log(userArr?.studentName)
        if (userArr[i].userJoinUID == memberId) {
          let log = document.getElementById("log");
          let botMsg = `<div id='bot-msg-cont'><span id='bot-msg'>${
            userArr[i]?.studentName
          } ${" "} joined the Channel <span></div>`;
          log.insertAdjacentHTML("beforeend", botMsg);
          // document.getElementById("log").appendChild(document.createElement('div')).append(userArr[i]?.studentName + " joined the channel")
        }
      }
      await getUserDetails();
      //console.log(userArr.length)
      //console.log(userArr)
    });
    document
      .getElementById("channelMessage")
      .addEventListener("keypress", async function (event) {
        // If the user presses the "Enter" key on the keyboard
        if (event.key === "Enter") {
          //console.log('hellllo')

          let channelMessage = document
            .getElementById("channelMessage")
            .value?.toString();

          // let { name } = await clientRtm.getUserAttributesByKeys(memberId, ['name'])

          if (channelRtm != null) {
            if (channelMessage != "") {
              await channelRtm
                .sendMessage({ text: `${nameO}:${channelMessage}` })
                .then(() => {
                  //console.log(channelMessage);
                  let log = document.getElementById("log");
                  let myMsg = `<div id='my-msg-cont'><span id='myMessage'>${nameO}:</span>  ${channelMessage}</div>`;
                  log.insertAdjacentHTML("beforeend", myMsg);
                  // document.getElementById("log").appendChild(document.createElement('div')).append(`<span id='myMessage'>${nameO}</span> : ${channelMessage}`)
                });
            }
          }
          event.preventDefault();
          document.getElementById("channelMessage").value = "";
          // Trigger the button element with a click
        }
      });

    document.getElementById("send_channel_message").onclick =
      async function () {
        let channelMessage = document
          .getElementById("channelMessage")
          .value?.toString();

        //Name
        // let { name } = await clientRtm.getUserAttributesByKeys(memberId, ['name'])
        if (channelRtm != null) {
          if (channelMessage != "") {
            await channelRtm
              .sendMessage({ text: `${nameO}:${channelMessage}` })
              .then(() => {
                //console.log(channelMessage);
                // //console.log(name);
                let log = document.getElementById("log");
                let myMsg = `<div id='my-msg-cont'><span id='myMessage'>${nameO}:</span> ${channelMessage}</div>`;
                log.insertAdjacentHTML("beforeend", myMsg);
                // document.getElementById("log").appendChild(document.createElement('div')).append(`${nameO} : ${channelMessage}`)
              });
          }
        }
        document.getElementById("channelMessage").value = "";
      };
    document.getElementById("block_user").onclick = async function (
      msdDataa,
      memberId
    ) {
      send(channelRtm);
    };

    try {
      await client.join(options.appId, options.channel, tokenRtcAudience, uid);

      let localVideoTrack = await AgoraRTC.createCameraVideoTrack({
        encoderConfig: {
          width: { min: 640, ideal: 1920, max: 1920 },
          height: { min: 480, ideal: 1080, max: 1080 },
          frameRate: 30,
          bitrateMin: 600,
          bitrateMax: 1000,
        },
      });
      let localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
      localVideoTrack.play("stream");
      localAudioTrack.play();
      start();
      try {
        await client.setClientRole("host");
        await client.publish([localAudioTrack, localVideoTrack]);
        //console.log("publish rejoin  success");
        document.getElementById("unmute").onclick = async function () {
          // //console.log('offsfsdf');
          if (localVideoTrack._muted) {
            await localVideoTrack.setMuted(false);
          } else {
            await localVideoTrack.setMuted(true);
          }
        };
        document.getElementById("mute").onclick = async function () {
          // //console.log('offsfsdf');
          if (localAudioTrack._muted) {
            await localAudioTrack.setMuted(false);
          } else {
            await localAudioTrack.setMuted(true);
          }
        };
      } catch (error) {
        //console.log("Rejoin Publish failed" + error);
      }
      document.getElementById("leave").onclick = async function () {
        localVideoTrack.close();
        localAudioTrack.close();
        await client.unpublish([localVideoTrack, localAudioTrack]);
        await client.leave();
        await clientRtm.logout();
      };
    } catch (e) {
      //console.log("join failed", e);
      pause();
    }
  };

  const joinaudience = async () => {
    await initRejoin();
  };

  const handleClicktwo = async () => {
    await joinaudience();
    handleCloseTwo();
  };
  const handleClickthree = () => {
    navigate(`/dashboard/addRecorded/${lectId}/${batchId}`);
    window.location.reload();
  };

  const startMeeting = () => {
    init();
  };
  React.useEffect(() => {
    if (first) {
      startMeeting();
    }
  }, []);

  const [active, setActive] = React.useState(false);
  const [idForActive, setIdForActive] = React.useState(false);

  return (
    <>
      <Typography variant="h3" ml={1}>
        Live Stream
      </Typography>

      <Grid container spacing={3} pr={2}>
        <Grid
          item
          xs={12}
          sm={state.zoom ? 12 : 8.5}
          md={state.zoom ? 12 : 8.5}
        >
          <Box
            id="stream"
            // onMouseEnter={() => {
            //     dispatch({
            //         type: 'SHOWCONTROLS',
            //         payload: { name: 'showControls', value: true }
            //     })
            // }}
            // onMouseLeave={() => dispatch({
            //     type: 'NOSHOWCONTROLS',
            //     payload: { name: 'showControls', value: false }
            // })}
            sx={{
              height: state.zoom ? "550px" : "450px",
              borderRadius: "17px",
              bgcolor: "black",
              position: "relative",
              cursor: "pointer",
            }}
          >
            <>
              <Box
                p={0.5}
                sx={{
                  position: "absolute",
                  zIndex: "99",
                  top: "3%",
                  left: "45%",
                  background: "#72A354",
                  borderRadius: "7px",
                  color: "white",
                  display: "flex",
                  alignitems: "center",
                }}
              >
                {/* <PauseIcon /> */}
                <span>{hours}</span>:<span>{minutes}</span>:
                <span>{seconds}</span>
              </Box>
              {/* <Tooltip title="More" sx={{ position: 'absolute', top: '3%', zIndex: '99', right: '2%' }}>
                            <IconButton aria-describedby={id} variant="contained" onClick={handleClick}>
                                <MoreVertIcon fontSize='small' sx={{ color: 'white' }} />

                            </IconButton>
                        </Tooltip> */}
              {/* <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}

                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            <Typography sx={{ p: 1, fontSize: '12px', cursor: 'pointer', '&:hover': { bgcolor: 'lightGray' } }}>Turn Off Comments </Typography>
                            <Typography sx={{ p: 1, fontSize: '12px', cursor: 'pointer', '&:hover': { bgcolor: 'lightGray' } }}> Start Recording Stream</Typography>
                            <Typography sx={{ p: 1, fontSize: '12px', cursor: 'pointer', '&:hover': { bgcolor: 'lightGray' } }}> Additional Settings</Typography>
                        </Popover> */}
              <Tooltip
                title={state.mute ? "Unmute" : "Mute"}
                sx={{
                  position: "absolute",
                  zIndex: "99",
                  bottom: "5%",
                  left: "40%",
                  background: "#EB4F6D",
                }}
              >
                <IconButton
                  id="mute"
                  onClick={() =>
                    dispatch({
                      type: state.mute ? "UNMUTE" : "MUTE",
                      payload: {
                        name: "mute",
                        value: state.mute ? false : true,
                      },
                    })
                  }
                >
                  {state.mute ? (
                    <MicOffIcon fontSize="small" sx={{ color: "white" }} />
                  ) : (
                    <MicIcon fontSize="small" sx={{ color: "white" }} />
                  )}
                </IconButton>
              </Tooltip>
              <Tooltip
                title={state.video ? "Turn On Camera" : "Turn Off Camera"}
                sx={{
                  position: "absolute",
                  zIndex: "99",
                  bottom: "5%",
                  left: "48%",
                  background: "#EB4F6D",
                }}
              >
                <IconButton
                  id="unmute"
                  onClick={() =>
                    dispatch({
                      type: state.video ? "VIDEOOFF" : "VIDEOON",
                      payload: {
                        name: "video",
                        value: state.video ? false : true,
                      },
                    })
                  }
                >
                  {state.video ? (
                    <VideocamOffIcon fontSize="small" sx={{ color: "white" }} />
                  ) : (
                    <VideocamIcon fontSize="small" sx={{ color: "white" }} />
                  )}
                </IconButton>
              </Tooltip>
              <Tooltip
                title={state.zoom ? "Zoom Out" : "Zoom In"}
                sx={{
                  position: "absolute",
                  zIndex: "99",
                  bottom: "5%",
                  left: "55%",
                  background: "rgba(255, 255, 255, 0.33)",
                }}
              >
                <IconButton
                  onClick={() =>
                    dispatch({
                      type: state.zoom ? "ZOOMOUT" : "ZOOMIN",
                      payload: {
                        name: "zoom",
                        value: state.zoom ? false : true,
                      },
                    })
                  }
                >
                  {state.zoom ? (
                    <ZoomInMapIcon fontSize="small" sx={{ color: "white" }} />
                  ) : (
                    <ZoomOutMapIcon fontSize="small" sx={{ color: "white" }} />
                  )}
                </IconButton>
              </Tooltip>
              <Tooltip
                title="Exit"
                sx={{
                  position: "absolute",
                  zIndex: "99",
                  bottom: "5%",
                  right: "5%",
                  background: " #4F89A9",
                }}
              >
                <IconButton onClick={Delete}>
                  <ExitToAppIcon
                    id="leave"
                    fontSize="small"
                    sx={{ color: "white" }}
                  />
                </IconButton>
              </Tooltip>
              {/* <Button variant='contained' id='screen' sx={{ position: 'absolute', zIndex: '99', bottom: '5%', left: '5%', background: '#EB4F4F', color: 'white' }}><StopCircleIcon fontSize='small' />{' '}Stop</Button> */}
            </>
          </Box>
        </Grid>
        <Grid item xs={12} sm={state.zoom ? 4 : 3.5} md={state.zoom ? 4 : 3.5}>
          <Box
            sx={{
              border: "1px solid gray",
              minHeight: "450px",
              maxHeight: "450px",
              padding: "10px 20px",
              borderRadius: "18px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <ScrollToBottom className="rootc">
              <Box id="log" pr={2}></Box>
            </ScrollToBottom>

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <TextField
                type="text"
                id="channelMessage"
                label="Hey..."
                sx={{ width: "100%" }}
              />
              <Button
                id="send_channel_message"
                sx={{ width: "10%", color: "#EB4F6D" }}
              >
                Send
              </Button>
            </Stack>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={state.zoom ? 8 : 12}
          md={state.zoom ? 8 : 12}
          sx={{}}
        >
          <Typography variant="h4" ml={1}>
            {channul}
          </Typography>
          <Typography variant="h7" ml={1} mr={2} mb={2} mt={2}>
            <span style={{ fontWeight: "700" }}>Stream Information:</span>{" "}
            {desc}
          </Typography>

          <Box
            p={2}
            mt={2}
            sx={{
              border: "2px solid rgba(198, 198, 198, 0.77)",
              borderRadius: "21px",
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ overflow: "hidden" }}
            >
              <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                <span id="members__count">{allUsers?.length}</span> Watching
              </Typography>
              <Typography>
                <Link to="#" style={{ color: "#4177A9" }}>
                  {" "}
                  View All
                </Link>{" "}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              id="member__list"
              pt={2}
              spacing={2}
              sx={{ overflowX: "auto" }}
            >
              {allUsers?.map((item, i) => {
                //console.log('UID' + item?.userUniqueId)
                let id = item?.userUniqueId;

                return (
                  <Stack
                    key={i}
                    alignItems="center"
                    justifyContent="center"
                    onClick={() => {
                      setBlockUid(id);
                      setActive(!active);
                    }}
                    sx={{
                      cursor: "pointer",
                      p: 1,
                      background:
                        blockUid === item?.userJoinUID
                          ? active
                            ? "#d6d6d630"
                            : ""
                          : "",
                      borderRadius: "5px",
                      // background: 'red',
                      "&:hover": {
                        bgcolor: "#d6d6d630",
                      },
                    }}
                  >
                    <Avatar alt="Remy Sharp" src={item?.profilePicture} />
                    <Typography variant="h7" class="btn">
                      {item?.studentName}
                    </Typography>
                  </Stack>
                );
              })}
            </Stack>
            <Button id="block_user">
              <span
                onClick={() => {
                  setBlock(!block);
                  //console.log(block)
                  if (!block) {
                    Block();
                  } else {
                    unBlock();
                  }
                }}
              >
                {allUsers.length > 0
                  ? blockUid
                    ? block
                      ? "Unblock User"
                      : "Block User"
                    : ""
                  : ""}{" "}
              </span>
              <span
                style={{ color: "transparent", textTransform: "lowercase" }}
                id="idForBlockedUser"
              >
                {block ? "UnBlockedUserID" : "BlockedUserId"}:{blockUid}
              </span>
            </Button>
          </Box>
          <Box mt={2} ml={2}>
            <Typography variant="h4">Resources</Typography>
            {users?.map((item, index) => {
              return (
                <Box key={index}>
                  <Stack direction="row" justifyContent="space-between">
                    <Stack
                      direction="row"
                      spacing={2}
                      pl={0}
                      pt={2}
                      mb={2}
                      sx={{ position: "relative" }}
                    >
                      <a href={item?.upload_file} style={{ color: "black" }}>
                        {" "}
                        <InsertLinkIcon
                          fontSize="large"
                          p={1}
                          style={{
                            background: "lightGray",
                            height: "50px",
                            width: "50px",
                            padding: "10px",
                            borderRadius: "10px",
                          }}
                        />
                      </a>
                      <Stack>
                        <Typography variant="h7">{item?.title}</Typography>
                        <Typography sx={{ fontSize: "10px" }}>
                          <span style={{ fontWeight: "700" }}>Time:</span>{" "}
                          1h2m3s
                        </Typography>
                      </Stack>
                      {/* <MoreVertIcon style={{ ...moreVertIcon }} aria-describedby={id} variant="contained" onClick={handleClick} /> */}
                    </Stack>
                    <UserMoreMenu
                      id={item?._id}
                      req="deleteLectureresourceDetails"
                    />
                  </Stack>
                  <Divider />
                </Box>
              );
            })}
          </Box>
          <Modal
            open={openModal}
            onClose={handleCloseTwo}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Stack>
                <Typography variant="h4" mb={3}>
                  Meeting Left
                </Typography>

                <Stack
                  direction="row"
                  spacing={3}
                  justifyContent="space-around"
                  sx={{ width: "100%" }}
                >
                  {" "}
                  <Button
                    onClick={handleClicktwo}
                    variant="contained"
                    sx={{
                      width: "20%",
                      alignSelf: "center",
                      background: theme.palette.primary.mainTheme,
                      "&:hover": { background: "red" },
                    }}
                  >
                    Rejoin?
                  </Button>
                  <Button
                    onClick={handleCloseTwo}
                    variant="contained"
                    sx={{
                      width: "20%",
                      alignSelf: "center",
                      background: theme.palette.primary.mainTheme,
                      "&:hover": { background: "red" },
                    }}
                  >
                    No
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </Modal>
          <Modal
            open={openModalThree}
            onClose={handleCloseThree}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Stack>
                <Typography variant="h4" mb={3}>
                  Push this lecture as recorded?
                </Typography>

                <Stack
                  direction="row"
                  spacing={3}
                  justifyContent="space-around"
                  sx={{ width: "100%" }}
                >
                  <Button
                    onClick={handleClickthree}
                    variant="contained"
                    sx={{
                      width: "20%",
                      alignSelf: "center",
                      background: theme.palette.primary.mainTheme,
                      "&:hover": { background: "red" },
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    onClick={handleCloseThree}
                    variant="contained"
                    sx={{
                      width: "20%",
                      alignSelf: "center",
                      background: theme.palette.primary.mainTheme,
                      "&:hover": { background: "red" },
                    }}
                  >
                    No
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </Modal>
        </Grid>
      </Grid>
    </>
  );
}
