import * as React from "react";
import Box from "@mui/material/Box";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import ModeIcon from "@mui/icons-material/Mode";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import YouTubeIcon from "@mui/icons-material/YouTube";
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridToolbar,
  GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  GridToolbarDensitySelector,
  useGridApiContext,
  gridExpandedSortedRowIdsSelector,
} from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Stack, Avatar, Typography } from "@mui/material";
import getAccess from "../../../utils/getAccess";

function CustomToolbar({ rows, columns, ...gridProps }) {
  const apiRef = useGridApiContext();
  const exportAllRows = async () => {
    const visibleRows = gridExpandedSortedRowIdsSelector(apiRef);
    const filteredRows = rows?.filter((row) => visibleRows.includes(row.id));
    const columnIds = columns
      ?.filter((column) => column.field !== "actions")
      ?.map((column) => column.field);
    const csvHeader = columnIds.join(", ");
    const csvContent = filteredRows
      ?.map((row, index) => {
        const rowValues = columnIds?.map((columnId) => {
          if (columnId === "serialNumber") {
            return index + 1;
          } else if (columnId === "profileWithName") {
            return row[columnId]?.name;
          }

          return row[columnId]?.toString().replaceAll(",", " ");
        });
        return rowValues.join(", ");
      })
      .join("\n");
    const fullCsvContent = `${csvHeader}\n${csvContent}`;
    const blob = new Blob([fullCsvContent], { type: `text/csv;charset=utf-8` });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", "AllStaff.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />

      <GridToolbarDensitySelector />
      <Tooltip title="Export All">
        <IconButton onClick={exportAllRows}>
          <SaveAltIcon style={{ color: "red" }} />
        </IconButton>
      </Tooltip>
      <GridToolbarQuickFilter style={{ marginLeft: "auto" }} />
    </GridToolbarContainer>
  );
}

export default function TeacherGrid() {
  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});

  const [loading, setLoading] = React.useState(true);
  const navigate = useNavigate();
  const access = getAccess();
  React.useEffect(() => {
    const token = localStorage.getItem("token");

    setLoading(true);
    async function fetchStaffs() {
      const fullResponse = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminTeacher/getteacherListByAdmin`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      setRows(responseJson?.data);
      setLoading(false);
    }

    fetchStaffs();
  }, []);

  const makeActiveAndInActiveTeacher = async (id) => {
    const token = localStorage.getItem("token");
    const response = await fetch(
      `https://api-prod.gyaankulam.com/api/v1/adminTeacher/makeActiveAndInActiveTeacher/${id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const responseJson = await response?.json();
    // console.log(responseJson);
    if (responseJson?.status) {
      toast.success(responseJson?.msg);
      setTimeout(() => window.location.reload(), 1000);
    } else {
      toast.error(responseJson?.msg);
    }
    // setLoadingButton(false)
    // toast.success('Deleted')
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows?.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows?.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows?.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  const columns = [
    {
      field: "sNo",
      headerName: "S NO",
      width: 75,
      align: "left",
      headerAlign: "left",
      // editable: false,
      renderCell: (params) => <strong>{params.value}</strong>,
    },

    {
      field: "profileWithName",
      headerName: "Name",
      type: "string",
      width: 250,
      align: "left",
      headerAlign: "left",
      // editable: true,
      renderCell: (params) => (
        <Stack direction="row" alignItems="center" spacing={2}>
          <Avatar
            alt={params?.value?.name}
            src={params?.value?.profilePhoto}
            sx={{ width: 28, height: 28 }}
          />
          <Typography variant="subtitle2" noWrap>
            {params?.value?.name}
          </Typography>
        </Stack>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      type: "string",
      width: 200,
      align: "left",
      headerAlign: "left",
      // editable: true,
      renderCell: (params) => <div>{params.value ?? "NA"}</div>,
    },
    {
      field: "mobileNumber",
      headerName: "Phone",
      type: "String",
      width: 150,
      align: "left",
      headerAlign: "left",
      // editable: true,
    },
    {
      field: "Role",
      headerName: "Role",
      type: "String",
      width: 150,
      align: "left",
      headerAlign: "left",
      // editable: true,
    },
    {
      field: "category",
      headerName: "Category",
      type: "String",
      width: 150,
      align: "left",
      headerAlign: "left",
      // editable: true,
      renderCell: (params) =>
        params.value ? <span>{params?.value?.title}</span> : <span>NA</span>,
    },
    {
      field: "demoVideo",
      headerName: "Demo Video",
      type: "String",
      width: 150,
      align: "left",
      headerAlign: "left",
      // editable: true,
      renderCell: (params) =>
        params.value != "" ? (
          <a
            href={params.value}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <YouTubeIcon
              fontSize="large"
              style={{ color: "red", marginRight: "8px" }}
            />
          </a>
        ) : (
          <span>NA</span>
        ),
    },

    {
      field: "created_at",
      headerName: "Created At",
      type: "String",
      width: 250,
      align: "left",
      headerAlign: "left",
      // editable: true,
    },
    {
      field: "isActive",
      headerName: "Status",
      type: "boolean",
      width: 100,
      align: "left",
      headerAlign: "left",
      // editable: true,
      renderCell: (params) => (
        <div style={{ color: params.value == true ? "green" : "red" }}>
          {params.value ? "Active" : "inActive"}
        </div>
      ),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 150,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        const rowData = rows?.find((item) => item?.id === id);
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          access?.accessForTab == "readWrite" ||
          access?.access.includes("all") ? (
            <Tooltip title="Edit">
              <GridActionsCellItem
                icon={<ModeIcon />}
                label="Edit"
                onClick={() => navigate(`/dashboard/staff/updateStaff/${id}`)}
                color="inherit"
              />
            </Tooltip>
          ) : (
            <></>
          ),
          access?.accessForTab == "readWrite" ||
          access?.access.includes("all") ? (
            <Tooltip title="Update Permission">
              <GridActionsCellItem
                icon={<LockPersonIcon sx={{ color: "red" }} />}
                label="Update Permission"
                onClick={() =>
                  navigate(`/dashboard/staff/updatePermission/${id}`)
                }
                color="inherit"
              />
            </Tooltip>
          ) : (
            <></>
          ),
          access?.accessForTab == "readWrite" ||
          access?.access.includes("all") ? (
            <Tooltip title="Change Status">
              <GridActionsCellItem
                icon={
                  rowData?.isActive ? (
                    <ToggleOffIcon style={{ color: "green" }} />
                  ) : (
                    <ToggleOnIcon style={{ color: "red" }} />
                  )
                }
                label="Status"
                onClick={() => {
                  makeActiveAndInActiveTeacher(id);
                }}
                color="inherit"
              />
            </Tooltip>
          ) : (
            <></>
          ),
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        height: 500,
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <Box mb={2}>{/* <h2>All Staff</h2> */}</Box>
      <DataGrid
        rows={rows}
        columns={columns || []}
        editMode="row"
        loading={loading}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        processRowUpdate={processRowUpdate}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 25,
            },
          },
        }}
        pageSizeOptions={[25, 50, 100]}
        slots={{
          toolbar: (gridProps) => (
            <CustomToolbar {...gridProps} rows={rows} columns={columns || []} />
          ),
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel, showQuickFilter: true },
        }}
      />
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Box>
  );
}
