import * as Yup from "yup";
import { useEffect, useState } from "react";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Stack, Typography, MenuItem } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components

import { FormProvider, RHFTextField } from "../../../components/hook-form";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import makeAnimated from "react-select/animated";

// import "./general.css";
import { useTheme } from "@emotion/react";

// ----------------------------------------------------------------------

export default function AddStoreAlert() {
  const theme = useTheme();
  const animatedComponents = makeAnimated();
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("none");
  const [valueId, setValueId] = useState("");
  const [list, setList] = useState([]);

  const RegisterSchema = Yup.object().shape({
    title: Yup.string().required("Title required"),
    link: Yup.string()
      .required("Link Banner Required")
      .oneOf(["none", "product", "category"], "Invalid Link Value"),
    // valueId: Yup.string().when('link', {
    //   is: (link) => link !== 'none' && link !== '',
    //   then: Yup.string().required('Link With Required'),
    //   otherwise: Yup.string().notRequired(),
    // }),
    isActive: Yup.boolean().required("Status Required"),
  });

  const defaultValues = {
    title: "",
    link: "",
    linkWith: "",
    isActive: "",
  };

  const handleValueChange = (value) => {
    setValueId(value);
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    axios
      .post(
        `https://api-prod.gyaankulam.com/api/v1/store/addStoreAlert`,
        { ...data, linkWith: valueId?._id },
        config
      )
      .then((response) => {
        //console.log(response?.data);
        if (response?.data?.msg === "Store alert added successfully") {
          toast.success(response?.data?.msg);
          setLoading(false);
          reset();
          window.location.href = "/dashboard/storeAlert";
        } else {
          toast.error(response?.data?.msg);
          setLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  //get batch or get testSeries
  useEffect(() => {
    if (type !== "" && type !== "none") {
      const token = localStorage.getItem("token");
      async function fetchDetails() {
        const fullResponse = await fetch(
          `https://api-prod.gyaankulam.com/api/v1/store/${
            type == "product"
              ? "getAllStoreProduct"
              : "getAllStoreProductCategory"
          }`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const responseJson = await fullResponse.json();
        let data = responseJson?.data?.filter((item) => item?.isActive == true);
        setList(data);
      }
      fetchDetails();
    }
    setLoading(false);
  }, [type]);

  return (
    <>
      <Stack spacing={3} px={5}>
        <Typography variant="h3">Add Store Alert</Typography>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} sx={{ width: "70%" }}>
            <RHFTextField name="title" label="Title" />
            <Stack direction="row" spacing={3}>
              <Stack
                sx={{ width: type !== "none" && type !== "" ? "50%" : "100%" }}
              >
                <RHFTextField
                  name="link"
                  label="Link Alert"
                  type="checkbox"
                  select
                >
                  <MenuItem value="none" onClick={() => setType("none")}>
                    None
                  </MenuItem>
                  <MenuItem value="product" onClick={() => setType("product")}>
                    Product
                  </MenuItem>
                  <MenuItem
                    value="category"
                    onClick={() => setType("category")}
                  >
                    Category
                  </MenuItem>
                </RHFTextField>
              </Stack>

              {type !== "none" && type !== "" && (
                //  <RHFTextField name="valueId" label={type == "product" ? "Link With Product" : "Link With Category"} select>
                //   {list?.map((item, index) => (

                //     <MenuItem
                //       key={index}
                //       value={item?.id}
                //       onClick={() => setValueId(item?.id)}
                //     >
                //       {item?.title}

                //     </MenuItem>
                //   )

                //   )}
                // </RHFTextField>
                <Stack sx={{ width: "50%" }}>
                  <Select
                    inputId="valueId"
                    placeholder={
                      type == "product"
                        ? "Link With Product"
                        : "Link With Category"
                    }
                    options={list}
                    isMulti={false}
                    value={valueId}
                    onChange={handleValueChange}
                  />
                </Stack>
              )}
            </Stack>

            <Stack direction="row" spacing={3}>
              <RHFTextField
                name="isActive"
                label="Status"
                type="checkbox"
                select
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </RHFTextField>
            </Stack>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
              sx={{
                bgcolor: theme.palette.primary.mainTheme,
                marginTop: "5%",
                "&:hover": { background: "red" },
              }}
            >
              Add
            </LoadingButton>
          </Stack>
        </FormProvider>
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Stack>
    </>
  );
}
