export function convertYoutubeUrlToEmbed(url){
    const youtube_regex = /^.*(youtu\.be\/|vi?\/|u\/\w\/|embed\/|live?\/|shorts?\/|\?vi?=|\&vi?=)([^#\&\?]*).*/;
    const parsed = url.match(youtube_regex);
    if (parsed && parsed[2].length == 11) {
      const videoId = parsed[2];
      const embedUrl = `https://www.youtube.com/embed/${videoId}`;
      return embedUrl;
    } else {
       return url;
      
    }
}