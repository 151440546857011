import { filter } from "lodash";
// import { sentenceCase } from 'change-case';
import { useEffect, useState } from "react";

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  // Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Button,
  Input,
  MenuItem,
  TextField,
} from "@mui/material";
// components
import Page from "../../../components/Page";

import Scrollbar from "../../../components/Scrollbar";

import SearchNotFound from "../../../components/SearchNotFound";
import { UserListHead, UserListToolbar, UserMoreMenu } from "../user";
import { Link, useNavigate } from "react-router-dom";
import SyncLoader from "react-spinners/SyncLoader";
//mui icons
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import axios from "axios";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import LinkIcon from "@mui/icons-material/Link";
import { ExportCSV } from "../../../utils/ExportCSV";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// for Dialog box
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { CSVLink } from "react-csv"; // for CSV file download
import { useTheme } from "@emotion/react";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  {
    id: "",
    label: "S.No",
    alignRight: false,
    editable: true,
  },
  { id: "title", label: "Title", alignRight: false, editable: true },
  // { id: 'category', label: 'Category', alignRight: false, editable: true },
  { id: "subject", label: "Subject", alignRight: false },
  { id: "file_url", label: "File ", alignRight: false },
  { id: "language", label: "Language", alignRight: false },
  // { id: 'is_active', label: 'Status', alignRight: false, },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.title.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis?.map((el) => el[0]);
}

export default function PathyakramTable() {
  const theme = useTheme();
  // Code for handling Dialog box
  const [download, setDownload] = useState(true);
  const [errorOTP, setErrorOtp] = useState();
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const sendOtp = () => {
    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    const data = {
      action: "Exporting All Pathyakram Data",
    };

    axios
      .post(
        `https://api-prod.gyaankulam.com/api/v1/adminTeacher/send-otp`,
        data,
        config
      )
      .then((response) => {})
      .catch((e) => {
        //console.log(e);
      });
  };
  const verifyOtp = () => {
    //console.log(document.getElementById('name').value);
    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    const data = {
      otp: document.getElementById("name").value,
    };

    axios
      .post(
        `https://api-prod.gyaankulam.com/api/v1/adminTeacher/verify-otp`,
        data,
        config
      )
      .then((response) => {
        // //console.log(response);
        if (response?.data?.status == false) {
          setErrorOtp("Invalid Otp");
        } else {
          setDownload(false);
          setErrorOtp("Successfully Verified");
        }
      })
      .catch((e) => {
        //console.log(e);
      });
  };

  const [page, setPage] = useState(0);
  // const [users, usersSet] = React.useState([]);
  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();
  const [users, usersSet] = useState();
  const [loading, setLoading] = useState(true);
  const [selectedId, setSelectedId] = useState();

  //Getting PathyaKram
  const [isActiveValue, setIsActiveValue] = useState(false);
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/ResourceDetails`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      usersSet(responseJson?.data);
      setLoading(false);
    }

    fetchUsers();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target?.checked) {
      const newSelecteds = users?.map((n) => n?.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected?.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected?.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected.concat(selected?.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected?.slice(0, selectedIndex),
        selected?.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target?.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target?.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users?.length) : 0;

  const filteredUsers = applySortFilter(
    users ? users : ["no"],
    getComparator(order, orderBy),
    filterName
  );

  let helperArray = [];
  filteredUsers?.map((item) =>
    helperArray.push({
      ID: item?._id,
      File_url: item?.file_url?.fileLoc,
      Title: item?.title,
      Language: item?.language,
      Resource_type: item?.resource_type,
      Subject: item?.subject,
    })
  );
  // console.log(filteredUsers);

  const isUserNotFound =
    filteredUsers?.length === 0 || filteredUsers[0] === "no";
  const [isEdit, setIsEdit] = useState(false);
  const [updatedValues, setUpdatedValues] = useState({});
  // //console.log(updatedValues)

  const [file, setFile] = useState();

  return (
    <>
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "60vh" }}
        >
          {" "}
          <SyncLoader
            color={theme.palette.primary.mainTheme}
            loading={loading}
            size={20}
          />
        </Stack>
      ) : (
        <Page title="Pathyakram">
          <Container sx={{ position: "absolute", minWidth: "100%" }}>
            <Card>
              <Stack
                pr={3}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <UserListToolbar
                  numSelected={selected?.length}
                  filterName={filterName}
                  onFilterName={handleFilterByName}
                />

                {/* <ExportCSV csvData={helperArray} fileName='Pathyakram Data' /> */}

                <Button
                  variant="outlined"
                  onClick={() => {
                    handleClickOpen();
                    sendOtp();
                  }}
                >
                  Export
                </Button>
                <Dialog open={open} onClose={handleClose}>
                  {download ? (
                    <>
                      <DialogTitle>OTP Varification</DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          OTP is send successfully to your regisered e-mail.
                        </DialogContentText>
                        <TextField
                          autoFocus
                          margin="dense"
                          id="name"
                          label="OTP Number"
                          type="number"
                          fullWidth
                          variant="standard"
                        />
                        <DialogContentText
                          sx={{
                            color: "red",
                            fontSize: "12px",
                            padding: "3px",
                          }}
                        >
                          {errorOTP}
                        </DialogContentText>
                      </DialogContent>

                      <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={verifyOtp}>Verify</Button>
                      </DialogActions>
                    </>
                  ) : (
                    <>
                      <DialogTitle>Download File</DialogTitle>
                      <DialogContent>
                        <CSVLink
                          data={helperArray}
                          filename="AllPathyakram.csv"
                        >
                          Download
                        </CSVLink>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={() => {
                            handleClose();
                            setDownload(true);
                          }}
                        >
                          Cancel
                        </Button>
                      </DialogActions>
                    </>
                  )}
                </Dialog>
              </Stack>

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={users?.length}
                      numSelected={selected?.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {filteredUsers[0] !== "no" &&
                        filteredUsers
                          ?.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          ?.map((row, index) => {
                            let {
                              id,
                              title,
                              subject,
                              file_url,
                              language,
                              is_active,
                              _id,
                              category,
                              resource_type,
                            } = row;
                            const isItemSelected =
                              selected?.indexOf(title) !== -1;

                            //File handling
                            const changeHandler = async (event) => {
                              if (resource_type === "file") {
                                setFile(event.target?.files[0]);
                              } else {
                                setFile(event.target?.value);
                              }
                            };

                            const onChange = (e, row, _id) => {
                              const { name, value } = e.target;
                              if (name == "is_active") {
                                //console.log('came')
                                setIsActiveValue(true);
                              }
                              setUpdatedValues((prev) => {
                                return { ...prev, [name]: value };
                              });
                            };
                            {
                              /* //console.log(updatedValues) */
                            }
                            const handleUpdate = async () => {
                              setLoading(true);

                              //console.log('Category' + updatedValues?.category)
                              //console.log(updatedValues)
                              let data;
                              if (!updatedValues.category) {
                                data = {
                                  ...updatedValues,
                                  category: category.title,
                                };
                              } else {
                                data = { ...updatedValues };
                              }
                              //console.log(data)
                              makeReq(data);
                            };
                            const makeReq = async (data) => {
                              const token = localStorage.getItem("token");
                              const authToken = token;
                              const formData = new FormData();
                              if (file) {
                                if (resource_type === "file") {
                                  formData.append("file", file);
                                }
                                if (resource_type === "link") {
                                  formData.append("link", file);
                                }
                              }

                              if (data?.title) {
                                formData.append("title", data?.title);
                              }
                              formData.append("category", "Pathyakram");
                              if (resource_type === "file") {
                                formData.append("resource_type", "file");
                              }
                              if (resource_type === "link") {
                                formData.append("resource_type", "link");
                              }

                              if (data?.subject) {
                                formData.append("subject", data?.subject);
                              }
                              if (data?.language) {
                                formData.append("language", data?.language);
                              }
                              if (isActiveValue) {
                                formData.append("is_active", data?.is_active);
                              }

                              const config = {
                                headers: {
                                  "content-type": "multipart/form-data",
                                  Authorization: `Bearer ${authToken}`,
                                },
                              };
                              const res = await axios.put(
                                `https://api-prod.gyaankulam.com/api/v1/adminPanel/updatedResourceDetails/${_id}`,
                                formData,
                                config
                              );
                              //console.log(res.msg)
                              setLoading(false);
                              toast.success("Updated");
                              setTimeout(() => window.location.reload(), 800);
                            };
                            {
                              /* //console.log(isEdit) */
                            }
                            return (
                              <TableRow
                                hover
                                key={id}
                                tabIndex={-1}
                                role="checkbox"
                                selected={isItemSelected}
                                aria-checked={isItemSelected}
                              >
                                {/* <TableCell padding="checkbox">
                                                        <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, title)} />
                                                    </TableCell> */}
                                <TableCell>
                                  <Typography variant="subtitle2">
                                    {index + 1}
                                  </Typography>
                                </TableCell>
                                <TableCell component="th" scope="row" pl={2}>
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={2}
                                  >
                                    {/* <Avatar alt={name} src={avatarUrl} /> */}
                                    <Typography variant="subtitle2" noWrap>
                                      {isEdit && selectedId === _id ? (
                                        <Input
                                          onChange={(e) =>
                                            onChange(e, row, _id)
                                          }
                                          // value={value}
                                          name="title"
                                          placeholder={title}
                                        />
                                      ) : (
                                        <span>{title}</span>
                                      )}
                                    </Typography>
                                  </Stack>
                                </TableCell>
                                {/* <TableCell align="left">
                                                        {isEdit && selectedId === _id ? (<Input
                                                            onChange={(e) => onChange(e, row)}
                                                            // value={value}
                                                            name='category'
                                                            placeholder={category.title}



                                                        />) : <span>{category.title}</span>}
                                                    </TableCell> */}
                                <TableCell align="left">
                                  {isEdit && selectedId === _id ? (
                                    <Input
                                      onChange={(e) => onChange(e, row)}
                                      // value={value}
                                      name="subject"
                                      placeholder={subject}
                                    />
                                  ) : (
                                    <span>{subject}</span>
                                  )}
                                </TableCell>
                                <TableCell align="left">
                                  {isEdit && selectedId === _id ? (
                                    <>
                                      <input
                                        style={{
                                          display:
                                            resource_type === "link"
                                              ? "block"
                                              : "none",
                                        }}
                                        id="choose-file"
                                        type={
                                          resource_type === "link"
                                            ? "url"
                                            : "file"
                                        }
                                        onChange={changeHandler}
                                      />
                                      <label htmlFor="choose-file">
                                        {resource_type === "link" ? (
                                          ""
                                        ) : (
                                          <FileUploadIcon
                                            sx={{
                                              cursor: "pointer",
                                              "&:hover": {
                                                color: "red",
                                              },
                                            }}
                                          />
                                        )}
                                      </label>
                                    </>
                                  ) : (
                                    <a
                                      href={file_url?.fileLoc}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      style={{ color: "black" }}
                                    >
                                      {resource_type === "link" ? (
                                        <LinkIcon />
                                      ) : (
                                        <PictureAsPdfIcon
                                          sx={{
                                            cursor: "pointer",
                                            "&:hover": {
                                              color: "red",
                                            },
                                          }}
                                        />
                                      )}
                                    </a>
                                  )}
                                </TableCell>
                                <TableCell align="left">
                                  {isEdit && selectedId === _id ? (
                                    <TextField
                                      onChange={(e) => onChange(e, row)}
                                      size="small"
                                      label={
                                        language === "hi"
                                          ? "Hindi"
                                          : language == "en"
                                          ? "English"
                                          : "Hindi/English"
                                      }
                                      name="language"
                                      placeholder={
                                        language === "hi"
                                          ? "Hindi"
                                          : language == "en"
                                          ? "English"
                                          : "Hindi/English"
                                      }
                                      select
                                      sx={{ width: "100px" }}
                                    >
                                      {/*
                                      <MenuItem value="hi">Hindi</MenuItem>
                                      <MenuItem value="en">English</MenuItem>
                                    */ }
                                      <MenuItem value="enhi">
                                        Bilingual
                                      </MenuItem>
                                    </TextField>
                                  ) : (
                                    <span>
                                      {" "}
                                      {language === "hi"
                                        ? "Hindi"
                                        : language == "en"
                                        ? "English"
                                        : "Hindi/English"}
                                    </span>
                                  )}
                                </TableCell>
                                {/* <TableCell align="left">
                                                        {isEdit && selectedId === _id ? (<TextField
                                                            onChange={(e) => onChange(e, row)}
                                                            size="small"
                                                            label={is_active ? 'Active' : 'Inactive'}
                                                            name='is_active'
                                                            placeholder={is_active ? 'Active' : 'Inactive'}
                                                            select
                                                            sx={{ width: '100px' }}>
                                                            <MenuItem value={true}>Active</MenuItem>
                                                            <MenuItem value={false}>Inactive</MenuItem>
                                                        </TextField>) : <span style={{ background: is_active ? 'green' : 'red', padding: '5px', borderRadius: '10px', color: 'white', fontWeight: '500' }}>{is_active ? 'Active' : 'Inactive'}</span>}</TableCell> */}
                                {isEdit && selectedId === _id ? (
                                  <TableCell align="left">
                                    <Button
                                      variant="outlined"
                                      onClick={handleUpdate}
                                    >
                                      Update
                                    </Button>
                                  </TableCell>
                                ) : null}

                                <TableCell
                                  align="right"
                                  onClick={() => setSelectedId(_id)}
                                >
                                  <UserMoreMenu
                                    id={_id}
                                    req="DeleteResourcesDetails"
                                    isEdit={isEdit}
                                    setIsEdit={setIsEdit}
                                    setLoading={setLoading}
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>

                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>
              <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />

              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={users?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </Container>
        </Page>
      )}
    </>
  );
}
