import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Stack,
  IconButton,
  InputAdornment,
  Typography,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components

import { FormProvider, RHFTextField } from "../../../components/hook-form";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
// import RHFTextEditor from "../../../../components/hook-form/RHFTextEditor";
import RHFTextEditor from "../../../components/hook-form/RHFTextEditor";
import CloseIcon from "@mui/icons-material/Close";

// import "./general.css";
import { useTheme } from "@emotion/react";

import { MuiChipsInput } from "mui-chips-input";
import Ckeditor from "../../../components/hook-form/Ckeditor";
import MyCkEditor from "../../../components/hook-form/MyCkEditor";

// ----------------------------------------------------------------------

export default function UpdateBlog() {
  const theme = useTheme();

  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [restrict, setRestrict] = useState("");
  const [focus, setFocused] = useState(false);
  const [pdf, setPdf] = useState(false);
  const [msg, setMsg] = useState("");
  const [val, setVal] = useState(false);
  const [categoryId, setCategoryId] = useState();
  const [editValue, setEditValue] = useState();

  //Chips
  const [chips, setChips] = useState([]);

  const handleChangeChips = (newChips) => {
    setChips(newChips);
  };
  const [desc, setDesc] = useState(editValue?.desc);
  const [dataForDesc, setDataForDesc] = useState(editValue?.desc);
  const onChangeDesc = (value) => {
    // console.log(value);
    setDesc(value);
  };
  const [metaDesc, setMetaDesc] = useState(editValue?.metaDesc);
  const onChangeMetaDesc = (value) => {
    // console.log(value);
    setMetaDesc(value);
  };
  const [category, setCategory] = useState([]);
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/getBlogById/${id}`,
        // `https://api-prod.gyaankulam.com/api/v1/store/getProductCategoryByIdAdmin/${editData?.id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      // console.log('line91', responseJson)
      setChips(responseJson?.data?.tags);
      onChangeDesc(responseJson?.data?.desc);
      setDataForDesc(responseJson?.data?.desc);
      // console.log("category" + responseJson?.data);
      setCategoryId(responseJson?.data?.category?._id);
      setEditValue(responseJson?.data);
    }
    fetchUsers();

    async function fetchCategory() {
      const response = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/getCategory?type=Stream`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await response?.json();
      setCategory(responseJson?.data);
      setLoading(false);
    }
    fetchCategory();
  }, []);

  const RegisterSchema = Yup.object().shape({});

  const defaultValues = {};
  const [file, setFile] = useState();
  const changeHandler = async (event) => {
    setFile(event.target?.files[0]);
    var fileName = document.getElementById("file").value?.toLowerCase();
    if (fileName.endsWith(".pdf") || fileName.endsWith(".jpg")) {
      setRestrict("");
    } else {
      setRestrict("JPG Or PDF");
    }
    //console.log(file)
  };
  const onChange2 = (e) => {
    const { name, value } = e.target;
    // console.log(name + " "+ value);
    setEditValue((prev) => ({ ...prev, [name]: value }));
  };
  //console.log(file)
  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const [progress, setProgress] = useState();
  const [showImage, setShowImage] = useState(true);
  const handleDelete = () => {
    setShowImage(false);
    setFile("");
  };

  const onSubmit = async (data) => {
    setLoading(true);
    const formData = new FormData();
    if (file) {
      formData.append("file", file);
    }

    if (data?.title) {
      formData.append("title", data?.title);
    } else {
      formData.append("title", editValue?.title);
    }
    // if (desc) {
    //     formData.append("desc", desc);
    // } else {
    //     formData.append("desc", editValue?.desc);
    // }
    formData.append("desc", dataForDesc);
    if (data?.language) {
      formData.append("language", data?.language);
    } else {
      formData.append("language", editValue?.language);
    }
    if (data?.platform) {
      formData.append("platform", data?.platform);
    } else {
      formData.append("platform", editValue?.platform);
    }
    chips?.length > 0 &&
      chips?.map((item) => {
        formData.append("tags", item);
      });

    if (metaDesc) {
      formData.append("metaDesc", metaDesc);
    } else {
      formData.append("metaDesc", editValue?.metaDesc);
    }

    console.log("category" + categoryId);
    formData.append("category", categoryId);
    if (data?.excerptTitle) {
      formData.append("excerptTitle", data?.excerptTitle);
    } else {
      formData.append("excerptTitle", editValue?.excerptTitle);
    }
    // formData.append("category", editValue?.category);

    if (data?.metaTitle) {
      formData.append("metaTitle", data?.metaTitle);
    } else {
      formData.append("metaTitle", editValue?.metaTitle);
    }
    formData.append("isActive", editValue?.isActive);
    formData.append("slug", editValue?.slug);

    // console.log(data)
    // for( let Pair of formData){
    //     console.log(Pair[0] + " "+Pair[1]);
    // }
    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${authToken}`,
      },
      onUploadProgress: (data) => {
        //Set the progress value to show the progress bar
        setProgress(Math.round((100 * data?.loaded) / data?.total));
      },
    };
    setProgress(0);
    axios
      .post(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/updateBlog/${editValue?._id}`,
        formData,
        config
      )
      .then((response) => {
        // console.log(response?.data);
        if (response?.data?.status === true) {
          // setProductId(response?.data?.data?._id)
          toast.success(response?.data?.msg);
          setLoading(false);
          setPdf(false);
          // reset();
          window.location.href = "/dashboard/blogs";
        } else {
          toast.error(response?.data?.msg);
          setLoading(false);
        }
      })
      .catch((e) => {
        //console.log(e);
      });
  };

  return (
    <>
      <Stack spacing={3} px={5}>
        <>
          <Typography variant="h3">
            Update Blog Details - {editValue?.title}
          </Typography>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3} sx={{ width: "80%" }}>
              <Stack direction="row" spacing={3}>
                <Stack
                  direction="row"
                  sx={{ width: "100%", alignSelf: "center" }}
                >
                  <InputLabel
                    sx={{
                      width: "20%",
                      marginRight: "10px",
                      alignSelf: "center",
                    }}
                  >
                    Title :{" "}
                  </InputLabel>
                  <RHFTextField
                    name="title"
                    value={editValue?.title}
                    label=""
                    onChange={onChange2}
                  />
                </Stack>

                {/* <RHFTextField name="file"
                                onChange={changeHandler}
                                id="file"
                                label="File"
                                // error={!file && val}
                                helperText='Image Only'
                                onFocus={() => setFocused(true)}
                                onBlur={() => setFocused(false)}
                                // inputProps={{ multiple: true }}
                                InputProps={{
                                    classes: {
                                        input: "CustomTextField"
                                    }
                                }}

                                onClick={() => setPdf(true)}
                                inputProps={{ accept: "image/*" }}
                                type={pdf || focus ? "file" : "text"}
                            /> */}
                {/* <Stack direction="row"> */}
                {showImage ? (
                  <Stack
                    direction="column"
                    sx={{ width: "100%", position: "relative" }}
                    alignItems="center"
                    spacing={1}
                  >
                    <IconButton
                      sx={{
                        position: "absolute",
                        top: "-10px",
                        right: "-10px",
                      }}
                      onClick={() => handleDelete()}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>

                    {/* <a href={values?.banner_url} style={{ textDecoration: 'none' }}><FileCopyIcon fontSize='small' /></a> */}
                    <img
                      src={editValue?.featuredImage}
                      alt="FeaturedImage"
                      style={{ height: "100px", width: "200px" }}
                    />
                    {/* <Typography sx={{ fontSize: '12px' }}>{imageName}</Typography> */}
                  </Stack>
                ) : (
                  <RHFTextField
                    name="file"
                    onChange={changeHandler}
                    id="file"
                    label="Select image"
                    // value={value?.banner_url}
                    error={!file && val}
                    helperText="Image Only ( Maximum allowed size 1MB)"
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    // inputProps={{ multiple: true }}
                    InputProps={{
                      classes: {
                        input: "CustomTextField",
                      },
                    }}
                    onClick={() => setPdf(true)}
                    inputProps={{ accept: "image/*" }}
                    type={pdf || focus ? "file" : "text"}
                  />
                )}

                {/* </Stack> */}
              </Stack>
              <Stack direction="row" sx={{ width: "100%" }}>
                <InputLabel
                  sx={{
                    width: "25%",
                    marginRight: "20px",
                    alignSelf: "center",
                  }}
                >
                  Slug :(only Chracters & Numbers){" "}
                </InputLabel>
                <RHFTextField
                  name="slug"
                  value={editValue?.slug}
                  onChange={onChange2}
                  type="string"
                  inputProps={{ pattern: "[A-Za-z0-9s-]*" }}
                />
              </Stack>

              <Stack direction="row" spacing={3}>
                <MuiChipsInput
                  style={{ width: "50%" }}
                  value={chips ? chips : editValue?.tags}
                  onChange={handleChangeChips}
                  placeholder="Enter Tags"
                />
                <RHFTextField
                  style={{ width: "50%" }}
                  label={
                    editValue?.category
                      ? editValue?.category?.title
                      : "Category"
                  }
                  select
                  onChange={onChange2}
                  name="category"
                >
                  {category?.map((item, index) => {
                    return (
                      <MenuItem
                        key={index}
                        value={item?._id}
                        onClick={() => setCategoryId(item?._id)}
                      >
                        {item?.title}{" "}
                      </MenuItem>
                    );
                  })}
                </RHFTextField>
              </Stack>

              <Stack direction="row" spacing={3}>
                <Stack direction="row" sx={{ width: "100%" }}>
                  <InputLabel
                    sx={{
                      width: "40%",
                      marginRight: "10px",
                      alignSelf: "center",
                    }}
                  >
                    Meta Title :{" "}
                  </InputLabel>
                  <RHFTextField
                    name="metaTitle"
                    value={editValue?.metaTitle}
                    onChange={onChange2}
                  />
                </Stack>
                <Stack direction="row" sx={{ width: "100%" }}>
                  <InputLabel
                    sx={{
                      width: "40%",
                      marginRight: "10px",
                      alignSelf: "center",
                    }}
                  >
                    Excerpt Title :{" "}
                  </InputLabel>
                  <RHFTextField
                    name="excerptTitle"
                    value={editValue?.excerptTitle}
                    onChange={onChange2}
                  />
                </Stack>
              </Stack>

              {/* <RHFTextField name="desc" value={editValue?.desc} onChange={onChange2} />  */}

              <Stack direction="column" sx={{ width: "100%" }}>
                <Typography>Description </Typography>

                <Stack sx={{ width: "100%" }}>
                  <MyCkEditor
                    dataForDesc={dataForDesc}
                    setDataForDesc={setDataForDesc}
                  />
                </Stack>
              </Stack>
              <Stack direction="row" sx={{ width: "100%" }}>
                <InputLabel
                  sx={{
                    width: "20%",
                    marginRight: "10px",
                    alignSelf: "center",
                  }}
                >
                  Meta Description :{" "}
                </InputLabel>
                <RHFTextEditor
                  name="metaDesc"
                  value={metaDesc ? metaDesc : editValue?.metaDesc}
                  onChange={onChangeMetaDesc}
                />
              </Stack>

              <Stack direction="row" spacing={3}>
                <RHFTextField
                  name="platform"
                  label={
                    editValue?.platform == "app"
                      ? "App"
                      : editValue?.platform == "website"
                      ? "WebSite"
                      : "Store"
                  }
                  select
                >
                  <MenuItem value="app">App</MenuItem>
                  <MenuItem value="website">WebSite</MenuItem>
                  <MenuItem value="store">Store</MenuItem>
                </RHFTextField>
                <RHFTextField
                  name="language"
                  label={editValue?.language == "en" ? "English" : "Hindi"}
                  select
                >
                  <MenuItem value="en">English</MenuItem>
                  <MenuItem value="hi">Hindi</MenuItem>
                </RHFTextField>

                <RHFTextField
                  name="isActive"
                  label={editValue?.isActive ? "Active" : "Inactive"}
                  sx={{ width: "100%" }}
                  select
                  type="checkbox"
                  onChange={onChange2}
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </RHFTextField>
              </Stack>

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={loading}
                sx={{
                  bgcolor: theme.palette.primary.mainTheme,
                  marginTop: "5%",
                  "&:hover": { background: "red" },
                }}
                onClick={() => {
                  setVal(true);
                  setMsg("Required");
                }}
              >
                Update
              </LoadingButton>
            </Stack>
          </FormProvider>
          <ToastContainer
            position="bottom-center"
            autoClose={1000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </>

        {/* <Typography color={positive ? "green" : "red"} sx={{ textAlign: 'center', width: '80%' }}>{fetchMessage}</Typography> */}
      </Stack>
    </>
  );
}
