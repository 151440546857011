import React from "react";
import * as Yup from "yup";
import { useContext, useState, useEffect } from "react";
import { useNavigate, useParams, useResolvedPath } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Link,
  Stack,
  IconButton,
  InputAdornment,
  Typography,
  TextField,
  MenuItem,
  Select,
  InputLabel,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Iconify from "../../../components/Iconify";
import {
  FormProvider,
  RHFTextField,
  RHFCheckbox,
} from "../../../components/hook-form";
import { User } from "../../../context/context";
import Page from "../../../components/Page";
import { useTheme } from "@mui/material/styles";
import axios from "axios";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, teacher, theme) {
  return {
    fontWeight:
      teacher?.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const UpdateTestSeries = () => {
  const navigate = useNavigate();
  // const { setRole } = useContext(User)
  const { testSeriesId } = useParams();
  const [category, setCategory] = React.useState("");
  const [type, setType] = React.useState("");
  const [testSeriesDetails, setTestSeriesDetails] = React.useState([]);
  const [loading, setLoading] = useState(true);
  const [streamCategory, setStreamCategory] = useState([]);
  const theme = useTheme();

  const LoginSchema = Yup.object().shape({});

  const defaultValues = {};

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  React.useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/getTestSeriesdetails/${testSeriesId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      setTestSeriesDetails(responseJson?.data);

      addDetails(responseJson?.data);
    }

    fetchUsers();
  }, []);
  //console.log(testSeriesDetails)

  //Helper Function

  const addDetails = (TestSeriesDetails) => {
    let arr2 = [];
    TestSeriesDetails?.teacher?.map((item) => {
      arr2.push(item?._id);
    });
    setteacher(arr2);
    setValue((prev) => ({
      ...prev,
      testseries_name: TestSeriesDetails?.testseries_name,
      discount: TestSeriesDetails?.discount,
      charges: TestSeriesDetails?.charges,
      link: TestSeriesDetails?.link,
      // no_of_test: TestSeriesDetails?.no_of_test,
      remark: TestSeriesDetails?.remark,
      description: TestSeriesDetails?.description,
      validity: TestSeriesDetails?.validity,
      category: TestSeriesDetails?.stream,
      starting_date: TestSeriesDetails?.starting_date,
      ending_date: TestSeriesDetails?.ending_date,
    }));
  };
  // //console.log(value0)
  //Teacher List
  const [teacher, setteacher] = useState([]);
  const [allteachers, teacherSet] = useState([]);
  const [val, setVal] = useState(false);
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminTeacher/teacherList`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      teacherSet(responseJson?.data);
      setLoading(false);
    }

    fetchUsers();
  }, []);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setteacher(
      // On autofill we get a stringified value?.
      typeof value === "string" ? value?.split(",") : value
    );
  };

  //Date
  const [focus, setFocused] = React.useState(false);
  const [showStartingDate, setShowStartingDate] = React.useState(false);
  const [focus1, setFocused1] = React.useState(false);
  const [showEndingDate, setShowEndingDate] = React.useState(false);

  //Stream
  useEffect(() => {
    const token = localStorage.getItem("token");
    const batch = "Batch";
    async function fetchUsers() {
      const fullResponseCategory = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/getCategory?type=Stream`,
        {
          method: "GET",

          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJsonCatergory = await fullResponseCategory.json();
      setStreamCategory(responseJsonCatergory?.data);

      setLoading(false);
    }

    fetchUsers();
  }, []);

  //Banner
  const [file, setFile] = useState();
  const [msg, setMsg] = useState("");
  const [focusThree, setFocusedThree] = React.useState(false);
  const [showBanner, setShowBanner] = React.useState(false);
  const changeHandler = async (event) => {
    setFile(event.target?.files[0]);
  };

  //Adding Details
  const onSubmit = async (data) => {
    //console.log('We Came Here')
    //console.log(data)

    setLoading(true);

    const formData = new FormData();

    if (file) {
      formData.append("file", file);
    }
    // teacher?.map()
    if (teacher) {
      teacher?.map((item) => formData.append("teacher", item));
      // batchDetails?.teacher?.map((item) => formData.append('teacher', item?._id))
    } else {
      testSeriesDetails?.teacher?.map((item) =>
        formData.append("teacher", item?._id)
      );
    }

    formData.append("testseries_name", value?.testseries_name);
    // formData.append('no_of_test', value?.no_of_test);
    formData.append("validity", value?.validity);
    formData.append("remark", value?.remark);
    formData.append("discount", value?.discount);
    formData.append("charges", value?.charges);
    formData.append("description", value?.description);
    formData.append("link", value?.link);

    // if (data?.testseries_name) { formData.append('testseries_name', data?.testseries_name); }
    // else { formData.append('testseries_name', testSeriesDetails?.testseries_name); }
    if (data?.exam_type) {
      formData.append("exam_type", data?.exam_type);
    } else {
      formData.append("exam_type", testSeriesDetails?.exam_type);
    }
    if (data?.category) {
      formData.append("stream", data?.category);
    } else {
      formData.append("stream", testSeriesDetails?.stream);
    }
    // if (data?.no_of_test) { formData.append('no_of_test', data?.no_of_test); }
    // else { formData.append('no_of_test', testSeriesDetails?.no_of_test); }
    if (data?.starting_date) {
      formData.append("starting_date", data?.starting_date);
    } else {
      formData.append("starting_date", testSeriesDetails?.starting_date);
    }
    if (data?.ending_date) {
      formData.append("ending_date", data?.ending_date);
    } else {
      formData.append("ending_date", testSeriesDetails?.ending_date);
    }
    // if (data?.discount) { formData.append('discount', data?.discount); }
    // else { formData.append('discount', testSeriesDetails?.discount); }
    // if (data?.charges) { formData.append('charges', data?.charges); }
    // else { formData.append('charges', testSeriesDetails?.charges); }
    if (data?.language) {
      formData.append("language", data?.language);
    } else {
      formData.append("language", testSeriesDetails?.language);
    }
    // if (data?.remark) { formData.append('remark', data?.remark); }
    // else { formData.append('remark', testSeriesDetails?.remark); }
    // if (data?.validity) { formData.append('validity', data?.validity); }
    // else { formData.append('validity', testSeriesDetails?.validity); }

    // if (data?.is_active) { formData.append('is_active', data?.is_active); }
    // else { formData.append('is_active', testSeriesDetails?.is_active); }
    if (typeof data?.is_active != "undefined") {
      formData.append("is_active", data?.is_active);
    } else {
      formData.append("is_active", testSeriesDetails?.is_active);
    }
    // formData.append('is_active' , data?.is_active || testSeriesDetails?.is_active);

    // for( const Pair of formData){
    //     console.log(Pair[0]+ " "+Pair[1]);
    // }
    // debugger;

    let token = localStorage.getItem("token");

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .put(`/adminPanel/UpdateTestSeries/${testSeriesId}`, formData, config)
      .then((response) => {
        if (response?.data?.msg === "updated  the TestSeries SuccessFully") {
          toast.success("Test Series Details Added");
          setShowBanner(false);
          setShowStartingDate(false);
          setLoading(false);
          setTimeout(() => {
            navigate("/dashboard/test");
          }, 1000);
          reset();
        } else {
          toast.error(response?.data?.msg);
          setLoading(false);
        }
      })
      .catch((e) => {
        //console.log(e);
      });
  };
  //console.log(testSeriesDetails?.testseries_name)
  const [value, setValue] = React.useState({
    testseries_name: "",
    charges: "",
    discount: "",
    remark: "",
    validity: "",
    no_of_test: "",
  });
  const [value2, setValue2] = React.useState("jhbj");
  const onChange2 = (e) => {
    const { name, value } = e.target;
    setValue((prev) => ({ ...prev, [name]: value }));
  };
  // onChange2()
  //console.log(value)
  return (
    <>
      <Page title="Test Series">
        <Stack spacing={3} ml={5}>
          <Typography variant="h3">Update Test series</Typography>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3} sx={{ width: "80%" }}>
              <RHFTextField
                name="testseries_name"
                label="Name"
                value={value?.testseries_name}
                onChange={onChange2}
              />
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ my: 2 }}
                spacing={2}
              >
                {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                <RHFTextField
                  // labelId="demo-simple-select-label"

                  label={testSeriesDetails?.stream || "Category"}
                  name="category"
                  select
                  sx={{ width: "100%" }}
                >
                  {streamCategory?.map((item, i) => {
                    return (
                      <MenuItem key={i} value={item?.title}>
                        {item?.title}
                      </MenuItem>
                    );
                  })}
                </RHFTextField>

                {/* <RHFTextField


                                select
                                name='exam_type'

                                label={testSeriesDetails?.exam_type}

                                sx={{ width: '100%' }}
                            >
                                <MenuItem value='Prelims'>Prelims</MenuItem>
                                <MenuItem value="Mains">Mains</MenuItem>
                                <MenuItem value='Interview'>Interview</MenuItem>
                            </RHFTextField> */}
              </Stack>
              <RHFTextField
                name="link"
                label="External Link"
                value={value?.link}
                onChange={onChange2}
              />
              <Stack direction="row">
                <InputLabel
                  sx={{
                    width: "20%",
                    marginRight: "20px",
                    alignSelf: "center",
                  }}
                >
                  Assign Teachers
                </InputLabel>
                <Stack direction="column" sx={{ width: "100%" }}>
                  <Select
                    // label    ="Name"
                    // id="demo-multiple-name"
                    // labelId="test-select-label"
                    label="Teacher"
                    multiple
                    error={teacher?.length == 0 && val}
                    name="teacher"
                    value={teacher}
                    onChange={handleChange}
                    sx={{ width: "100%", color: "black", zIndex: 9 }}
                    MenuProps={MenuProps}
                  >
                    {allteachers?.map((name) => (
                      <MenuItem
                        key={name}
                        value={name._id}
                        style={getStyles(name, teacher, theme)}
                      >
                        {name.FullName}
                      </MenuItem>
                    ))}
                  </Select>
                  {teacher?.length == 0 && (
                    <Typography
                      sx={{
                        fontSize: "12px",
                        marginTop: "5px",
                        marginLeft: "10px",
                        color: "red",
                      }}
                    >
                      Required
                    </Typography>
                  )}
                </Stack>
              </Stack>
              <Stack direction="row" spacing={3}>
                <InputLabel
                  sx={{
                    width: "20%",
                    marginRight: "20px",
                    alignSelf: "center",
                  }}
                >
                  Starting Date
                </InputLabel>
                <RHFTextField
                  name="starting_date"
                  onFocus={() => setFocused(true)}
                  onBlur={() => setFocused(false)}
                  InputProps={{
                    classes: {
                      input: "CustomTextField",
                    },
                  }}
                  label={testSeriesDetails?.starting_date}
                  onClick={() => setShowStartingDate(true)}
                  type={showStartingDate || focus ? "date" : "text"}
                />
                <RHFTextField
                  name="ending_date"
                  onFocus={() => setFocused1(true)}
                  onBlur={() => setFocused1(false)}
                  InputProps={{
                    classes: {
                      input: "CustomTextField",
                    },
                  }}
                  label={testSeriesDetails?.ending_date}
                  onClick={() => setShowEndingDate(true)}
                  type={showEndingDate || focus1 ? "date" : "text"}
                />
                <Stack direction="column" sx={{ width: "100%" }}>
                  <RHFTextField
                    name="file"
                    error={!file && val}
                    onChange={changeHandler}
                    onFocus={() => setFocusedThree(true)}
                    onBlur={() => setFocusedThree(false)}
                    InputProps={{
                      classes: {
                        input: "CustomTextField",
                      },
                    }}
                    label="Banner"
                    onClick={() => setShowBanner(true)}
                    type={showBanner || focusThree ? "file" : "text"}
                  />
                  {/* {!file && <Typography sx={{ fontSize: '12px', marginTop: '5px', marginLeft: '10px', color: 'red' }}>Banner Requied</Typography>} */}
                  {!file && (
                    <Typography
                      sx={{
                        fontSize: "12px",
                        marginTop: "5px",
                        marginLeft: "10px",
                        color: "red",
                      }}
                    >
                      {msg}
                    </Typography>
                  )}
                </Stack>
              </Stack>
              <Stack direction="row" spacing={3}>
                <RHFTextField
                  name="charges"
                  label="Regular Price"
                  value={value?.charges}
                  type="number"
                  onChange={onChange2}
                />
                <RHFTextField
                  name="discount"
                  label="Discount Price"
                  value={value?.discount}
                  type="number"
                  InputProps={{
                    inputProps: {
                      max: 100,
                      min: 0,
                    },
                  }}
                  onChange={onChange2}
                />
                {/*
                  <RHFTextField
                    name="language"
                    label={
                      testSeriesDetails?.language == "enhi"
                        ? "Hindi/English"
                        : testSeriesDetails?.language == "hi"
                          ? "Hindi"
                          : "English"
                    }
                    sx={{ width: "100%" }}
                    select
                  >
                    <MenuItem value="en">English</MenuItem>
                    <MenuItem value="hi">Hindi</MenuItem>
                    <MenuItem value="enhi">Bilungual</MenuItem>
                  </RHFTextField>
                  */ }
                {/* <RHFTextField name="stream" label={testSeriesDetails?.stream} select >
                                {streamCategory?.map((item, index) => {
                                    return <MenuItem key={index} value={item?.title} >{item?.title}</MenuItem>
                                })}
                            </RHFTextField> */}
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ my: 2 }}
                spacing={2}
              >
                {/* <RHFTextField name="no_of_test" label='No of Test' value={value?.no_of_test} type='number' onChange={onChange2} /> */}
                <RHFTextField
                  name="validity"
                  label="Validity"
                  value={value?.validity}
                  onChange={onChange2}
                />
                <RHFTextField
                  name="remark"
                  label="Remark"
                  value={value?.remark}
                  onChange={onChange2}
                />
                {/* <RHFTextField name="link" label='TestSeries Link' value={value?.link} onChange={onChange2} /> */}
                <RHFTextField
                  name="is_active"
                  label={testSeriesDetails?.is_active ? "Active" : "InActive"}
                  sx={{ width: "100%" }}
                  // value={testSeriesDetails?.is_active}
                  select
                  type="checkbox"
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </RHFTextField>
              </Stack>

              <RHFTextField
                name="description"
                value={value?.description}
                onChange={onChange2}
                multiline
                rows={4}
              />

              {/* <RHFTextField
                    name="password"
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                /> */}
            </Stack>

            {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                <RHFCheckbox name="remember" label="Remember me" />
                <Link variant="subtitle2" underline="hover" sx={{ color: theme.palette.primary.mainTheme }}>
                    Forgot password?
                </Link>
            </Stack> */}

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
              sx={{
                bgcolor: theme.palette.primary.mainTheme,
                width: "80%",
                marginTop: "5%",
                "&:hover": { background: "red" },
              }}
            >
              Update
            </LoadingButton>
          </FormProvider>
          <ToastContainer
            position="bottom-center"
            autoClose={1000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          {/* <Typography sx={{ color: 'red', textAlign: 'center' }}>{fetchMessage}</Typography> */}
        </Stack>
      </Page>
    </>
  );
};

export default UpdateTestSeries;
