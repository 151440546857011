import { IconButton } from "@mui/material";

import * as React from "react";
import { useParams } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";

import "./style.css";

import {
  GridRowModes,
  DataGrid,
  GridRowEditStopReasons,
  GridToolbar,
} from "@mui/x-data-grid";

import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

import SyncLoader from "react-spinners/SyncLoader";
import { useTheme } from "@emotion/react";
import { Stack } from "@mui/material";

const BatchDpp = () => {
  const theme = useTheme();
  const { Id } = useParams();
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    const token = localStorage.getItem("token");
    //Admin Side Lecture Details
    setLoading(true);
    async function fetchUsers() {
      const fullResponse = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/getAllDppByBatchId/${Id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      // usersSet(responseJson?.data);
      setLoading(false);
      setRows(responseJson?.data);
    }

    fetchUsers();
  }, []);
  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };
  //   const navigate = useNavigate()

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows?.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows?.filter((row) => row.id !== id));
    }
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const rowsWithSerialNumber = rows?.map((row, index) => ({
    ...row,
    serialNumber: index + 1,
  }));

  const columns = [
    {
      field: "serialNumber",
      headerName: "S NO",
      width: 75,
      align: "left",
      headerAlign: "left",
      editable: false,
      renderCell: (params) => <strong>{params.value}</strong>,
    },

    {
      field: "title",
      headerName: "Title",
      type: "string",
      width: 150,
      align: "left",
      headerAlign: "left",
      // editable: true
    },
    {
      field: "lecture_title",
      headerName: "Lecture",
      type: "string",
      width: 150,
      align: "left",
      headerAlign: "left",
      // editable: true
    },
    {
      field: "file",
      headerName: "Pdf",
      type: "object",
      width: 200,
      align: "left",
      headerAlign: "left",
      // editable: true,
      renderCell: (params) => (
        <IconButton
          href={params.value?.fileLoc}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none", color: "blue" }}
        >
          <PictureAsPdfIcon
            fontSize="large"
            style={{ color: "blue", marginRight: "8px" }}
          />
        </IconButton>
      ),
    },

    {
      field: "language",
      headerName: "Language",
      type: "number",
      width: 150,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => (
        <div>{params.value === "hi" ? "Hindi" : "English"}</div>
      ),
    },
  ];
  return (
    <div>
      {loading == false ? (
        <DataGrid
          // sx={{ overflow: "auto" }}
          rowHeight={50}
          rows={rowsWithSerialNumber || []}
          columns={columns || []}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          // processRowUpdate={processRowUpdate}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 8,
              },
            },
          }}
          pageSizeOptions={[8, 25, 50]}
          slots={{
            //   toolbar: EditToolbar,
            toolbar: GridToolbar,
          }}
          slotProps={{
            toolbar: {
              setRows,
              setRowModesModel,
              showQuickFilter: true,
            },
          }}
        />
      ) : (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          {" "}
          <SyncLoader
            color={theme.palette.primary.mainTheme}
            loading={loading}
            size={20}
          />
        </Stack>
      )}
    </div>
  );
};

export default BatchDpp;
