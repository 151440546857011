import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Stack,
  IconButton,
  InputAdornment,
  Typography,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components

import { FormProvider, RHFTextField } from "../../../components/hook-form";
import RHFTextEditor from "../../../components/hook-form/RHFTextEditor";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import makeAnimated from "react-select/animated";

// import "./general.css";
import { useTheme } from "@emotion/react";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// ----------------------------------------------------------------------

export default function AddCenterAnnouncements() {
  const theme = useTheme();
  const animatedComponents = makeAnimated();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [restrict, setRestrict] = useState("");
  const [focus, setFocused] = useState(false);
  const [pdf, setPdf] = useState(false);
  const [msg, setMsg] = useState("");
  const [val, setVal] = useState(false);
  const [type, setType] = useState("none");
  const [valueId, setValueId] = useState("");
  const [list, setList] = useState([]);
  const [batches, setBatches] = useState([]);
  const [selectedBatches, setSelectedBatches] = useState([]);

  const RegisterSchema = Yup.object().shape({
    title: Yup.string().required("Title required"),
    description: Yup.string().required("Description required"),
    isActive: Yup.boolean().required("Status Required"),
  });

  const defaultValues = {
    title: "",
    description: "",

    isActive: true,
  };
  const [file, setFile] = useState();

  const handleChange = (value) => {
    //console.log(`${value}`);
  };
  const changeHandler = async (event) => {
    setFile(event.target?.files);
    var fileName = document.getElementById("file").value?.toLowerCase();
    if (fileName.endsWith(".pdf") || fileName.endsWith(".jpg")) {
      setRestrict("");
    } else {
      setRestrict("JPG Or PDF");
    }
    //console.log(file)
  };
  const handleChangeBatches = (data) => {
    // const {
    //   target: { value },
    // } = event;
    // setSelectedBatches(
    //   typeof value === 'string' ? value?.split(',') : value,
    // );
    setSelectedBatches(data);
  };
  //console.log(file)
  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const [progress, setProgress] = useState();
  // for progress bar

  const onSubmit = async (data) => {
    console.log(data);

    setLoading(true);
    // debugger;
    //console.log(file)

    // formData.append('fileName', file.name);
    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    let reqObject = {
      title: data?.title,
      description: data?.description,
      link: "batch",
      batches: selectedBatches?.map((item) => item?._id),
      isActive: data?.isActive,
    };
    // console.log(reqObject);
    // document.querySelector(".mainDiv").style.display = "block";
    setProgress(0);
    axios
      .post(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/createAnnouncementForBatches`,
        reqObject,
        config
      )
      .then((response) => {
        //console.log(response?.data);
        if (
          response?.data?.msg ===
          "New Announcement created successfully for batches"
        ) {
          toast.success(response?.data?.msg);
          setLoading(false);
          setPdf(false);
          reset();
          // window.location.href = "/dashboard/announcements";
          window.location.reload();
        } else {
          toast.error(`SomeThing went wrong`);
          setLoading(false);
        }
      })
      .catch((e) => {
        //console.log(e);
      });

    // } else {

    //     setRestrict('Please Select pdf or jpg')
    // }
  };

  //get batch or get testSeries
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchBatches() {
      const fullResponse = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/getBatches`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      let batchesData = responseJson?.data?.filter(
        (item) => item?.is_active == true
      );
      // setBatches(responseJson?.data);
      setBatches(batchesData);

      // setLoading(false)
    }
    fetchBatches();
    setLoading(false);
  }, []);

  return (
    <>
      <Stack spacing={3} px={5}>
        <Typography variant="h3">Add Announcement For Batches</Typography>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} sx={{ width: "70%" }}>
            <RHFTextField name="title" label="Title" />
            <Stack direction="row">
              <InputLabel
                sx={{ width: "20%", marginRight: "20px", alignSelf: "center" }}
              >
                Select Batches
              </InputLabel>
              <Stack direction="column" sx={{ width: "100%" }}>
                {/* <Select
                label=""
                error={selectedBatches.length == 0 && val}
                multiple
                name='batches'
                value={selectedBatches}
                onChange={handleChangeBatches}
                sx={{ width: '100%', color: 'black', zIndex: 9 }}
                MenuProps={MenuProps}
              >
                {batches?.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item?._id}>
                      {item?.batch_name}
                    </MenuItem>

                  );

                })}
              </Select> */}
                <Select
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  value={selectedBatches}
                  onChange={handleChangeBatches}
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  //  defaultValue={[colourOptions[4], colourOptions[5]]}
                  isMulti
                  options={batches}
                />

                {selectedBatches.length == 0 && (
                  <Typography
                    sx={{
                      fontSize: "12px",
                      marginTop: "5px",
                      marginLeft: "10px",
                      color: "red",
                    }}
                  >
                    {msg}
                  </Typography>
                )}
              </Stack>
            </Stack>

            <RHFTextField name="description" label="Description" />
            {/* <Stack direction="row" spacing={3} >
          
        </Stack> */}

            <Stack direction="row" spacing={3}>
              <RHFTextField
                name="isActive"
                label="Status"
                type="checkbox"
                select
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </RHFTextField>
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
              sx={{
                bgcolor: theme.palette.primary.mainTheme,
                marginTop: "5%",
                "&:hover": { background: "red" },
              }}
              onClick={() => {
                setVal(true);
                setMsg("Required");
              }}
            >
              Add
            </LoadingButton>
          </Stack>
        </FormProvider>
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {/* <Typography color={positive ? "green" : "red"} sx={{ textAlign: 'center', width: '80%' }}>{fetchMessage}</Typography> */}
      </Stack>
    </>
  );
}
