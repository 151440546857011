import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AddTeacherForm from '../register/AddTeacherForm';
import Student from '../../../pages/User';
import AddNewsClip from './AddNewsClip';
import NewsClipTable from './NewsClipTable';
import DeleteIcon from '@mui/icons-material/Delete';

import EditIcon from '@mui/icons-material/Edit';
import { Divider, Stack } from '@mui/material';
import getAccess from '../../../utils/getAccess';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const NewsClip = () => {
    const access = getAccess();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Typography variant='h3' mb={3}  >Manage News Clips</Typography>
            <Divider />
            <Box sx={{ borderBottom: 1, borderColor: 'divider', }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" sx={{ width: '100%', }}>
                    <Tab label="All News Clips" sx={{ width: '100%' }} {...a11yProps(0)} />
                    {(access?.access?.includes('666') || access?.access.includes('all')) && (<Tab label="Add News Clip" sx={{ width: '100%' }} {...a11yProps(1)} />)}
                    {/* <Tab label="Item Three" sx={{ width: '100%' }} {...a11yProps(2)} /> */}
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <NewsClipTable />
            </TabPanel>
            {(access?.access?.includes('666') || access?.access.includes('all')) && (<TabPanel value={value} index={1}>
                <AddNewsClip />
            </TabPanel>)}

        </Box >
    );
}

export default NewsClip