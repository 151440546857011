import { yupResolver } from "@hookform/resolvers/yup";
import { Typography, Stack, MenuItem, InputLabel } from "@mui/material";
import * as React from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { FormProvider, RHFTextField } from "../../../components/hook-form";
import Select from "react-select";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import { useTheme } from "@emotion/react";
import { toast, ToastContainer } from "react-toastify";

const AddNotification = () => {
  const theme = useTheme();
  const [loading, setLoading] = React.useState(false);
  const [type1, setType1] = React.useState();
  const [list, setList] = React.useState([]);

  const [type2, setType2] = React.useState("");
  const [list2, setList2] = React.useState([]);
  const [selectedValue, setSelectedValue] = React.useState([]);
  const [value2, setValue2] = React.useState();
  const [value1, setValue1] = React.useState();

  React.useEffect(() => {
    // setLoading(true);
    setValue1([]);
    setList([]);
    async function fetchList() {
      let endUrl =
        type1 == "batch"
          ? `adminPanel/getBatches`
          : type1 == "category"
          ? "adminPanel/getCategoryListByAdmin"
          : "adminPanel/getCategoryListByAdmin";
      const token = localStorage.getItem("token");
      let config = {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(
        `https://api-prod.gyaankulam.com/api/v1/${endUrl}`,
        config
      );
      setList(response?.data?.data ?? []);
    }
    if (type1 != "" && type1 != "all") {
      fetchList();
    }
  }, [type1]);

  React.useEffect(() => {
    // setLoading(true);
    setValue2("");
    setList2([]);
    async function fetchList() {
      let endUrl =
        type2 == "ytvideosbyid"
          ? `notification/getYTVideos`
          : type2 == "feedById"
          ? "notification/getFeeds"
          : type2 == "dailyQuizbyid"
          ? "notification/getDailyQuiz"
          : `adminPanel/getBatches`;
      //  'notification/getFeeds'
      //  type2 =="" ? 'notification/getFeeds' : 'notification/getFeeds'
      const token = localStorage.getItem("token");
      let config = {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(
        `https://api-prod.gyaankulam.com/api/v1/${endUrl}`,
        config
      );
      setList2(response?.data?.data ?? []);
    }
    if (
      type2 == "ytvideosbyid" ||
      type2 == "feedById" ||
      type2 == "batchbyid" ||
      type2 == "mybatchbyid" ||
      type2 == "dailyQuizbyid"
    ) {
      fetchList();
    }
  }, [type2]);

  const NotificationSchema = Yup.object().shape({
    title: Yup.string().required("Title is Required"),
    userType: Yup.string().required("User Type Required"),
    // message : Yup.string().required('Message is Required'),
    notificationType: Yup.string().required("Notiifcation Type Required"),
    isActive: Yup.boolean().required("Status is required"),
    externalLink: Yup.string().when("notificationType", {
      is: (notificationType) => notificationType == "openLink",
      then: Yup.string()
        .required("External Url Required")
        .matches(
          /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
          "Enter correct url!"
        ),
      otherwise: Yup.string().notRequired(),
    }),
  });

  const defaultValues = {
    title: "",
    message: "",
    isActive:true,
  };

  const methods = useForm({
    resolver: yupResolver(NotificationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const [file, setFile] = React.useState();
  const [restrict, setRestrict] = React.useState("");
  const [focus, setFocused] = React.useState(false);
  const [pdf, setPdf] = React.useState(false);
  const [msg, setMsg] = React.useState("");
  const [val, setVal] = React.useState(false);
  const [message, setMessage] = React.useState("");

  const handleMessage = (e) => {
    setMessage(e.target?.value);
  };

  const handleValue1 = (newValue) => {
    setValue1(newValue);
  };
  const handleValue2 = (newValue) => {
    setValue2(newValue);
  };

  const changeHandler = async (event) => {
    let size = event?.target?.files[0]?.size / 1024;
    if (size > 100) {
      setVal(true);
    } else {
      setFile(event?.target?.files[0]);
      var fileName = document.getElementById("file").value?.toLowerCase();
      if (fileName.endsWith(".pdf") || fileName.endsWith(".jpg")) {
        setRestrict("");
      } else {
        setRestrict("JPG Or PDF");
      }
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);
    let formData = new FormData();
    // formData.append('title')
    if (file) {
      formData.append("file", file);
    }
    let data1 = {
      title: data?.title ?? "",
      message: message ?? "",
      route: type2 ?? "",
      rootId: value2?._id ? value2?._id : "",
      childId: "",
      linkUrl: value2 ?? "",
    };
    formData.append("title", data?.title);
    formData.append("message", message);
    formData.append("route", type2);
    formData.append("rootId", value2 ? value2?._id : "");
    formData.append("childId", "");
    formData.append("linkUrl", type2 == "openLink" ? data?.externalLink : "");
    // formData.append("")

    // formData.append('data' , data1);
    if (type2 == "mybatchbyid") {
      formData.append("userType", "batch");
      formData.append("linkWith", value2?._id);
      formData.append("linkWith", "");
    } else {
      formData.append("userType", data?.userType);
      if (value1?.length > 1) {
        value1?.map((item) => {
          formData.append("linkWith", item?._id);
        });
      } else if (value1?.length == 1) {
        formData.append("linkWith", value1[0]?._id);
        formData.append("linkWith", "");
      }

      // formData.append('linkWith' , "")
    }

    formData.append("isActive", data?.isActive);

    // for( let Pair of formData){
    //   console.log(Pair[0] + " " + Pair[1])
    // }
    // console.log(data1)
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.post(
      `https://api-prod.gyaankulam.com/api/v1/notification/createNotification`,
      formData,
      config
    );
    if (response?.data?.status == true) {
      setLoading(false);
      toast.success(response?.data?.msg);
      window.location.href = "/dashboard/notification";
    } else {
      setLoading(false);
      toast.error(response?.data?.msg);
    }
  };
  return (
    // <div>AddNotification</div>
    <Stack spacing={3} px={5}>
      {/* <Typography>
          Fill The Details for NOtification
       </Typography> */}
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3} sx={{ width: "70%" }}>
          <Stack direction="row" spacing={2}>
            <Stack direction="row" sx={{ width: "100%" }}>
              <InputLabel
                sx={{ width: "16%", marginRight: "20px", alignSelf: "center" }}
              >
                Title* :{" "}
              </InputLabel>
              <Stack sx={{ width: "87%" }}>
                <RHFTextField name="title" label="Enter Title" />
              </Stack>
            </Stack>

            <RHFTextField
              name="file"
              onChange={changeHandler}
              id="file"
              label="File"
              // error={!file && val}
              helperText="Image Only (Maximum 100KB allowed)"
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              // inputProps={{ multiple: true }}
              InputProps={{
                classes: {
                  input: "CustomTextField",
                },
              }}
              onClick={() => setPdf(true)}
              inputProps={{ accept: "image/*" }}
              type={pdf || focus ? "file" : "text"}
            />
          </Stack>
          <Stack direction="row" sx={{ width: "100%" }}>
            <InputLabel
              sx={{ width: "17%", marginRight: "20px", alignSelf: "center" }}
            >
              Message* :{" "}
            </InputLabel>
            <Stack sx={{ width: "83%" }}>
              <textarea
                name="message"
                rows="4"
                cols="50"
                value={message}
                onChange={handleMessage}
              />
            </Stack>
            {/* <textarea  name="message" rows="4" cols="50" value={message} onChange={handleMessage}/>  */}
            {/* <RHFTextField name="batch_name" value={value?.batch_name} onChange={onChange2} /> */}
          </Stack>
          <RHFTextField name="userType" label="Select Which User type " select>
            <MenuItem value="all" onClick={() => setType1("all")}>
              All
            </MenuItem>
            {/* <MenuItem value="mybatch" onClick={() => setType1("mybatch")}>Paid Batch</MenuItem> */}
            {/* <MenuItem value="batch" onClick={() => setType1("batch")}> Batch</MenuItem> */}
            <MenuItem value="category" onClick={() => setType1("category")}>
              Category
            </MenuItem>
            <MenuItem
              value="currentCategory"
              onClick={() => setType1("currentCategory")}
            >
              Current Category
            </MenuItem>
            {/* <MenuItem value="">All</MenuItem>
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="all">All</MenuItem> */}
          </RHFTextField>
          {type1 != "" && type1 != "all" && (
            <Select
              isMulti
              options={list}
              placeholder="Please Select Categories"
              closeMenuOnSelect={false}
              value={value1}
              onChange={handleValue1}
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            />
          )}
          <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
            <Stack
              sx={{
                width:
                  type2 == "ytvideosbyid" ||
                  type2 == "feedById" ||
                  type2 == "mybatchbyid" ||
                  type2 == "batchbyid" ||
                  type2 == "dailyQuizbyid" ||
                  type2 == "openLink"
                    ? "50%"
                    : "100%",
              }}
            >
              <RHFTextField
                name="notificationType"
                label="Notification Type"
                select
              >
                {/* <MenuItem value="none" onClick={() => setType1('none')}>None</MenuItem> */}
                <MenuItem value="openLink" onClick={() => setType2("openLink")}>
                  External Link
                </MenuItem>
                <MenuItem value="feed" onClick={() => setType2("feed")}>
                  Feed
                </MenuItem>
                <MenuItem value="ytvideos" onClick={() => setType2("ytvideos")}>
                  YT Videos
                </MenuItem>
                <MenuItem value="pyqs" onClick={() => setType2("pyqs")}>
                  PYQs
                </MenuItem>
                <MenuItem value="news" onClick={() => setType2("news")}>
                  News
                </MenuItem>
                <MenuItem value="syllabus" onClick={() => setType2("syllabus")}>
                  Syllabus
                </MenuItem>

                <MenuItem
                  value="ytvideosbyid"
                  onClick={() => setType2("ytvideosbyid")}
                >
                  YT Video Particular
                </MenuItem>
                <MenuItem value="feedById" onClick={() => setType2("feedById")}>
                  Feed Particular
                </MenuItem>

                <MenuItem
                  value="dailyQuizbyid"
                  onClick={() => setType2("dailyQuizbyid")}
                >
                  Quiz
                </MenuItem>
                {/* <MenuItem value="batch" onClick={() => setType2('batch') } >UnPaid Batch(All)</MenuItem>  */}
                <MenuItem
                  value="batchbyid"
                  onClick={() => setType2("batchbyid")}
                >
                  UnPaid Batch
                </MenuItem>
                {/* <MenuItem value="myBatch" onClick={() => setType2('myBatch')}>Paid Batch(All)</MenuItem> */}
                {/* <MenuItem value="myBatch" onClick={() => setType2('myBatch')}>Paid Batch(All)</MenuItem> */}
                <MenuItem
                  value="mybatchbyid"
                  onClick={() => setType2("mybatchbyid")}
                >
                  Paid Batch{" "}
                </MenuItem>
                {/* <MenuItem value="news" onClick={() => setType2("news")}>News</MenuItem>
                <MenuItem value="news" onClick={() => setType2("news")}>News</MenuItem>
                <MenuItem value="news" onClick={() => setType2("news")}>News</MenuItem> */}
                {/* <MenuItem value={""}></MenuItem> */}
              </RHFTextField>
            </Stack>

            {(type2 == "ytvideosbyid" ||
              type2 == "feedById" ||
              type2 == "mybatchbyid" ||
              type2 == "batchbyid" ||
              type2 == "dailyQuizbyid") && (
              <Stack sx={{ width: "50%" }}>
                <Select
                  isMulti={false}
                  options={list2}
                  closeMenuOnSelect={false}
                  value={value2}
                  onChange={handleValue2}
                  // onChange={handleChangeBatches}
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                />
              </Stack>
            )}
            {type2 == "openLink" && (
              <Stack sx={{ width: "50%" }}>
                <RHFTextField name="externalLink" label="Enter External Link" />
              </Stack>
            )}
          </Stack>

          <RHFTextField name="isActive" label="Status" type="checkbox" select>
            <MenuItem value={true}>Active</MenuItem>
            <MenuItem value={false}>Inactive</MenuItem>
          </RHFTextField>

          {/* <div className="mainDiv">
                        <div className="childDiv" style={{ width: `${progress}%` }}>
                            <span> </span>
                        </div>
                    </div> */}

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={loading}
            sx={{
              bgcolor: theme.palette.primary.mainTheme,
              marginTop: "5%",
              "&:hover": { background: "red" },
            }}
            onClick={() => {
              setVal(true);
              setMsg("Required");
            }}
          >
            Add
          </LoadingButton>
        </Stack>
      </FormProvider>
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        deProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Stack>
  );
};

export default AddNotification;
