import { Divider, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTheme } from "@emotion/react";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import Modal from "@mui/material/Modal";
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  GridToolbarDensitySelector,
  useGridApiContext,
  gridExpandedSortedRowIdsSelector,
} from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import { Avatar, Box } from "@mui/material";
import CancelIcon from "@mui/icons-material/Close";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SaveIcon from "@mui/icons-material/Save";
// import CancelIcon from "@mui/icons-material/Close";
import { VisibilityOutlined } from "@mui/icons-material";
import getAccess from "../../../utils/getAccess";

function CustomToolbar({ rows, columns, ...gridProps }) {
  const { setQuickFilter, ...otherProps } = gridProps;
  const apiRef = useGridApiContext();
  const exportAllRows = async () => {
    const visibleRows = gridExpandedSortedRowIdsSelector(apiRef);
    const filteredRows = rows?.filter((row) => visibleRows.includes(row.id));
    const columnIds = columns
      ?.filter((column) => column.field !== "actions")
      ?.map((column) => column.field);
    const csvHeader = columnIds.join(", ");
    const csvContent = filteredRows
      ?.map((row, index) => {
        const rowValues = columnIds?.map((columnId) => {
          if (columnId === "namePhoto") {
            return `${row[columnId]?.name}`;
          } else if (columnId === "serialNumber") {
            return index + 1;
          }
          return row[columnId]?.toString()?.replaceAll(",", " ");
        });
        return rowValues.join(", ");
      })
      .join("\n");
    const fullCsvContent = `${csvHeader}\n${csvContent}`;
    const blob = new Blob([fullCsvContent], { type: `text/csv;charset=utf-8` });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", "StudentPurchaseList.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />

      <GridToolbarDensitySelector />
      <Tooltip title="Export All">
        <IconButton onClick={exportAllRows}>
          <SaveAltIcon style={{ color: "red" }} />
        </IconButton>
      </Tooltip>
      <GridToolbarQuickFilter style={{ marginLeft: "auto" }} />
    </GridToolbarContainer>
  );
}

const StudentList1 = () => {
  const theme = useTheme();
  const { batchId } = useParams();
  const d = getAccess();

  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  //  table thind
  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows?.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows?.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows?.map((row) => (row.id === newRow.id ? updatedRow : row)));
    //   editDataInDb(newRow)
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    borderRadius: 4,
  };

  const removeStudent = async (user_id) => {
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/removeStudentBatch/${user_id}/${batchId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response?.ok) {
        throw new Error("Network response was not ok");
      }

      toast.success("Deleted");
      setRows(rows?.filter((row) => row.user_id !== user_id));
      //   setTimeout(() => window.location.reload()
      //     , 1000)
    } catch (error) {
      toast.error(error);
    }
  };

  const [openModel, setOpenModel] = useState(false);
  const [emiDetails, setEmiDetails] = useState({});
  const handleClose = () => {
    setOpenModel(false);
  };

  const handleEmi = async (user_id) => {
    //
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/getCourseOrder?userId=${user_id}&batchId=${batchId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response?.ok) {
        throw new Error("Network response was not ok");
      }
      const responseJson = await response?.json();

      // console.log(responseJson)
      toast.success(responseJson?.msg);
      setEmiDetails(responseJson?.data);
      // setRows(rows?.filter((row) => row.user_id !== user_id));
      //   setTimeout(() => window.location.reload()
      //     , 1000)
    } catch (error) {
      toast.error(error);
    }
  };

  const columns = [
    {
      field: "sNo",
      headerName: "S NO",
      width: 75,
      align: "left",
      headerAlign: "left",
      editable: false,
      renderCell: (params) => <strong>{params.value}</strong>,
    },

    {
      field: "namePhoto",
      headerName: "Student",
      type: "string",
      width: 220,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => (
        <Stack direction="row" alignItems="center" spacing={2}>
          <Avatar
            alt={params?.value?.name}
            src={params?.value?.profilePhoto}
            sx={{ width: 28, height: 28 }}
          />
          <Typography variant="subtitle2" noWrap>
            {params?.value?.name}
          </Typography>
        </Stack>
      ),
    },
    {
      field: "mobileNumber",
      headerName: "Mobile",
      type: "string",
      width: 100,
      align: "left",
      headerAlign: "left",
      // editable: true,
    },
    {
      field: "email",
      headerName: "Email",
      type: "string",
      width: 280,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => {
        <span>
          {params?.value === "user@gmail.com" ? "NA" : params?.value}
        </span>;
      },
    },
    {
      field: "username",
      headerName: "UserName",
      type: "string",
      width: 250,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "isEmi",
      headerName: "Emi",
      type: "boolean",
      width: 100,
      align: "left",
      headerAlign: "left",
      // editable: true,
      renderCell: (params) => (
        <div style={{ color: params.value == true ? "green" : "red" }}>
          {params.value ? "With Emi" : "With Out EMI"}
        </div>
      ),
    },
    {
      field: "nextInstallmentDate",
      headerName: "Next Installment Date",
      type: "string",
      width: 100,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "pendingInstallment",
      headerName: "Pending Installment",
      type: "string",
      width: 100,
      align: "left",
      headerAlign: "left",
    },

    {
      field: "is_active",
      headerName: "Status",
      type: "boolean",
      width: 100,
      align: "left",
      headerAlign: "left",
      editable: true,
      renderCell: (params) => (
        <div style={{ color: params.value == true ? "green" : "red" }}>
          {params.value ? "Active" : "inActive"}
        </div>
      ),
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 200,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        const rowData = rows?.find((item) => item?.id === id);
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          d.accessForTab == "read" ||
          d?.access.includes("all") ||
          d.accessForTab == "readWrite" ? (
            <Tooltip title="View">
              <GridActionsCellItem
                icon={<VisibilityOutlined />}
                label="Edit"
                className="textPrimary"
                // onClick={handleEditClick(id)}
                onClick={() =>
                  navigate(
                    `/dashboard/students/studentsProfile/${rowData?.user_id}`
                  )
                }
                color="inherit"
              />
            </Tooltip>
          ) : (
            <></>
          ),
          d?.accessForTab == "readWrite" ||
          (d?.access.includes("all") && rowData?.is_active) ? (
            <Tooltip title="Assign Student">
              <GridActionsCellItem
                icon={<DeleteIcon style={{ color: "red" }} />}
                label="Edit"
                className="textPrimary"
                // onClick={handleEditClick(id)}
                onClick={() => {
                  removeStudent(rowData?.user_id);
                }}
                color="inherit"
              />
            </Tooltip>
          ) : (
            <></>
          ),
          (d.accessForTab == "read" ||
            d?.access.includes("all") ||
            d.accessForTab == "readWrite") &&
          rowData?.isEmi ? (
            <Tooltip title="View">
              <GridActionsCellItem
                icon={<PriceChangeIcon sx={{ color: "blue" }} />}
                label="Edit"
                className="textPrimary"
                // onClick={handleEditClick(id)}
                onClick={() => {
                  setOpenModel(true);
                  handleEmi(rowData?.user_id);
                }}
                color="inherit"
              />
            </Tooltip>
          ) : (
            <></>
          ),
        ];
      },
    },
  ];

  //BatchesDetails
  useEffect(() => {
    const token = localStorage.getItem("token");
    setLoading(true);
    async function fetchUsers() {
      const fullResponse = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/getPaidStudentOfBatch/${batchId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      //console.log(responseJson?.data);
      //   setBatchInfo(responseJson?.data);
      setRows(responseJson?.data);
      setLoading(false);
    }
    fetchUsers();
  }, []);
  //console.log(batchInfo)
  return (
    <>
      <Typography variant="h3" mb={5} ml={3}>
        All Student of Batch
      </Typography>
      <DataGrid
        autoHeight={true}
        getRowHeight={() => "auto"}
        rows={rows}
        loading={loading}
        columns={columns || []}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        processRowUpdate={processRowUpdate}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10, 50, 100]}
        slots={{
          toolbar: (gridProps) => (
            <CustomToolbar {...gridProps} rows={rows} columns={columns || []} />
          ),
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel, showQuickFilter: true },
        }}
      />
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <Modal
        open={openModel}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: 3,
            }}
          >
            <h3>EMI Details</h3>
            <IconButton
              onClick={() => {
                handleClose();
                setEmiDetails({});
              }}
            >
              <CancelIcon />
            </IconButton>
          </div>
          <Divider />
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body1" mr={2}>
              Selected Plan :
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {emiDetails?.noOfInstallments} months
            </Typography>
          </div>
          <Divider />
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body1" mr={2}>
              Total Amount :
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {emiDetails?.totalAmount}
            </Typography>
          </div>
          <Divider />
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body1" mr={2}>
              Pending Installment :
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {emiDetails?.pendingInstallment}
            </Typography>
          </div>
          <Divider />
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body1" mr={2}>
              Pending Amount :
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {emiDetails?.pendingAmount}
            </Typography>
          </div>
          <Divider />
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body1" mr={2}>
              Each Installment Amount :
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {emiDetails?.eachInstallmentAmount}
            </Typography>
          </div>
          <Divider />
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body1" mr={2}>
              Next Installment Date :
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {emiDetails?.nextInstallmentDate}
            </Typography>
          </div>
          <Divider />
        </Box>
      </Modal>
    </>
  );
};

export default StudentList1;
