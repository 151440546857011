import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Stack,
  IconButton,
  InputAdornment,
  Typography,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components

import { FormProvider, RHFTextField } from "../../../components/hook-form";
import RHFTextEditor from "../../../components/hook-form/RHFTextEditor";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import MyCkEditor from "../../../components/hook-form/MyCkEditor";
// import "./general.css";
import { useTheme } from "@emotion/react";

// ----------------------------------------------------------------------

export default function AddCommunity() {
  const theme = useTheme();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [pdf, setPdf] = useState(false);
  const [msg, setMsg] = useState("");
  const [val, setVal] = useState("");
  const [file, setFile] = useState("");
  const [restrict, setRestrict] = useState("");
  const [dataForDesc, setDataForDesc] = useState("");
  const [focus, setFocused] = useState("");
  const RegisterSchema = Yup.object().shape({
    title: Yup.string().required("Title required"),
    // description: Yup.string().required('Description required'),
    isActive: Yup.boolean().required("Status Required"),
  });

  const defaultValues = {
    title: "",
    // description: '',
    tags: "",
    isActive: "",
  };
  const changeHandler = async (event) => {
    let size = event.target?.files[0].size / (1024 * 1024);
    if (size > 1) {
      setVal(true);
      // setMsg("Maximum banner size 1MB allowed")
    } else {
      setFile(event.target?.files);
      var fileName = document.getElementById("file").value?.toLowerCase();
      if (fileName.endsWith(".jpg")) {
        setRestrict("");
      } else {
        setRestrict("JPG");
      }
    }

    //console.log(file)
  };
  //console.log(file)
  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const [progress, setProgress] = useState();
  // for progress bar

  const onSubmit = async (data) => {
    const formData = new FormData();

    setLoading(true);
    // formData.append('file', file);
    Object.values(file).forEach((file) => {
      formData.append("file", file);
    });
    formData.append("title", data?.title);
    formData.append("desc", dataForDesc);
    formData.append("language", data?.language);
    formData.append("tags", data?.tags);
    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${authToken}`,
      },
    };

    setProgress(0);
    axios
      .post(
        `https://api-prod.gyaankulam.com/api/v1/community/addCMSPost`,
        formData,
        config
      )
      .then((response) => {
        //console.log(response?.data);
        if (response?.data?.msg === "New Post added successfully") {
          toast.success(response?.data?.msg);
          setLoading(false);
          reset();
          window.location.href = "/dashboard/community";
        } else {
          toast.error("Community not created");
          setLoading(false);
        }
      })
      .catch((e) => {});
  };

  return (
    <>
      <Stack spacing={3} px={5}>
        {/* <Typography variant='h3'>Add Store Banner</Typography> */}
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} sx={{ width: "70%" }}>
            <RHFTextField name="title" label="Title" />
            <Stack direction="row" spacing={3}>
              <RHFTextField
                name="file"
                onChange={changeHandler}
                id="file"
                label="File"
                error={!file && val}
                helperText="Image Only ( Maximum allowed size 1MB)"
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                // inputProps={{ multiple: true }}
                InputProps={{
                  classes: {
                    input: "CustomTextField",
                  },
                }}
                onClick={() => setPdf(true)}
                inputProps={{ accept: "image/*" }}
                type={pdf || focus ? "file" : "text"}
              />
              <RHFTextField name="tags" label="Tag" select>
                <MenuItem value="news">News</MenuItem>
                <MenuItem value="blog">Blog</MenuItem>
                <MenuItem value="notification">Notification</MenuItem>
              </RHFTextField>
            </Stack>
            <Stack direction="column">
              <Typography>Description</Typography>
              <MyCkEditor
                dataForDesc={dataForDesc}
                setDataForDesc={setDataForDesc}
              />
            </Stack>

            <Stack direction="row" spacing={3}>
              <RHFTextField name="language" label="Language" select>
                <MenuItem value="en">English</MenuItem>
                <MenuItem value="hi">Hindi</MenuItem>
                <MenuItem value="enhi">Bilingual</MenuItem>
              </RHFTextField>
              <RHFTextField
                name="isActive"
                label="Status"
                type="checkbox"
                select
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </RHFTextField>
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
              sx={{
                bgcolor: theme.palette.primary.mainTheme,
                marginTop: "5%",
                "&:hover": { background: "red" },
              }}
              onClick={() => {
                //   setVal(true)
                setMsg("Required");
              }}
            >
              Add
            </LoadingButton>
          </Stack>
        </FormProvider>
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {/* <Typography color={positive ? "green" : "red"} sx={{ textAlign: 'center', width: '80%' }}>{fetchMessage}</Typography> */}
      </Stack>
    </>
  );
}
