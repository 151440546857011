import React, { useEffect } from "react";
import * as Yup from "yup";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import axios from "axios";

// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Stack, Typography, MenuItem, Grid, IconButton } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { FormProvider, RHFTextField } from "../../../components/hook-form";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTheme } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";
const UpdateCategory = ({ editData }) => {
  const theme = useTheme();
  const { id } = useParams();
  const navigate = useNavigate();
  const [focus, setFocused] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState("");
  const LoginSchema = Yup.object().shape({});
  const defaultValues = {};
  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const [file, setFile] = useState();

  const changeHandler = async (event) => {
    setFile(event.target?.files[0]);
  };
  const [value, setValue] = useState();
  const [showImage, setShowImage] = useState();
  const handleDelete = () => {
    setShowImage(false);
    setFile("");
  };
  const [parentCategory, setParentCategory] = useState({});
  const [category, setCategory] = useState([]);
  const [categoryOrder, setCategoryOrder] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/store/getProductCategoryByIdAdmin/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      setValue((prev) => ({
        ...prev,
        title: responseJson?.data?.title,
        icon: responseJson?.data?.icon,
        parentCategory: responseJson?.data?.parentCategory?.title,
        order: responseJson?.data?.order,
        isActive: responseJson?.data?.isActive,
      }));
      if (responseJson?.data?.parentCategory?.title == "") {
        setParentCategory({ id: null, title: "none" });
      } else {
        setParentCategory(responseJson?.data?.parentCategory);
      }

      setOrder(responseJson?.data?.order);
      if (
        responseJson?.data?.parentCategory?.title == "" &&
        responseJson?.data?.icon != ""
      ) {
        setShowImage(true);
      }
    }
    async function fetchCategory() {
      const response = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/store/getAllStoreProductCategory`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await response?.json();
      setCategory(responseJson?.data);
      let list = Array.from(
        { length: responseJson?.data?.length },
        (_, index) => index + 1
      );
      setCategoryOrder(list);
      setLoading(false);
    }
    fetchUsers();
    fetchCategory();
  }, []);
  const onSubmit = async (data) => {
    setLoading(true);
    const formData = new FormData();
    if (file && parentCategory?.id == null) {
      formData.append("file", file);
    }

    if (!data?.title) {
      formData.append("title", value?.title);
    }
    formData.append("parentCategory", parentCategory?.id);
    formData.append("order", order);
    if (data?.isActive) {
      formData.append("isActive", data?.isActive);
    } else {
      formData.append("isActive", value?.isActive);
    }
    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${authToken}`,
      },
    };
    axios
      .put(`/store/updateProductCategory/${id}`, formData, config)
      .then((response) => {
        if (response?.data?.status == true) {
          reset();
          setLoading(false);
          toast.success(response?.data?.msg);

          setTimeout(() => {
            navigate(`/dashboard/storeCategory`);
            // window.location.reload();
          }, 1500);
        } else {
          toast.error(response?.data?.msg);
          setLoading(false);
        }
      })
      .catch((e) => {
        //console.log(e);
      });
  };

  const onChange2 = (e) => {
    const { name, value } = e.target;
    setValue((prev) => ({ ...prev, [name]: value }));
  };
  const [pdf, setPdf] = useState(false);
  const [val, setVal] = useState(false);
  return (
    <Grid direction="row" pl={5}>
      <Typography variant="h2" m={2}>
        Update Store Category - {value?.title}
      </Typography>

      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        {/* <Stack direction="row" spacing={5} > */}
        <Stack spacing={3} sx={{ width: "70%" }}>
          <Stack spacing={3} direction="row">
            <Stack sx={{ width: "50%" }}>
              <RHFTextField
                name="title"
                value={value?.title}
                onChange={onChange2}
              />
            </Stack>

            <Stack sx={{ width: "50%" }}>
              <RHFTextField
                name="order"
                value={value?.order}
                onChange={onChange2}
                label="Order"
                select
              >
                {categoryOrder?.map((item, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={item}
                      onClick={() => {
                        setOrder(item);
                      }}
                    >
                      {item}
                    </MenuItem>
                  );
                })}
              </RHFTextField>
            </Stack>
          </Stack>
          <Stack spacing={2} direction="row">
            {parentCategory?.id == null && (
              <Stack sx={{ width: "50%" }} direction="row" spacing={3}>
                {showImage ? (
                  <Stack
                    direction="column"
                    sx={{ width: "100%", position: "relative" }}
                    alignItems="center"
                    spacing={1}
                  >
                    <IconButton
                      sx={{
                        position: "absolute",
                        top: "-10px",
                        right: "-10px",
                      }}
                      onClick={() => handleDelete()}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>

                    <img
                      src={value?.icon}
                      alt="BannerImage"
                      style={{ height: "100px", width: "200px" }}
                    />
                  </Stack>
                ) : (
                  <RHFTextField
                    name="file"
                    onChange={changeHandler}
                    id="file"
                    label="Select image"
                    // value={value?.banner_url}
                    error={!file && val}
                    helperText="Image Only (Maximum size 100KB allowed)"
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    // inputProps={{ multiple: true }}
                    InputProps={{
                      classes: {
                        input: "CustomTextField",
                      },
                    }}
                    onClick={() => setPdf(true)}
                    inputProps={{ accept: "image/*" }}
                    type={pdf || focus ? "file" : "text"}
                  />
                )}
                {/* <Typography fontSize="10px" color="red" sx={{ display: restrict == '' ? 'none' : 'block' }}>{restrict}</Typography> */}
              </Stack>
            )}

            <Stack
              direction="row"
              sx={{ width: parentCategory?.id == null ? "50%" : "100%" }}
              spacing={3}
            >
              <RHFTextField
                name="parentCategory"
                label={
                  parentCategory?.title == "none"
                    ? "None"
                    : parentCategory?.title
                }
                select
                onChange={onChange2}
              >
                <MenuItem
                  value="none"
                  onClick={() => {
                    setParentCategory({ id: null, title: "none" });
                  }}
                >
                  None
                </MenuItem>
                {category?.map((item, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={item?.id}
                      onClick={() => {
                        setParentCategory(item);
                      }}
                    >
                      {item?.title}
                    </MenuItem>
                  );
                })}
              </RHFTextField>
            </Stack>
          </Stack>

          <RHFTextField
            name="isActive"
            label={value?.isActive ? "Active" : "Inactive"}
            sx={{ width: "100%" }}
            select
            type="checkbox"
            onChange={onChange2}
          >
            <MenuItem value={true}>Active</MenuItem>
            <MenuItem value={false}>Inactive</MenuItem>
          </RHFTextField>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={loading}
          sx={{
            bgcolor: theme.palette.primary.mainTheme,
            width: "70%",
            marginTop: "5%",
            "&:hover": { background: "red" },
          }}
        >
          Update
        </LoadingButton>
      </FormProvider>
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* <Typography color={positive ? 'green' : "red"} mt={3} sx={{ textAlign: 'center', width: '70%', textTransform: 'capitalize' }}>{fetchMessage}</Typography> */}
    </Grid>
  );
};

export default UpdateCategory;
