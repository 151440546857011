import PropTypes from "prop-types";
// material
import { Paper, Typography } from "@mui/material";
import Img from "../../src/data/not_found.png";

// ----------------------------------------------------------------------

SearchNotFound.propTypes = {
  searchQuery: PropTypes.string,
};

export default function SearchNotFound({ searchQuery = "", ...other }) {
  return (
    <Paper {...other}>
      <Typography gutterBottom align="center" variant="subtitle1">
        <img src={Img} style={{ width: "80px", margin: "auto" }} />
        <p style={{ fontSize: "10px", marginTop: "5px" }}>
          We couldn’t find what you’re looking for.
        </p>
        {/* Data Not found ❗❗ */}
      </Typography>
      {/* <Typography variant="body2" align="center">
        No results found for &nbsp;
        <strong>{searchQuery}</strong>. Try checking for typos or using complete words.
      </Typography> */}
    </Paper>
  );
}
