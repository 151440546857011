import { filter } from "lodash";
// import React from 'react';
import React, { useEffect, useState } from "react";
// import { useEffect, useState } from 'react';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Button,
  MenuItem,
} from "@mui/material";
// components
import Page from "../components/Page";

import Scrollbar from "../components/Scrollbar";

import SearchNotFound from "../components/SearchNotFound";
import {
  UserListHead,
  UserListToolbar,
  UserMoreMenu,
} from "../sections/@dashboard/user";
// mock
import { useNavigate } from "react-router-dom";
import SyncLoader from "react-spinners/SyncLoader";
import { ExportCSV } from "../utils/ExportCSV";
import { ToastContainer, toast } from "react-toastify";

// for Dialog box
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios"; // for axios request

import { CSVLink } from "react-csv"; // for CSV file download
import { RHFTextField } from "../components/hook-form";

import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ro } from "date-fns/locale";
import { useTheme } from "@emotion/react";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "", label: "S.No", alignRight: false },
  { id: "enrolid", label: "Enrollment No.", alignRight: false },
  { id: "StudentName", label: "Name", alignRight: false },
  { id: "mobileNumber", label: "Phone No.", alignRight: false },
  { id: "email", label: "Email", alignRight: false },
  { id: "purchaseAt", label: "Purchased At", alignRight: false },
  { id: "paid", label: "Paid", alignRight: false },
  { id: "Status", label: "Status", alignRight: false },
  { id: "Action", label: "Action", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  if (query) {
    return filter(array, (_user) => {
      const studentNameMatch =
        _user?.user?.name &&
        _user?.user?.name.toLowerCase().includes(query.toLowerCase());
      const emailMatch =
        _user?.user?.email &&
        _user?.user?.email.toLowerCase().includes(query.toLowerCase());
      const phoneMatch =
        _user?.mobileNumber &&
        _user.mobileNumber.toLowerCase().includes(query.toLowerCase());
      return studentNameMatch || emailMatch || phoneMatch;
    });
  }

  return stabilizedThis?.map((el) => el[0]);
}

export default function Students() {
  const theme = useTheme();

  //date-time
  const [starting, setStarting] = React.useState(dayjs());
  const [ending, setEnding] = React.useState(dayjs());

  const handleChange = (newValue) => {
    setStarting(newValue);
  };
  const handleChangeTwo = (newValue) => {
    setEnding(newValue);
  };

  // Code for handling Dialog box
  const [showDownload, setShowDownload] = useState(false);
  const [download, setDownload] = useState(true);
  const [statusUser, setStatusUser] = useState();
  const [statusStaus, setStatusStatus] = useState();
  const [errorOTP, setErrorOtp] = useState();
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const sendOtp = () => {
    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    const data = {
      action: "Exporting All Student Data",
    };

    axios
      .post(
        `https://api-prod.gyaankulam.com/api/v1/adminTeacher/send-otp`,
        data,
        config
      )
      .then((response) => {})
      .catch((e) => {
        //console.log(e);
      });
  };
  const verifyOtp = () => {
    //console.log(document.getElementById('name').value);
    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    const data = {
      otp: document.getElementById("name").value,
    };

    axios
      .post(
        `https://api-prod.gyaankulam.com/api/v1/adminTeacher/verify-otp`,
        data,
        config
      )
      .then((response) => {
        // //console.log(response);
        if (response?.data?.status == false) {
          setErrorOtp("Invalid Otp");
        } else {
          if (showDownload) {
            setShowDownload(false);
            setDownload(false);
          } else {
            //console.log("status");
            //console.log(statusStaus);
            const data1 = {
              isActive: !statusStaus,
            };
            axios
              .post(
                `https://api-prod.gyaankulam.com/api/v1/adminTeacher/activeInactiveStudent/${statusUser}`,
                data1,
                config
              )
              .then((response) => {
                //console.log(response);
              });

            handleClose();

            toast.success("Status Updated");
            setTimeout(() => window.location.reload(), 1000);

            setErrorOtp("");
          }
        }
      })
      .catch((e) => {
        //console.log(e);
      });
  };

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();
  const [users, usersSet] = useState();
  const [loading, setLoading] = useState(true);

  //Getting Details
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminTeacher/admingetallstudentsdetails`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      usersSet(responseJson?.data);
      // //console.log(responseJson?.data);
      setLoading(false);
      if (responseJson?.msg === "not an admin") {
        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("details");
        navigate("/");
      }
    }

    fetchUsers();
  }, []);

  const [type, setType] = useState("batch");
  const [valueId, setValueId] = useState();
  const [list, setList] = useState();
  // const [type,setType]=useState();

  React.useEffect(() => {
    const token = localStorage.getItem("token");
    // const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InNkY2FtcHVzQHRyYW5kby5pbiIsInN0dWRlbnRJZCI6IjNjZjcwODYwLTZjMWMtMTFlZC04YjVlLWMxZjc4OTdkMzM5OCIsImlhdCI6MTY5NjkxMzkwNSwiZXhwIjoxNjk3MDg2NzA1fQ.6fO3U1pCsbTOO2AVgjDOohKa4b73wtyG9o3zLksTJk8";
    async function fetchUsers() {
      // const fullResponse = await fetch(`https://api-prod.gyaankulam.com/api/v1/adminPanel/getBatchesDetails`, {
      const fullResponse = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/${
          type == "batch" ? "getBatchesDetails" : "getallTestSeriesdetails"
        }`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      setList(responseJson?.data);
      setLoading(false);
      // console.log('285',list)

      // //console.log('Teacher' + teacherData)
    }
    fetchUsers();
  }, [type]);

  useEffect(() => {
    handleFilter("batch", "all");
  }, []);

  // console.log('296',valueId)

  // console.log('292',list)
  const [filterSearch, setFilterSearch] = useState([]);
  const handleFilter = async (selectedType, valueIdType) => {
    // setLoading(true)
    let token = localStorage.getItem("token");
    const data = {
      type: selectedType == "" ? type : selectedType,
      valueId: valueIdType == "" ? valueId : valueIdType,
      startAt: selectedType == "" ? starting.$d : "2023-10-05T08:59:55.000Z",
      endAt: selectedType == "" ? ending.$d : "2023-10-11T08:59:55.000Z",
    };
    // console.log('data',data)
    try {
      const res = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/purchaseReport`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ ...data }),
        }
      );
      const result = await res.json();
      setFilterSearch(result?.data);
      // setLoading(false)
    } catch (error) {
      // console.log(error);
    }
    // navigate('/dashboard', { replace: true });
  };
  // console.log(filterSearch)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target?.checked) {
      const newSelecteds = users?.map((n) => n?.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected?.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected?.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected.concat(selected?.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected?.slice(0, selectedIndex),
        selected?.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target?.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target?.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users?.length) : 0;

  const filteredUsers = applySortFilter(
    filterSearch ? filterSearch : ["no"],
    getComparator(order, orderBy),
    filterName
  );
  // console.log(filteredUsers);
  let helperArr = [];
  helperArr = filteredUsers?.map((item) => {
    return {
      enrollId: item?.user?.enrollId,
      studentName: item?.user?.name,
      studentEmail: item?.user?.email,
      mobileNumber: item?.user?.phone,
      userName: item?.user?.username,
      paid: item?.is_paid,
      value: item?.value,
      amount: item?.amount,
      purchaseDate: item?.purchaseAt?.split(" ")[0] ?? "",
    };
  });
  const isUserNotFound = filteredUsers?.length === 0;
  let i = 0;
  return (
    <>
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          {" "}
          <SyncLoader
            color={theme.palette.primary.mainTheme}
            loading={loading}
            size={20}
          />
        </Stack>
      ) : (
        <Page title="Students">
          <Container sx={{ position: "absolute", minWidth: "100%" }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={3}
            >
              <Typography variant="h3" gutterBottom>
                All Purchase Report
              </Typography>
            </Stack>

            <Card
              sx={{
                width: "100%",
                position: "relative",
                padding: "40px",
                height: "100%",
                marginBottom: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "40px",
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>Filters</Typography>

              <TextField
                name="type"
                label="Type"
                select
                sx={{ height: "50px", width: "150px" }}
              >
                <MenuItem value="batch" onClick={() => setType("batch")}>
                  Batch
                </MenuItem>
                <MenuItem
                  value="testseries"
                  onClick={() => setType("testseries")}
                >
                  Test Series
                </MenuItem>
                {/* <MenuItem value="enhi">Bilungual</MenuItem> */}
              </TextField>
              <TextField
                name="valueId"
                label={type == "batch" ? "All Batches" : "All Test Series"}
                select
                sx={{ height: "50px", width: "150px" }}
              >
                {list?.map((item) => {
                  return (
                    <MenuItem
                      value={item?._id}
                      onClick={(e) => setValueId(item?._id)}
                    >
                      {type == "batch"
                        ? item?.batch_name
                        : item?.testseries_name}
                    </MenuItem>
                  );
                })}
              </TextField>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack
                  direction="row"
                  spacing={3}
                  sx={{ justifyContent: "space-between" }}
                >
                  <DateTimePicker
                    label="From"
                    value={starting.$d}
                    onChange={handleChange}
                    // name="starting_date"
                    renderInput={(params) => (
                      <TextField {...params} sx={{ width: "100%" }} />
                    )}
                  />
                  <DateTimePicker
                    label="To"
                    value={ending.$d}
                    onChange={handleChangeTwo}
                    sx={{ color: "black" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ width: "100%", color: "black" }}
                      />
                    )}
                  />
                </Stack>
              </LocalizationProvider>

              <Button variant="contained" onClick={() => handleFilter("", "")}>
                Search
              </Button>
            </Card>

            <Card>
              <Stack
                pr={3}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <UserListToolbar
                  numSelected={selected?.length}
                  filterName={filterName}
                  onFilterName={handleFilterByName}
                />

                {/* <ExportCSV csvData={users} fileName='Student Data' /> */}
                <Button
                  variant="outlined"
                  onClick={() => {
                    handleClickOpen();
                    sendOtp();
                    setShowDownload(true);
                  }}
                >
                  Export
                </Button>
                <Dialog open={open} onClose={handleClose}>
                  {download ? (
                    <>
                      <DialogTitle>OTP Varification</DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          OTP is send successfully to your regisered e-mail.
                        </DialogContentText>
                        <TextField
                          autoFocus
                          margin="dense"
                          id="name"
                          label="OTP Number"
                          type="number"
                          fullWidth
                          variant="standard"
                        />
                        <DialogContentText
                          sx={{
                            color: "red",
                            fontSize: "12px",
                            padding: "3px",
                          }}
                        >
                          {errorOTP}
                        </DialogContentText>
                      </DialogContent>

                      <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={verifyOtp}>Verify</Button>
                      </DialogActions>
                    </>
                  ) : (
                    <>
                      <DialogTitle>Download File</DialogTitle>
                      <DialogContent>
                        <CSVLink data={helperArr} filename="AllUsers.csv">
                          Download
                        </CSVLink>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={() => {
                            handleClose();
                            setDownload(true);
                          }}
                        >
                          Cancel
                        </Button>
                      </DialogActions>
                    </>
                  )}
                </Dialog>
              </Stack>

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={users?.length}
                      numSelected={selected?.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {/* {filterSearch?.map((row, index) => { */}
                      {filteredUsers
                        ?.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        ?.map((row, index) => {
                          const { id, purchaseAt, is_paid } = row;
                          // const { row.user?.is_active} = row.user;
                          // console.log('row',row)
                          const isItemSelected =
                            selected?.indexOf(row.user?.name) !== -1;
                          // row.sno = index + 1 + rowsPerPage * page

                          return (
                            <TableRow
                              hover
                              key={index}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              {/* <TableCell padding="checkbox">
                            <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, StudentName)} />
                          </TableCell> */}
                              <TableCell>
                                <Typography variant="subtitle2" noWrap>
                                  {index + 1}
                                  {/* {row.sno} */}
                                </Typography>
                              </TableCell>

                              <TableCell>
                                <Typography variant="subtitle2" noWrap>
                                  {row.user?.enrollId}
                                </Typography>
                              </TableCell>

                              <TableCell component="th" scope="row" pl={2}>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  <Avatar
                                    alt={row.user?.name}
                                    src={row.user?.icon}
                                    sx={{ width: 28, height: 28 }}
                                  />
                                  <Typography variant="subtitle2" noWrap>
                                    {row.user?.name}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell align="left">
                                {row.user?.phone ? row.user?.phone : "NA"}
                              </TableCell>
                              <TableCell align="left">
                                {row.user?.email}
                              </TableCell>
                              <TableCell align="left">
                                {purchaseAt?.split(" ")[0] ?? ""}
                              </TableCell>
                              <TableCell align="left">
                                {is_paid ? (
                                  <span style={{ color: "green" }}>Paid</span>
                                ) : (
                                  <span style={{ color: "red" }}>Not paid</span>
                                )}
                              </TableCell>
                              <TableCell align="left">
                                {row.user?.is_active ? (
                                  <Button
                                    variant="outlined"
                                    size="small"
                                    color="success"
                                    onClick={() => {
                                      handleClickOpen();
                                      sendOtp();
                                      setStatusStatus(row.user?.is_active);
                                      setStatusUser(id);
                                    }}
                                  >
                                    Active
                                  </Button>
                                ) : (
                                  <Button
                                    variant="outlined"
                                    size="small"
                                    color="error"
                                    onClick={() => {
                                      handleClickOpen();
                                      sendOtp();
                                      setStatusStatus(row.user?.is_active);
                                      setStatusUser(id);
                                    }}
                                  >
                                    InActive
                                  </Button>
                                )}
                              </TableCell>
                              <TableCell align="left">
                                <Button
                                  variant="outlined"
                                  size="small"
                                  color="error"
                                  onClick={() =>
                                    navigate(
                                      `/dashboard/students/studentsProfile/${id}`
                                    )
                                  }
                                >
                                  view
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>

                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filterSearch?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </Container>
          <ToastContainer
            position="bottom-center"
            autoClose={1000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </Page>
      )}
    </>
  );
}
