import * as React from "react";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridToolbar,
  GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  GridToolbarDensitySelector,
  useGridApiContext,
  gridExpandedSortedRowIdsSelector,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import SaveAltIcon from "@mui/icons-material/SaveAlt";

import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import getAccess from "../../../utils/getAccess";

function CustomToolbar({ rows, columns }) {
  const apiRef = useGridApiContext();
  const exportAllRows = async () => {
    const visibleRows = gridExpandedSortedRowIdsSelector(apiRef);
    const filteredRows = rows?.filter((row) => visibleRows.includes(row.id));
    const columnIds = columns
      ?.filter((column) => column.field != "actions")
      ?.map((column) => column.field);
    const csvHeader = columnIds.join(", ");
    const csvContent = filteredRows
      ?.map((row, index) => {
        const rowValues = columnIds?.map((columnId) => {
          if (columnId === "admin") {
            return `${row.admin.name}(${row.admin.role})`;
          } else if (columnId === "linkWith") {
            return row.linkWith.title;
          } else if (columnId === "serialNumber") {
            return index + 1;
          }
          return row[columnId]?.toString()?.replaceAll(",", "");
        });
        return rowValues.join(", ");
      })
      .join("\n");
    const fullCsvContent = `${csvHeader}\n${csvContent}`;
    const blob = new Blob([fullCsvContent], { type: `text/csv;charset=utf-8` });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", "StoreBanner.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />

      <GridToolbarDensitySelector />
      <Tooltip title="Export All">
        <IconButton onClick={exportAllRows}>
          <SaveAltIcon style={{ color: "red" }} />
        </IconButton>
      </Tooltip>
      <GridToolbarQuickFilter style={{ marginLeft: "auto" }} />
    </GridToolbarContainer>
  );
}

export default function CTABannerTable() {
  const access = getAccess();
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState();
  const [rowModesModel, setRowModesModel] = React.useState({});

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const navigate = useNavigate();
  const handleEditClick = (id) => () => {
    navigate(`/dashboard/updateStoreBanner/${id}`);
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  React.useEffect(() => {
    const token = localStorage.getItem("token");
    setLoading(true);
    async function fetchOrder() {
      const response = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/webContains/getAllCTABanner`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responseJson = await response?.json();
      setRows(responseJson?.data);
      // setRows(responseJson?.data ?? []);
      setLoading(false);
    }
    fetchOrder();
  }, []);

  const handleDeleteClick = (id) => async () => {
    // console.log(id);
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this CTA Banner?"
    );
    if (confirmDelete) {
      const token = localStorage.getItem("token");
      const res = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/webContains/deleteCTABanner/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // setLoadingButton(false)
      const resJson = await res.json();
      if (resJson?.status) {
        toast.success(resJson?.msg);
        setRows(rows?.filter((row) => row.id !== id));
      } else {
        toast.error(resJson?.msg);
      }
      // toast.success(resJson);
    }
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows?.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows?.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows?.map((row) => (row.id === newRow.id ? updatedRow : row)));
    // editDataInDb(newRow)
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    {
      field: "sNo",
      headerName: "S NO",
      width: 75,
      align: "left",
      headerAlign: "left",
      editable: false,
      renderCell: (params) => <strong>{params.value}</strong>,
    },
    {
      field: "admin",
      headerName: "Created By",
      type: "string",
      width: 200,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => {
        return <div>{`${params.value?.FullName} (${params.value?.Role})`}</div>;
      },
    },
    {
      field: "icon",
      headerName: "Image",
      type: "string",
      width: 200,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => (
        <img
          src={params.value}
          alt={`Image for row ${params.id}`}
          style={{ width: 150, height: 70 }}
        />
      ),
    },
    {
      field: "renderLink",
      headerName: "Render Link",
      type: "number",
      width: 150,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => (
        <a href={params.value ? params.value : "NA"} target="_blank">
          Link
        </a>
      ),
    },
    {
      field: "link",
      headerName: "Link",
      type: "number",
      width: 150,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "linkWith",
      headerName: "Link With",
      type: "object",
      width: 250,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => (
        <div>{params.value?.title ? params.value?.title : "NA"}</div>
      ),
    },
    {
      field: "isActive",
      headerName: "Status",
      type: "boolean",
      width: 100,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => (
        <div style={{ color: params.value == true ? "green" : "red" }}>
          {params.value ? "Active" : "inActive"}
        </div>
      ),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      type: "object",
      width: 150,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 150,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          // ((access?.accessForTab == 'readWrite' || access?.access.includes('all')) ? <GridActionsCellItem
          //   icon={<EditIcon />}
          //   label="Edit"
          //   className="textPrimary"
          //   onClick={handleEditClick(id)}
          //   color="inherit"
          // /> : <></>),
          access?.deleteAccess || access?.access.includes("all") ? (
            <GridActionsCellItem
              icon={<DeleteIcon style={{ color: "red" }} />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="inherit"
            />
          ) : (
            <></>
          ),
        ];
      },
    },
  ];
  return (
    <Box
      sx={{
        // height: 700,
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <DataGrid
        // rowHeight={50}
        rows={rows}
        columns={columns || []}
        loading={loading}
        autoHeight={true}
        getRowHeight={() => "auto"}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 7,
            },
          },
        }}
        pageSizeOptions={[7, 25, 50]}
        slots={{
          toolbar: (gridProps) => (
            <CustomToolbar {...gridProps} rows={rows} columns={columns || []} />
          ),
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel, showQuickFilter: true },
        }}
      />
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Box>
  );
}
