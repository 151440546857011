import {
  Box,
  Card,
  CardMedia,
  Divider,
  Stack,
  Typography,
  Button,
} from "@mui/material";
import React from "react";
import "./batchDetailsCard.css";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import TranslateIcon from "@mui/icons-material/Translate";
import { useState } from "react";
import { useEffect } from "react";

import "../style.css";
import { useTheme } from "@emotion/react";

const BatchDetailsCard = ({ batchId, setBatchName }) => {
  const theme = useTheme();

  const iconStyle = {
    background: theme.palette.primary.mainThemeLight,
    padding: "5px",
    color: theme.palette.primary.mainTheme,
    borderRadius: "50%",
    cursor: "pointer",
  };
  const [sub, setSub] = useState(0);
  const [tea, setTea] = useState(0);
  const [demo, setDemo] = useState(0);
  const scrollSubject = {
    transition: "all 0.3s",
    translate: `${sub}px`,
  };
  const scrollTeacher = {
    transition: "all 0.3s",
    translate: `${tea}px`,
  };
  const scrollDemo = {
    transition: "all 0.3s",
    translate: `${demo}px`,
  };

  //Getting Batch Details
  const [batch, setBatch] = useState([]);
  const [link, setLink] = useState();
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchBatchDetails() {
      const fullResponse = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/getBatchesDetails?id=${batchId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();

      // console.log(responseJson?.data[0]);
      // console.log(responseJson?.data[0]?.isCoinApplicable);
      setBatch(responseJson?.data[0]);
      setBatchName(responseJson?.data[0]?.batch_name);
      setLink(responseJson?.data[0]?.banner[0]?.fileLoc);
    }
    fetchBatchDetails();
  }, []);

  return (
    <Card sx={{ minHeight: "100%" }}>
      <Stack direction="column" sx={{ minHeight: "80vh" }}>
        <CardMedia
          component="img"
          height="100%"
          width="100%"
          image={link}
          alt="Banner"
          style={{
            objectFit: "cover",
            maxWidth: "100%",
            maxHeight: "100%",
          }}
        />
        <Typography
          color={theme.palette.primary.mainTheme}
          m={1}
          sx={{ fontWeight: "600", textAlign: "center" }}
        >
          {batch?.batch_name}{" "}
        </Typography>
        <Divider />
        <div
          className="batch_desc"
          dangerouslySetInnerHTML={{
            __html: batch?.description,
          }}
        ></div>
        {/* <Typography color="#727070" m={1} ml={2} mr={2} sx={{ fontWeight: '400', fontSize: '15px' }}>{batch?.description}</Typography> */}
        {batch?.remark != "" && (
          <Typography
            color={theme.palette.primary.mainTheme}
            m={1}
            mt={0}
            ml={2}
            mr={2}
            sx={{ fontWeight: "400", fontSize: "15px" }}
          >
            <span style={{ color: "#4A4A4A", fontWeight: "600" }}>Remark-</span>
            {batch?.remark}
          </Typography>
        )}
        {/* coin details */}
        <Box ml={2} mb={2} sx={{ color: "#4A4A4A", fontWeight: "600" }}>
          Coin Details -
          <div
            style={{ marginLeft: "7px", color: "#4A4A4A", fontWeight: "400" }}
          >
            Coin Applicable -{" "}
            {batch?.isCoinApplicable
              ? "Applicable" + "\u{2705}"
              : "Not Applicable" + "\u{274C}"}
          </div>
          <div
            style={{ marginLeft: "7px", color: "#4A4A4A", fontWeight: "400" }}
          >
            {" "}
            Maximum Coin Allowed - {batch?.maxAllowedCoins + "\u{1FA99}" || 0}
          </div>
        </Box>
        <Box ml={2} mb={2} sx={{ color: "#4A4A4A", fontWeight: "600" }}>
          EMI : -
          <span
            style={{ color: batch?.isEmi ? "green" : "red", fontWeight: "400" }}
          >
            {batch?.isEmi ? "Available" : "Not Available"}
          </span>
        </Box>
        {batch?.isEmi && (
          <Box ml={2} mb={2} sx={{ color: "#4A4A4A", fontWeight: "600" }}>
            EMI Options: -
            {batch?.emiOptions?.map((item) => (
              <span style={{ color: "#4A4A4A", fontWeight: "400" }}>
                {item == "1"
                  ? "One time ,"
                  : item == "2"
                  ? "Two Months ,"
                  : item == "3"
                  ? "Three Months ,"
                  : item == "6"
                  ? "Six Months ,"
                  : ""}
              </span>
            ))}
          </Box>
        )}
        <Box ml={2} mb={2} sx={{ color: "#4A4A4A", fontWeight: "600" }}>
          Meta Title : -
          <span style={{ color: "#4A4A4A", fontWeight: "400" }}>
            {batch?.metaTitle}
          </span>
        </Box>
        <Box ml={2} mb={2} sx={{ color: "#4A4A4A", fontWeight: "600" }}>
          Meta Description : -
          <div
            style={{ marginLeft: "7px", color: "#4A4A4A", fontWeight: "400" }}
            dangerouslySetInnerHTML={{ __html: batch?.metaDesc }}
          ></div>
        </Box>
        <Divider />
        <Stack
          direction="row"
          justifyContent="space-between"
          m={1}
          ml={2}
          mr={2}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            {/* <Typography p={1} sx={{ borderRadius: '44px', background: '#FFC8C8', fontSize: '10px' }}>L</Typography> */}
            <TranslateIcon
              fontSize="medium"
              sx={{
                borderRadius: "50%",
                background: theme.palette.primary.mainThemeLight,
                padding: "4px",
              }}
            />
            <Typography fontSize="small" fontWeight="700">
              {batch?.language == "en"
                ? "English"
                : batch?.language == "hi"
                ? "हिंदी"
                : "हिंदी/English"}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <div
              style={{
                borderRadius: "50%",
                background: theme.palette.primary.mainThemeLight,
                fontSize: "10px",
                width: "30px",
                height: "30px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              B
            </div>
            <Typography fontSize="small" fontWeight="700">
              {batch?.category?.title}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <div
              style={{
                borderRadius: "50%",
                background: theme.palette.primary.mainThemeLight,
                fontSize: "10px",
                width: "30px",
                height: "30px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              ET
            </div>
            <Typography fontSize="small" fontWeight="700">
              {batch?.exam_type}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <div
              style={{
                borderRadius: "50%",
                background: theme.palette.primary.mainThemeLight,
                fontSize: "10px",
                width: "30px",
                height: "30px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              V
            </div>
            <Typography fontSize="small" fontWeight="700">
              {" "}
              {batch?.validity}m Validity
            </Typography>
          </Stack>
        </Stack>
        <Divider />
        <Typography
          color={theme.palette.primary.mainTheme}
          mt={1}
          sx={{ fontWeight: "500", textAlign: "center" }}
        >
          Teachers
        </Typography>
        <Stack direction="row" ml={2} mr={2} justifyContent="space-between">
          <ArrowBackIosOutlinedIcon
            fontSize="small"
            sx={{ ...iconStyle }}
            onClick={() => setTea(tea + 50)}
          />
          <Stack
            direction="row"
            spacing={2}
            ml={1}
            mr={1}
            className="TeacherScroll"
            sx={{ flex: 3 }}
          >
            {batch?.teacher?.map((item, index) => (
              <Typography
                key={index}
                mb={1}
                sx={{ fontSize: "14px", ...scrollTeacher }}
              >
                {item?.FullName}
              </Typography>
            ))}
          </Stack>
          <ArrowForwardIosOutlinedIcon
            fontSize="small"
            sx={{ ...iconStyle }}
            onClick={() => setTea(tea - 50)}
          />
        </Stack>
        <Divider />
        <Typography
          color={theme.palette.primary.mainTheme}
          mt={1}
          sx={{ fontWeight: "500", textAlign: "center" }}
        >
          Subject
        </Typography>
        <Stack direction="row" ml={2} mr={2} justifyContent="space-between">
          <ArrowBackIosOutlinedIcon
            fontSize="small"
            sx={{ ...iconStyle }}
            onClick={() => setSub(sub - 50)}
          />
          <Stack
            direction="row"
            spacing={2}
            ml={1}
            mr={1}
            className="SubjectScroll"
            sx={{ flex: 3 }}
          >
            {batch?.subject?.map((item, index) => (
              <Typography
                key={index}
                mb={1}
                sx={{ fontSize: "14px", ...scrollSubject }}
              >
                {item?.title}
              </Typography>
            ))}
          </Stack>
          <ArrowForwardIosOutlinedIcon
            fontSize="small"
            sx={{ ...iconStyle }}
            onClick={() => setSub(sub + 50)}
          />
        </Stack>
        <Divider />
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          m={1}
          ml={2}
          mr={2}
        >
          <Typography
            color="#4A4A4A"
            sx={{ fontWeight: "600", fontSize: "20px" }}
          >
            Batch Amount
          </Typography>
          <Stack alignItems="flex-end">
            <Typography
              color={theme.palette.primary.mainTheme}
              sx={{ fontWeight: "700", fontSize: "16px" }}
            >
              ₹{batch?.charges}
            </Typography>
            <Typography
              color="#4A4A4A"
              sx={{
                fontWeight: "600",
                fontSize: "8px",
                margidemogonTop: "-5px",
              }}
            >
              GST+{batch?.discount}% Discount
            </Typography>
          </Stack>
        </Stack>
        <Divider />
        <Stack direction="row" m={1}>
          <Typography color={theme.palette.primary.mainTheme} mr={4}>
            Planner
          </Typography>

          <a href={batch?.planner?.fileLoc} target="_blank" download>
            {/* <DownloadIcon /> */}
            {batch?.planner?.fileName}
          </a>
        </Stack>

        <Divider />
        <Typography
          color={theme.palette.primary.mainTheme}
          sx={{ textAlign: "center" }}
          mt={1}
        >
          Demo Videos
        </Typography>
        <Stack
          direction="row"
          ml={2}
          mr={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <ArrowBackIosOutlinedIcon
            fontSize="small"
            sx={{ ...iconStyle }}
            onClick={() => setDemo(demo + 50)}
          />
          <Stack
            direction="row"
            justifyContent="space-around"
            m={1}
            mb={2}
            ml={2}
            mr={2}
            spacing={3}
            className="TeacherScroll"
          >
            {batch?.demoVideo?.map((item, index) => {
              {
                /* const link = item?.fileLoc.split(' ')[0]
                        //console.log(link) */
              }
              {
                /* //console.log(item?.fileLoc)
                        //console.log(typeof (item?.fileLoc)) */
              }
              return (
                <>
                  {item?.DemoVideofileType == "link" ? (
                    typeof item?.fileLoc !== "string" ? (
                      item?.fileLoc?.map((link, i) => {
                        return (
                          <div style={{ ...scrollDemo }}>
                            <iframe
                              key={i}
                              width="130"
                              height="100"
                              src={link}
                              title="YouTube video player"
                              frameBorder="0"
                              allowFullScreen="allowfullscreen"
                            ></iframe>
                          </div>
                        );
                      })
                    ) : (
                      <div style={{ ...scrollDemo }}>
                        <iframe
                          width="130"
                          height="100"
                          src={item?.fileLoc}
                          title="YouTube video player"
                          frameBorder="0"
                          allowFullScreen="allowfullscreen"
                        ></iframe>
                      </div>
                    )
                  ) : (
                    <video
                      key={item?._id}
                      id="capsule"
                      style={{ ...scrollDemo }}
                      src={item?.fileLoc}
                      width="130"
                      height="100"
                      controls
                    />
                  )}
                </>
              );
            })}
          </Stack>
          <ArrowForwardIosOutlinedIcon
            fontSize="small"
            sx={{ ...iconStyle, color: theme.palette.primary.mainTheme }}
            onClick={() => setDemo(demo - 50)}
          />
        </Stack>
      </Stack>
    </Card>
  );
};

export default BatchDetailsCard;
