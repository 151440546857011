import * as Yup from "yup";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Stack, Typography, MenuItem, InputLabel } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import { FormProvider, RHFTextField } from "../../../components/hook-form";
import { MuiChipsInput } from "mui-chips-input";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { useTheme } from "@emotion/react";
import RHFTextEditor from "../../../components/hook-form/RHFTextEditor";
// ----------------------------------------------------------------------

export default function AddCategory() {
  const theme = useTheme();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState();
  const [restrict, setRestrict] = useState("");
  const [focus, setFocused] = useState(false);
  const [pdf, setPdf] = useState(false);
  const [msg, setMsg] = useState("");
  const [val, setVal] = useState(false);

  // tags
  const [chips, setChips] = useState([]);
  const handleChangeChips = (newChips) => {
    setChips(newChips);
  };

  const [progress, setProgress] = useState();

  const RegisterSchema = Yup.object().shape({
    title: Yup.string().required("Title required"),
   // metaTitle: Yup.string().required("Meta Title required"),
   // type: Yup.string().required("Type required"),
    is_active: Yup.boolean().required("Status required"),
  });

  const changeHandler = async (event) => {
    let size = event.target?.files[0].size / 1024;
    if (size > 100) {
      setVal(true);
    } else {
      setFile(event.target?.files);
      var fileName = document.getElementById("file").value?.toLowerCase();
      if (fileName.endsWith(".pdf") || fileName.endsWith(".jpg")) {
        setRestrict("");
      } else {
        setRestrict("JPG Or PDF");
      }
    }
  };

  const defaultValues = {
    title: "",
    type: "Stream",
    is_active: true,
    metaTitle: "",
    metaDesc: "",
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    if (file) {
      const formData = new FormData();
      Object.values(file).forEach((file) => {
        formData.append("file", file);
      });
      formData.append("title", data?.title);
      formData.append("is_active", data?.is_active);
      formData.append("type", data?.type);
      formData.append("metaTitle", data?.metaTitle);
      formData.append("metaDesc", data?.metaDesc);
      formData.append("seoMetaTitle", data?.seoMetaTitle);
     formData.append("seoMetaDesc", data?.seoMetaDesc);
      formData.append("seoSlug", data?.seoSlug);
      chips?.map((item) => {
        formData.append("tags", item);
      });

      setLoading(true);
      let token = localStorage.getItem("token");
      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setProgress(Math.round((100 * data?.loaded) / data?.total));
        },
      };
      try {
        axios
          .post(
            `https://api-prod.gyaankulam.com/api/v1/adminPanel/addCategorialDetails`,
            formData,
            config
          )
          .then((response) => {
            //console.log(response?.data);
            if (response?.data?.msg === "added Category details successfully") {
              toast.success(response?.data?.msg);
              setLoading(false);
              setPdf(false);
              reset();
              window.location.href = "/dashboard/category";
            } else {
              toast.error(response?.data?.msg);
              setLoading(false);
            }
          })
          .catch((e) => {
            //console.log(e);
          });
      } catch (error) {
        //console.log(error);
      }
    }
  };

  return (
    <>
      <Stack spacing={3} px={5}>
        <Typography variant="h3">Add Category</Typography>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} sx={{ width: "70%" }}>
            <RHFTextField name="title" label="Title" />
            <Stack direction="row" spacing={3}>
              <RHFTextField
                name="file"
                onChange={changeHandler}
                id="file"
                label="File"
                error={!file && val}
                helperText="Image Only (Maximum size 100KB allowed) "
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                // inputProps={{ multiple: true }}
                InputProps={{
                  classes: {
                    input: "CustomTextField",
                  },
                }}
                onClick={() => setPdf(true)}
                inputProps={{ accept: "image/*" }}
                type={pdf || focus ? "file" : "text"}
              />
              {/*
                <RHFTextField name="type" label="Type" select>
                  <MenuItem value="Contact">Contact</MenuItem>
                  <MenuItem value="Stream">Stream</MenuItem>
                </RHFTextField>
                */
              }
            </Stack>
            { /*
              <Stack direction="row">
                <InputLabel
                  sx={{ width: "20%", marginRight: "20px", alignSelf: "center" }}
                >
                  Meta Title
                </InputLabel>
                <RHFTextField name="metaTitle" label="Meta Title" />
              </Stack>
            */ }
            {/*
              <Stack direction="row">
                <InputLabel
                  sx={{ width: "20%", marginRight: "20px", alignSelf: "center" }}
                >
                  SEO Meta Title
                </InputLabel>
                <RHFTextField name="seoMetaTitle" label="SEO Meta Title" />
              </Stack>
          */}
            
            {/*
            
              <Stack direction="row">
                <InputLabel
                  sx={{ width: "20%", marginRight: "20px", alignSelf: "center" }}
                >
                  Seo Slug
                </InputLabel>
                <RHFTextField name="seoSlug" label="SEO Slug" />
              </Stack>
              
        */ }
            {/*
      <RHFTextEditor name="metaDesc" label="Meta Description" /> */}
            
            { /*<RHFTextEditor name="seoMetaDesc" label="SEO Meta Description" /> */}
            <MuiChipsInput
              style={{ width: "100%" }}
              value={chips}
              onChange={handleChangeChips}
              placeholder="Enter Tags"
            />
            
            <Stack direction="row" spacing={3}>
              <RHFTextField
                name="is_active"
                label="Status"
                type="checkbox"
                select
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </RHFTextField>
            </Stack>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
              sx={{
                bgcolor: theme.palette.primary.mainTheme,
                marginTop: "5%",
                "&:hover": { background: "red" },
              }}
              onClick={() => {
                setVal(true);
                setMsg("Required");
              }}
            >
              Add
            </LoadingButton>
          </Stack>
        </FormProvider>
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {/* <Typography color={positive ? "green" : "red"} sx={{ textAlign: 'center', width: '80%', textTransform: 'capitalize' }}>{fetchMessage}</Typography> */}
      </Stack>
    </>
  );
}
