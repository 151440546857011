
import Iconify from '../../components/Iconify';
import getAccess from '../../utils/getAccess';


const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const d  = getAccess();


const assignBatch = localStorage.getItem("assignBatch")
// let d = JSON.parse(detailObject);
//console.log(d)
//Main Heading
let studentsheading = {}
if (d?.access?.includes('1111') || d?.access?.includes('1112') || d?.access?.includes('1113') || d?.access?.includes('1114') || d?.access?.includes('1115')) {
    //console.log(' goneInside')
    studentsheading = {
        title: 'Users',
        path: '#',
        icon: getIcon('eva:people-fill'),
        dropIcon: getIcon('gridicons:dropdown'),
        upIcon: getIcon('mi:caret-up')
    }
}
let batchesheading = {}
// console.log('line25', assignBatch)
// console.log('line25', assignBatch !== 0)
if (d?.access?.includes('21') || d?.access?.includes('22') || (parseInt(assignBatch) == 0 ? false : assignBatch?.length > 0 ? true : false)) {
    batchesheading = {
        title: 'Batches',
        path: '#',
        icon: getIcon('eva:shopping-bag-fill'),
        dropIcon: getIcon('gridicons:dropdown'),
        upIcon: getIcon('mi:caret-up'),
    }
}
let testseriesheading = {}
if (d?.access?.includes('31') || d?.access?.includes('32')) {
    //console.log('came in here test ')
    testseriesheading = {
        title: 'Test Series',
        path: '#',
        icon: getIcon('eva:file-text-fill'),
        dropIcon: getIcon('gridicons:dropdown'),
        upIcon: getIcon('mi:caret-up'),
    }
}
let quizheading = {};
if (d?.access?.includes('41') || d?.access?.includes('42')) {
    //console.log('came in here test ')
    quizheading = {
        title: 'Quiz',
        path: '#',
        icon: getIcon('material-symbols:quiz-outline'),
        dropIcon: getIcon('gridicons:dropdown'),
        upIcon: getIcon('mi:caret-up'),
    }
}
let couponheading = {}
if (d?.access?.includes('51') || d?.access?.includes('52')) {
    //console.log('came in here test ')
    couponheading = {
        title: "Coupon",
        path: "#",
        icon: getIcon("ri:coupon-3-line"),
        dropIcon: getIcon("gridicons:dropdown"),
        upIcon: getIcon("mi:caret-up"),
    }
}
let cmsheading = {}
if (d?.access?.includes('121') || d?.access?.includes('122')) {
    //console.log('came in here test ')
    cmsheading = {
        title: "CMS",
        path: "#",
        icon: getIcon("eva:file-text-fill"),
        dropIcon: getIcon("gridicons:dropdown"),
        upIcon: getIcon("mi:caret-up"),
    }
}
let community = {}
if (d?.access?.includes('13') || d?.access?.includes('131')) {
    //console.log('came in here test ')
    community = {
        title: "Community",
        path: "#",
        icon: getIcon("eva:file-text-fill"),
        dropIcon: getIcon("gridicons:dropdown"),
        upIcon: getIcon("mi:caret-up"),
    }
}


let resourcesheading = {}
if (d?.access?.includes('61') || d?.access?.includes('62') || d?.access?.includes('63') || d?.access?.includes('64') || d?.access?.includes('65') || d?.access?.includes('66') || d?.access?.includes('67') || d?.access?.includes('68')) {
    resourcesheading = {
        title: 'Resources',
        path: '#',
        icon: getIcon('eva:alert-triangle-fill'),
        dropIcon: getIcon('gridicons:dropdown'),
        upIcon: getIcon('mi:caret-up'),
    }
}
let generalsettings = {}
if (d?.access?.includes('101') || d?.access?.includes('102') || d?.access?.includes('103') || d?.access?.includes('104') || d?.access?.includes('105') || d?.access?.includes('106') || d?.access?.includes('107') || d?.access?.includes('108')) {
    generalsettings = {
        title: 'General Settings',
        path: '#',
        icon: getIcon('ci:settings'),
        dropIcon: getIcon('gridicons:dropdown'),
        upIcon: getIcon('mi:caret-up'),
    }
}
let managestaff = {}
if (d?.access?.includes('81') || d?.access?.includes('82') || d?.access?.includes('Sub Admin')) {
    managestaff = {
        title: 'Manage Staff',
        path: '/dashboard/staff',
        icon: getIcon('ic:outline-manage-accounts'),
        dropIcon: getIcon('mi:caret-down'),
        upIcon: getIcon('mi:caret-up'),
    }
}
let assignment = {}
if (d?.access?.includes('71') || d?.access?.includes('72')) {
    assignment = {
        title: 'Assignment',
        path: '/dashboard/assignment',
        icon: getIcon('eva:book-fill'),
        dropIcon: getIcon('gridicons:dropdown'),
        upIcon: getIcon('mi:caret-up'),

    }
}
let testimonial = {}
if (d?.access?.includes('91') || d?.access?.includes('92')) {
    testimonial = {
        title: 'Testimonial',
        path: '/dashboard/testimonial',
        icon: getIcon('dashicons:testimonial'),
        dropIcon: getIcon('gridicons:dropdown'),
        upIcon: getIcon('mi:caret-up'),

    }
}

let storeheading = {}
if (d?.access?.includes('111') || d?.access?.includes('112') || d?.access?.includes('113') || d?.access?.includes('114') || d?.access?.includes('115') || d?.access?.includes('116') || d?.access?.includes('117') || d?.access?.includes('118') || d?.access?.includes('119') || d?.access?.includes('120')) {
    storeheading = {
        title: 'Store',
        path: '#',
        icon: getIcon("dashicons:products"),
        dropIcon: getIcon("gridicons:dropdown"),
        upIcon: getIcon("mi:caret-up")
    }
}



//Sub Heading
let students = {}
if (d?.access?.includes('1111')) {
    students = {
        title: "All Users",
        path: "/dashboard/students",
        navicon: getIcon("icon-park-twotone:data-user"),
        cName: "sub-nav",
    }
}
let studentsbatches = {}
if (d?.access?.includes('1112')) {
    studentsbatches = {
        title: "Courses Purchases",
        path: "/dashboard/studentBatches",
        navicon: getIcon("eva:folder-fill"),
        cName: "sub-nav",
    }
}

let testseriesPurchase = {}
if (d?.access?.includes('1113')) {
    testseriesPurchase = {
        title: "Test Series Purchases",
        path: "/dashboard/studentTestSeries",
        navicon: getIcon("eva:file-fill"),
        cName: "sub-nav",
    }
}

let purchaseReport = {}
if (d?.access?.includes('1114')) {
    purchaseReport = {
        title: "Purchase Report",
        path: "/dashboard/purchaseReport",
        navicon: getIcon("carbon:report-data"),
        cName: "sub-nav",
    }
}

let userReport = {}
if (d?.access?.includes('1115')) {
    userReport = {
        title: "User Report",
        path: "/dashboard/userReport",
        navicon: getIcon("carbon:report-data"),
        cName: "sub-nav",
    }
}

let allbatches = {}
if (d?.access?.includes('21') || assignBatch?.length > 0) {
    allbatches = {
        title: "All batches",
        path: "/dashboard/batches",
        navicon: 'material-symbols:batch-prediction',
        cName: "sub-nav",
    }
}
let addbatches = {}
if (d?.access?.includes('22')) {
    addbatches = {
        title: "Add Batch",
        path: "/dashboard/addBatch",
        navicon: 'bxs:message-square-add',
        cName: "sub-nav",
    }
}
let testseries = {}
if (d?.access?.includes('31')) {
    testseries = {
        title: "All Test Series",
        path: "/dashboard/test",
        navicon: 'akar-icons:book-close',
        cName: "sub-nav",
    }
}
let addtestseries = {}
if (d?.access?.includes('32')) {
    addtestseries = {
        title: "Add Test Series",
        path: "/dashboard/addTest",
        navicon: 'ant-design:file-add-outlined',
        cName: "sub-nav",
    }
}
let quiz = {}
if (d?.access?.includes('41')) {
    quiz = {
        title: "All Quiz",
        path: "/dashboard/quiz",
        navicon: 'akar-icons:book-close',
        cName: "sub-nav",
    }
}
let addquiz = {}
if (d?.access?.includes('42')) {
    addquiz = {
        title: "Add Quiz",
        path: "/dashboard/addQuiz",
        navicon: 'ant-design:file-add-outlined',
        cName: "sub-nav",
    }
}
let allBlogs = {}
if (d?.access?.includes('121')) {
    allBlogs = {
        title: "All Blogs",
        path: "/dashboard/blogs",
        // navicon: "akar-icons:book-close",
        navicon: getIcon("mdi:folder-file"),
        cName: "sub-nav",
    }
}
let addBlog = {}
if (d?.access?.includes('122')) {
    addBlog = {
        title: "Add Blog",
        path: "/dashboard/addBlog",
        // navicon: "ant-design:file-add-outlined",
        navicon: getIcon("ant-design:file-add-filled"),
        cName: "sub-nav",
    }
}

let allcoupon = {}
if (d?.access?.includes('51')) {
    allcoupon = {
        title: "All Coupon",
        path: "/dashboard/allCoupon",
        navicon: "akar-icons:book-close",
        cName: "sub-nav",
    }
}
let addcoupon = {}
if (d?.access?.includes('52')) {
    addcoupon = {
        title: "Add Coupon",
        path: "/dashboard/addCoupon",
        navicon: "ant-design:file-add-outlined",
        cName: "sub-nav",
    }
}
let recordedvideos = {}
if (d?.access?.includes('61')) {
    recordedvideos = {
        title: "Recorded Video",
        path: "/dashboard/recorded",
        navicon: 'carbon:course',
        cName: "sub-nav",
    }
}
let pathyakram = {}
if (d?.access?.includes('62')) {
    pathyakram = {
        title: "Pathyakram",
        path: "/dashboard/pathyakram",
        navicon: 'carbon:course',
        cName: "sub-nav",
    }
}
let notes = {}
if (d?.access?.includes('63')) {
    notes = {
        title: "Notes",
        path: "/dashboard/notes",
        navicon: 'charm:notes',
        cName: "sub-nav",
    }
}
let pyq = {}
if (d?.access?.includes('64')) {
    pyq = {
        title: "Previous Year Question Papers",
        path: "/dashboard/previousYearQuestionPapers",
        navicon: "carbon:course",
        cName: "sub-nav",
    }
}
let air = {}
if (d?.access?.includes('65')) {
    air = {
        title: "AIR",
        path: "/dashboard/air",
        navicon: 'carbon:radio',
    }
}
let newsclip = {}
if (d?.access?.includes('66')) {
    newsclip = {
        title: "News Clip",
        path: "/dashboard/newsClip",
        navicon: 'wpf:news',
    }
}
let teachercontent = {}
if (d?.access?.includes('67')) {
    teachercontent = {
        title: "Teacher Generated Content of Batch",
        path: "/dashboard/teacherGenerated",
        navicon: 'bx:book-content',
    }
}
let alerts = {}
if (d?.access?.includes('68')) {
    alerts = {
        title: "Alerts",
        path: "/dashboard/customNotification",
        navicon: "carbon:course",
        cName: "sub-nav",
    }
}
let teacher = {}
if (d?.access?.includes('Teachers')) {
    teacher = {
        title: "Teachers",
        path: "/dashboard/teacher",
        navicon: 'fa-solid:chalkboard-teacher',
        cName: "sub-nav",
    }
}
let mod = {}
if (d?.access?.includes('Moderators')) {
    mod = {
        title: "Moderators",
        path: "/dashboard/moderators",
        navicon: 'ic:outline-interpreter-mode',
        cName: "sub-nav",
    }
}
let subadmin = {}
if (d?.access?.includes('Sub Admin')) {
    subadmin = {
        title: "Sub Admin",
        path: "/dashboard/subAdmin",
        navicon: 'subway:admin-1',
    }
}
let banner = {}
if (d?.access?.includes('101')) {
    banner = {
        title: "Banner",
        path: "/dashboard/banner",
        navicon: 'ri:file-settings-line',
        cName: "sub-nav",
    }
}
let logoutusers = {}
if (d?.access?.includes('102')) {
    logoutusers = {
        title: "Logout Users",
        path: "/dashboard/logoutUsers",
        navicon: 'ant-design:logout-outlined',
        cName: "sub-nav",
    }
}
let subject = {}
if (d?.access?.includes('103')) {
    subject = {
        title: "Subject",
        path: "/dashboard/subject",
        navicon: 'ri:file-settings-line',
        cName: "sub-nav",
    }
}
let youtube = {}
if (d?.access?.includes('104')) {
    youtube = {
        title: "Youtube",
        path: "/dashboard/youtube",
        navicon: 'ri:file-settings-line',
        cName: "sub-nav",
    }
}
let telegram = {}
if (d?.access?.includes('105')) {
    telegram = {
        title: "Telegram",
        path: "/dashboard/telegram",
        navicon: 'ri:file-settings-line',
        cName: "sub-nav",
    }
}
let category = {}
if (d?.access?.includes('106')) {
    category = {
        title: "Category",
        path: "/dashboard/category",
        navicon: 'ic:outline-category',
        cName: "sub-nav",
    }
}
let subcategory = {}
if (d?.access?.includes('107')) {
    subcategory = {
        title: "Sub Category",
        path: "/dashboard/subCategory",
        navicon: "ic:outline-category",
        cName: "sub-nav",
    }
}
let contactus = {}
if (d?.access?.includes('108')) {
    contactus = {
        title: "Contact Us",
        path: "/dashboard/contactUs",
        navicon: 'cil:contact',
        cName: "sub-nav",
    }
}
let helpandsupport = {}
if (d?.access?.includes('109')) {
    helpandsupport = {

        title: "Help and Support",
        path: "/dashboard/helpAndSupport",
        navicon: 'ic:baseline-support-agent',
        cName: 'sub-nav'
    }
}
let storecategory = {}
if (d?.access?.includes('111')) {
    storecategory = {

        title: "Category",
        path: "/dashboard/storeCategory",
        // navicon: "ph:student",
        navicon: getIcon("carbon:category"),
        // navicon:getIcon("https://icons8.com/icon/s4MzQ849Sdas/inspection"),
        cName: "sub-nav",
    }
}
let storebanner = {}
if (d?.access?.includes('112')) {
    storebanner = {

        title: "Banner",
        path: "/dashboard/storeBanner",
        navicon: getIcon("game-icons:vertical-banner"),
        // navlink : <img width="94" height="94" src="https://img.icons8.com/3d-fluency/94/inspection.png" alt="inspection"/>,
        cName: "sub-nav",
    }
}

let storefeaturedvideo = {}
if (d?.access?.includes('113')) {
    storefeaturedvideo = {

        title: "Featured Video",
        path: "/dashboard/storeFeaturedVideo",
        // navicon: "ant-design:file-add-outlined",
        navicon: getIcon("lets-icons:video-duotone"),
        cName: "sub-nav",
    }
}
let storealerts = {}
if (d?.access?.includes('114')) {
    storealerts = {

        title: "Alerts",
        path: "/dashboard/storeAlert",
        // navicon: "ant-design:file-add-outlined",
        navicon: getIcon("fluent:alert-on-20-filled"),
        cName: "sub-nav",
    }
}
let allproducts = {}
if (d?.access?.includes('115')) {
    allproducts = {

        title: "All Product",
        path: "/dashboard/storeAllProducts",
        // navicon: "ant-design:file-add-outlined",
        navicon: getIcon("gridicons:product"),
        cName: "sub-nav",
    }
}
let addproduct = {}
if (d?.access?.includes('116')) {
    addproduct = {

        title: "Add Product",
        path: "/dashboard/addStoreProduct",
        // navicon: "ant-design:file-add-outlined",
        navicon: getIcon("carbon:product"),
        cName: "sub-nav",
    }
}

let storeorderreport = {}
if (d?.access?.includes('117')) {
    storeorderreport = {

        title: "Order Report",
        path: "/dashboard/storeOrders",
        // navicon: "ant-design:file-add-outlined",
        navicon: getIcon("carbon:product"),
        cName: "sub-nav",
    }
}

let storepurchasereport = {}
if (d?.access?.includes('118')) {

    storepurchasereport = {

        title: "Purchase Report",
        path: "/dashboard/storePurchaseReport",
        // navicon: "ant-design:file-add-outlined",
        navicon: getIcon("carbon:product"),
        cName: "sub-nav",
    }
    // console.log(storepurchasereport)
}

let storeWishlist = {}
if (d?.access?.includes('119')) {
    storeWishlist = {
        title: "Store Wishlist",
        path: "/dashboard/storeWishlist",
        // navicon: "ant-design:file-add-outlined",
        navicon: getIcon("carbon:product"),
        cName: "sub-nav",
    }
}

let storeCart = {}
if (d?.access?.includes('120')) {

    storeCart = {
        title: "Store Cart",
        path: "/dashboard/storeCarts",
        // navicon: "ant-design:file-add-outlined",
        navicon: getIcon("carbon:product"),
        cName: "sub-nav",
    }
    // console.log(storepurchasereport)
}
let AllCommunity = {}
if (d?.access?.includes('131')) {

    AllCommunity = {
        title: "All Community",
        path: "/dashboard/community",
        navicon: getIcon("fluent:people-community-16-filled"),
        cName: "sub-nav",
    }
}















export const navConfigForTeacher = [{
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill'),
    dropIcon: getIcon('gridicons:dropdown')
    , upIcon: getIcon('mi:caret-up')
},
{
    ...studentsheading,

    subNav: [
        {
            ...students
        },
        {
            ...studentsbatches
        },
        { ...testseriesPurchase },
        { ...purchaseReport },
        { ...userReport }

    ]
},
{
    ...storeheading,

    subNav: [
        {
            ...storecategory
        },
        {
            ...storebanner
        },
        { ...storefeaturedvideo },
        { ...storealerts },
        { ...allproducts },
        { ...addproduct },
        { ...storeorderreport },
        { ...storepurchasereport },
        { ...storeWishlist },
        { ...storeCart }
    ]
}
    ,
{

    // ...batchesheading,
    // title: 'Batches',
    // path: '#',
    // icon: getIcon('eva:shopping-bag-fill'),
    // dropIcon: getIcon('gridicons:dropdown'),
    // upIcon: getIcon('mi:caret-up'),
    ...batchesheading,

    subNav: [
        { ...allbatches },
        {
            ...addbatches

        },

    ]
},
{
    ...testseriesheading,
    subNav: [
        {
            ...testseries

        },
        {

            ...addtestseries
        },

    ]

},
{
    ...quizheading,

    subNav: [
        {
            ...quiz,


        },
        {
            ...addquiz,


        },
    ]

},
{
    ...cmsheading,

    subNav: [
        {
            ...allBlogs,


        },
        {
            ...addBlog,


        },
    ]

},
{
    ...community,
    subNav: [
        {
            ...AllCommunity,

        },
    ]

},
{
    ...couponheading,
    subNav: [
        { ...allcoupon },
        { ...addcoupon }
    ]
},


{
    ...resourcesheading,
    subNav: [
        {
            ...recordedvideos
        },
        {
            ...pathyakram
        },
        {
            title: "All Resources",
            path: "/dashboard/resources",
            navicon: 'carbon:course',
            cName: "sub-nav",
        },
        {
            title: "Add Resources",
            path: "/dashboard/addResources  ",
            navicon: 'carbon:course',
            cName: "sub-nav",
        },
        {
            ...notes
        },
        { ...pyq },
        {
            ...air
        },
        {
            ...newsclip
        },
        {
            ...teachercontent
        },
        { ...alerts }
    ]
},
{
    ...assignment
},
{
    ...managestaff,
    // subNav: [
    //     {
    //         ...teacher
    //     },
    //     {
    //         ...mod
    //     },
    //     {
    //         ...subadmin
    //     }
    // ]
},
{
    ...testimonial

},
{
    // ...generalsettings,
    title: 'General Settings',
    path: '#',
    icon: getIcon('ci:settings'),
    dropIcon: getIcon('gridicons:dropdown'),
    upIcon: getIcon('mi:caret-up'),

    subNav: [
        {
            ...banner
        },
        {
            ...logoutusers
        },
        {
            ...subject
        },
        {
            ...youtube
        },
        {
            ...telegram
        },

        {
            ...category
        },

        {
            ...subcategory
        },
        {
            ...contactus
        },
        {
            // ...helpandsupport
            title: "Help and Support",
            path: "/dashboard/message",
            navicon: 'ic:baseline-support-agent',
            cName: 'sub-nav'
        },
    ]
},
{
    // ...generalsettings,
    // title: 'Quiz',
    // path: '#',
    // icon: getIcon('ci:settings'),
    // dropIcon: getIcon('gridicons:dropdown'),
    // upIcon: getIcon('mi:caret-up'),



},
]