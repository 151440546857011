import { jwtDecode } from "jwt-decode";
const getAccess = () => {
    const token = localStorage.getItem("token");
    // console.log(token);
    
    if( token ){
        const decode = jwtDecode(token);
        return decode?.accessObj 
     }else {
       let obj =  {
            accessForTab: "",
            deleteAccess:  "",
            access: "",
            role :"",
            profilePhoto :  ""
        }
        return obj;
     }
      
}


export default getAccess;