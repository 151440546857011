import * as Yup from "yup";
import { useEffect, useState } from "react";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Stack,
  IconButton,
  InputAdornment,
  Typography,
  MenuItem,
  Select,
  InputLabel,
  Card,
  Box,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Iconify from "../../../components/Iconify";
import { FormProvider, RHFTextField } from "../../../components/hook-form";
import { useTheme } from "@mui/material/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./form.css";
import MultiLevelSelect from "react-select-multi-level";
import options from "../../../data/tabArray";
// ----------------------------------------------------------------------
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, teacher, theme) {
  return {
    fontWeight:
      teacher?.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const label = { inputProps: { "aria-label": "Checkbox demo" } };
export default function AddTeacherForm() {
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const [users, usersSet] = useState();
  const [teacher, setteacher] = useState([]);
  const [batch, setbatch] = useState([]);
  const [accessToContent, setAaccessToContent] = useState([]);
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState("");
  const [accessForTab, setAccesForTab] = useState("read");
  const [deleteAccess, setDeleteAcess] = useState(false);

  const RegisterSchema = Yup.object().shape({
    FullName: Yup.string().required("First name required"),
    Role: Yup.string().required("Role is Required"),
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    mobile: Yup.string().required("Mobile No. is required"),
    password: Yup.string().required("Password is required"),
    is_Special: Yup.boolean().default(true),
    demoVideo: Yup.string().when("Role", {
      is: (Role) => Role == "teacher",
      then: Yup.string()
        .required("Demo video URL should be  youtube url")
        .matches(
          /^(https?\:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+/,
          "Invalid YouTube URL"
        ),
      otherwise: Yup.string().notRequired(),
    }),
    category: Yup.string().when("Role", {
      is: (Role) => Role == "teacher",
      then: Yup.string().required("Category Required"),
      otherwise: Yup.string().notRequired(),
    }),
    isActive: Yup.boolean().required("Status is Required"),
  });

  const defaultValues = {
    FullName: "",
    email: "",
    mobile: "",
    Role: "",
    password: "",
    is_Special: true,
    isActive: true,
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;
  const [permissionValidation, setPermissionValidation] = useState({
    set: false,
    text: "",
  });
  const onSubmit = async (data) => {
    // let check =
    // console.log(data);
    if (permissionValidation?.set || true) {
      setLoading(true);
      let token = localStorage.getItem("token");
      try {
        const res = await fetch(
          `https://api-prod.gyaankulam.com/api/v1/adminTeacher/addNewTeacher`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              ...data,
              ejective: true,
              Subject: teacher ? teacher : "",
              TeacherAccess: permission,
              qualification: "be",
              access: accessForTab,
              deleteAccess: deleteAccess,
              batch: batch,
            }),
          }
        );
        const result = await res.json();

        if (result?.msg === "teacher details added successfully") {
          toast.success("Staff Added");
          setLoading(false);
          reset();
        } else if (result?.msg === "teacher Already exists ") {
          toast.error("Staff Exist with these credentials");
          setLoading(false);
          // reset()
        } else {
          toast.error(result?.msg);
          reset();
          setLoading(false);
          // window.location.href="/dashboard/staff";
        }
      } catch (error) {
        console.log(error);
      }
    } else setPermissionValidation({ set: false, text: "Mandatory" });
    // navigate('/dashboard', { replace: true });
  };
  //Getting Subjects
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/get-select-subject`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      usersSet(responseJson?.data);
    }

    fetchUsers();
  }, []);

  const handleChangeBatch = (event) => {
    const {
      target: { value },
    } = event;
    setbatch(
      // On autofill we get a stringified value?.
      typeof value === "string" ? value?.split(",") : value
    );
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setteacher(
      // On autofill we get a stringified value?.
      typeof value === "string" ? value?.split(",") : value
    );
  };

  const handleChangeAccess = (event) => {
    const {
      target: { value },
    } = event;
    setAaccessToContent(
      // On autofill we get a stringified value?.
      typeof value === "string" ? value?.split(",") : value
    );
  };

  const moderatorAccess = [
    {
      label: "Batch",
      value: ["21", "23"],
    },
  ];

  const [permission, setPermission] = useState(
    role == "teacher"
      ? ["21", "23", "51"]
      : role == "moderator"
      ? moderatorAccess[0]?.value
      : []
  );

  const handleMultiLevelSelect = (e) => {
    // console.log("line181" , e);
    const pushArray = (a) => {
      if (a?.options) {
        return a?.options?.map((ii) => ii.value);
      } else {
        return a?.value?.map((ii) => ii);
      }
    };
    const final = [];
    const pushNestedArray = (arr) => {
      // console.log('line276', arr)
      return arr?.map((item) => final.push(item));
    };
    const helperArray = [];
    // console.log(helperArray)
    e?.map((item, index) => {
      helperArray.push(pushArray(item));
    });
    helperArray?.map((item, index) => pushNestedArray(item));

    setPermission(final);
  };

  useEffect(() => {
    setPermission(
      role == "teacher"
        ? ["21", "23", "51"]
        : role == "moderator"
        ? moderatorAccess[0]?.value
        : []
    );
  }, [role]);

  // category fetch
  const [categoryList, setCategoryList] = useState([]);

  useEffect(() => {
    async function fetchCategoryData() {
      let token = localStorage.getItem("token");
      try {
        const res = await fetch(
          `https://api-prod.gyaankulam.com/api/v1/adminPanel/getCategorailDetails`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const result = await res.json();
        if (!result?.status) {
          throw new Error(" categories not found");
        }
        setCategoryList(result?.data);
        // console.log(result?.data);
      } catch (error) {
        //
      }
    }
    fetchCategoryData();
  }, []);

  const [batch_id, setBatchId] = useState();
  const [lectureDetails, setLectureDetails] = useState();
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/getLecturedetails?BatchId=${batch_id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      setLectureDetails(responseJson?.data);
    }
    fetchUsers();
  }, [batch_id]);

  //Lecture list
  const [batchDetails, setBatchDetails] = useState();
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/getBatchesDetails`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      setBatchDetails(responseJson?.data);
    }
    fetchUsers();
  }, [batch_id]);
  // console.log(permission)
  return (
    <>
      <Stack spacing={3} px={5}>
        <Typography variant="h3">Add Staff</Typography>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} sx={{ width: "70%" }}>
            <RHFTextField name="FullName" label="Name" />
            <Stack direction="row" spacing={2}>
              <RHFTextField name="email" label="Email address" />
              <RHFTextField name="mobile" label="Mobile No." type="number" />
            </Stack>
            <Stack direction="row" spacing={2}>
              <RHFTextField name="Role" label="Role" select>
                <MenuItem value="teacher" onClick={() => setRole("teacher")}>
                  Teacher
                </MenuItem>
                <MenuItem value="subadmin" onClick={() => setRole("subadmin")}>
                  Sub-Admin
                </MenuItem>
                <MenuItem
                  value="moderator"
                  onClick={() => setRole("moderator")}
                >
                  Moderators
                </MenuItem>
              </RHFTextField>
              <RHFTextField
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        <Iconify
                          icon={
                            showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                          }
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
            {role == "teacher" && (
              <Stack direction="row" spacing={2}>
                <RHFTextField
                  name="demoVideo"
                  label="Enter Demo Youtube Url "
                />
                <RHFTextField name="category" label="Select Category" select>
                  {categoryList?.map((item) => (
                    <MenuItem key={item?._id} value={item?._id}>
                      {item?.title}
                    </MenuItem>
                  ))}
                </RHFTextField>
              </Stack>
            )}

            <Stack direction="row" spacing={3}>
              <RHFTextField
                name="isActive"
                label="Status"
                type="checkbox"
                select
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </RHFTextField>
            </Stack>
            {role !== "subadmin" && role !== "moderator" && (
              <Stack direction="row">
                <InputLabel
                  sx={{
                    width: "20%",
                    marginRight: "20px",
                    alignSelf: "center",
                  }}
                >
                  Assign Subjects
                </InputLabel>
                <Select
                  label=""
                  multiple
                  name="Subject"
                  value={teacher}
                  onChange={handleChange}
                  sx={{ width: "100%", color: "black", zIndex: 9 }}
                  MenuProps={MenuProps}
                >
                  {users?.map((name) => (
                    <MenuItem
                      key={name}
                      value={name._id}
                      style={getStyles(name, teacher, theme)}
                    >
                      {name.title}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
            )}
            {role == "moderator" && (
              <Stack direction="row">
                <InputLabel
                  sx={{
                    width: "20%",
                    marginRight: "20px",
                    alignSelf: "center",
                  }}
                >
                  Assign Batch
                </InputLabel>
                <Select
                  label=""
                  multiple
                  name="batch"
                  value={batch}
                  onChange={handleChangeBatch}
                  sx={{ width: "100%", color: "black", zIndex: 9 }}
                  MenuProps={MenuProps}
                >
                  {batchDetails?.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item?._id}
                      onClick={() => setBatchId(item?._id)}
                      style={getStyles(item, batch, theme)}
                    >
                      {item?.batch_name}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
            )}

            <Stack direction="column" sx={{ width: "100%" }}>
              {role != "moderator" && (
                <Stack direction="row">
                  {" "}
                  <InputLabel
                    sx={{
                      width: "20%",
                      marginRight: "20px",
                      alignSelf: "center",
                    }}
                  >
                    Assign Tabs
                  </InputLabel>
                  <MultiLevelSelect
                    options={
                      role == "teacher"
                        ? options?.optionsForTeacher
                        : options?.options
                    }
                    className="selectClass"
                    placeholder=" "
                    onChange={(e) => handleMultiLevelSelect(e)}
                  />
                </Stack>
              )}

              {!permissionValidation?.set && role == "subadmin" && (
                <Typography color="red" sx={{ marginLeft: "150px" }}>
                  {" "}
                  {permissionValidation?.text}
                </Typography>
              )}
            </Stack>

            {permission.length > 0 && permission[0] != "all" && (
              <FormControl
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "90px",
                  alignItems: "center",
                }}
              >
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Access Level
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="female"
                    onClick={() => setAccesForTab("read")}
                    control={<Radio />}
                    label="Read Only"
                  />
                  <FormControlLabel
                    value="male"
                    onClick={() => setAccesForTab("readWrite")}
                    control={<Radio />}
                    label="Read & Write "
                  />
                  <FormControlLabel
                    value="other"
                    control={
                      <Checkbox
                        {...label}
                        checked={deleteAccess}
                        onChange={(e) => setDeleteAcess(e.target?.checked)}
                      />
                    }
                    label="Delete Access"
                  />
                </RadioGroup>
              </FormControl>
            )}
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
              sx={{
                bgcolor: theme.palette.primary.mainTheme,
                marginTop: "5%",
                "&:hover": { background: "red" },
              }}
            >
              Register
            </LoadingButton>
          </Stack>
        </FormProvider>
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Stack>
    </>
  );
}
