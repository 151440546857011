import * as React from "react";
import { useState } from "react";
import { Box, Select, MenuItem } from "@mui/material";
import { Modal } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Typography } from "@mui/material";
import { Stack } from "@mui/material";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridToolbar,
} from "@mui/x-data-grid";
import { randomId } from "@mui/x-data-grid-generator";
import { ToastContainer, toast } from "react-toastify";
import { useTheme } from "@emotion/react";
import axios from "axios";
import { useParams } from "react-router";

// ----------------------------------------------------------------------
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",

  boxShadow: 24,
  borderRadius: "20px",

  p: 4,
};

const DropDownComponent = ({ value, onDropdownChange }) => {
  const [selectedValue, setSelectedValue] = useState(value);

  const handleChange = (event) => {
    const newValue = event.target?.value;
    setSelectedValue(newValue);
    onDropdownChange(newValue);
  };

  return (
    <Select value={selectedValue} onChange={handleChange}>
      <MenuItem value="processing">Processing</MenuItem>
      <MenuItem value="placed">Placed</MenuItem>
      <MenuItem value="shipped">Shipped</MenuItem>
      <MenuItem value="inTransit">In Transit</MenuItem>
      <MenuItem value="outForDelivery">Out For Delivery</MenuItem>
      <MenuItem value="delivered">Delivered</MenuItem>
      <MenuItem value="pending">Pending</MenuItem>
      <MenuItem value="cancelled">Cancelled</MenuItem>
      <MenuItem value=""></MenuItem>
      {/* Add more menu items as needed */}
    </Select>
  );
};

function EditToolbar(props) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    const id = randomId();
    setRows((oldRows) => [...oldRows, { id, name: "", age: "", isNew: true }]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "name" },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add record
      </Button>
    </GridToolbarContainer>
  );
}

export default function SchlorshipTestUser() {
  const theme = useTheme();
  const { id, title } = useParams();
  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const editDataInDb = async (newRow) => {
    const token = localStorage.getItem("token");
    const authToken = token;
    const formData = new FormData();
    formData.append("title", newRow.title);
    // formData.append('isActive', newRow.isActive)
    // formData.append('parentCategory', newRow.parentCategory)
    // formData.append('parentCategory', newRow.parentCategory)
    console.log(newRow);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${authToken}`,
      },
    };
    const res = await axios.put(
      `https://api-prod.gyaankulam.com/api/v1/store/updateFeatureVideo/${newRow?.id}`,
      formData,
      config
    );
    // console.log(res)
    // setLoading(false)
    toast.success("Updated");
    // setTimeout(() => window.location.reload()
    //   , 800)
  };
  const handleDeleteClick = (id) => async () => {
    let confirmDelete = window.confirm("Want to Delete Product?");
    if (confirmDelete) {
      const token = localStorage.getItem("token");
      const res = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/store/deleteStoreProduct/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // setLoadingButton(false)
      const resJson = await res.json();
      if (resJson?.status) {
        toast.success(resJson?.msg);
        setRows(rows?.filter((row) => row.id !== id));
      } else {
        toast.error(resJson?.msg);
      }
      // toast.success(resJson);
    }
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows?.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows?.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows?.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  React.useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchOrder() {
      const response = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/getAllUsersScholarshipTest/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responseJson = await response?.json();
      setRows(responseJson?.data ?? []);
    }
    fetchOrder();
  }, []);

  const handleCellClick = (id) => {
    setSelectedRowId(id === selectedRowId ? null : id);
  };

  const handleDropdownChange = async (id, selectedValue) => {
    // Perform the database update using an API call or any suitable method
    let token = localStorage.getItem("token");

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .put(
        `https://api-prod.gyaankulam.com/api/v1/store//updateOrder/${id}`,
        { deliveryStatus: selectedValue },
        config
      )
      .then((response) => {
        if (response?.data?.status === true) {
          toast.success(response?.data?.msg);
          setTimeout(() => {
            window.location.reload();
          }, 800);
        } else {
          toast.error(response?.data?.msg);
        }
      });
    console.log(`Update database for row ${id} with value: ${selectedValue}`);
  };

  const columns = [
    {
      field: "sN",
      headerName: "S NO",
      width: 150,
      align: "left",
      headerAlign: "left",
      editable: false,
    },
    {
      field: "name",
      headerName: "User Name",
      type: "object",
      width: 200,
      align: "left",
      headerAlign: "left",
    },

    {
      field: "email",
      headerName: "User Email",
      width: 300,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "phone",
      headerName: "User Phone",
      type: "string",
      width: 200,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "registrateredAt",
      headerName: "Registratered At",
      type: "string",
      width: 250,
      align: "left",
      headerAlign: "left",
    },
  ];

  return (
    <Box
      sx={{
        height: 590,
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <h2 style={{ marginBottom: "6px" }}>{`All Users for ${title}`}</h2>
      <DataGrid
        rowHeight={50}
        rows={rows}
        columns={columns || []}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 7,
            },
          },
        }}
        pageSizeOptions={[7, 25, 50]}
        slots={{
          //   toolbar: EditToolbar,
          toolbar: GridToolbar,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel, showQuickFilter: true },
        }}
      />

      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Box>
  );
}
