import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Stack,
  IconButton,
  InputAdornment,
  Typography,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components

import { FormProvider, RHFTextField } from "../../../../components/hook-form";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

// import "./general.css";
import { useTheme } from "@emotion/react";

// ----------------------------------------------------------------------

export default function AddProductFourth({
  setShowForm,
  setProductId,
  productId,
  value,
  setValue,
  title,
  setSubmit4,
}) {
  const theme = useTheme();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [restrict, setRestrict] = useState("");
  const [focus, setFocused] = useState(false);
  const [pdf, setPdf] = useState(false);
  const [msg, setMsg] = useState("");
  const [val, setVal] = useState(false);

  const RegisterSchema = Yup.object().shape({});

  const defaultValues = {};
  const [file, setFile] = useState();

  const [previewImages, setPreviewImages] = useState();
  const changeHandler = async (event) => {
    setFile(event.target?.files);
    const selectedFIles = [];
    const targetFiles = event.target?.files;
    const targetFilesObject = [...targetFiles];
    targetFilesObject?.map((file) => {
      return selectedFIles.push(URL.createObjectURL(file));
    });
    setPreviewImages(selectedFIles);
  };
  //console.log(file)
  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const [progress, setProgress] = useState();
  // for progress bar

  const onSubmit = async (data) => {
    if (file) {
      const formData = new FormData();

      setLoading(true);

      let newArr = [];
      //********* HERE IS THE CHANGE ***********
      for (let i = 0; i < file.length; i++) {
        formData.append("files", file[i]);
      }
      formData.append("images", []);
      // console.log(formData)

      // formData.append("icon", file);
      const token = localStorage.getItem("token");
      const authToken = token;
      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${authToken}`,
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setProgress(Math.round((100 * data?.loaded) / data?.total));
        },
      };
      // document.querySelector(".mainDiv").style.display = "block";
      setProgress(0);
      axios
        .post(
          `https://api-prod.gyaankulam.com/api/v1/store/addStoreProductFourth/${productId}`,
          formData,
          config
        )
        .then((response) => {
          //console.log(response?.data);
          if (response?.data?.msg === "Product Details added") {
            setProductId(response?.data?.data?._id);
            toast.success(response?.data?.msg);
            setLoading(false);
            setPdf(false);
            reset();
            setValue(value + 1);
            setSubmit4(true);
            // window.location.href = "/dashboard/storeAllProducts";
          } else {
            setLoading(false);
            toast.error(response?.data?.msg);
          }
        })
        .catch((e) => {
          //console.log(e);
        });
    }
  };
  return (
    <>
      <Stack spacing={3} px={5}>
        <Typography variant="h3">
          Add Images for <span style={{ color: "blue" }}>{title}</span>{" "}
        </Typography>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} sx={{ width: "70%" }}>
            <Stack direction="row" spacing={3}>
              <RHFTextField
                name="files"
                onChange={changeHandler}
                id="file"
                label="File"
                error={!file && val}
                helperText="Images Only(Maximum size allowed size 100KB)"
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                inputProps={{ multiple: true, accept: "image/*" }}
                InputProps={{
                  classes: {
                    input: "CustomTextField",
                  },
                }}
                onClick={() => setPdf(true)}
                type={pdf || focus ? "file" : "text"}
              />

              {file?.length > 0 &&
                previewImages?.map((item, index) => (
                  <Stack direction="row">
                    {" "}
                    <img
                      src={item}
                      alt="imglist"
                      style={{ width: 100, height: 100 }}
                    />{" "}
                  </Stack>
                ))}
            </Stack>

            {/* <div className="mainDiv">
                        <div className="childDiv" style={{ width: `${progress}%` }}>
                            <span> </span>
                        </div>
                    </div> */}

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
              sx={{
                bgcolor: theme.palette.primary.mainTheme,
                marginTop: "5%",
                "&:hover": { background: "red" },
              }}
              onClick={() => {
                setVal(true);
                setMsg("Required");
              }}
            >
              Next Step
            </LoadingButton>
          </Stack>
        </FormProvider>
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {/* <Typography color={positive ? "green" : "red"} sx={{ textAlign: 'center', width: '80%' }}>{fetchMessage}</Typography> */}
      </Stack>
    </>
  );
}
