import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import { FormHelperText, Modal, TextField } from "@mui/material";
import { Stack } from "@mui/material";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import ClearIcon from "@mui/icons-material/Clear";
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  GridToolbarDensitySelector,
  useGridApiContext,
  GridRowEditStopReasons,
  gridExpandedSortedRowIdsSelector,
} from "@mui/x-data-grid";

import IconButton from "@mui/material/IconButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Tooltip from "@mui/material/Tooltip";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { ToastContainer, toast } from "react-toastify";
import { useTheme } from "@emotion/react";
import DraggableComponent from "./Draggable";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AttributeBulkUodate from "./components/AttributeBulkUodate";
import getAccess from "../../../utils/getAccess";

// ----------------------------------------------------------------------

const styleForModal = {
  position: "absolute",
  top: "10%",
  left: "30%",
  // transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const flattenArray = (arr) => {
  return arr?.map((item) => `${item.replaceAll(",", "")}`).join("");
};

function CustomToolbar({ rows, columns }) {
  const apiRef = useGridApiContext();

  const exportAllRows = async () => {
    const visibleRows = gridExpandedSortedRowIdsSelector(apiRef);

    const filteredRows = rows?.filter((row) => visibleRows.includes(row.id));

    let columnIds = columns
      ?.filter((column) => column.field !== "actions")
      ?.map((column) => column.field);
    columnIds.push("id");
    const csvHeader = columnIds.join(", ");
    const csvContent = filteredRows
      ?.map((row, index) => {
        const rowValues = columnIds?.map((columnId) => {
          if (columnId === "admin") {
            return `${row.admin.name}(${row.admin.role})`;
          } else if (columnId === "title") {
            return row.title?.replaceAll(",", " ");
          } else if (columnId === "id") {
            return row.id;
          } else if (columnId === "badge") {
            return row.badge.replaceAll(",", " ");
          } else if (columnId === "serialNumber") {
            return index + 1;
          } else if (columnId === "images") {
            return flattenArray(row.images);
          } else if (columnId === "tags") {
            return flattenArray(row.tags);
          } else if (columnId === "category") {
            return row.category.title;
          }
          return row[columnId]?.toString()?.replaceAll(",", "");
        });
        return rowValues.join(", ");
      })
      .join("\n");
    const fullCsvContent = `${csvHeader}\n${csvContent}`;

    const blob = new Blob([fullCsvContent], { type: `text/csv;charset=utf-8` });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", "StoreProducts.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />

      <GridToolbarDensitySelector />
      <Tooltip title="Export All">
        <IconButton onClick={exportAllRows}>
          <SaveAltIcon style={{ color: "red" }} />
        </IconButton>
      </Tooltip>
      <GridToolbarQuickFilter style={{ marginLeft: "auto" }} />
    </GridToolbarContainer>
  );
}

export default function StoreProductsTable({ data, fetchProduct }) {
  const theme = useTheme();

  const [rows, setRows] = React.useState(data);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [selectedRows, setSelectedRows] = useState([]);

  const navigate = useNavigate();
  const handleEditClick = (id) => () => {
    // navigate(`/dashboard/updateStoreProduct/${id}`)
    window.open(`/dashboard/updateStoreProduct/${id}`);
    // setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const editDataInDb = async (newRow) => {
    const details = {
      isCoinApplicable: newRow?.isCoinApplicable,
      maxAllowedCoins: newRow?.maxAllowedCoins,
      regularPrice: newRow?.regularPrice,
      salePrice: newRow?.salePrice,
      metaTitle: newRow?.metaTitle,
      metaDesc: newRow?.desc,
    };

    // setLoading(true)

    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      onUploadProgress: (data) => {
        //Set the progress value to show the progress bar
        setProgress(Math.round((100 * data?.loaded) / data?.total));
      },
    };
    setProgress(0);
    if (newRow.salePrice <= newRow.regularPrice) {
      axios
        .post(
          `https://api-prod.gyaankulam.com/api/v1/store/addStoreProductThird/${newRow?.id}`,
          details,
          config
        )
        .then((response) => {
          if (response?.data?.msg === "Product Details added") {
            toast.success(response?.data?.msg);
          } else {
            toast.error(response?.data?.msg);
          }
        })
        .catch((e) => {
          //console.log(e);
        });
    } else {
      toast.error("Sale Price should be less than regular price");
      // window.reload();
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };
  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };
  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };
  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows?.map((row) => (row.id === newRow.id ? updatedRow : row)));
    editDataInDb(newRow);
    return updatedRow;
  };

  const handleDeleteClick = (id) => async () => {
    let confirmDelete = window.confirm("Want to Delete Product?");
    if (confirmDelete) {
      const token = localStorage.getItem("token");
      const res = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/store/deleteStoreProduct/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // setLoadingButton(false)
      const resJson = await res.json();
      if (resJson?.status) {
        toast.success(resJson?.msg);
        setRows(rows?.filter((row) => row.id !== id));
      } else {
        toast.error(resJson?.msg);
      }
      // toast.success(resJson);
    }
  };

  const handleTrashClick = (id) => async () => {
    let confirmDelete = window.confirm("Want to Trash Product?");
    if (confirmDelete) {
      const token = localStorage.getItem("token");
      const res = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/store/trashStoreProduct/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // setLoadingButton(false)
      const resJson = await res.json();
      if (resJson?.status) {
        toast.success(resJson?.msg);
        setRows(rows?.filter((row) => row.id !== id));
      } else {
        toast.error(resJson?.msg);
      }
      // toast.success(resJson);
    }
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows?.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows?.filter((row) => row.id !== id));
    }
  };

  const handleSelectionModelChange = (selection) => {
    console.log("console" + selection);
    setSelectedRows(selection);
  };

  const access = getAccess();
  const rowsWithSerialNumber = rows?.map((row, index) => ({
    ...row,
    serialNumber: index + 1,
  }));
  const [open, setOpen] = React.useState(false);
  const [modalData, setModalData] = React.useState();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // bulkPrice update
  const [openForPrice, setOpenForPrice] = React.useState(false);
  const [openForMultiplePrice, setOpenForMultiplePrice] = React.useState(false);
  // const [modalData, setModalData] = React.useState();
  const [price, setPrice] = useState();
  const [salePrice, setSalePrice] = useState();
  const [priceError, setPriceError] = useState();
  const handleOpenForPrice = () => setOpenForPrice(true);
  const handleOpenForMultiplePrice = () => setOpenForMultiplePrice(true);
  const handleCloseForPrice = () => setOpenForPrice(false);
  const handleCloseForMultiplePrice = () => setOpenForMultiplePrice(false);

  // for bulk import product
  const handleUploadExcel = () => {
    document.getElementById("fileInput").click();
    document.querySelector(".mainDiv").style.display = "block";
    setProgress(0);
  };
  const handleUploadExcelForUpdate = () => {
    document.getElementById("fileInput2").click();
    document.querySelector(".mainDiv").style.display = "block";
    setProgress(0);
  };

  const [progress, setProgress] = useState();

  const handleFileUpload = async (event) => {
    const file1 = event.target?.files[0];
    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      onUploadProgress: (data) => {
        //Set the progress value to show the progress bar
        setProgress(Math.round((100 * data?.loaded) / data?.total));
      },
    };
    const formData = new FormData();
    formData.append("file", file1);
    // console.log(file1);
    const response = await axios.post(
      `https://api-prod.gyaankulam.com/api/v1/store/importProductsFromExcel`,
      formData,
      config
    );
    if (response?.data?.status == true) {
      toast.success("Product Uploaded Successfully", { autoClose: false });
      setTimeout(() => window.location.reload(), 1000);
    } else {
      toast.error(response?.data?.message, { autoClose: false });
    }
    // console.log(response);
    // setTimeout(() => window.location.reload(), 3000);
  };
  const handleFileUploadForUpdate = async (event) => {
    const file2 = event.target?.files[0];
    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      onUploadProgress: (data) => {
        //Set the progress value to show the progress bar
        setProgress(Math.round((100 * data?.loaded) / data?.total));
      },
    };
    const formData = new FormData();
    formData.append("file", file2);
    // console.log(file1);
    const response = await axios.post(
      `https://api-prod.gyaankulam.com/api/v1/store/updateProductsFromExcel`,
      formData,
      config
    );
    if (response?.data?.status == true) {
      toast.success("Product Updated Successfully", { autoClose: false });
      window.location.reload();
    } else {
      toast.error(response?.data?.message, { autoClose: false });
    }
    console.log(response);
    // setTimeout(() => window.location.reload(), 3000);
  };

  const columns = [
    {
      field: "serialNumber",
      headerName: "S NO",
      width: 75,
      align: "left",
      headerAlign: "left",
      editable: false,
      renderCell: (params) => <strong>{params.value}</strong>,
    },

    {
      field: "title",
      headerName: "Title",
      type: "string",
      width: 250,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "admin",
      headerName: "Created By",
      type: "string",
      width: 200,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => {
        return <div>{`${params.value?.name} (${params.value?.role})`}</div>;
      },
    },
    {
      field: "url",
      headerName: "Url",
      type: "string",
      width: 100,
      align: "left",
      headerAlign: "left",
      editable: false,
      renderCell: (params) => (
        <a href={params.value ? params.value : "NA"} target="_blank">
          Link
        </a>
      ),
    },
    {
      field: "featuredImage",
      headerName: "Feature Image",
      type: "string",
      width: 100,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => (
        <img
          src={params.value}
          alt={`Image for row ${params.id}`}
          style={{ width: 70, height: 70 }}
        />
      ),
    },
    {
      field: "images",
      headerName: "Image",
      type: "string",
      width: 300,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => (
        <div
          style={{ display: "flex" }}
          onClick={() => {
            handleOpen();
            setModalData(params);
          }}
        >
          {params.value?.map((imageUrl, index) => (
            <img
              key={index}
              src={imageUrl}
              alt={`Image ${index + 1} for row ${params.id}`}
              style={{ width: 40, height: 70, marginRight: 5 }}
            />
          ))}
        </div>
      ),
    },
    {
      field: "productType",
      headerName: "Product Type",
      type: "object",
      width: 100,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "category",
      headerName: "Category",
      type: "object",
      width: 100,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => (
        <div>{params.value?.title ? params.value?.title : "NA"}</div>
      ),
    },
    {
      field: "marketingCat",
      headerName: "MarketingCategory",
      type: "object",
      width: 100,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => <div>{params.value ? params.value : "NA"}</div>,
    },
    {
      field: "code",
      headerName: "Code",
      type: "object",
      width: 100,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => <div>{params.value ? params.value : "NA"}</div>,
    },
    {
      field: "inStock",
      headerName: "Stock",
      type: "object",
      width: 100,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "maxPurchaseQty",
      headerName: "Maximum Purchase Qty",
      type: "object",
      width: 100,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "regularPrice",
      headerName: "Regular Price",
      type: "object",
      width: 140,
      align: "left",
      description: access?.accessForTab?.includes("readWrite")
        ? "Regular Price is editable"
        : " Regular Price",
      headerAlign: "left",
      editable: access?.accessForTab?.includes("readWrite") ? true : false,
      renderHeader: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <strong style={{ marginRight: 4 }}>Regular Price</strong>
          {access?.accessForTab?.includes("readWrite") && (
            <EditIcon style={{ color: "red" }} />
          )}
        </div>
      ),
    },
    {
      field: "salePrice",
      headerName: "Sale Price",
      type: "object",
      width: 120,
      align: "left",
      description: access?.accessForTab?.includes("readWrite")
        ? "Sale Price is editable"
        : "Sale Price ",
      headerAlign: "left",
      editable: access?.accessForTab?.includes("readWrite") ? true : false,
      renderHeader: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <strong style={{ marginRight: 4 }}>Sale Price</strong>
          {access?.accessForTab?.includes("readWrite") && (
            <EditIcon style={{ color: "red" }} />
          )}
        </div>
      ),
    },
    {
      field: "badge",
      headerName: "Badge",
      type: "object",
      width: 100,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "tags",
      headerName: "Tags",
      type: "object",
      width: 100,
      align: "left",
      headerAlign: "left",
    },
    //   {
    //     field: "attributes",
    //     headerName: "Attributes",
    //     type: "object",
    //     width: 100,
    //     align: "left",
    //     headerAlign: "left",
    //     editable: true,
    //     // renderCell: (params) => (
    //     //     <div>{params.value?.title ? params.value?.title : "NA"}</div>
    //     //   ),
    //   },
    //   {
    //     field: "variations",
    //     headerName: "Variations",
    //     type: "object",
    //     width: 100,
    //     align: "left",
    //     headerAlign: "left",
    //     editable: true,
    //     // renderCell: (params) => (
    //     //     <div>{params.value?.title ? params.value?.title : "NA"}</div>
    //     //   ),
    //   },
    {
      field: "isCoinApplicable",
      headerName: "Coin Applicable",
      type: "object",
      width: 100,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => (
        <div style={{ color: params.value == true ? "green" : "red" }}>
          {params.value ? "Applicable" : "Not Applicable"}
        </div>
      ),
    },
    {
      field: "maxAllowedCoins",
      headerName: "Maximun Allowed Coins",
      type: "object",
      width: 100,
      align: "left",
      headerAlign: "left",
    },

    {
      field: "isActive",
      headerName: "Status",
      type: "boolean",
      width: 100,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => (
        <div style={{ color: params.value == true ? "green" : "red" }}>
          {params.value ? "Active" : "inActive"}
        </div>
      ),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      type: "object",
      width: 150,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 150,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <Tooltip title="View Product">
            <GridActionsCellItem
              icon={<VisibilityOutlinedIcon />}
              label="View"
              // onClick={handleDeleteClick(id)}
              onClick={() => navigate(`/dashboard/product_profile/${id}`)}
              color="inherit"
            />
          </Tooltip>,
          access?.accessForTab == "readWrite" ||
          access?.access.includes("all") ? (
            <Tooltip title="Edit Product">
              <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                className="textPrimary"
                onClick={handleEditClick(id)}
                color="inherit"
              />
            </Tooltip>
          ) : (
            <></>
          ),
          access?.deleteAccess || access?.access.includes("all") ? (
            <Tooltip title="Trash Product">
              <GridActionsCellItem
                icon={<DeleteForeverIcon style={{ color: "red" }} />}
                label="Trash"
                onClick={handleTrashClick(id)}
                // onClick={ (id) => handleOpen(id)}
                color="inherit"
              />
            </Tooltip>
          ) : (
            <></>
          ),
          // ((access?.deleteAccess || access?.access.includes('all')) ? <GridActionsCellItem
          //   icon={<DeleteIcon />}
          //   label="Delete"
          //   onClick={handleDeleteClick(id)}
          //   // onClick={ (id) => handleOpen(id)}
          //   color="inherit"
          // /> : <></>)
        ];
      },
    },
  ];

  const handleSubmitForPrice = async () => {
    // console.log(selectedRows);
    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    if (!price || !salePrice) {
      setPriceError("Please Provide Price & SalePrice");
    } else if (salePrice > price) {
      setPriceError("Sale Price must be less than Price");
    } else {
      axios
        .post(
          `https://api-prod.gyaankulam.com/api/v1/store/setBulkPriceForProducts`,
          { products: selectedRows, regularPrice: price, salePrice: salePrice },
          config
        )
        .then((response) => {
          //console.log(response?.data);
          if (response?.data?.status === true) {
            handleCloseForPrice();
            toast.success(response?.data?.msg);
            // window.location.reload();
            setTimeout(() => {
              window.location.reload();
            }, "1000");
          } else {
            setPriceError(response?.data?.msg);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
    // setProgress(0);
  };
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleForModal}>
          <DraggableComponent
            modalData={modalData?.value}
            item={modalData}
            handleClose={handleClose}
            fetchProduct={fetchProduct}
          />
        </Box>
      </Modal>
      <Modal
        open={openForPrice}
        onClose={handleCloseForPrice}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleForModal}>
          <ClearIcon
            size="small"
            sx={{
              position: "absolute",
              right: "20px",
              top: "10px",
              zIndex: 100,
              cursor: "pointer",
              background: "lightgray",
              borderRadius: "5px",
              padding: "4px",
            }}
            onClick={() => handleCloseForPrice()}
          />
          <Stack direction="column" spacing={2}>
            <h2>Bulk Price Changes</h2>
            <Stack
              direction="column"
              spacing={2}
              m={3}
              align="center"
              justifyContent="space-evenly"
            >
              <TextField
                type="number"
                value={price}
                onChange={(e) => setPrice(e.target?.value)}
                placeholder="Regular Price"
              />
              <TextField
                type="number"
                value={salePrice}
                onChange={(e) => setSalePrice(e.target?.value)}
                placeholder="Sale Price"
              />
            </Stack>
            <FormHelperText
              id="my-helper-text"
              sx={{ color: "red", fontSize: "15px" }}
            >
              {priceError}
            </FormHelperText>
            <Stack
              direction="row"
              spacing={1}
              m={3}
              align="center"
              justifyContent="space-evenly"
            >
              <Button
                variant="contained"
                style={{
                  backgroundColor: theme.palette.primary.mainTheme,
                  color: "white",
                  "&:hover": { background: "red" },
                }}
                onClick={() => handleCloseForPrice()}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                style={{
                  backgroundColor: theme.palette.primary.mainTheme,
                  color: "white",
                  "&:hover": { background: "red" },
                }}
                onClick={() => handleSubmitForPrice()}
              >
                Submit
              </Button>
            </Stack>

            {/* <Button variant="contained" sx={{ bgcolor: theme.palette.primary.mainTheme, marginTop: '5%', '&:hover': { background: 'red' } }} onClick={handleSubmitForPrice}>Submit</Button> */}
          </Stack>
        </Box>
      </Modal>
      <Modal
        open={openForMultiplePrice}
        onClose={handleCloseForPrice}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleForModal}>
          <Stack direction="column" spacing={2}>
            <h2>Bulk Attribute Change</h2>

            <AttributeBulkUodate
              selectedRows={selectedRows}
              handleCloseForMultiplePrice={handleCloseForMultiplePrice}
            />
            {/* <FormHelperText id="my-helper-text" sx={{ color: 'red', fontSize: '15px' }}>{priceError}</FormHelperText> */}
            {/* <Stack direction='row' spacing={1} m={3} align='center' justifyContent="space-evenly">
            <Button variant="contained" style={{ backgroundColor: theme.palette.primary.mainTheme, color: 'white', "&:hover": { background: "red" } }} onClick={() => handleCloseForMultiplePrice()}>Cancel</Button>
            <Button variant="contained" style={{ backgroundColor: theme.palette.primary.mainTheme, color: 'white', "&:hover": { background: "red" } }} onClick={() => handleSubmitForPrice()}>Submit</Button>
          </Stack> */}

            {/* <Button variant="contained" sx={{ bgcolor: theme.palette.primary.mainTheme, marginTop: '5%', '&:hover': { background: 'red' } }} onClick={handleSubmitForPrice}>Submit</Button> */}
          </Stack>
        </Box>
      </Modal>
      <Box
        sx={{
          height: 590,
          width: "100%",
          "& .actions": {
            color: "text.secondary",
          },
          "& .textPrimary": {
            color: "text.primary",
          },
        }}
      >
        <Stack
          direction="row"
          style={{ display: "flex", justifyContent: "space-between" }}
          m={3}
        >
          <h2>All Store Product</h2>
          <div>
            <small>
              <a
                href="https://docs.google.com/spreadsheets/d/1evaFfmd4vLFhDDKyWnhw1RiLcbIuLm7UG-io7jiPbw4/edit?usp=sharing"
                target="_blank"
              >
                Bulk Upload Product Sample Sheet
              </a>
            </small>{" "}
            <br></br>
            <small>
              <a
                href="https://docs.google.com/spreadsheets/d/1mOZMY8eNDd8D5EjILRZkEROWAnIb1c1MZdvYvTSoyOo/edit?usp=sharing"
                target="_blank"
              >
                Bulk Update Product Sample Sheet
              </a>
            </small>
          </div>
          <Stack direction="row" spacing={2}>
            {access?.accessForTab == "readWrite" ||
            access?.access.includes("all") ? (
              <Button
                variant="contained"
                sx={{
                  bgcolor: theme.palette.primary.mainTheme,
                  "&:hover": { background: "red" },
                }}
                onClick={handleUploadExcelForUpdate}
              >
                Update From Excel{" "}
              </Button>
            ) : (
              <></>
            )}

            {access?.accessForTab == "readWrite" ||
            access?.access.includes("all") ? (
              <Button
                variant="contained"
                sx={{
                  bgcolor: theme.palette.primary.mainTheme,
                  "&:hover": { background: "red" },
                }}
                onClick={handleUploadExcel}
              >
                Import From Excel
              </Button>
            ) : (
              <></>
            )}
            {access?.accessForTab == "readWrite" ||
            access?.access.includes("all") ? (
              <Button
                variant="contained"
                sx={{
                  bgcolor: theme.palette.primary.mainTheme,
                  "&:hover": { background: "red" },
                }}
                disabled={selectedRows.length == 0}
                onClick={handleOpenForMultiplePrice}
              >
                Set Attributes
              </Button>
            ) : (
              <></>
            )}
            {access?.accessForTab == "readWrite" ||
            access?.access.includes("all") ? (
              <Button
                variant="contained"
                sx={{
                  bgcolor: theme.palette.primary.mainTheme,
                  "&:hover": { background: "red" },
                }}
                disabled={selectedRows.length == 0}
                onClick={handleOpenForPrice}
              >
                Set Price
              </Button>
            ) : (
              <></>
            )}
            <input
              type="file"
              id="fileInput"
              accept=".xlsx, .xls"
              onChange={handleFileUpload}
              style={{ display: "none" }}
            />
            <input
              type="file"
              id="fileInput2"
              accept=".xlsx, .xls"
              onChange={handleFileUploadForUpdate}
              style={{ display: "none" }}
            />
          </Stack>
        </Stack>
        <div className="mainDiv">
          <div className="childDiv" style={{ width: `${progress}%` }}>
            <span>{progress}% </span>
          </div>
        </div>
        <DataGrid
          getRowHeight={() => "auto"}
          rows={rowsWithSerialNumber || []}
          columns={columns || []}
          autoHeight={true}
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          editMode="row"
          checkboxSelection
          onRowSelectionModelChange={handleSelectionModelChange}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 25,
              },
            },
          }}
          pageSizeOptions={[25, 50, 100]}
          slots={{
            toolbar: (gridProps) => (
              <CustomToolbar
                {...gridProps}
                rows={rows}
                columns={columns || []}
              />
            ),
          }}
          slotProps={{
            toolbar: { setRows, setRowModesModel, showQuickFilter: true },
          }}
        />

        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Box>
    </>
  );
}
