import { Button, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Page from "../../../components/Page";
import { useNavigate, useParams } from "react-router-dom";
import TestCard from "./components/TestCard";
import TestSeriesDetailsCard from "./components/TestSeriesDetailsCard";
import SyncLoader from "react-spinners/SyncLoader";

import axios from "axios";
import { useTheme } from "@emotion/react";
import getAccess from "../../../utils/getAccess";

const TestSeriesDetails = () => {
  const access = getAccess();
  // console.log(access)
  const navigate = useNavigate();
  const { testSeriesId } = useParams();

  const theme = useTheme();
  //Loading
  const [loading, setLoading] = useState(true);

  //Test Info
  const [test, setTest] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchtestSeriesDetails() {
      // const fullResponse = await fetch(`https://api-prod.gyaankulam.com/api/v1/adminPanel/getTestForTestSeriesDetails?TestSeries_id=${testSeriesId}`, {
      //     method: 'GET',
      //     headers: {
      //         'Content-Type': 'application/json',
      //         Authorization: `Bearer ${token}`
      //     },
      // });

      // const responseJson = await fullResponse.json();
      // setLoading(false)
      // setTest(responseJson?.data);
      axios
        .get(
          `https://api-prod.gyaankulam.com/api/v1/adminPanel/getTestForTestSeriesDetails?TestSeries_id=${testSeriesId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setLoading(false);
          setTest(res?.data?.data);
          //console.log(res)
        });
    }
    fetchtestSeriesDetails();
  }, []);

  //console.log(testSeriesId)

  return (
    <>
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          {" "}
          <SyncLoader
            color={theme.palette.primary.mainTheme}
            loading={loading}
            size={20}
          />
        </Stack>
      ) : (
        <Page title="Test Series">
          <Grid container pl={5} pr={3} spacing={4}>
            <Grid item xs={12} sm={6} md={8}>
              <Stack
                direction="row"
                mb={5}
                justifyContent="space-between"
                sx={{ width: "100%" }}
              >
                <Typography variant="h3">All Test</Typography>
                {(access?.access?.includes("34") ||
                  access?.access.includes("all")) && (
                  <Button
                    variant="contained"
                    sx={{
                      width: "20%",
                      alignSelf: "center",
                      background: theme.palette.primary.mainTheme,
                      "&:hover": {
                        background: theme.palette.primary.mainTheme,
                      },
                    }}
                    onClick={() =>
                      navigate(
                        `/dashboard/test/testSeriesDetails/addTest/${testSeriesId}`
                      )
                    }
                  >
                    Add Test
                  </Button>
                )}
              </Stack>
              <Stack direction="column">
                {test?.length !== 0 ? (
                  test?.map((item, index) => (
                    <TestCard
                      key={index}
                      data={item}
                      setLoading={setLoading}
                      testSeriesId={testSeriesId}
                    />
                  ))
                ) : (
                  <Typography variant="h4">No Tests</Typography>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TestSeriesDetailsCard testSeriesId={testSeriesId} />
            </Grid>
          </Grid>
        </Page>
      )}
    </>
  );
};

export default TestSeriesDetails;
