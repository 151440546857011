import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Stack,
  IconButton,
  InputAdornment,
  Typography,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Select from "react-select";
import makeAnimated from "react-select/animated";
// components

import { FormProvider, RHFTextField } from "../../../components/hook-form";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTheme } from "@emotion/react";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
// ----------------------------------------------------------------------

export default function AddCenterLectureResources() {
  const theme = useTheme();
  const animatedComponents = makeAnimated();
  const navigate = useNavigate();
  const [fetchMessage, setFetchMessage] = useState();
  const [subject, setSubject] = useState();
  const [loading, setLoading] = useState(false);
  const [positive, setPositive] = useState(false);
  const [focus, setFocused] = useState(false);
  const [pdf, setPdf] = useState(false);
  const [fileSelected, setFileSelected] = useState(false);
  const [msg, setMsg] = useState(false);
  const [val, setVal] = useState(false);
  // const { lectId, batchId } = useParams()
  const RegisterSchema = Yup.object().shape({
    title: Yup.string().required("Title required"),
    // batch: Yup.string().required('batch required'),
    // lecture : Yup.string().required("Lecture required"),
    resurce_type: Yup.string().required("Type is required"),
    // file_url: Yup.string().required('Url  is required'),
    is_active: Yup.boolean().required("Status Required"),
    language: Yup.string().required("Language is required"),
  });

  const defaultValues = {
    title: "",
    resurce_type: "",
    // file_url: '',
    is_active: true,
    language: "enhi",
  };
  const [file, setFile] = useState();
  //role
  const role = localStorage.getItem("role");

  const changeHandler = async (event) => {
    if (!type) {
      setFile(event.target?.files[0]);
    } else {
      setFile(event.target?.value);
    }
    // setFile(event.target?.files[0])
    setFileSelected(true);
  };
  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    setLoading(true);
    const formData = new FormData();
    if (!type) {
      formData.append("resurce_type", typeName);
      formData.append("file", file);
    }
    if (type) {
      formData.append("resurce_type", typeName);
      formData.append("link", file);
    }
    formData.append("batch_id", batchId?._id);
    if (selectedLectures.length === 1) {
      formData.append("lectures", selectedLectures[0]?._id);
      formData.append("lectures", "");
    } else {
      selectedLectures?.map((item) => formData.append("lectures", item?._id));
    }
    formData.append("title", data?.title);

    role === "Admin"
      ? formData.append("is_Verified", true)
      : formData.append("is_Verified", false);

    formData.append("language", data?.language);
    formData.append("is_active", data?.is_active);

    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${authToken}`,
      },
    };

    axios
      .post(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/postCenterLectureResource`,
        formData,
        config
      )
      .then((response) => {
        if (response?.data?.msg == "Resources added in given lectures") {
          toast.success("Resources added in given lectures");
          setLoading(false);
          setTimeout(() => {
            window.location.reload();
          }, 200);
        } else {
          toast.error("Resources not added");
          setLoading(false);
        }
      })
      .catch((e) => {
        //console.log(e);
      });
  };
  const [users, usersSet] = useState([]);
  const [type, setType] = useState(false);
  const [typeName, setTypeName] = useState("");
  const [batches, setBatches] = useState([]);
  const [batchId, setBatchId] = useState("");
  const [lectures, setLectures] = useState([]);
  const [selectedLectures, setSelectedLectures] = useState([]);
  const handleChangeLectures = (data) => {
    setSelectedLectures(data);
  };
  const handleChangeBatch = (data) => {
    setBatchId(data);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/getCategory?type=Resources`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      usersSet(responseJson?.data);
      const res = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/get-select-subject`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const resSubject = await res.json();
      setSubject(resSubject?.data);
      const resBatch = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/getBatches`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const resBatchesData = await resBatch.json();
      let batchData = resBatchesData?.data?.filter(
        (item) => item?.is_active == true
      );
      setBatches(batchData);
      setLoading(false);
    }

    fetchUsers();
  }, []);

  useEffect(() => {
    // console.log("11"+batchId?._id)
    const token = localStorage.getItem("token");
    if (batchId) {
      async function fetchLectures() {
        const fullResponse = await fetch(
          `https://api-prod.gyaankulam.com/api/v1/adminPanel/getLectures?BatchId=${batchId?._id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const responseJson = await fullResponse.json();
        setLectures(responseJson?.data);
      }
      fetchLectures();
    }
  }, [batchId]);

  return (
    <>
      <Stack spacing={3} px={5}>
        <Typography variant="h3">Add Resource</Typography>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} sx={{ width: "70%" }}>
            <RHFTextField name="title" label="Title" />
            <Stack direction="row" spacing={3}>
              <Stack direction="row" width="100%">
                <InputLabel
                  sx={{
                    width: "20%",
                    marginRight: "20px",
                    alignSelf: "center",
                  }}
                >
                  Select Batch
                </InputLabel>
                <Stack direction="column" sx={{ width: "100%" }}>
                  <Select
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    value={batchId}
                    onChange={handleChangeBatch}
                    isMulti={false}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    options={batches}
                  />
                </Stack>
              </Stack>
            </Stack>
            <Stack direction="row">
              <InputLabel
                sx={{ width: "20%", marginRight: "20px", alignSelf: "center" }}
              >
                Select Lectures
              </InputLabel>
              <Stack direction="column" sx={{ width: "100%" }}>
                <Select
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  value={selectedLectures}
                  onChange={handleChangeLectures}
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  isMulti
                  options={lectures}
                />
                {selectedLectures.length == 0 && (
                  <Typography
                    sx={{
                      fontSize: "12px",
                      marginTop: "5px",
                      marginLeft: "10px",
                      color: "red",
                    }}
                  >
                    {msg}
                  </Typography>
                )}
              </Stack>
            </Stack>

            <RHFTextField name="resurce_type" label="Resource type" select>
              <MenuItem
                onClick={() => {
                  setType(true);
                  setTypeName("yt_videos");
                }}
                value="yt_videos"
                defaultValue="yt_videos"
              >
                Youtube Link
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setType(true);
                  setTypeName("link");
                }}
                value="link"
              >
                Link
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setType(false);
                  setTypeName("video");
                }}
                value="video"
              >
                Video
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setType(false);
                  setTypeName("pdf");
                }}
                value="pdf"
              >
                PDF
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setType(false);
                  setTypeName("DPP");
                }}
                value="DPP"
              >
                DPP
              </MenuItem>
            </RHFTextField>

            <Stack direction="row" spacing={3}>
              <Stack direction="column" spacing={0} sx={{ width: "100%" }}>
                <RHFTextField
                  name="file"
                  onChange={changeHandler}
                  sx={{ width: "100%" }}
                  error={!fileSelected && val}
                  label="Resource"
                  onFocus={() => setFocused(true)}
                  onBlur={() => setFocused(false)}
                  inputProps={{
                    accept:
                      typeName == "pdf" || typeName == "DPP"
                        ? "application/pdf"
                        : "video/*",
                  }}
                  helperText={
                    type
                      ? typeName == "link"
                        ? "Links Only"
                        : "Youtube Videos link"
                      : typeName == "pdf" || typeName == "DPP"
                      ? "Pdf Files Only"
                      : "Video Files Only"
                  }
                  InputProps={{
                    classes: {
                      input: "CustomTextField",
                    },
                  }}
                  onClick={() => setPdf(true)}
                  type={pdf || focus ? (type ? "url" : "file") : "text"}
                />
                {!fileSelected && (
                  <Typography
                    sx={{
                      fontSize: "12px",
                      marginTop: "5px",
                      marginLeft: "10px",
                      color: "red",
                    }}
                  >
                    {msg}
                  </Typography>
                )}
              </Stack>
              {/*
                <RHFTextField name="language" label="Language" select>
                  <MenuItem value="en">English</MenuItem>
                  <MenuItem value="hi">Hindi</MenuItem>
                  <MenuItem value="enhi">Bilingual</MenuItem>
                </RHFTextField>
              */ }
              <RHFTextField name="is_active" label="Status" select>
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </RHFTextField>
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
              sx={{
                bgcolor: theme.palette.primary.mainTheme,
                marginTop: "5%",
                "&:hover": { background: theme.palette.primary.mainTheme },
              }}
              onClick={() => {
                setMsg("Required");
                setVal(true);
              }}
            >
              Add
            </LoadingButton>
          </Stack>
          {/* <Typography variant='h4'>{fetchMessage}</Typography> */}
        </FormProvider>
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {/* <Typography color={positive ? "green" : "red"} sx={{ textAlign: 'center', width: '70%', textTransform: 'capitalize' }}>{fetchMessage}</Typography> */}
      </Stack>
    </>
  );
}
