import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Stack,
  IconButton,
  InputAdornment,
  Typography,
  MenuItem,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components

import { FormProvider, RHFTextField } from "../../../components/hook-form";
import axios from "axios";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ToastContainer, toast } from "react-toastify";

// import "./general.css";
import { useTheme } from "@emotion/react";
import RHFTextEditor from "../../../components/hook-form/RHFTextEditor";

// ----------------------------------------------------------------------

export default function EditScholarshipTest() {
  const { id } = useParams();
  const theme = useTheme();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [restrict, setRestrict] = useState("");
  //   const [focus, setFocused] = useState(false);
  const [pdf, setPdf] = useState(false);
  const [msg, setMsg] = useState("");
  const [val, setVal] = useState(false);
  const [focus, setFocused] = useState(false);
  const [startingAt, setStartingAt] = useState(dayjs());
  const [registrationEndAt, setRegistrationEndAt] = useState(dayjs());
  const [resultDeclaration, setResultDeclaration] = useState(dayjs());
  const [values, setValues] = useState();

  const [list, setList] = useState([]);

  const RegisterSchema = Yup.object().shape({});

  const defaultValues = {
    title: "",
    description: "",
    duration: "",
  };
  const [file, setFile] = useState();

  const handleChange1 = (value) => {
    // console.log(`${value}`);
    setStartingAt(value);
  };
  const handleChange2 = (value) => {
    // console.log(`${value}`);
    setRegistrationEndAt(value);
  };
  const handleChange3 = (value) => {
    // console.log(`${value}`);\
    setResultDeclaration(value);
  };
  const changeHandler = async (event) => {
    setFile(event.target?.files);
    var fileName = document.getElementById("file").value?.toLowerCase();
    if (fileName.endsWith(".pdf") || fileName.endsWith(".jpg")) {
      setRestrict("");
    } else {
      setRestrict("JPG Or PDF");
    }
    //console.log(file)
  };
  //console.log(file)
  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const [progress, setProgress] = useState();
  // for progress bar

  const onSubmit = async (data) => {
    if (file) {
      const formData = new FormData();

      setLoading(true);
      // debugger;
      //console.log(file)
      Object.values(file).forEach((file) => {
        formData.append("file", file);
      });
      formData.append("title", data?.title);
      formData.append("duration", data?.duration);
      formData.append("description", data?.description);
      formData.append("quizId", data?.quizId);
      formData.append("link", data?.link);
      formData.append("startingAt", startingAt?.$d);
      formData.append("resultDeclaration", resultDeclaration?.$d);
      formData.append("registrationEndAt", registrationEndAt?.$d);
      const token = localStorage.getItem("token");
      const authToken = token;
      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${authToken}`,
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setProgress(Math.round((100 * data?.loaded) / data?.total));
        },
      };
      // document.querySelector(".mainDiv").style.display = "block";
      for (let Pair of formData) {
        console.log(Pair[0] + " " + Pair[1]);
      }
      setProgress(0);
      axios
        .post(
          `https://api-prod.gyaankulam.com/api/v1/adminPanel/createScholarshipTest`,
          formData,
          config
        )
        .then((response) => {
          //console.log(response?.data);
          if (response?.data?.msg === "New Scholar ship Test added") {
            toast.success(response?.data?.msg);
            setLoading(false);
            setPdf(false);
            reset();
            // window.location.href = "/dashboard/";
            window.location.reload();
          }
        })
        .catch((e) => {
          //console.log(e);
        });
    }
  };
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchDetails() {
      const fullResponse = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/getQuizes?isActive=${true}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      setList(responseJson?.data);
    }
    fetchDetails();
    async function fetchTest() {
      const fullResponse = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/getScholarshipTest/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      //   console.log(responseJson);
      setValues((prev) => ({
        ...prev,
        title: responseJson?.data?.title,
        description: responseJson?.data?.description,
        duration: responseJson?.data?.duration,
        quizId: responseJson?.data?.quizId?.id,
        startingAt: responseJson?.data?.startingAt,
        registrationEndAt: responseJson?.data?.registrationEndAt,
        resultDeclaration: responseJson?.data?.resultDeclaration,
      }));
    }
    fetchTest();

    setLoading(false);
  }, []);
  // console.log(values);
  return (
    <>
      <Stack spacing={3} px={5}>
        <Typography variant="h3">Add Scholarship Test</Typography>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} sx={{ width: "70%" }}>
            <RHFTextField name="title" value={values?.title} />
            <Stack direction="row" spacing={3}>
              <RHFTextField
                name="file"
                onChange={changeHandler}
                id="file"
                label="Banner"
                error={!file && val}
                helperText="Image Only"
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                // inputProps={{ multiple: true }}
                InputProps={{
                  classes: {
                    input: "CustomTextField",
                  },
                }}
                onClick={() => setPdf(true)}
                inputProps={{ accept: "image/*" }}
                type={pdf || focus ? "file" : "text"}
              />
              {/* <Typography fontSize="10px" color="red" sx={{ display: restrict == '' ? 'none' : 'block' }}>{restrict}</Typography> */}
              <RHFTextField
                name="duration"
                // label="Duration (in Min)"
                type="number"
                value={values?.duration}
              />
            </Stack>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack direction="row" spacing={3}>
                <DateTimePicker
                  label="Starting At"
                  value={startingAt.$d}
                  onChange={handleChange1}
                  // name="starting_date"
                  renderInput={(params) => (
                    <TextField {...params} sx={{ width: "100%" }} />
                  )}
                />
                <DateTimePicker
                  label="Registration End At"
                  value={registrationEndAt.$d}
                  onChange={handleChange2}
                  // name="starting_date"
                  renderInput={(params) => (
                    <TextField {...params} sx={{ width: "100%" }} />
                  )}
                />
              </Stack>
            </LocalizationProvider>

            <Stack direction="row" spacing={3}>
              <RHFTextField name="quizId" value={values?.quizId} select>
                {list?.map((item, i) => {
                  return (
                    <MenuItem key={i} value={item?._id}>
                      {item?.quiz_title}
                    </MenuItem>
                  );
                })}
              </RHFTextField>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label="Result Declaration"
                  value={resultDeclaration.$d}
                  onChange={handleChange3}
                  // name="starting_date"
                  renderInput={(params) => (
                    <TextField {...params} sx={{ width: "100%" }} />
                  )}
                />
              </LocalizationProvider>

              {/* <RHFTextField name="isActive" label="Status" type='checkbox' select >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </RHFTextField> */}
            </Stack>
            <RHFTextEditor name="description" label="Description" />

            {/* <div className="mainDiv">
                        <div className="childDiv" style={{ width: `${progress}%` }}>
                            <span> </span>
                        </div>
                    </div> */}

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
              sx={{
                bgcolor: theme.palette.primary.mainTheme,
                marginTop: "5%",
                "&:hover": { background: "red" },
              }}
              onClick={() => {
                setVal(true);
                setMsg("Required");
              }}
            >
              Add
            </LoadingButton>
          </Stack>
        </FormProvider>
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {/* <Typography color={positive ? "green" : "red"} sx={{ textAlign: 'center', width: '80%' }}>{fetchMessage}</Typography> */}
      </Stack>
    </>
  );
}
