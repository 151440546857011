import { filter } from "lodash";
// import { sentenceCase } from 'change-case';
import { useEffect, useState } from "react";
// import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  // Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Button,
  Input,
  MenuItem,
  TextField,
} from "@mui/material";
// components
import Page from "../../../components/Page";

import Scrollbar from "../../../components/Scrollbar";

import SearchNotFound from "../../../components/SearchNotFound";
import { UserListHead, UserListToolbar, UserMoreMenu } from "../user";
// mock
// importusers ? from '../_mock/user';
import { useNavigate } from "react-router-dom";
import SyncLoader from "react-spinners/SyncLoader";
//mui icons
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import axios from "axios";
import { ExportCSV } from "../../../utils/ExportCSV";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

// For Dialog Box
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { CSVLink } from "react-csv"; // for CSV file download
import { useTheme } from "@emotion/react";
import getAccess from "../../../utils/getAccess";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "", label: "S.No", alignRight: false },
  { id: "title", label: "Title", alignRight: false },
  { id: "desc", label: "Description", alignRight: false },
  { id: "type", label: "Type", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "created", label: "Created At", alignRight: false },
  { id: "action", label: "Action", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.title.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis?.map((el) => el[0]);
}

export default function CustomNotification() {
  const access = getAccess();
  const theme = useTheme();

  // Code for handling Dialog box
  const [download, setDownload] = useState(true);
  const [errorOTP, setErrorOtp] = useState();
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const sendOtp = () => {
    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    const data = {
      action: "Exporting All Student Data",
    };

    axios
      .post(
        `https://api-prod.gyaankulam.com/api/v1/adminTeacher/send-otp`,
        data,
        config
      )
      .then((response) => {})
      .catch((e) => {
        //console.log(e);
      });
  };
  const verifyOtp = () => {
    //console.log(document.getElementById('name').value);
    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    const data = {
      otp: document.getElementById("name").value,
    };

    axios
      .post(
        `https://api-prod.gyaankulam.com/api/v1/adminTeacher/verify-otp`,
        data,
        config
      )
      .then((response) => {
        // //console.log(response);
        if (response?.data?.status == false) {
          setErrorOtp("Invalid Otp");
        } else {
          setDownload(false);
          setErrorOtp("Successfully Verified");
        }
      })
      .catch((e) => {
        //console.log(e);
      });
  };

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();
  const [users, usersSet] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/getAlerts`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      usersSet(responseJson?.data);
      setLoading(false);
    }

    fetchUsers();
  }, []);

  const handleDelete = async (id) => {
    const token = localStorage.getItem("token");

    // await fetch(`https://api-prod.gyaankulam.com/api/v1/adminPanel/deleteAlert/${id}`, {
    //   // await fetch(`http://localhost:3001/api/v1/${section}/${req}/${id}`, {
    //   method: 'DELETE',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     Authorization: `Bearer ${token}`
    //   },

    // });

    // toast.success('Deleted')
    // setTimeout(() => window.location.reload()
    //   , 1000)
    // const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    const data = {
      id: id,
    };
    axios
      .delete(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/deleteAlert/${id}`,
        config
      )
      .then((response) => {
        // Handle the success response
        toast.success(response?.data?.msg);
        //console.log("Delete request successful", response);
        setTimeout(() => window.location.reload(), 1000);
      })
      .catch((error) => {
        // Handle the error response
        toast.success(error);
        console.error("Error deleting resource", error);
      });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target?.checked) {
      const newSelecteds = users?.map((n) => n?.title);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected?.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected?.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected.concat(selected?.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected?.slice(0, selectedIndex),
        selected?.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target?.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target?.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users?.length) : 0;

  const filteredUsers = applySortFilter(
    users ? users : ["no"],
    getComparator(order, orderBy),
    filterName
  );

  let helperArray = [];
  const isUserNotFound =
    filteredUsers?.length === 0 || filteredUsers[0] === "no";

  filteredUsers?.map((item) =>
    helperArray.push({
      ID: item?._id,
      Title: item?.title,
      Is_active: item?.is_active,
    })
  );

  return (
    <>
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "60vh" }}
        >
          {" "}
          <SyncLoader
            color={theme.palette.primary.mainTheme}
            loading={loading}
            size={20}
          />
        </Stack>
      ) : (
        <Page title="Coupons">
          <Container sx={{ position: "absolute", minWidth: "100%" }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={3}
            >
              <Typography variant="h3" gutterBottom>
                All Custom Alert
              </Typography>
              {(access?.access?.includes("688") ||
                access?.access.includes("all")) && (
                <Button
                  variant="contained"
                  sx={{
                    width: "20%",
                    alignSelf: "center",
                    background: theme.palette.primary.mainTheme,
                    "&:hover": { background: "red" },
                  }}
                  onClick={() => navigate("/dashboard/addCustomNotification")}
                >
                  Add Alert
                </Button>
              )}
            </Stack>

            <Card>
              <Stack
                pr={3}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <UserListToolbar
                  numSelected={selected?.length}
                  filterName={filterName}
                  onFilterName={handleFilterByName}
                />

                {/* <ExportCSV csvData={users} fileName="Notes Data" /> */}

                {/* <Button variant="outlined" onClick={ () => { handleClickOpen(); sendOtp(); }}>
                Export
              </Button> */}
                <Dialog open={open} onClose={handleClose}>
                  {download ? (
                    <>
                      <DialogTitle>OTP Varification</DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          OTP is send successfully to your regisered e-mail.
                        </DialogContentText>
                        <TextField
                          autoFocus
                          margin="dense"
                          id="name"
                          label="OTP Number"
                          type="number"
                          fullWidth
                          variant="standard"
                        />
                        <DialogContentText
                          sx={{
                            color: "red",
                            fontSize: "12px",
                            padding: "3px",
                          }}
                        >
                          {errorOTP}
                        </DialogContentText>
                      </DialogContent>

                      <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={verifyOtp}>Verify</Button>
                      </DialogActions>
                    </>
                  ) : (
                    <>
                      <DialogTitle>Download File</DialogTitle>
                      <DialogContent>
                        <CSVLink data={users} filename="AllCoupons.csv">
                          Download
                        </CSVLink>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={() => {
                            handleClose();
                            setDownload(true);
                          }}
                        >
                          Cancel
                        </Button>
                      </DialogActions>
                    </>
                  )}
                </Dialog>
              </Stack>

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={users?.length}
                      numSelected={selected?.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {filteredUsers[0] !== "no" &&
                        filteredUsers
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          ?.map((row, index) => {
                            const {
                              id,
                              user,
                              title,
                              desc,
                              type,
                              isActive,
                              createdAt,
                            } = row;

                            return (
                              <TableRow
                                hover
                                key={id}
                                tabIndex={-1}
                                role="checkbox"
                              >
                                <TableCell>
                                  <Typography variant="subtitle2">
                                    {index + 1}
                                  </Typography>
                                </TableCell>
                                <TableCell component="th" scope="row" pl={2}>
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={2}
                                  >
                                    <Typography variant="subtitle2" noWrap>
                                      {title}
                                    </Typography>
                                  </Stack>
                                </TableCell>
                                <TableCell component="th" scope="row" pl={2}>
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={2}
                                  >
                                    <Typography variant="subtitle2" noWrap>
                                      {desc}
                                    </Typography>
                                  </Stack>
                                </TableCell>
                                <TableCell component="th" scope="row" pl={2}>
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={2}
                                  >
                                    <Typography variant="subtitle2" noWrap>
                                      {type}
                                    </Typography>
                                  </Stack>
                                </TableCell>
                                <TableCell component="th" scope="row" pl={2}>
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={2}
                                  >
                                    <Typography variant="subtitle2" noWrap>
                                      {isActive ? "Active" : "In Active"}
                                    </Typography>
                                  </Stack>
                                </TableCell>
                                <TableCell align="left">
                                  {createdAt.split("T")[0]}
                                </TableCell>
                                <TableCell align="left">
                                  <Button
                                    onClick={() => {
                                      handleDelete(id);
                                    }}
                                  >
                                    Delete
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>
              <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={users?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </Container>
        </Page>
      )}
    </>
  );
}
