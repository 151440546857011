import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AddStoreProductFirst from './AddStoreProductFirst';
import AddProductSecond from './components/AddProductSecond';
import AddProductFourth from './components/AddProductFourth';
import AddProductThird from './components/AddProductThird';
import AddProductFifth from './components/AddProductFifth';







function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;


    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const AddStoreProduct = () => {
    const [value, setValue] = React.useState(0);
    const [title, setTitle] = React.useState("")
    const [productId, setProductId] = React.useState();
    const [submit1, setSubmit1] = React.useState(false);
    const [submit2, setSubmit2] = React.useState(false);
    const [submit3, setSubmit3] = React.useState(false);
    const [submit4, setSubmit4] = React.useState(false);
    const [submit5, setSubmit5] = React.useState(false);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // console.log(productId)
    return (
        <Box sx={{ width: '100%' }}>

            <Typography variant='h3' mb={3}>Add Store Product</Typography>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Add Product Basic Details" {...a11yProps(0)} />
                    <Tab
                        disabled={!submit1}
                        label="Add Product Details Second" {...a11yProps(1)} />
                    <Tab
                        disabled={!submit2}
                        label="Add Product Details Third" {...a11yProps(2)} />
                    <Tab
                        disabled={!submit3}
                        label="Add Product Details Fourth" {...a11yProps(3)} />
                    <Tab
                        disabled={!submit4}
                        label="Add Product Details Five" {...a11yProps(4)} />
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <AddStoreProductFirst productId={productId} value={value} setProductId={setProductId} setValue={setValue} setTitle={setTitle} setSubmit1={setSubmit1} />
            </CustomTabPanel>
            <CustomTabPanel
                style={{ display: submit1 ? 'block' : 'none' }}
                value={value} index={1}>
                <AddProductSecond productId={productId} value={value} setProductId={setProductId} setValue={setValue} title={title} setSubmit2={setSubmit2} />
            </CustomTabPanel>


            <CustomTabPanel
                style={{ display: submit2 ? 'block' : 'none' }}
                value={value} index={2}>
                <AddProductThird productId={productId} value={value} setProductId={setProductId} setValue={setValue} title={title} setSubmit3={setSubmit3} />
            </CustomTabPanel>



            <CustomTabPanel
                style={{ display: submit3 ? 'block' : 'none' }}
                value={value} index={3}>
                <AddProductFourth productId={productId} value={value} setProductId={setProductId} setValue={setValue} title={title} setSubmit4={setSubmit4} />
            </CustomTabPanel>


            <CustomTabPanel
                style={{ display: submit4 ? 'block' : 'none' }}
                value={value} index={4}>
                <AddProductFifth productId={productId} value={value} setProductId={setProductId} setValue={setValue} title={title} />
            </CustomTabPanel>




        </Box>
    );
}

export default AddStoreProduct