import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import AddStoreProductFirst from "./AddStoreProductFirst";
import AddProductSecond from "./components/AddProductSecond";
import AddProductFourth from "./components/AddProductFourth";
import AddProductThird from "./components/AddProductThird";
import AddProductFifth from "./components/AddProductFifth";
import { useParams } from "react-router-dom";
import UpdateFirst from "./components/UpdateFirst";
import UpdateSecond from "./components/UpdateSecond";
import UpdateThird from "./components/UpdateThird";
import UpdateFourth from "./components/UpdateFourth";
import UpdateFifth from "./components/UpdateFifth";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const UpdateStoreProduct = () => {
  const [value, setValue] = React.useState(0);
  const [title, setTitle] = React.useState("");
  const [productId, setProductId] = React.useState();
  const [submit1, setSubmit1] = React.useState(false);
  const [submit2, setSubmit2] = React.useState(false);
  const [submit3, setSubmit3] = React.useState(false);
  const [submit4, setSubmit4] = React.useState(false);
  const [submit5, setSubmit5] = React.useState(false);
  const [details, setDetails] = React.useState();
  const [editValue, setEditValue] = React.useState();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { id } = useParams();
  const token = localStorage.getItem("token");
  async function fetchUsers() {
    const fullResponse = await fetch(
      `https://api-prod.gyaankulam.com/api/v1/store/getStoreProductById/${id}`,
      // `https://api-prod.gyaankulam.com/api/v1/store/getProductCategoryByIdAdmin/${editData?.id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const responseJson = await fullResponse.json();
    // console.log('line91', responseJson)
    setDetails(responseJson?.data);

    setEditValue((prev) => ({
      ...prev,
      id: responseJson?.data?._id,
      title: responseJson?.data?.title,
      tags: responseJson?.data?.tags,
      category: responseJson?.data?.category?._id,
      categoryName: responseJson?.data?.category?.title,
      badge: responseJson?.data?.badge,
      code: responseJson?.data?.code,
      desc: responseJson?.data?.desc,
      language: responseJson?.data?.language,
      slug: responseJson?.data?.slug,
      productType: responseJson?.data?.productType,
      inStock: responseJson?.data?.inStock,
      maxPurchaseQty: responseJson?.data?.maxPurchaseQty,
      deliveryType: responseJson?.data?.deliveryType,
      regularPrice: responseJson?.data?.regularPrice,
      salePrice: responseJson?.data?.salePrice,
      maxAllowedCoins: responseJson?.data?.maxAllowedCoins,
      images: responseJson?.data?.images,
      metaTitle: responseJson?.data?.metaTitle,
      metaDesc: responseJson?.data?.metaDesc,
      varitions: responseJson?.data?.varitions,
      attributes: responseJson?.data?.attributes,
      marketingCat: responseJson?.data?.marketingCat,
      isCoinApplicable: responseJson?.data?.isCoinApplicable,
      isActive: responseJson?.data?.isActive,
      keyFeature: responseJson?.data?.keyFeature ?? "",
    }));
  }

  React.useEffect(() => {
    fetchUsers();
  }, []);

  const onChange2 = (e) => {
    const { name, value } = e.target;
    // console.log('11'+ name + ""+ value)
    setEditValue((prev) => ({ ...prev, [name]: value }));
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Typography variant="h3" mb={3}>
        Update Store Product
      </Typography>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Update Product Basic Details" {...a11yProps(0)} />
          <Tab
            //  disabled={!submit1}
            label="Update Product Details Second"
            {...a11yProps(1)}
          />
          <Tab
            //  disabled={!submit2}
            label="Update Product Details Third"
            {...a11yProps(2)}
          />
          <Tab
            //  disabled={!submit3}
            label="Update Product Details Fourth"
            {...a11yProps(3)}
          />
          <Tab
            //  disabled={!submit4}
            label="Update Product Details Five"
            {...a11yProps(4)}
          />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <UpdateFirst
          productId={productId}
          value={value}
          setProductId={setProductId}
          setValue={setValue}
          setTitle={setTitle}
          setSubmit1={setSubmit1}
          details={details}
          onChange2={onChange2}
          editValue={editValue}
          fetchUsers={fetchUsers}
        />
      </CustomTabPanel>
      <CustomTabPanel
        // style={{ display: submit1 ? 'block' : 'none' }}
        value={value}
        index={1}
      >
        <UpdateSecond
          productId={productId}
          value={value}
          setProductId={setProductId}
          setValue={setValue}
          title={title}
          setSubmit2={setSubmit2}
          onChange2={onChange2}
          editValue={editValue}
          fetchUsers={fetchUsers}
        />
      </CustomTabPanel>

      <CustomTabPanel
        // style={{ display: submit2 ? 'block' : 'none' }}
        value={value}
        index={2}
      >
        <UpdateThird
          productId={productId}
          value={value}
          setProductId={setProductId}
          setValue={setValue}
          title={title}
          setSubmit3={setSubmit3}
          onChange2={onChange2}
          editValue={editValue}
          fetchUsers={fetchUsers}
        />
      </CustomTabPanel>

      <CustomTabPanel
        //  style={{ display: submit3 ? 'block' : 'none' }}
        value={value}
        index={3}
      >
        <UpdateFourth
          productId={productId}
          value={value}
          setProductId={setProductId}
          setValue={setValue}
          title={title}
          setSubmit4={setSubmit4}
          onChange2={onChange2}
          editValue={editValue}
          fetchUsers={fetchUsers}
        />
      </CustomTabPanel>

      <CustomTabPanel
        //  style={{ display: submit4 ? 'block' : 'none' }}
        value={value}
        index={4}
      >
        <UpdateFifth
          productId={productId}
          value={value}
          setProductId={setProductId}
          setValue={setValue}
          title={title}
          onChange2={onChange2}
          editValue={editValue}
          fetchUsers={fetchUsers}
        />
      </CustomTabPanel>
    </Box>
  );
};

export default UpdateStoreProduct;
