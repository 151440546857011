import * as React from "react";
import { useState } from "react";
import { Box } from "@mui/material";

import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  GridToolbarDensitySelector,
  useGridApiContext,
  gridExpandedSortedRowIdsSelector,
  GridActionsCellItem,
} from "@mui/x-data-grid";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { ToastContainer, toast } from "react-toastify";
import Modal from "@mui/material/Modal";
import { useTheme } from "@emotion/react";
import axios from "axios";
import StoreOrderUpdate from "./StoreOrderUpdate";
import getAccess from "../../../utils/getAccess";

// ----------------------------------------------------------------------
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "20px",
  p: 4,
};

const flattenObject = (obj) => {
  return `${obj.name} ${obj.email} ${obj.phone}`;
};
const flattenArray = (arr) => {
  return arr
    ?.map(
      (item) =>
        `Title - ${item?.title.replaceAll(",", "")} Quantity - ${
          item?.quantity
        }  Code - ${item?.code}`
    )
    .join("; ");
};
const removeComma = (string) => {
  return string.replaceAll(",", "");
};
function CustomToolbar({ rows, columns, ...gridProps }) {
  const { setQuickFilter, ...otherProps } = gridProps;
  // debugger;
  const extractSearchableFields = (row) => {
    const userFields = ["name", "email", "phone"]; // User details fields
    const productFields = ["title", "code"]; // Product details fields

    const userValues = userFields?.map((field) => row.user[field]);
    const productValues = row.productDetails.flatMap((detail) =>
      productFields?.map((field) => detail[field])
    );

    return [...userValues, ...productValues];
  };
  const apiRef = useGridApiContext();
  // console.log(gridProps)
  const exportAllRows = async () => {
    const visibleRows = gridExpandedSortedRowIdsSelector(apiRef);

    const filteredRows = rows?.filter((row) => visibleRows.includes(row.id));

    const columnIds = columns
      ?.filter((column) => column.field !== "actions")
      ?.map((column) => column.field);
    const csvHeader = columnIds.join(", ");
    const csvContent = filteredRows
      ?.map((row) => {
        const rowValues = columnIds?.map((columnId) => {
          if (columnId === "shippingAddress") {
            return removeComma(row.shippingAddress);
          } else if (columnId === "productDetails") {
            return flattenArray(row.productDetails);
          } else if (columnId === "user") {
            return flattenObject(row.user);
          }
          return row[columnId]?.toString()?.replaceAll(",", " ");
        });
        return rowValues.join(", ");
      })
      .join("\n");
    const fullCsvContent = `${csvHeader}\n${csvContent}`;

    // console.log('Exporting all rows:', fullCsvContent);
    const blob = new Blob([fullCsvContent], { type: `text/csv;charset=utf-8` });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", "StoreOrders.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />

      <GridToolbarDensitySelector />
      <Tooltip title="Export All">
        <IconButton onClick={exportAllRows}>
          <SaveAltIcon style={{ color: "red" }} />
        </IconButton>
      </Tooltip>
      <GridToolbarQuickFilter style={{ marginLeft: "auto" }} />
    </GridToolbarContainer>
  );
}

export default function StoreOrdersTable({ data, type, paid, isLoading }) {
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    const token = localStorage.getItem("token");
    setLoading(true);
    async function fetchOrder() {
      const response = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/store/getAllOrder?type=${type}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responseJson = await response?.json();
      let Orders = responseJson?.data ?? [];
      if (paid == "paid") {
        Orders = Orders.filter((item) => item?.isPaid != false);
        setRows(Orders);
      } else if (paid == "notPaid") {
        Orders = Orders.filter((item) => item?.isPaid != true);
        setRows(Orders);
      } else {
        setRows(responseJson?.data ?? []);
      }
      // setRows(responseJson?.data ?? []);
      setLoading(false);
    }
    fetchOrder();
  }, []);

  const [rowModesModel, setRowModesModel] = React.useState({});
  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows?.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const access = getAccess();

  const [open, setOpen] = useState(false);
  const [close, setClose] = useState(false);
  const [id, setId] = useState("");
  const [orderData, setorderData] = useState("");

  const columns = [
    {
      field: "orderId",
      headerName: "Order Id",
      type: "string",
      width: 100,
      align: "left",
      headerAlign: "left",
      // editable: true,
      renderCell: (params) => (
        <div>
          <span>{params.value ? params.value : "NA"}</span>
        </div>
      ),
    },

    {
      field: "productDetails",
      headerName: "Product Details",
      width: 300,
      align: "left",
      type: "object",
      headerAlign: "left",
      editable: true,
      renderCell: (params) => (
        <div>
          {params.value?.map((detail, index) => (
            <div key={index}>
              <strong>Title:</strong> {detail.title}
              <br />
              <strong>Quantity:</strong> {detail.quantity}
              <br />
              <strong>Code:</strong> {detail?.code}
            </div>
          ))}
        </div>
      ),
      getApplyQuickFilterFn: (value) => {
        return (params) => {
          let flatArray = params?.value
            ?.map(
              (item) =>
                `${item?.title?.replaceAll(",", "")} ${item?.quantity} ${
                  item?.code
                }`
            )
            .join("; ");
          return flatArray.includes(value);
        };
      },
    },
    {
      field: "user",
      headerName: "User Details",
      type: "object",
      width: 250,
      align: "left",
      headerAlign: "left",
      editable: true,
      renderCell: (params) => (
        <div>
          <strong>Name:</strong> {params.value?.name}
          <br />
          <strong>Email:</strong> {params.value?.email}
          <br />
          <strong>Phone:</strong> {params.value?.phone}
        </div>
      ),
      getApplyQuickFilterFn: (value) => {
        return (params) => {
          return (
            params?.value?.name?.includes(value) ||
            params?.value?.email?.includes(value) ||
            params?.value?.phone?.includes(value)
          );
        };
        // return ( params.value?.name.includes(value) )
      },
    },
    {
      field: "deliveryStatus",
      headerName: "Delivery Status",
      type: "string",
      width: 120,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => (
        <div>
          {(
            params?.value?.charAt(0).toUpperCase() + params?.value?.substring(1)
          )
            .split(/(?=[A-Z])/)
            .join(" ") ?? ""}
        </div>
      ),
    },
    {
      field: "awbNumber",
      headerName: "AWB Number",
      type: "string",
      width: 100,
      align: "left",
      headerAlign: "left",
      // editable: true,
    },
    {
      field: "trackingId",
      headerName: "Tracking Id",
      type: "string",
      width: 100,
      align: "left",
      headerAlign: "left",
      // editable: true,
    },
    {
      field: "trackingLink",
      headerName: "Tracking Link",
      type: "string",
      width: 200,
      align: "left",
      headerAlign: "left",
      // editable: true,
    },

    {
      field: "shippingAddress",
      headerName: "Shipping Address",
      type: "string",
      width: 200,
      align: "left",
      headerAlign: "left",
      // editable: true,
    },
    {
      field: "totalAmount",
      headerName: "Total Amount",
      type: "string",
      width: 100,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "paymentStatus",
      headerName: "Payment Status",
      type: "string",
      width: 150,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "isPaid",
      headerName: "Paid",
      width: 100,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => (
        <div style={{ color: params.value == true ? "green" : "red" }}>
          {params.value ? "Paid" : "Not Paid"}
        </div>
      ),
    },
    {
      field: "purchaseDate",
      headerName: "Purchase Date",
      width: 150,
      // type:"dateTime",
      type: "string",
      align: "left",
      headerAlign: "left",
      // valueGetter : ({value}) => value && new Date(value)
      getApplyQuickFilterFn: (value) => {
        // 1-1-2023
        // if(!value || value?.length < 8 ){
        //   return null;
        // }
        return (params) => {
          return params?.value?.includes(value);
        };
      },
    },
    {
      field: "updatedAt",
      headerName: "Updated Date",
      // type: "object",
      type: "Date",
      width: 150,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        const rowData = rows?.find((item) => item?.id === id);
        return [
          access?.accessForTab == "readWrite" ||
          access?.access.includes("all") ? (
            <Tooltip title="Change Status" arrow>
              <GridActionsCellItem
                icon={<AutorenewIcon style={{ color: "green" }} />}
                label="View"
                // onClick={() => navigate(`/dashboard/batchDetails/${id}`)}
                onClick={() => {
                  setId(id);
                  setorderData(rowData);
                  setOpen(!open);
                }}
                color="inherit"
              />
            </Tooltip>
          ) : (
            <></>
          ),
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        height: 590,
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      {/* <h2 style={{ marginBottom: "6px" }}>All Store Orders</h2> */}
      {/* <Button>Paid & UnPaid</Button> */}
      <DataGrid
        rows={rows || []}
        columns={columns || []}
        // loading={isLoading}
        loading={loading}
        // loading={rows?.length === 0}
        getRowHeight={() => "auto"}
        getEstimatedRowHeight={() => 200}
        rowModesModel={rowModesModel}
        autoHeight={true}
        onRowModesModelChange={handleRowModesModelChange}
        processRowUpdate={processRowUpdate}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 25,
            },
          },
        }}
        pageSizeOptions={[25, 50, 100]}
        slots={{
          toolbar: (gridProps) => (
            <CustomToolbar {...gridProps} rows={rows} columns={columns || []} />
          ),
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel, showQuickFilter: true },
        }}
      />
      <div>
        <Modal
          open={open}
          onClose={close}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <StoreOrderUpdate id={id} data={orderData} setOpen={setOpen} />
          </Box>
        </Modal>
      </div>

      {/* <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      /> */}
    </Box>
  );
}
