import * as Yup from "yup";
import { useEffect, useState } from "react";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Stack, Typography, MenuItem } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import { FormProvider, RHFTextField } from "../../../components/hook-form";
import axios from "axios";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTheme } from "@emotion/react";
// ----------------------------------------------------------------------

export default function AddAssignment() {
  const theme = useTheme();

  const [users, usersSet] = useState([]);
  const [fetchMessage, setFetchMessage] = useState();
  const [positive, setPositive] = useState(false);
  const [focusTwo, setFocusedTwo] = useState(false);
  const [showAudio, setShowAudio] = useState(false);
  const [loading, setLoading] = useState(false);

  const [fileName, setFileName] = useState("");
  const RegisterSchema = Yup.object().shape({
    batch: Yup.string().required("Batch required"),
    // file: Yup.string().required('File is required'),
    link: Yup.string().required("Link Required"),
    language: Yup.string().required("Language Required"),
    is_active: Yup.boolean().required("Link Required"),
  });

  const defaultValues = {
    batch: "",
    // file: '',
    link: "",
    language: "",
    is_active: true,
  };
  const [file, setFile] = useState();

  const changeHandler = async (event) => {
    setFile(event.target?.files[0]);
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    //console.log(data);
    setLoading(true);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("batch", data?.batch);
    formData.append("link", data?.link);
    formData.append("language", data?.language);
    formData.append("is_active", data?.is_active);
    // formData.append('fileName', file.name);
    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${authToken}`,
      },
    };
    axios
      .post(`/adminPanel/addAssignmentDetails`, formData, config)
      .then((response) => {
        setLoading(false);

        if (response?.data?.msg === "added Assignment  successfully") {
          toast.success("Added Assignment");
          reset();
        }
      })

      .catch((e) => {
        //console.log(e);
      });

    //console.log(formData);

    // formData.append('file', data?.file[0])
    // let token = localStorage.getItem("token");
    // try {
    //     const res = await fetch(`https://api-prod.gyaankulam.com/api/v1/adminPanel/addAssignmentDetails`, {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             Authorization: `Bearer ${token}`
    //         },
    //         body: JSON.stringify(data)
    //     })
    //     const result = await res.json()
    //     //console.log(result)
    //     setFetchMessage(result?.msg)
    //     if (result?.msg === 'added AIR details successfully') {

    //         setPositive(true)
    //     }
    // } catch (error) {
    //     //console.log(error);
    // }
  };

  //Batch Details
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/getBatchesDetails`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      usersSet(responseJson?.data);
    }
    fetchUsers();
  }, []);

  return (
    <>
      <Stack spacing={3} px={5}>
        {/* <Typography variant='h3'>Add Assignment</Typography> */}
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} sx={{ width: "70%" }}>
            <RHFTextField name="batch" label="Batch name" select>
              {users?.map((item, index) => {
                return (
                  <MenuItem key={index} value={item?.batch_name}>
                    {item?.batch_name}
                  </MenuItem>
                );
              })}
            </RHFTextField>
            <Stack direction="row" spacing={3}>
              <RHFTextField
                label="File"
                name="file"
                onChange={changeHandler}
                onFocus={() => setFocusedTwo(true)}
                onBlur={() => setFocusedTwo(false)}
                InputProps={{
                  classes: {
                    input: "CustomTextField",
                  },
                }}
                onClick={() => setShowAudio(true)}
                type={showAudio || focusTwo ? "file" : "text"}
              />
              <RHFTextField name="link" label="Link" type="url" />
            </Stack>
            <Stack direction="row" spacing={3}>
              <RHFTextField
                name="is_active"
                label="Status"
                type="checkbox"
                select
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </RHFTextField>

              {
              <RHFTextField name="language" label="Language" select>
                <MenuItem value="en">English</MenuItem>
                <MenuItem value="hi">Hindi</MenuItem>
                </RHFTextField>
              }
            </Stack>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
              sx={{
                bgcolor: theme.palette.primary.mainTheme,
                marginTop: "5%",
                "&:hover": { background: theme.palette.primary.mainTheme },
              }}
            >
              Add
            </LoadingButton>
          </Stack>
        </FormProvider>
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Stack>
    </>
  );
}
