import React, { useEffect, useState } from "react";
import StoreProductsTable from "./StoreProductTable";
import getAccess from "../../../utils/getAccess";

const StoreProduct = () => {
  const access = getAccess();
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchVideos() {
      const response = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/store/getAllStoreProduct`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await response?.json();
      // console.log(responseJson?.data);
      setProducts(responseJson?.data);
      setLoading(false);
    }
    fetchVideos();
  }, []);
  const fetchProduct = async () => {
    const token = localStorage.getItem("token");
    const response = await fetch(
      `https://api-prod.gyaankulam.com/api/v1/store/getAllStoreProduct`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const responseJson = await response?.json();
    // console.log(responseJson?.data);
    setProducts(responseJson?.data);
    setLoading(false);
  };

  return (
    <div>
      {/* <h3>All Products</h3> */}
      {!loading && (
        <StoreProductsTable data={products} fetchProduct={fetchProduct} />
      )}
    </div>
  );
};

export default StoreProduct;
