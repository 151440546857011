import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import YouTubeIcon from "@mui/icons-material/YouTube";
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridToolbar,
} from "@mui/x-data-grid";
import { randomId } from "@mui/x-data-grid-generator";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import getAccess from "../../../utils/getAccess";

function EditToolbar(props) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    const id = randomId();
    setRows((oldRows) => [...oldRows, { id, name: "", age: "", isNew: true }]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "name" },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add record
      </Button>
    </GridToolbarContainer>
  );
}

export default function YoutubeTable1() {
  const access = getAccess();
  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const navigate = useNavigate();
  const handleEditClick = (id) => () => {
    navigate(`/dashboard/updateStoreBanner/${id}`);
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };
  const editDataInDb = async (newRow) => {
    const token = localStorage.getItem("token");
    const authToken = token;
    const formData = new FormData();
    formData.append("title", newRow.title);
    formData.append("link", newRow.link);
    formData.append("linkWith", newRow.linkWith?.id);
    // formData.append('parentCategory', newRow.parentCategory)
    // formData.append('parentCategory', newRow.parentCategory)
    // console.log(newRow)
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${authToken}`,
      },
    };
    const res = await axios.put(
      `https://api-prod.gyaankulam.com/api/v1/store/updateStoreBanner/${newRow?.id}`,
      formData,
      config
    );
    // console.log(res)
    // setLoading(false)
    toast.success("Updated");
    // setTimeout(() => window.location.reload()
    //   , 800)
  };

  const handleDeleteClick = (id) => async () => {
    // console.log(id);
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this Video?"
    );
    if (confirmDelete) {
      const token = localStorage.getItem("token");
      const res = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/DeleteYouTubeDetails/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // setLoadingButton(false)
      const resJson = await res.json();
      if (resJson?.status) {
        toast.success(resJson?.msg);
        setRows(rows?.filter((row) => row.id !== id));
      } else {
        toast.error(resJson?.msg);
      }
      // toast.success(resJson);
    }
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows?.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows?.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows?.map((row) => (row.id === newRow.id ? updatedRow : row)));
    editDataInDb(newRow);
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  const rowsWithSerialNumber = rows?.map((row, index) => ({
    ...row,
    serialNumber: index + 1,
  }));
  React.useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel//getAllYoutubeVideo`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      setRows(responseJson?.data);
    }

    fetchUsers();
  }, []);

  const columns = [
    {
      field: "serialNumber",
      headerName: "S NO",
      width: 75,
      align: "left",
      headerAlign: "left",
      editable: false,
      renderCell: (params) => <strong>{params.value}</strong>,
    },

    {
      field: "title",
      headerName: "Title",
      type: "string",
      width: 250,
      align: "left",
      headerAlign: "left",
      // editable: true
    },
    {
      field: "video_url",
      headerName: "Video",
      type: "string",
      width: 100,
      align: "left",
      headerAlign: "left",
      // editable: true,
      renderCell: (params) => (
        <a
          href={params.value}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <YouTubeIcon
            fontSize="large"
            style={{ color: "red", marginRight: "8px" }}
          />
        </a>
      ),
    },

    {
      field: "link",
      headerName: "Link",
      type: "number",
      width: 150,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "linkWith",
      headerName: "Link With",
      type: "object",
      width: 250,
      align: "left",
      headerAlign: "left",
      // editable: true,
      renderCell: (params) => (
        <div>{params.value?.name ? params.value?.name : "NA"}</div>
      ),
    },
    {
      field: "language",
      headerName: "Language",
      type: "string",
      width: 150,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => (
        <div>{params.value === "hi" ? "Hindi" : "English"}</div>
      ),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      type: "number",
      width: 150,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "isActive",
      headerName: "Status",
      type: "boolean",
      width: 100,
      align: "left",
      headerAlign: "left",
      editable: true,
      renderCell: (params) => (
        <div style={{ color: params.value == true ? "green" : "red" }}>
          {params.value ? "Active" : "inActive"}
        </div>
      ),
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 150,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          (access?.accessForTab == "readWrite" &&
            access?.access.includes("104")) ||
          access?.access.includes("all") ? (
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={() => navigate(`/dashboard/updateYoutubeVideo/${id}`)}
              color="inherit"
            />
          ) : (
            <></>
          ),
          (access?.deleteAccess && access?.access.includes("104")) ||
          access?.access.includes("all") ? (
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="inherit"
            />
          ) : (
            <></>
          ),
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        height: 590,
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <DataGrid
        // rowHeight={50}
        rows={rowsWithSerialNumber || []}
        columns={columns || []}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 7,
            },
          },
        }}
        pageSizeOptions={[7, 25, 50]}
        slots={{
          //   toolbar: EditToolbar,
          toolbar: GridToolbar,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel, showQuickFilter: true },
        }}
      />
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Box>
  );
}
