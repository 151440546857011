import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AddLectureResources from './AddLectureResources';
import { Divider, Stack } from '@mui/material';
import LectureResourcesTable1 from './LectureResourceTable1';
import SyncLoader from "react-spinners/SyncLoader";
import { useTheme } from '@emotion/react';
import getAccess from '../../../utils/getAccess';



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const LectureResources = () => {

    const access = getAccess();
    const theme = useTheme()
    const [value, setValue] = React.useState(0);
    const [loading, setLoading] = React.useState(false);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // //console.log(loading)
    return (<>
        {loading ? (<Stack justifyContent='center' alignItems='center' sx={{ height: '100%' }}> <SyncLoader color={theme.palette.primary.mainTheme} loading={loading} size={20} /></Stack>) : (<Box sx={{ width: '100%' }}>
            <Typography variant='h3' mb={3} >Manage Lecture Resources</Typography>
            <Divider />
            <Box sx={{ borderBottom: 1, borderColor: 'divider', }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" sx={{ width: '100%', }}>
                    <Tab label="All Resourcess" sx={{ width: '100%' }} {...a11yProps(0)} />
                    { (access?.accessForTab?.includes('readWrite') || access?.access?.includes('all')) && <Tab label="Add Resource" sx={{ width: '100%' }} {...a11yProps(1)} />}
                    {/* <Tab label="Item Three" sx={{ width: '100%' }} {...a11yProps(2)} /> */}
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>

                {/* <LectureResourcesTable setLoadingTable={setLoading} />
                 */}
                 <LectureResourcesTable1 setLoadingTable={setLoading} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                { (access?.accessForTab?.includes('readWrite') || access?.access?.includes('all')) && <AddLectureResources />}
            </TabPanel>

        </Box>)}</>
    );
}

export default LectureResources