import { Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SyncLoader from "react-spinners/SyncLoader";
import { useTheme } from "@emotion/react";

import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  GridToolbarDensitySelector,
  useGridApiContext,
  gridExpandedSortedRowIdsSelector,
} from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import { Modal, Box } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import EventSeatIcon from "@mui/icons-material/EventSeat";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { VisibilityOutlined } from "@mui/icons-material";
import AssignStudent from "./AssignStudent";
import getAccess from "../../../utils/getAccess";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "20px",
  p: 4,
};

function CustomToolbar({ rows, columns, ...gridProps }) {
  const { setQuickFilter, ...otherProps } = gridProps;
  const apiRef = useGridApiContext();
  const exportAllRows = async () => {
    const visibleRows = gridExpandedSortedRowIdsSelector(apiRef);
    const filteredRows = rows?.filter((row) => visibleRows?.includes(row.id));
    const columnIds = columns
      ?.filter((column) => column.field !== "actions")
      ?.map((column) => column.field);
    const csvHeader = columnIds.join(", ");
    const csvContent = filteredRows
      ?.map((row, index) => {
        const rowValues = columnIds?.map((columnId) => {
          if (columnId === "student") {
            return row.student?.length;
          } else if (columnId === "serialNumber") {
            return index + 1;
          }
          return row[columnId]?.toString()?.replaceAll(",", " ");
        });
        return rowValues.join(", ");
      })
      .join("\n");
    const fullCsvContent = `${csvHeader}\n${csvContent}`;
    const blob = new Blob([fullCsvContent], { type: `text/csv;charset=utf-8` });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", "PurchasedCourses.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />

      <GridToolbarDensitySelector />
      <Tooltip title="Export All">
        <IconButton onClick={exportAllRows}>
          <SaveAltIcon style={{ color: "red" }} />
        </IconButton>
      </Tooltip>
      <GridToolbarQuickFilter style={{ marginLeft: "auto" }} />
    </GridToolbarContainer>
  );
}

const StudentBatches1 = () => {
  const theme = useTheme();
  const d = getAccess();

  const navigate = useNavigate();
  const [batchInfo, setBatchInfo] = useState();
  const [loading, setLoading] = React.useState(true);

  //  table thind
  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows?.find((row) => row.id === id);
    if (editedRow?.isNew) {
      setRows(rows?.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows?.map((row) => (row.id === newRow.id ? updatedRow : row)));
    //   editDataInDb(newRow)
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const rowsWithSerialNumber = rows?.map((row, index) => ({
    ...row,
    serialNumber: index + 1,
  }));
  const [open, setOpen] = useState(false);
  const [close, setClose] = useState(false);
  const [batchId, setBatchId] = useState("");
  const [count, setCount] = useState("");

  const columns = [
    {
      field: "serialNumber",
      headerName: "S NO",
      width: 75,
      align: "left",
      headerAlign: "left",
      editable: false,
      renderCell: (params) => <strong>{params.value}</strong>,
    },

    {
      field: "batch_name",
      headerName: "Title",
      type: "string",
      width: 350,
      align: "left",
      headerAlign: "left",
      editable: true,
    },
    {
      field: "starting_date",
      headerName: "Start Date",
      type: "string",
      width: 200,
      align: "left",
      headerAlign: "left",
      // editable: true,
    },
    {
      field: "ending_date",
      headerName: "Ending Date",
      type: "string",
      width: 150,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "student",
      headerName: "Students",
      type: "object",
      width: 100,
      align: "left",
      headerAlign: "left",
      // editable: true,
      renderCell: (params) => (
        <div>{params.value ? [...new Set(params.value)]?.length : 0}</div>
      ),
    },

    {
      field: "is_active",
      headerName: "Status",
      type: "boolean",
      width: 100,
      align: "left",
      headerAlign: "left",
      editable: true,
      renderCell: (params) => (
        <div style={{ color: params.value == true ? "green" : "red" }}>
          {params.value ? "Active" : "inActive"}
        </div>
      ),
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 200,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        const rowData = rows?.find((item) => item?.id === id);
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          d.accessForTab == "read" ||
          d?.access.includes("all") ||
          d?.accessForTab == "readWrite" ? (
            <Tooltip title="View">
              <GridActionsCellItem
                icon={<VisibilityOutlined />}
                label="Edit"
                className="textPrimary"
                // onClick={handleEditClick(id)}
                onClick={() =>
                  navigate(
                    `/dashboard/studentBatches/studentList/${
                      rowData.is_active ? "1" : "0"
                    }/${id}`
                  )
                }
                color="inherit"
              />
            </Tooltip>
          ) : (
            <></>
          ),
          d?.accessForTab == "readWrite" ||
          (d?.access.includes("all") && rowData?.is_active) ? (
            <Tooltip title="Assign Student">
              <GridActionsCellItem
                icon={<EventSeatIcon style={{ color: "red" }} />}
                label="Edit"
                className="textPrimary"
                // onClick={handleEditClick(id)}
                onClick={() => {
                  setBatchId(id);
                  setOpen(!open);
                }}
                color="inherit"
              />
            </Tooltip>
          ) : (
            <></>
          ),
        ];
      },
    },
  ];

  //BatchesDetails
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/getBatches`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      //console.log(responseJson?.data);
      setBatchInfo(responseJson?.data);
      setRows(responseJson?.data);
      setLoading(false);
    }
    fetchUsers();
  }, []);
  //console.log(batchInfo)
  return (
    <>
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          {" "}
          <SyncLoader
            color={theme.palette.primary.mainTheme}
            loading={loading}
            size={20}
          />
        </Stack>
      ) : (
        <>
          <Typography variant="h3" mb={5} ml={3}>
            Courses Purchases
          </Typography>
          <DataGrid
            autoHeight
            getRowHeight={() => "auto"}
            rows={rowsWithSerialNumber || []}
            loading={loading}
            columns={columns || []}
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            processRowUpdate={processRowUpdate}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10, 50, 100]}
            slots={{
              toolbar: (gridProps) => (
                <CustomToolbar
                  {...gridProps}
                  rows={rows}
                  columns={columns || []}
                />
              ),
            }}
            slotProps={{
              toolbar: { setRows, setRowModesModel, showQuickFilter: true },
            }}
          />
          <ToastContainer
            position="bottom-center"
            autoClose={1000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />{" "}
          <div>
            <Modal
              open={open}
              onClose={close}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <AssignStudent
                  batchId={batchId}
                  count={count}
                  setOpen={setOpen}
                />
              </Box>
            </Modal>
          </div>
        </>
      )}
    </>
  );
};

export default StudentBatches1;
