import React, { useEffect } from "react";
import * as Yup from "yup";
import { useState } from "react";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Stack,
  Typography,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  Button,
  IconButton,
  Box,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { FormProvider, RHFTextField } from "../../../components/hook-form";

import { useTheme } from "@mui/material/styles";

import { useNavigate } from "react-router-dom";
import axios from "axios";

import Page from "../../../components/Page";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TagsInput } from "react-tag-input-component";
import CircularProgress from "@mui/material/CircularProgress";
import "./style.css";

import RHFTextEditor from "../../../components/hook-form/RHFTextEditor";
import { convertYoutubeUrlToEmbed } from "../../../utils/convertYoutubeUrlToEmbed";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, teacher, theme) {
  return {
    fontWeight:
      teacher?.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const AddBatch = () => {
  // const [users, usersSet] = useState([]);
  const [subject, setSubject] = useState([]);
  const [allteachers, teacherSet] = useState([]);
  const [teacher, setteacher] = useState([]);
  const [subjectList, setsubjectList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [streamCategory, setStreamCategory] = useState([]);
  const navigate = useNavigate();
  const [type, setType] = useState(true);
  const [selected, setSelected] = useState([]);
  const [uploadSize, setUploadSize] = useState({
    completed: 0,
    totalSize: 0,
  });
  const [showProgress, setShowProgress] = useState(false);
  const [progress, setProgress] = React.useState(0);
  const [submitted, setSubmitted] = React.useState(false);
  const [isEmi, setIsEmi] = React.useState(false);
  const [emiOptions, setEmiOptions] = React.useState([]);
  const theme = useTheme();
  const LoginSchema = Yup.object().shape({
    batch_name: Yup.string().required("Name is required"),
    starting_date: Yup.string().required("Starting Date is required"),
    ending_date: Yup.string().required("Ending Date is required"),
    mode: Yup.string().required("mode is required"),
    // metaTitle: Yup.string().required("metaTitle is required"),
    materials: Yup.string().required("materials is required"),
    language: Yup.string().required("language is required"),
    stream: Yup.string().required("Stream is required"),
    bannerfileType: Yup.string().required("Banner File Type is required"),
    demoVideoType: Yup.string().required("Demo video Type is required"),
    //validity: Yup.string().required("validity is required"),
    is_active: Yup.boolean().required("Status Required is required"),
    isPaid: Yup.boolean().required("Batch Type  is required"),
    charges: Yup.number().when("isPaid", {
      is: true,
      then: Yup.number()
        .positive("Charges always postive")
        .required("charges is required"),
      otherwise: Yup.number(),
    }),
    discount: Yup.number().when("isPaid", {
      is: true,
      then: Yup.number()
        .positive("Sale Price always postive")
        .required("Sale Price is required"),
      otherwise: Yup.number(),
    }),
    isEmi: Yup.boolean().when("isPaid", {
      is: true,
      then: Yup.boolean().required("EMI is required"),
      otherwise: Yup.boolean().notRequired(),
    }),
    emiOptions: Yup.boolean().when("isEmi", {
      is: true,
      then: Yup.boolean().required("EMI Options is required"),
      otherwise: Yup.boolean().notRequired(),
    }),
  });

  const defaultValues = {
    batch_name: "",
    stream: "",
    starting_date: "",
    ending_date: "",
    mode: "",
    materials: "",
    description: "",
    metaDesc: "",
    metaTitle: "",
    language: "enhi",
    remark: "",
    validity: "",
    remember: true,
    isEmi: false,
    emiOptions: ["1"],
    is_active: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;
  const [file, setFile] = useState();
  const [demoVideo, setDemoVideo] = useState();
  const [msg, setMsg] = useState("");

  const changeHandler = async (event) => {
    let size = event.target?.files[0].size / 1024;
    console.log(size);
    if (size <= 100) {
      setFile(event.target?.files[0]);
    } else {
      setVal(true);
      setFile();
    }
  };
  const changeHandlerTwo = async (event) => {
    if (!type) {
      setDemoVideo(event.target?.files);
    } else {
      setDemoVideo(event.target?.value);
    }
  };

  //Add Details'

  const onSubmit = async (data) => {
    let cond = false;
    // console.log(file.size);

    if (data?.demoVideoType == "link") {
      if (selected?.length !== 0) {
        cond = true;
      }
    } else {
      if (demoVideo?.length) {
        cond = true;
      }
    }

    if (
      file?.size &&
      teacher?.length !== 0 &&
      subjectList?.length !== 0 &&
      cond
    ) {
      setShowProgress(true);
      setLoading(true);

      const formData = new FormData();
      formData.append("file", file);
      if (type) {
        formData.append("demoVideoType", data?.demoVideoType);
        selected?.map((item, index) =>
          formData.append("demoVideoLink", convertYoutubeUrlToEmbed(item))
        );
      } else if (!type) {
        formData.append("demoVideoType", data?.demoVideoType);

        Object.values(demoVideo).forEach((demoVideo) => {
          formData.append("demoVideo", demoVideo);
        });
      }
      formData.append("file1", file1);
      formData.append("materials", data?.materials);
      formData.append("bannerfileType", data?.bannerfileType);
      // teacher?.map()
      teacher?.map((item) => formData.append("teacher", item));
      subjectList?.map((item) => formData.append("subject", item));
      // formData.append('teacher', teacher);
      formData.append("batch_name", data?.batch_name);
      // formData.append('category', 'Batch');
      formData.append("exam_type", "Prelims");
      formData.append("stream", data?.stream);
      // formData.append('subject', data?.subject);
      formData.append("starting_date", data?.starting_date);
      formData.append("ending_date", data?.ending_date);
      formData.append("mode", data?.mode);
      if (isPaid === true) {
        formData.append("isPaid", isPaid);
        formData.append("discount", data?.discount);
        formData.append("charges", data?.charges);
        formData.append("isEmi", data?.isEmi);
        // formData.append('emiOptions' , data?.emiOptions)
        formData.append("emiOptions", "1");
        // console.log(emiOptions);
        // debugger;
        emiOptions?.map((item) => formData.append("emiOptions", item));
      } else {
        formData.append("isPaid", isPaid);
        formData.append("discount", "0");
        formData.append("charges", "0");
        formData.append("isEmi", false);
        // formData.append('emiOptions' , ['1'])
      }

      formData.append("description", data?.description);
      formData.append("metaDesc", data?.metaDesc);
      formData.append("metaTitle", data?.metaTitle);
      formData.append("language", data?.language);
      formData.append("remark", data?.remark);
      formData.append("validity", data?.validity);
      formData.append("course_review", "Review");
      formData.append("is_active", data?.is_active);

      let token = localStorage.getItem("token");

      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);
          //console.log(`${loaded / 1000000}kb of ${total / 1000000}kb | ${percent}%`);
          setUploadSize((prev) => ({
            completed: Math.floor(loaded / 1000000),
            totalSize: Math.floor(total / 1000000),
          }));
          // setInterval(() => {
          //     if (percent < 90) {
          //         setProgress(prev => prev + 10)
          //     }
          // }, 1200)
          if (percent < 90) {
            setProgress(percent);
          }
        },
        maxBodyLength: Infinity,
        maxContentLength: Infinity,
      };

      axios
        .post(
          `https://api-prod.gyaankulam.com/api/v1/adminPanel/addBatchesDetails`,
          formData,
          config
        )
        .then((response) => {
          if (response?.data?.msg === "added Batches details successfully") {
            setProgress(100);
            toast.success("Batch Details Added");
            setShowBanner(false);
            setShowEndingDate(false);
            setShowStartingDate(false);
            setSubmitted(true);
            setTimeout(() => {
              setLoading(false);
              navigate("/dashboard/batches");
            }, 1000);
            reset();
          } else {
            setProgress(100);
            setLoading(false);
            setSubmitted(false);
            setShowProgress(false);
            toast.error(response?.data?.msg);
          }
        })
        .catch((e) => {
          //console.log(e);
        });
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminTeacher/teacherList`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      teacherSet(responseJson?.data);
      setLoading(false);
    }

    fetchUsers();
  }, []);

  //Getting category
  useEffect(() => {
    const token = localStorage.getItem("token");
    const batch = "Batch";
    async function fetchUsers() {
      setLoading(true); // Set loading to true when starting the fetch

      // Fetch all subjects
      const res = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/get-select-subject`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const resSubject = await res.json();

      // Filter active subjects
      const activeSubjects = resSubject.data.filter(
        (subject) => subject.is_active === "true"
      );
      setSubject(activeSubjects);

      // Fetch stream categories
      const fullResponseCategory = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/getCategory?type=Stream`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJsonCatergory = await fullResponseCategory.json();
      setStreamCategory(responseJsonCatergory?.data);

      setLoading(false); // Set loading to false after fetching
    }

    fetchUsers();
  }, []);

  // //console.log(streamCategory)

  const [focus, setFocused] = React.useState(false);
  const [focusTwo, setFocusedTwo] = React.useState(false);
  const [focusThree, setFocusedThree] = React.useState(false);
  const [focusFour, setFocusedFour] = React.useState(false);
  const [showStartingDate, setShowStartingDate] = React.useState(false);
  const [showEndingDate, setShowEndingDate] = React.useState(false);
  const [showBanner, setShowBanner] = React.useState(false);
  const [showDemoVideo, setShowDemoVideo] = React.useState(false);
  const [isPaid, setIsPaid] = React.useState(false);

  const [focusFive, setFocusedFive] = React.useState(false);
  const [planner, setPlanner] = React.useState(false);
  const [file1, setFile1] = useState();
  const changeHandler1 = async (event) => {
    setFile1(event.target?.files[0]);
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setteacher(typeof value === "string" ? value?.split(",") : value);
  };
  const handleChangeSubject = (event) => {
    const {
      target: { value },
    } = event;
    setsubjectList(
      // On autofill we get a stringified value?.
      typeof value === "string" ? value?.split(",") : value
    );
  };

  const handleChangeEmiOptions = (event) => {
    const {
      target: { value },
    } = event;
    setEmiOptions(
      // On autofill we get a stringified value?.
      typeof value === "string" ? value?.split(",") : value
    );
  };

  const [val, setVal] = useState(false);
  const [bannerType, setBannerType] = useState(false);
  const optionList = [
    // {
    //     id : 1 ,
    //     title : 'One Time'
    // },
    {
      id: 2,
      title: "Two Months",
    },
    {
      id: 3,
      title: "Three Months",
    },
    {
      id: 6,
      title: "Six Months",
    },
  ];

  return (
    <>
      <Page title="Batch">
        <Stack spacing={3} ml={5}>
          <Typography className="page-heading">Add Batch </Typography>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3} sx={{ width: "80%" }}>
              <Stack direction="row" spacing={2}>
                <RHFTextField
                  name="isPaid"
                  label="Batch Type"
                  sx={{ width: isPaid ? "50%" : "100%" }}
                  select
                  type="checkbox"
                >
                  <MenuItem value={true} onClick={() => setIsPaid(true)}>
                    Paid
                  </MenuItem>
                  <MenuItem value={false} onClick={() => setIsPaid(false)}>
                    Free
                  </MenuItem>
                </RHFTextField>
                {isPaid && (
                  <RHFTextField
                    name="isEmi"
                    label="EMI"
                    sx={{ width: "50%" }}
                    select
                    type="checkbox"
                  >
                    <MenuItem value={true} onClick={() => setIsEmi(true)}>
                      Avaible
                    </MenuItem>
                    <MenuItem value={false} onClick={() => setIsEmi(false)}>
                      Not Avaible
                    </MenuItem>
                  </RHFTextField>
                )}
              </Stack>

              {isEmi && (
                <Stack direction="row" spacing={2}>
                  <InputLabel
                    sx={{
                      width: "20%",
                      marginRight: "20px",
                      alignSelf: "center",
                    }}
                  >
                    EMI Options
                  </InputLabel>
                  <Stack direction="column" sx={{ width: "100%" }}>
                    <Select
                      label=""
                      error={emiOptions.length == 0 && val}
                      multiple
                      name="emiOptions"
                      value={emiOptions}
                      onChange={handleChangeEmiOptions}
                      sx={{ width: "100%", color: "black", zIndex: 9 }}
                      MenuProps={MenuProps}
                    >
                      {optionList?.map((name) => (
                        <MenuItem
                          key={name}
                          value={name.id}
                          style={getStyles(name, subject, theme)}
                        >
                          {name.title}
                        </MenuItem>
                      ))}
                    </Select>
                    {/* {subjectList?.length == 0 && <Typography sx={{ fontSize: '12px', marginTop: '5px', marginLeft: '10px', color: 'red' }}>{msg}</Typography>
                                } */}
                  </Stack>
                </Stack>
              )}

              <RHFTextField name="batch_name" label="Batch Name" />
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ my: 2 }}
                spacing={2}
              >
                <RHFTextField name="stream" label="Stream" select>
                  {streamCategory?.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item?.title}>
                        {item?.title}
                      </MenuItem>
                    );
                  })}
                </RHFTextField>
              </Stack>

              <Stack direction="row">
                <InputLabel
                  sx={{
                    width: "20%",
                    marginRight: "20px",
                    alignSelf: "center",
                  }}
                >
                  Assign Subjects
                </InputLabel>
                <Stack direction="column" sx={{ width: "100%" }}>
                  <Select
                    label=""
                    error={subjectList?.length == 0 && val}
                    multiple
                    name="subject"
                    value={subjectList}
                    onChange={handleChangeSubject}
                    sx={{ width: "100%", color: "black", zIndex: 9 }}
                    MenuProps={MenuProps}
                  >
                    {subject?.map((name) => (
                      <MenuItem
                        key={name}
                        value={name._id}
                        style={getStyles(name, subject, theme)}
                      >
                        {name.title}
                      </MenuItem>
                    ))}
                  </Select>
                  {subjectList?.length == 0 && (
                    <Typography
                      sx={{
                        fontSize: "12px",
                        marginTop: "5px",
                        marginLeft: "10px",
                        color: "red",
                      }}
                    >
                      {msg}
                    </Typography>
                  )}
                </Stack>
              </Stack>
              <Stack direction="row">
                <InputLabel
                  sx={{
                    width: "20%",
                    marginRight: "20px",
                    alignSelf: "center",
                  }}
                >
                  Assign Teachers
                </InputLabel>
                <Stack direction="column" sx={{ width: "100%" }}>
                  <Select
                    label=""
                    multiple
                    error={teacher?.length == 0 && val}
                    name="teacher"
                    value={teacher}
                    onChange={handleChange}
                    sx={{ width: "100%", color: "black", zIndex: 9 }}
                    MenuProps={MenuProps}
                  >
                    {allteachers?.map((name) => (
                      <MenuItem
                        key={name}
                        value={name._id}
                        style={getStyles(name, teacher, theme)}
                      >
                        {name.FullName}
                      </MenuItem>
                    ))}
                  </Select>
                  {teacher?.length == 0 && (
                    <Typography
                      sx={{
                        fontSize: "12px",
                        marginTop: "5px",
                        marginLeft: "10px",
                        color: "red",
                      }}
                    >
                      {msg}
                    </Typography>
                  )}
                </Stack>
              </Stack>

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ my: 2 }}
                spacing={2}
              >
                <RHFTextField
                  name="starting_date"
                  onFocus={() => setFocused(true)}
                  onBlur={() => setFocused(false)}
                  InputProps={{
                    classes: {
                      input: "CustomTextField",
                    },
                  }}
                  label="Registration Start Date"
                  onClick={() => setShowStartingDate(true)}
                  type={showStartingDate || focus ? "date" : "text"}
                />
                <RHFTextField
                  name="ending_date"
                  onFocus={() => setFocusedTwo(true)}
                  onBlur={() => setFocusedTwo(false)}
                  InputProps={{
                    classes: {
                      input: "CustomTextField",
                    },
                  }}
                  label="Registration End Date"
                  onClick={() => setShowEndingDate(true)}
                  type={showEndingDate || focusTwo ? "date" : "text"}
                />
                <RHFTextField
                  name="mode"
                  label="Mode"
                  sx={{ width: "100%" }}
                  select
                >
                  <MenuItem value="online">Online</MenuItem>
                  <MenuItem value="offline">Offline</MenuItem>
                </RHFTextField>
                <RHFTextField
                  name="materials"
                  label="Materials"
                  sx={{ width: "100%" }}
                  select
                >
                  <MenuItem value="live">Live</MenuItem>
                  <MenuItem value="recorded">Recorded</MenuItem>
                  <MenuItem value="both">Both</MenuItem>
                </RHFTextField>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ my: 2 }}
                spacing={2}
              >
                {isPaid ? (
                  <>
                    <Stack direction="row" sx={{ width: "50%" }}>
                      <InputLabel
                        sx={{
                          width: "50%",
                          marginRight: "5px",
                          alignSelf: "center",
                        }}
                      >
                        Regular Price :{" "}
                      </InputLabel>
                      <RHFTextField
                        name="charges"
                        type="number"
                        id="custom-css-outlined-input"
                      />
                    </Stack>
                    <Stack direction="row" sx={{ width: "50%" }}>
                      <InputLabel
                        sx={{
                          width: "50%",
                          marginRight: "5px",
                          alignSelf: "center",
                        }}
                      >
                        Sale Price :{" "}
                      </InputLabel>
                      <RHFTextField name="discount" type="number" />
                    </Stack>
                  </>
                ) : (
                  <>
                    <Stack direction="row" sx={{ width: "50%" }}>
                      <InputLabel
                        sx={{
                          width: "50%",
                          marginRight: "5px",
                          alignSelf: "center",
                        }}
                      >
                        Regular Price :{" "}
                      </InputLabel>
                      <RHFTextField name="charges" value={0} disabled />
                    </Stack>
                    <Stack direction="row" sx={{ width: "50%" }}>
                      <InputLabel
                        sx={{
                          width: "50%",
                          marginRight: "5px",
                          alignSelf: "center",
                        }}
                      >
                        Discount Price :{" "}
                      </InputLabel>
                      <RHFTextField name="discount" value={0} disabled />
                    </Stack>
                  </>
                )}
              </Stack>
              <Stack direction="row" justifyContent="space-between" spacing={2}>
                <RHFTextField name="bannerfileType" label="Banner type" select>
                  <MenuItem value="image" onClick={() => setBannerType(false)}>
                    Image
                  </MenuItem>
                  <MenuItem value="video" onClick={() => setBannerType(true)}>
                    Video
                  </MenuItem>
                </RHFTextField>

                <RHFTextField
                  name="file"
                  error={!file && val}
                  onChange={changeHandler}
                  onFocus={() => setFocusedThree(true)}
                  onBlur={() => setFocusedThree(false)}
                  inputProps={{ accept: bannerType ? "video/*" : "image/*" }}
                  helperText={
                    bannerType
                      ? "Video Files"
                      : "Image Only(Maximum allowed size 100KB)"
                  }
                  InputProps={{
                    classes: {
                      input: "CustomTextField",
                    },
                  }}
                  label="Banner"
                  onClick={() => setShowBanner(true)}
                  type={showBanner || focusThree ? "file" : "text"}
                />
                {!file && (
                  <Typography
                    sx={{
                      fontSize: "12px",
                      marginTop: "5px",
                      marginLeft: "10px",
                      color: "red",
                    }}
                  >
                    {msg}
                  </Typography>
                )}
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ my: 2 }}
                spacing={2}
              >
                <RHFTextField
                  name="demoVideoType"
                  label="Demo Video Type"
                  sx={{ width: "100%" }}
                  select
                >
                  <MenuItem onClick={() => setType(true)} value="link">
                    Youtube Link
                  </MenuItem>
                  <MenuItem onClick={() => setType(false)} value="Video">
                    Video File
                  </MenuItem>
                </RHFTextField>
                {type && (
                  <Stack direction="column" sx={{ width: "100%" }}>
                    <TagsInput
                      value={selected}
                      onChange={setSelected}
                      name="link"
                      placeHolder="Enter Youtube Links"
                      className="rti--input"
                    />
                    {selected?.length == 0 && (
                      <Typography
                        sx={{
                          fontSize: "12px",
                          marginTop: "5px",
                          marginLeft: "10px",
                          color: "red",
                        }}
                      >
                        {msg}
                      </Typography>
                    )}
                  </Stack>
                )}
                {!type && (
                  <Stack direction="column" sx={{ width: "100%" }}>
                    <RHFTextField
                      name="demoVideo"
                      // helperText={type ? "Youtube Link" : "Video File"}
                      error={!demoVideo && val}
                      onChange={changeHandlerTwo}
                      onFocus={() => setFocusedFour(true)}
                      onBlur={() => setFocusedFour(false)}
                      inputProps={{ multiple: true }}
                      InputProps={{
                        classes: {
                          input: "CustomTextField",
                        },
                      }}
                      label={type ? "Youtube Link" : "Video File"}
                      onClick={() => setShowDemoVideo(true)}
                      type={showDemoVideo || focusFour ? "file" : "text"}
                    />
                    {!demoVideo && (
                      <Typography
                        sx={{
                          fontSize: "12px",
                          marginTop: "5px",
                          marginLeft: "10px",
                          color: "red",
                        }}
                      >
                        {msg}
                      </Typography>
                    )}
                  </Stack>
                )}

                {/*
                  <RHFTextField
                    name="language"
                    label="Language"
                    sx={{ width: "100%" }}
                    select
                  >
                    <MenuItem value="en">English</MenuItem>
                    <MenuItem value="hi">Hindi</MenuItem>
                    <MenuItem value="enhi">Bilungual</MenuItem>
                  </RHFTextField>
              */}
                {/*
                  <RHFTextField
                    name="validity"
                    label="Validity(In months)"
                    type="number"
              />*/}
              </Stack>

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ my: 2 }}
                spacing={3}
              >
                <RHFTextField name="remark" label="Remark" />
                <RHFTextField
                  name="is_active"
                  label="Status"
                  sx={{ width: "100%" }}
                  select
                  type="checkbox"
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </RHFTextField>
                <RHFTextField
                  id="planner"
                  name="planner"
                  label="Planner( in Pdf)"
                  onChange={changeHandler1}
                  onFocus={() => setFocusedFive(true)}
                  onBlur={() => setFocusedFive(false)}
                  inputProps={{ accept: "application/pdf" }}
                  //  helperText="Pdf Files Only"
                  InputProps={{
                    classes: {
                      input: "CustomTextField",
                    },
                  }}
                  onClick={() => setPlanner(true)}
                  type={planner || focusFive ? "file" : "text"}
                />
              </Stack>

              {/*
                <Stack direction="row">
                  <InputLabel
                    sx={{
                      width: "20%",
                      marginRight: "20px",
                      alignSelf: "center",
                    }}
                  >
                    Meta Title
                  </InputLabel>
                  <RHFTextField name="metaTitle" label="Meta Title" />
                  </Stack>*/}
              {/*
              <RHFTextEditor name="metaDesc" label="Meta Description" />
                */ }
              <RHFTextEditor name="description" label="Description" />
                
            </Stack>

            <Button
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              sx={{
                bgcolor: theme.palette.primary.mainTheme,
                width: "80%",
                marginTop: "5%",
                "&:hover": { background: theme.palette.primary.mainTheme },
              }}
              onClick={() => {
                if (selected?.length === 0) setMsg("Required");
                setVal(true);
              }}
            >
              {showProgress ? (
                <Typography mr={2} sx={{ textAlign: "center" }}>
                  {submitted
                    ? "Submitted"
                    : progress < 91
                    ? `Uploading File..... ${progress}%`
                    : "Submitting Form"}
                </Typography>
              ) : (
                "Add"
              )}
              {showProgress ? (
                submitted ? (
                  ""
                ) : (
                  <CircularProgress color="secondary" />
                )
              ) : (
                ""
              )}
            </Button>
          </FormProvider>
          <ToastContainer
            position="bottom-center"
            autoClose={1000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </Stack>
      </Page>
    </>
  );
};

export default AddBatch;
