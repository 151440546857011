import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridToolbar,
} from "@mui/x-data-grid";
import { randomId } from "@mui/x-data-grid-generator";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import getAccess from "../../../utils/getAccess";

export default function User1() {
  const access = getAccess();
  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const navigate = useNavigate();
  React.useEffect(() => {
    async function fetchUsers() {
      const token = localStorage.getItem("token");
      const fullResponse = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/getAllBlog`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      setRows(responseJson?.data || []);
    }

    fetchUsers();
  }, []);

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => async () => {
    // console.log(id);
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this Blog?"
    );
    if (confirmDelete) {
      const token = localStorage.getItem("token");
      const res = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/deleteBlog/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // setLoadingButton(false)
      const resJson = await res.json();
      if (resJson?.status) {
        toast.success(resJson?.msg);
        setRows(rows?.filter((row) => row.id !== id));
      } else {
        toast.error(resJson?.msg);
      }
      // toast.success(resJson);
    }
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows?.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows?.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows?.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    {
      field: "sNo",
      headerName: "S NO",
      width: 75,
      align: "left",
      headerAlign: "left",
      editable: false,
      renderCell: (params) => <strong>{params.value}</strong>,
    },

    {
      field: "title",
      headerName: "Title",
      type: "string",
      width: 200,
      align: "left",
      headerAlign: "left",
      editable: true,
    },
    {
      field: "author",
      headerName: "Author",
      type: "string",
      width: 200,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => <div>{params.value?.FullName ?? "NA"}</div>,
    },
    {
      field: "metaTitle",
      headerName: "Meta Title",
      type: "object",
      width: 150,
      align: "left",
      headerAlign: "left",
      // editable: true,
      renderCell: (params) => <div>{params.value ?? "NA"}</div>,
    },
    {
      field: "excerptTitle",
      headerName: "Excerpt Title",
      type: "object",
      width: 150,
      align: "left",
      headerAlign: "left",
      // editable: true,
    },
    {
      field: "category",
      headerName: "Category",
      type: "object",
      width: 150,
      align: "left",
      headerAlign: "left",
      editable: true,
      renderCell: (params) => (
        <div>{params.value ? params.value?.title : "NA"}</div>
      ),
    },
    {
      field: "platform",
      headerName: "Platform",
      type: "object",
      width: 150,
      align: "left",
      headerAlign: "left",
      editable: true,
      renderCell: (params) => <div>{params.value ? params.value : "NA"}</div>,
    },

    {
      field: "tags",
      headerName: "Tags",
      type: "object",
      width: 250,
      align: "left",
      headerAlign: "left",
      editable: true,
      renderCell: (params) => (
        <div style={{ display: "flex" }}>
          {params.value?.map((title, index) => (
            <div key={index}>{title} ,</div>
          ))}
        </div>
      ),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      type: "Date",
      width: 150,
      align: "left",
      headerAlign: "left",
      // editable: true,
    },
    {
      field: "updatedAt",
      headerName: "Updated At",
      type: "Date",
      width: 150,
      align: "left",
      headerAlign: "left",
      // editable: true,
    },
    {
      field: "isActive",
      headerName: "Status",
      type: "boolean",
      width: 100,
      align: "left",
      headerAlign: "left",
      editable: true,
      renderCell: (params) => (
        <div style={{ color: params.value == true ? "green" : "red" }}>
          {params.value ? "Active" : "inActive"}
        </div>
      ),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 150,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          (access?.accessForTab == "readWrite" &&
            access?.access?.includes("121")) ||
          access?.access.includes("all") ? (
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={() => navigate(`/dashboard/updateBlog/${id}`)}
              color="inherit"
            />
          ) : (
            <></>
          ),
          //   onClick={() => navigate(`/dashboard/students/studentsProfile/${id}`)}
          ((access?.accessForTab == "read" ||
            access?.accessForTab == "readWrite") &&
            access?.access?.includes("121")) ||
          access?.access.includes("all") ? (
            <GridActionsCellItem
              icon={<VisibilityOutlinedIcon />}
              label="View"
              // onClick={handleDeleteClick(id)}
              onClick={() => navigate(`/dashboard/blog/${id}`)}
              color="inherit"
            />
          ) : (
            <></>
          ),
          (access?.deleteAccess && access?.access?.includes("121")) ||
          access?.access.includes("all") ? (
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="inherit"
            />
          ) : (
            <></>
          ),
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        height: 500,
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <Box mb={2}>
        <h2>All Blogs</h2>
      </Box>
      <DataGrid
        getRowHeight={() => "auto"}
        autoHeight
        rows={rows}
        columns={columns || []}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 7,
            },
          },
        }}
        pageSizeOptions={[7, 25, 50]}
        slots={{
          //   toolbar: EditToolbar,
          toolbar: GridToolbar,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel, showQuickFilter: true },
        }}
      />
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Box>
  );
}
