import React, { useEffect } from "react";
import * as Yup from "yup";
import { useState } from "react";
// import { useParams } from 'react-router-dom';
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Stack,
  Typography,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  Box,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { FormProvider, RHFTextField } from "../../../components/hook-form";

import { useTheme } from "@mui/material/styles";

import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import Page from "../../../components/Page";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TagsInput } from "react-tag-input-component";
import "./style.css";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import RHFTextEditor from "../../../components/hook-form/RHFTextEditor";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, teacher, theme) {
  return {
    fontWeight:
      teacher?.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const UpdateBatchDetails = () => {
  const [users, usersSet] = useState([]);
  const [subject, setSubject] = useState([]);
  const [allteachers, teacherSet] = useState([]);
  const [loading, setLoading] = useState(false);
  const [subjectList, setsubjectList] = useState([]);
  const [streamCategory, setStreamCategory] = useState([]);
  const [type, setType] = useState(true);
  const [teacher, setteacher] = useState([]);
  const [selected, setSelected] = useState([]);
  const [emiOptions, setEmiOptions] = useState([]);

  const navigate = useNavigate();
  const { batchId } = useParams();
  const theme = useTheme();
  const LoginSchema = Yup.object().shape({});
  // //console.log(subjectList)
  const defaultValues = {
    batch_name: "",
    category: "",
    // exam_type: '',
    subject: "",
    // teacherEmail: '',
    starting_date: "",
    ending_date: "",
    mode: "",
    materials: "",
    discount: "",
    charges: "",
    description: "",
    // banner: '',
    language: "enhi",
    remark: "",
    validity: "",
    // course_review: '',
    remember: true,
    is_active: "",
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;
  const [file, setFile] = useState();
  const [demoVideo, setDemoVideo] = useState([]);

  const changeHandler = async (event) => {
    setFile(event.target?.files[0]);
  };

  const changeHandlerTwo = async (event) => {
    setDemoVideo(event.target?.files);
  };

  //Getting Batch Details
  // let arr = [];
  const [batchDetails, setBatchDetails] = useState();
  useEffect(() => {
    let token = localStorage.getItem("token");

    async function fetchUsers() {
      const fullResponse = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/getBatchesDetails?id=${batchId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();

      setBatchDetails(responseJson?.data[0]);
      checkDetails(responseJson?.data[0]);
    }

    fetchUsers();
  }, []);
  // //console.log(batchDetails)
  // //console.log(batchDetails?.demoVideo)
  const [fileSelected, setFileSelected] = useState([]);

  //multipple Links Update
  // //console.log(subjectList)
  const [value, setValue] = useState();
  const checkDetails = (batchDetails) => {
    if (batchDetails?.demoVideo[0]?.DemoVideofileType == "link") {
      setType(true);
    } else {
      setType(false);
    }
    let arr = [];
    let arr2 = [];
    batchDetails?.subject?.map((i, index) => arr?.push(i?._id));
    batchDetails?.teacher?.map((i, index) => arr2.push(i?._id));
    setsubjectList(arr);
    setteacher(arr2);
    let arr3 = [];
    let arr4 = [];
    batchDetails?.demoVideo?.map((i, indx) => arr3.push(i?.fileLoc));
    batchDetails?.demoVideo?.map((i, indx) => arr4.push(i));
    let emiArray = [];
    batchDetails?.emiOptions?.map((item) => {
      if (item == "2") {
        emiArray.push(2);
      } else if (item == "3") {
        emiArray.push(3);
      } else if (item == "6") {
        emiArray.push(6);
      }
    });
    setEmiOptions(emiArray);
    setIsEmi(batchDetails?.isEmi);
    setSelected(arr3);
    setFileSelected(arr4);
    setValue((prev) => ({
      ...prev,
      batch_name: batchDetails?.batch_name,
      discount: batchDetails?.discount,
      charges: batchDetails?.charges,
      isPaid: batchDetails?.isPaid,
      isEmi: batchDetails?.isEmi,
      description: batchDetails?.description,
      remark: batchDetails?.remark,
      validity: batchDetails?.validity,
      metaTitle: batchDetails?.metaTitle,
      metaDesc: batchDetails?.metaDesc,
    }));
    onChangeDesc(batchDetails?.description);
    onChangeMetaDesc(batchDetails?.metaDesc);
    setIsPaid(batchDetails?.isPaid);
    setStatus(batchDetails?.is_active);
    // batchDetails?.demoVideo?.map((i, indx) => helperArray.push(i))
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminTeacher/teacherList`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      teacherSet(responseJson?.data);
      setLoading(false);
    }

    fetchUsers();
  }, []);

  //Getting category
  React.useEffect(() => {
    const token = localStorage.getItem("token");

    async function fetchUsers() {
      const fullResponse = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/getCategory?type=Batch`,
        {
          method: "GET",

          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      usersSet(responseJson?.data);
      setLoading(false);
      const res = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/get-select-subject`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const resSubject = await res.json();
      // setSubject(resSubject?.data);
      // Filter active subjects
      const activeSubjects = resSubject.data.filter(
        (subject) => subject.is_active === "true"
      );
      setSubject(activeSubjects);
      setLoading(false);
      const fullResponseCategory = await fetch(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/getCategory?type=Stream`,
        {
          method: "GET",

          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJsonCatergory = await fullResponseCategory.json();
      setStreamCategory(responseJsonCatergory?.data);
    }

    fetchUsers();
  }, []);

  // console.log(value);

  const [focus, setFocused] = React.useState(false);
  const [focusTwo, setFocusedTwo] = React.useState(false);
  const [focusThree, setFocusedThree] = React.useState(false);
  const [focusFour, setFocusedFour] = React.useState(false);

  const [showStartingDate, setShowStartingDate] = React.useState(false);
  const [showEndingDate, setShowEndingDate] = React.useState(false);
  const [showBanner, setShowBanner] = React.useState(false);
  const [showDemoVideo, setShowDemoVideo] = React.useState(false);
  const [isPaid, setIsPaid] = React.useState(false);

  const [focusFive, setFocusedFive] = React.useState(false);
  const [file1, setFile1] = React.useState();
  const [planner, setPlanner] = React.useState(false);
  const [isEmi, setIsEmi] = React.useState(false);
  const changeHandler1 = async (event) => {
    setFile1(event.target?.files[0]);
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setteacher(
      // On autofill we get a stringified value?.
      typeof value === "string" ? value?.split(",") : value
    );
  };

  const handleChangeEmiOptions = (event) => {
    const {
      target: { value },
    } = event;
    setEmiOptions(
      // On autofill we get a stringified value?.
      typeof value === "string" ? value?.split(",") : value
    );
  };
  const optionList = [
    // {
    //     id : 1 ,
    //     title : 'One Time'
    // },
    {
      id: 2,
      title: "Two Months",
    },
    {
      id: 3,
      title: "Three Months",
    },
    {
      id: 6,
      title: "Six Months",
    },
  ];

  //delete
  const handleDelete = (id) => {
    setFileSelected([]);

    fileSelected?.map((item, index) => {
      if (item?.fileLoc !== id) {
        setFileSelected((prev) => [...prev, item]);
      }
    });
  };

  const onSubmit = async (data) => {
    let arr = [];
    batchDetails?.demoVideo?.map((item, index) => arr?.push(item?.fileLoc));
    let arr2 = [];
    batchDetails?.demoVideo?.map((item, index) => arr2.push(item?.fileLoc));
    let helperText = [];
    let helperTextForSelected = [];
    setLoading(true);
    const formData = new FormData();
    if (file) {
      formData.append("file", file);
    }
    if (file1) {
      formData.append("file1", file1);
    }
    if (batchDetails?.demoVideo[0]?.DemoVideofileType == "link") {
      if (selected?.length !== 0) {
        arr2?.map((item) => {
          if (selected?.includes(item)) {
            helperText.push(item);
          }
        });
        //console.log(helperText)
        if (helperText.length == 1) {
          formData.append("demoVideoResentLinks", "");
        }
        helperText?.map((item, index) => {
          formData.append("demoVideoResentLinks", item);
        });
        selected?.map((itemOfSelected) => {
          if (!arr?.includes(itemOfSelected)) {
            helperTextForSelected.push(itemOfSelected);
            formData.append("demoVideoLink", itemOfSelected);
          }
        });
        if (helperTextForSelected.length == 1) {
          formData.append("demoVideoLink", "");
        }
      } else {
        batchDetails?.demoVideo?.map((item, index) =>
          formData.append("demoVideoResentLinks", item?.fileLoc)
        );
        formData.append("demoVideoResentLinks", "");
      }
    }
    if (batchDetails?.demoVideo[0]?.DemoVideofileType == "Video") {
      if (fileSelected.length !== 0) {
        fileSelected?.map((item, index) =>
          formData.append("demoVideoResentLinks", item?.fileLoc)
        );

        if (fileSelected.length == 1) {
          formData.append("demoVideoResentLinks", "");
        }
      }
      if (demoVideo?.length) {
        Object.values(demoVideo).forEach((demoVideo) => {
          formData.append("demoVideo", demoVideo);
        });
        if (demoVideo.length == 1) {
          formData.append("demoVideo", "");
        }
      }
    }

    if (data?.bannerfileType) {
      formData.append("bannerfileType", data?.bannerfileType);
    } else {
      formData.append(
        "bannerfileType",
        batchDetails?.banner[0]?.bannerfileType
      );
    }
    if (data?.demoVideoType) {
      formData.append("demoVideoType", data?.demoVideoType);
    } else {
      formData.append(
        "demoVideoType",
        batchDetails?.demoVideo[0]?.DemoVideofileType
      );
    }
    if (data?.materials) {
      formData.append("materials", data?.materials);
    } else {
      formData.append("materials", batchDetails?.materials);
    }
    if (teacher) {
      teacher?.map((item) => formData.append("teacher", item));
    } else {
      batchDetails?.teacher?.map((item) =>
        formData.append("teacher", item?._id)
      );
    }
    if (subjectList) {
      subjectList?.map((item) => formData.append("subject", item));
    } else {
      batchDetails?.subject?.map((item) => formData.append("subject", item));
    }

    if (data?.stream) {
      formData.append("stream", data?.stream);
    } else {
      formData.append("stream", batchDetails?.stream);
    }

    formData.append("exam_type", "Prelims");

    formData.append("batch_name", value?.batch_name);
    formData.append("validity", value?.validity);
    formData.append("remark", value?.remark);
    formData.append("description", desc);
    formData.append("metaTitle", value?.metaTitle);
    formData.append("metaDesc", metaDesc);
    if (isPaid === true) {
      formData.append("isPaid", isPaid);
      formData.append("discount", value?.discount);
      formData.append("charges", value?.charges);
      formData.append("isEmi", value?.isEmi);
      formData.append("emiOptions", "1");
      emiOptions?.map((item) => formData.append("emiOptions", item));
    } else {
      formData.append("isPaid", isPaid);
      formData.append("discount", "0");
      formData.append("charges", "0");
      formData.append("isEmi", false);
    }
    if (data?.starting_date) {
      formData.append("starting_date", data?.starting_date);
    } else {
      formData.append("starting_date", batchDetails?.starting_date);
    }
    if (data?.ending_date) {
      formData.append("ending_date", data?.ending_date);
    } else {
      formData.append("ending_date", batchDetails?.ending_date);
    }
    if (data?.mode) {
      formData.append("mode", data?.mode);
    } else {
      formData.append("mode", batchDetails?.mode);
    }

    if (data?.language) {
      formData.append("language", data?.language);
    } else {
      formData.append("language", batchDetails?.language);
    }

    if (data?.course_review) {
      formData.append("course_review", data?.course_review);
    } else {
      formData.append("course_review", batchDetails?.course_review);
    }
    formData.append("is_active", status);
    let token = localStorage.getItem("token");

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .put(
        `https://api-prod.gyaankulam.com/api/v1/adminPanel/updatedBatchesDetails/${batchId}`,
        formData,
        config
      )
      .then((response) => {
        //console.log(response?.data);
        if (response?.data?.msg === "Updated the Batches details") {
          reset();
          setLoading(false);
          toast.success("Batch Details Updated");
          setTimeout(() => {
            navigate("/dashboard/batches");
            // window.location.reload()
          }, 1000);
        } else {
          toast.error(response?.data?.msg);
          setLoading(false);
        }
      })
      .catch((e) => {
        //console.log(e);
      });
  };

  const handleChangeSubject = (event) => {
    const {
      target: { value },
    } = event;
    setsubjectList(
      // On autofill we get a stringified value?.
      typeof value === "string" ? value?.split(",") : value
    );
  };

  const onChange2 = (e) => {
    const { name, value } = e.target;
    setValue((prev) => ({ ...prev, [name]: value }));
  };

  const onChange3 = (e) => {
    const { name, value } = e;
    setValue((prev) => ({ ...prev, [name]: value }));
  };
  const [desc, setDesc] = useState(value?.description);
  const [metaDesc, setMetaDesc] = useState(value?.metaDesc);
  const [status, setStatus] = useState();
  const onChangeDesc = (value) => {
    // console.log(value);
    setDesc(value);
  };
  const onChangeMetaDesc = (value) => {
    // console.log(value);
    setMetaDesc(value);
  };

  // console.log("emiArray",emiOptions)
  return (
    <>
      <Page title="Batch">
        <Stack spacing={3} ml={5}>
          <Typography className="page-heading">Update Batch </Typography>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3} sx={{ width: "80%" }}>
              <Stack spacing={3} direction="row">
                <Stack direction="row" sx={{ width: isPaid ? "50%" : "100%" }}>
                  <InputLabel
                    sx={{
                      width: "25%",
                      marginRight: "10px",
                      alignSelf: "center",
                    }}
                  >
                    Batch Type :{" "}
                  </InputLabel>
                  <RHFTextField
                    name="isPaid"
                    label={value?.isPaid == true ? "Paid" : "Free"}
                    // value={value?.isPaid}
                    sx={{ width: "100%" }}
                    select
                    type="checkbox"
                  >
                    <MenuItem value={true} onClick={() => setIsPaid(true)}>
                      Paid
                    </MenuItem>
                    <MenuItem
                      value={false}
                      onClick={() => {
                        setIsPaid(false);
                        setIsEmi(false);
                      }}
                    >
                      Free
                    </MenuItem>
                  </RHFTextField>
                </Stack>
                {isPaid && (
                  <Stack direction="row" sx={{ width: "50%" }}>
                    <InputLabel
                      sx={{
                        width: "10%",
                        marginRight: "20px",
                        alignSelf: "center",
                      }}
                    >
                      EMI :{" "}
                    </InputLabel>
                    <RHFTextField
                      name="isEmi"
                      label={
                        value?.isEmi == true ? "Available" : "Not Available"
                      }
                      // value={value?.isPaid}
                      sx={{ width: "100%" }}
                      select
                      type="checkbox"
                      onChange={onChange2}
                    >
                      <MenuItem value={true} onClick={() => setIsEmi(true)}>
                        Available
                      </MenuItem>
                      <MenuItem value={false} onClick={() => setIsEmi(false)}>
                        Not Available
                      </MenuItem>
                    </RHFTextField>
                  </Stack>
                )}
              </Stack>
              {isEmi && (
                <Stack direction="row" spacing={2}>
                  <InputLabel
                    sx={{
                      width: "20%",
                      marginRight: "20px",
                      alignSelf: "center",
                    }}
                  >
                    EMI Options
                  </InputLabel>
                  <Stack direction="column" sx={{ width: "100%" }}>
                    <Select
                      label=""
                      // error={emiOptions.length == 0}
                      multiple
                      name="emiOptions"
                      value={emiOptions}
                      onChange={handleChangeEmiOptions}
                      sx={{ width: "100%", color: "black", zIndex: 9 }}
                      MenuProps={MenuProps}
                    >
                      {optionList?.map((name) => (
                        <MenuItem
                          key={name}
                          value={name.id}
                          style={getStyles(name, emiOptions, theme)}
                        >
                          {name.title}
                        </MenuItem>
                      ))}
                    </Select>
                    {/* {subjectList?.length == 0 && <Typography sx={{ fontSize: '12px', marginTop: '5px', marginLeft: '10px', color: 'red' }}>{msg}</Typography>
                                } */}
                  </Stack>
                </Stack>
              )}

              <Stack direction="row" sx={{ width: "100%" }}>
                <InputLabel
                  sx={{
                    width: "20%",
                    marginRight: "20px",
                    alignSelf: "center",
                  }}
                >
                  Batch Name :{" "}
                </InputLabel>
                <RHFTextField
                  name="batch_name"
                  value={value?.batch_name}
                  onChange={onChange2}
                />
              </Stack>

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ my: 2 }}
                spacing={2}
              >
                <Stack direction="row" sx={{ width: "100%" }}>
                  <InputLabel
                    sx={{
                      width: "20%",
                      marginRight: "20px",
                      alignSelf: "center",
                    }}
                  >
                    Stream :{" "}
                  </InputLabel>
                  <RHFTextField
                    name="stream"
                    label={batchDetails?.stream}
                    select
                  >
                    {streamCategory?.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item?.title}>
                          {item?.title}
                        </MenuItem>
                      );
                    })}
                  </RHFTextField>
                </Stack>
              </Stack>

              <Stack direction="row">
                <InputLabel
                  sx={{
                    width: "20%",
                    marginRight: "20px",
                    alignSelf: "center",
                  }}
                >
                  Assign Subjects
                </InputLabel>
                <Select
                  label=""
                  multiple
                  name="subject"
                  value={subjectList}
                  onChange={handleChangeSubject}
                  sx={{ width: "100%", color: "black", zIndex: 9 }}
                  MenuProps={MenuProps}
                >
                  {subject?.map((name) => (
                    <MenuItem
                      key={name}
                      value={name._id}
                      style={getStyles(name, subject, theme)}
                    >
                      {name.title}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>

              <Stack direction="row">
                <InputLabel
                  sx={{
                    width: "20%",
                    marginRight: "20px",
                    alignSelf: "center",
                  }}
                >
                  Assign Teachers
                </InputLabel>
                <Select
                  label=""
                  multiple
                  name="teacher"
                  value={teacher}
                  onChange={handleChange}
                  sx={{ width: "100%", color: "black", zIndex: 9 }}
                  MenuProps={MenuProps}
                >
                  {allteachers?.map((name, index) => {
                    return (
                      <MenuItem
                        key={name}
                        value={name._id}
                        style={getStyles(name, teacher, theme)}
                      >
                        {name.FullName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Stack>

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ my: 2 }}
                spacing={2}
              >
                <RHFTextField
                  name="mode"
                  label="Mode"
                  sx={{ width: "100%" }}
                  select
                >
                  <MenuItem value="online">Online</MenuItem>
                  <MenuItem value="offline">Offline</MenuItem>
                </RHFTextField>
                <RHFTextField
                  name="materials"
                  label="Materials"
                  sx={{ width: "100%" }}
                  select
                >
                  <MenuItem value="live">Live</MenuItem>
                  <MenuItem value="recorded">Recorded</MenuItem>
                  <MenuItem value="both">Both</MenuItem>
                </RHFTextField>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ my: 2 }}
                spacing={2}
              >
                <Stack direction="row" sx={{ width: "100%" }}>
                  <InputLabel
                    sx={{
                      width: "45%",
                      marginRight: "20px",
                      alignSelf: "center",
                    }}
                  >
                    Starting Date :{" "}
                  </InputLabel>
                  <RHFTextField
                    name="starting_date"
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    InputProps={{
                      classes: {
                        input: "CustomTextField",
                      },
                    }}
                    label={batchDetails?.starting_date}
                    onClick={() => setShowStartingDate(true)}
                    type={showStartingDate || focus ? "date" : "text"}
                  />
                </Stack>
                <Stack direction="row" sx={{ width: "100%" }}>
                  <InputLabel
                    sx={{
                      width: "45%",
                      marginRight: "20px",
                      alignSelf: "center",
                    }}
                  >
                    Ending Date :{" "}
                  </InputLabel>
                  <RHFTextField
                    name="ending_date"
                    onFocus={() => setFocusedTwo(true)}
                    onBlur={() => setFocusedTwo(false)}
                    InputProps={{
                      classes: {
                        input: "CustomTextField",
                      },
                    }}
                    label={batchDetails?.ending_date}
                    onClick={() => setShowEndingDate(true)}
                    type={showEndingDate || focusTwo ? "date" : "text"}
                  />
                </Stack>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ my: 2 }}
                spacing={2}
              >
                {isPaid ? (
                  <>
                    <Stack direction="row" sx={{ width: "50%" }}>
                      <InputLabel
                        sx={{
                          width: "50%",
                          marginRight: "5px",
                          alignSelf: "center",
                        }}
                      >
                        Regular Price :{" "}
                      </InputLabel>
                      <RHFTextField
                        name="charges"
                        value={value?.charges}
                        onChange={onChange2}
                        type="number"
                      />
                    </Stack>
                    <Stack direction="row" sx={{ width: "50%" }}>
                      <InputLabel
                        sx={{
                          width: "50%",
                          marginRight: "5px",
                          alignSelf: "center",
                        }}
                      >
                        Sale Price :{" "}
                      </InputLabel>
                      <RHFTextField
                        name="discount"
                        value={value?.discount}
                        onChange={onChange2}
                        type="number"
                      />
                    </Stack>
                  </>
                ) : (
                  <>
                    <Stack direction="row" sx={{ width: "50%" }}>
                      <InputLabel
                        sx={{
                          width: "50%",
                          marginRight: "5px",
                          alignSelf: "center",
                        }}
                      >
                        Regular Price :{" "}
                      </InputLabel>
                      <RHFTextField name="charges" value={"0"} disabled />
                    </Stack>
                    <Stack direction="row" sx={{ width: "50%" }}>
                      <InputLabel
                        sx={{
                          width: "50%",
                          marginRight: "5px",
                          alignSelf: "center",
                        }}
                      >
                        Discount Price :{" "}
                      </InputLabel>
                      <RHFTextField name="discount" value={"0"} disabled />
                    </Stack>
                  </>
                )}
              </Stack>
              <Stack direction="row" justifyContent="space-between" spacing={2}>
                <RHFTextField
                  name="bannerfileType"
                  label={batchDetails?.banner[0]?.bannerfileType}
                  select
                >
                  <MenuItem value="image">Image</MenuItem>
                  <MenuItem value="video">Video</MenuItem>
                </RHFTextField>
                <RHFTextField
                  name="banner"
                  onChange={changeHandler}
                  onFocus={() => setFocusedThree(true)}
                  onBlur={() => setFocusedThree(false)}
                  helperText={
                    batchDetails?.banner[0]?.bannerfileType == "video"
                      ? "Video Files"
                      : "Image Only(Maximum allowed size 100KB)"
                  }
                  InputProps={{
                    classes: {
                      input: "CustomTextField",
                    },
                  }}
                  label="Banner"
                  onClick={() => setShowBanner(true)}
                  type={showBanner || focusThree ? "file" : "text"}
                />
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ my: 2 }}
                spacing={2}
              >
                <Stack direction="row" sx={{ width: "100%" }}>
                  <InputLabel
                    sx={{
                      width: "45%",
                      marginRight: "20px",
                      alignSelf: "center",
                    }}
                  >
                    Demo Video Type:{" "}
                  </InputLabel>
                  <RHFTextField
                    name="demoVideoType"
                    label={batchDetails?.demoVideo[0]?.DemoVideofileType}
                    sx={{ width: "100%" }}
                    select
                  >
                    <MenuItem onClick={() => setType(true)} value="link">
                      Youtube Link
                    </MenuItem>
                    <MenuItem onClick={() => setType(false)} value="Video">
                      Video File
                    </MenuItem>
                  </RHFTextField>
                </Stack>
                <Stack direction="row" sx={{ width: "100%" }}>
                  <InputLabel
                    sx={{
                      width: "45%",
                      marginRight: "20px",
                      alignSelf: "center",
                    }}
                  >
                    {type ? "Link" : "DemoVideo"}:{" "}
                  </InputLabel>
                  {type && (
                    <TagsInput
                      value={selected}
                      onChange={setSelected}
                      name="link"
                      placeHolder="Enter Youtube Links"
                      className="rti--input"
                    />
                  )}
                  {!type && (
                    <>
                      <RHFTextField
                        name="demoVideo"
                        onChange={changeHandlerTwo}
                        onFocus={() => setFocusedFour(true)}
                        onBlur={() => setFocusedFour(false)}
                        inputProps={{ multiple: true }}
                        InputProps={{
                          classes: {
                            input: "CustomTextField",
                          },
                        }}
                        label="Demo Videos"
                        onClick={() => setShowDemoVideo(true)}
                        type={
                          showDemoVideo || focusFour
                            ? type
                              ? "url"
                              : "file"
                            : "text"
                        }
                      />
                      {fileSelected?.map((item, index) => (
                        <Stack
                          key={index}
                          direction="column"
                          sx={{ width: "100%", position: "relative" }}
                          alignItems="center"
                          spacing={1}
                        >
                          <IconButton
                            sx={{
                              position: "absolute",
                              top: "-10px",
                              right: "-10px",
                            }}
                            onClick={() => handleDelete(item?.fileLoc)}
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>

                          <a
                            href={item?.fileLoc}
                            style={{ textDecoration: "none" }}
                          >
                            <FileCopyIcon fontSize="small" />
                          </a>
                          <Typography sx={{ fontSize: "12px" }}>
                            {item?.fileName}
                          </Typography>
                        </Stack>
                      ))}
                    </>
                  )}
                </Stack>
              </Stack>

              <Stack direction="row" spacing={2}>
                <Stack direction="row" sx={{ width: "100%" }}>
                  <InputLabel
                    sx={{
                      width: "45%",
                      marginRight: "20px",
                      alignSelf: "center",
                    }}
                  >
                    Remark :{" "}
                  </InputLabel>
                  <RHFTextField
                    name="remark"
                    value={value?.remark}
                    onChange={onChange2}
                  />
                </Stack>
                {/*
                  <Stack direction="row" sx={{ width: "100%" }}>
                    <InputLabel
                      sx={{
                        width: "45%",
                        marginRight: "20px",
                        alignSelf: "center",
                      }}
                    >
                      Validity :{" "}
                    </InputLabel>
                    <RHFTextField
                      name="validity"
                      value={value?.validity}
                      onChange={onChange2}
                      type="number"
                    />
                  </Stack>
                  */}
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ my: 2 }}
                spacing={2}
              >  
                {/*
                  <RHFTextField
                    name="language"
                    label={
                      batchDetails?.language == "en"
                        ? "English"
                        : batchDetails?.language == "enhi"
                          ? "Hindi/English"
                          : "Hindi"
                    }
                    sx={{ width: "100%" }}
                    select
                  >
                    <MenuItem value="en">English</MenuItem>
                    <MenuItem value="hi">Hindi</MenuItem>
                    <MenuItem value="enhi">Bilingual</MenuItem>
                  </RHFTextField>
                  */ }
                <RHFTextField
                  name="is_active"
                  label={status ? "Active" : "Inactive"}
                  sx={{ width: "100%" }}
                  select
                  type="checkbox"
                >
                  <MenuItem value={true} onClick={() => setStatus(true)}>
                    Active
                  </MenuItem>
                  <MenuItem value={false} onClick={() => setStatus(false)}>
                    Inactive
                  </MenuItem>
                </RHFTextField>
                <RHFTextField
                  id="planner"
                  name="planner"
                  label="Planner( in Pdf)"
                  onChange={changeHandler1}
                  onFocus={() => setFocusedFive(true)}
                  onBlur={() => setFocusedFive(false)}
                  inputProps={{ accept: "application/pdf" }}
                  //  helperText="Pdf Files Only"
                  InputProps={{
                    classes: {
                      input: "CustomTextField",
                    },
                  }}
                  onClick={() => setPlanner(true)}
                  type={planner || focusFive ? "file" : "text"}
                />
              </Stack>

              {/*
                <Stack direction="row">
                  <InputLabel
                    sx={{
                      width: "20%",
                      marginRight: "20px",
                      alignSelf: "center",
                    }}
                  >
                    Meta Title
                  </InputLabel>
                  <RHFTextField
                    name="metaTitle"
                    value={value?.metaTitle}
                    onChange={onChange2}
                  />
                
                </Stack>
                */}
              {/*
                <RHFTextEditor
                  name="metaDesc"
                  value={metaDesc ? metaDesc : value?.metaDesc}
                  label="Meta Description"
                  onChange={onChangeMetaDesc}
                />
            */}
              
                <Stack direction="column" sx={{ width: "100%" }}>
                  <InputLabel
                    sx={{ width: "15%", marginRight: "20px", alignSelf: "left" }}
                  >
                    Description :{" "}
                  </InputLabel>
                  <RHFTextEditor
                    name="description"
                    value={desc ? desc : value?.description}
                    onChange={onChangeDesc}
                  />
                </Stack>
                
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
              sx={{
                bgcolor: theme.palette.primary.mainTheme,
                width: "80%",
                marginTop: "5%",
                "&:hover": { background: "red" },
              }}
            >
              Update
            </LoadingButton>
          </FormProvider>
          <ToastContainer
            position="bottom-center"
            autoClose={1000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          {/* <Typography color={positive ? 'green' : "red"} sx={{ textAlign: 'center', textTransform: 'capitalize' }}>{fetchMessage}</Typography> */}
        </Stack>
      </Page>
    </>
  );
};

export default UpdateBatchDetails;
