import React from "react";
import { Editor as ClassicEditor } from "ckeditor5-custom-build/build/ckeditor";
// import Editor   from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from "@ckeditor/ckeditor5-react";
const MyCkEditor = ({ dataForDesc, setDataForDesc }) => {
  const token = localStorage.getItem("token");
  const authToken = token;
  const uploadAdapter = (loader) => {
    console.log(loader + "loader");
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("file", file);
            // console.log(file);
            // let headers = new Headers();
            // headers.append("Origin", "http://localhost:3000");
            fetch(
              `https://api-prod.gyaankulam.com/api/v1/adminPanel/uploadImage`,
              {
                method: "post",
                body: body,
                headers: {
                  Authorization: `Bearer ${authToken}`,
                  // 'Content-type': 'multipart/form-data',
                  // Add any other headers if needed
                },
                // mode: "no-cors"
              }
            )
              .then((res) => res.json())
              .then((res) => {
                console.log("102" + res?.data);
                resolve({
                  //   default: `${API_URL}/${res.filename}`
                  default: `${res?.data}`,
                  //  default : `<img src="${res?.data}" alt="Uploaded Image"/>`
                });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      },
    };
  };
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      console.log("inplugin");
      return uploadAdapter(loader);
    };
  }
  return (
    // <div>Editor</div>
    <CKEditor
      // editor={Editor}
      editor={ClassicEditor}
      data={dataForDesc}
      config={{
        extraPlugins: [uploadPlugin],
      }}
      onReady={(editor) => {
        console.log("ready", editor);
      }}
      onChange={(event, editor) => {
        let data = editor.getData();
        setDataForDesc(data);
      }}
      onBlur={(event, editor) => {
        console.log("focus", editor);
      }}
      onFocus={(event, editor) => {
        console.log("blur", editor);
      }}
    />
  );
};

export default MyCkEditor;
